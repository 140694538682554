"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.syncCompanyWithCrewCal = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function syncCompanyWithCrewCal(companyId) {
    try {
        await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!) {
                    syncCompanyWithCrewCal(companyId: $companyId)
                }
            `, {
            companyId,
        });
    }
    catch (error) {
        throw new Error(`syncCompanyWithCrewCal error: ${error}`);
    }
}
exports.syncCompanyWithCrewCal = syncCompanyWithCrewCal;
