"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
require("./jo-input");
const utilities_1 = require("../../services/utilities");
const utilities_2 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-loading-text");
require("./jo-mobile-table");
const test_id_directive_1 = require("../directives/test-id.directive");
const InitialState = {
    areItemsSuppliedByParent: false,
    columns: [],
    companyId: 'NOT_SET',
    csvFileName: `NOT_SET`,
    currentPage: 1,
    customActionTemplate: () => null,
    generatingCSV: false,
    getItems: 'NOT_SET',
    isCompanyUser: false,
    isMobileDevice: false,
    items: [],
    loading: false,
    manuallySuppliedItems: [],
    maxItemsPerPage: 10,
    mobileTemplate: () => null,
    moveActionItemsSelectedOption: '',
    numPages: 1,
    offset: 0,
    searchDebounce: 200,
    searchText: '',
    selectedActionItems: [],
    selectedColumn: 'NOT_SET',
    showCSVButton: false,
    showEndDatePicker: false,
    showLoadingText: false,
    mobileTableHeader: () => null,
    showReloadButton: false,
    showSearchBar: true,
    showStartDatePicker: false,
    showTableTitle: true,
    showPaginationInfo: true,
    sortItems: () => null,
    tableName: '',
    totalItems: 0,
};
class JOTable extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => {
            return (0, lit_html_1.render)(this.render(state), this);
        }, this);
    }
    set getItems(getItems) {
        if (getItems === this.store.getItems) {
            return;
        }
        this.store.getItems = getItems;
        this.store.currentPage = 1;
        this.store.offset = 0;
        this.handleGetAndSetItems();
    }
    set columns(columns) {
        this.store.columns = columns.map((column) => {
            const columnInState = this.store.columns.find((columnInState) => {
                return columnInState.title === column.title;
            });
            return {
                ...column,
                sortDirection: columnInState?.sortDirection || 'DESC',
            };
        });
    }
    set totalItems(totalItems) {
        this.store.totalItems = totalItems;
        this.store.numPages = Math.ceil(totalItems / this.store.maxItemsPerPage);
    }
    set loading(loading) {
        if (loading === true) {
            (async () => {
                this.store.loading = true;
                await (0, utilities_1.wait)(750);
                // if this.store.loading is still true after the wait
                if (this.store.loading === true) {
                    this.store.showLoadingText = true;
                }
            })();
        }
        else {
            this.store.loading = false;
            this.store.showLoadingText = false;
        }
    }
    set manuallySuppliedItems(manuallySuppliedItems) {
        this.store.manuallySuppliedItems = manuallySuppliedItems;
        this.handleGetAndSetItems();
    }
    async handleGetAndSetItems() {
        if (this.store.getItems === 'NOT_SET') {
            if (this.store.manuallySuppliedItems.length > 0) {
                this.store.items = this.store.manuallySuppliedItems.slice(this.store.offset, this.store.offset + this.store.maxItemsPerPage);
                return;
            }
            return;
        }
        this.loading = true;
        await this.searchAndSetItems();
        this.loading = false;
    }
    async searchAndSetItems() {
        if (this.store.getItems === 'NOT_SET') {
            return;
        }
        const getItemsResult = await this.store.getItems(this.store.offset, this.store.maxItemsPerPage, this.store.searchText, this.store.selectedColumn, this.store.items);
        this.store.items = getItemsResult.items;
        this.store.totalItems = getItemsResult.totalItems;
        this.store.numPages = Math.ceil(getItemsResult.totalItems / this.store.maxItemsPerPage);
        (0, utilities_1.raiseCustomEvent)(this, 'itemsfetched', this.store.items);
    }
    newPageNumberClicked(pageIndex) {
        if (pageIndex + 1 === this.store.currentPage) {
            return;
        }
        const newOffset = pageIndex * this.store.maxItemsPerPage;
        (0, utilities_1.raiseCustomEvent)(this, 'tablePageClicked', {
            pageIndex,
            newOffset,
        });
        this.store.offset = newOffset;
        this.store.currentPage = pageIndex + 1;
        this.handleGetAndSetItems();
    }
    searchTextChanged(searchText) {
        this.store.searchText = searchText;
        // TODO if we do not do this, the search results do not work properly
        // TODO but because we do this, when the search box is emptied, we lose the users place
        // TODO we might want to consider saving the position of the user before the search, and replacing it after
        this.store.offset = 0;
        this.store.currentPage = 1;
        this.handleGetAndSetItems();
    }
    async downloadCSVClicked() {
        (0, utilities_1.raiseCustomEvent)(this, 'downloadCSVClicked');
        if (this.store.getItems === 'NOT_SET') {
            return;
        }
        this.store.generatingCSV = true;
        const csv = await (0, utilities_1.generateCSVForJoTable)({
            columns: this.store.columns,
            getItems: this.store.getItems,
            items: this.store.items,
            offset: 0,
            limit: this.store.maxItemsPerPage,
            searchString: this.store.searchText,
            selectedColumn: this.store.selectedColumn,
        });
        if (csv === 'NOT_SET') {
            return;
        }
        const fileName = this.store.csvFileName === 'NOT_SET'
            ? this.generateCSVFileName()
            : this.store.csvFileName;
        (0, utilities_2.downloadCSV)(csv, fileName);
        this.store.generatingCSV = false;
    }
    generateCSVFileName() {
        const dateInfo = `${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}`;
        return `${this.store.tableName}_${dateInfo}.csv`;
    }
    columnHeaderClicked(column) {
        if (isSelectableColum(column) === false) {
            return;
        }
        const newColumn = {
            ...column,
            sortDirection: column.sortDirection === 'DESC' ? 'ASC' : 'DESC',
        };
        (0, utilities_1.raiseCustomEvent)(this, 'columnHeaderClicked', newColumn);
        this.store.selectedColumn = newColumn;
        this.store.columns = updateColumns(this.store.columns, newColumn);
        if (this.store.areItemsSuppliedByParent === true) {
            this.sortColumnsManually();
        }
        else {
            this.searchAndSetItems();
        }
    }
    sortColumnsManually() {
        this.store.items = this.store.sortItems(this.store.items, this.store.selectedColumn);
    }
    render(state) {
        const firstItemNumber = calculateFirstItemNumberForPage(state.currentPage, state.totalItems, state.maxItemsPerPage);
        const lastItemNumber = calculateLastItemNumberForPage(firstItemNumber, state.totalItems, state.maxItemsPerPage);
        // this will need to be able to be extended to different types of mobile tables somehow
        // action items are a bit weird and need their own
        // but preferably there will be one mobile table view that can be popped on to all the rest of the components that use jo-table
        // ex: jo-contacts, jo-company-employees, jo-company-voicemails
        // note: jo-company-call-log might be a tough one as well
        if (state.isMobileDevice) {
            return (0, lit_html_1.html) `
                <jo-mobile-table
                    .tableName=${state.tableName}
                    .maxItemsPerPage=${state.maxItemsPerPage}
                    .columns=${state.columns}
                    .items=${state.items}
                    .showSearchBar=${state.showSearchBar}
                    .debounce=${state.searchDebounce}
                    .isCompanyUser=${state.isCompanyUser}
                    .companyId=${state.companyId}
                    .selectedActionItems=${state.selectedActionItems}
                    .numPages=${state.numPages}
                    .currentPage=${state.currentPage}
                    .mobileTemplate=${state.mobileTemplate}
                    .customActionTemplate=${state.customActionTemplate}
                    .mobileTableHeader=${state.mobileTableHeader}
                    @searchTextChange=${(e) => {
                this.searchTextChanged(e.detail);
            }}
                    @newPageNumberClicked="${(e) => this.newPageNumberClicked(e.detail)}"
                ></jo-mobile-table>
            `;
        }
        return (0, lit_html_1.html) `
            <style>
                .jo-table--main-container {
                    box-sizing: border-box;
                    height: 100%;
                    max-width: 100%;
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                }

                .jo-table--upper-container {
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 2rem;
                }

                .jo-table--table-name-header {
                    box-sizing: border-box;
                    margin: 0;
                }

                .jo-table--search-container {
                }

                .jo-table--line-break {
                    background-color: var(--jo-accent-background-dark);
                    margin-bottom: 0px;
                }

                .jo-table--column-headers-row {
                    display: flex;
                    max-width: 100%;
                }

                .jo-table--header-item {
                    font-weight: bold;
                    padding-right: 1rem;
                    display: flex;
                    align-items: center;
                    user-select: none;
                }

                .jo-table--sort-arrow-selected {
                    color: var(--jo-primary);
                    transform: scale(1.2);
                }

                .jo-table--row {
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 0rem;
                    width: 100%;
                }

                .jo-table--cell-item {
                    box-sizing: border-box;
                    padding-right: 1rem;
                    width: 100%;
                    word-break: break-word;
                }

                .jo-table--row:hover {
                    background-color: var(--jo-accent-background-light);
                }

                .jo-table--page-numbers-container {
                    box-sizing: border-box;
                    padding-top: 2rem;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                }

                .jo-table--page-number {
                    box-sizing: border-box;
                    height: 2rem;
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--jo-accent-background-dark);
                    border-radius: 2px;
                    color: var(--jo-primary);
                    margin-left: -1px;
                }

                .jo-table--page-number:hover {
                    background-color: var(--jo-accent-background-light);
                    cursor: pointer;
                }

                .jo-table--page-number-selected {
                    background-color: var(--jo-primary);
                    box-shadow: var(--jo-box-shadow-2);
                    border: 1px solid var(--jo-primary);
                    color: white;
                    transform: scale(1.05);
                    font-weight: bold;
                }

                .jo-table--page-number-selected:hover {
                    background-color: var(--jo-primary);
                }

                .jo-table--results-count-container {
                    display: flex;
                    box-sizing: border-box;
                    padding-top: 1rem;
                }

                .jo-table--header-item-selected {
                    color: var(--jo-primary);
                }

                .jo-table--pointer {
                    cursor: pointer;
                }

                table {
                    table-layout: fixed;
                    display: block;
                }

                tbody {
                    width: 100%;
                    display: table;
                }

                th,
                td {
                    text-align: left;
                }

                .table-wrapper {
                    width: 100%;
                    overflow-x: auto;
                }

                @media screen and (max-width: 1300px) {
                    th,
                    td {
                        font-size: 12px;
                    }

                    table {
                        width: 100%;
                    }
                }

                @media screen and (max-width: 1000px) {
                    .jo-table--table-name-header {
                        font-size: calc(15px + 1vmin);
                    }

                    .jo-table--search-container {
                        width: 50%;
                    }

                    .jo-table--input-container {
                        width: 50%;
                    }

                    table {
                        width: 200%;
                    }
                }
            </style>

            <div class="jo-table--main-container">
                <div class="jo-table--upper-container">
                    <div ?hidden=${state.showTableTitle === false}>
                        <h2 class="jo-table--table-name-header jo-global--card-title">
                            ${state.tableName}
                        </h2>
                    </div>

                    <div
                        class="jo-table--input-container"
                        ?hidden=${state.showStartDatePicker === false}
                    >
                        <jo-input
                            .label=${'Start Date'}
                            .type=${'DATE'}
                            @inputchange=${(e) => {
            (0, utilities_1.raiseCustomEvent)(this, 'startDateChanged', {
                inputValue: e.detail.inputValue,
            });
        }}
                        />
                    </div>

                    <div
                        class="jo-table--input-container"
                        ?hidden=${state.showEndDatePicker === false}
                    >
                        <jo-input
                            .label=${'End Date'}
                            .type=${'DATE'}
                            @inputchange=${(e) => {
            (0, utilities_1.raiseCustomEvent)(this, 'endDateChanged', {
                inputValue: e.detail.inputValue,
            });
        }}
                        />
                    </div>

                    <div ?hidden=${state.showReloadButton === false}>
                        <jo-button
                            .type=${'ICON'}
                            .icon=${'refresh'}
                            @joButtonClick=${() => {
            (0, utilities_1.raiseCustomEvent)(this, 'triggerTableReload');
        }}
                        ></jo-button>
                    </div>

                    <div ?hidden=${state.showCSVButton === false}>
                        <jo-button
                            .text=${state.generatingCSV === true
            ? 'Generating CSV...'
            : 'Download as CSV'}
                            .disabled=${state.generatingCSV === true}
                            .type=${'ACTION_BUTTON_2'}
                            @joButtonClick=${() => this.downloadCSVClicked()}
                        ></jo-button>
                    </div>

                    <div
                        class="jo-table--search-container"
                        ?hidden=${state.showSearchBar === false}
                    >
                        <jo-input
                            .type=${'SEARCH'}
                            .debounce=${state.searchDebounce}
                            @searchTextChanged=${(event) => {
            this.searchTextChanged(event.detail.searchText);
        }}
                        ></jo-input>
                    </div>
                </div>

                <div class="table-wrapper">
                    <table>
                        <tr class="jo-table--column-headers-row ">
                            ${state.columns.map((column) => {
            return (0, lit_html_1.html) `
                                    <th
                                        class="
                                            jo-table--header-item 
                                            ${isSelectableColum(column) === true
                ? 'jo-table--pointer'
                : ''}
                                            ${isSelectedColumn(column, state.selectedColumn)
                ? 'jo-table--header-item-selected'
                : ''}
                                        "
                                        style="flex: ${column.width};"
                                        @click=${async () => {
                this.columnHeaderClicked(column);
            }}
                                    >
                                        ${column.title}
                                        ${getDropdownArrowHTML(column, state.selectedColumn)}
                                    </th>
                                `;
        })}
                        </tr>

                        <tr class="jo-table--line-break">
                            <td colspan="100%"></td>
                        </tr>

                        ${state.items
            ? state.items.map((item, index) => {
                return (0, lit_html_1.html) `
                                      <tr class="jo-table--row" ${(0, test_id_directive_1.testId)('jo-table--row')}>
                                          ${state.columns.map((column) => {
                    return (0, lit_html_1.html) `
                                                  <td
                                                      class="jo-table--cell-item"
                                                      style="flex: ${column.width};"
                                                  >
                                                      ${column.getCellData(item, index, state.items)}
                                                  </td>
                                              `;
                })}
                                      </tr>
                                  `;
            })
            : ''}
                    </table>
                </div>

                ${state.numPages > 1 && state.showPaginationInfo === true
            ? (0, lit_html_1.html) `
                          <div class="jo-table--page-numbers-container">
                              ${new Array(state.numPages).fill(0).map((_, index) => {
                const pageNumber = index + 1;
                return (0, lit_html_1.html) `
                                      <div
                                          class="jo-table--page-number ${state.currentPage ===
                    pageNumber
                    ? 'jo-table--page-number-selected'
                    : ''}"
                                          @click=${() => this.newPageNumberClicked(index)}
                                      >
                                          ${pageNumber}
                                      </div>
                                  `;
            })}
                          </div>
                      `
            : (0, lit_html_1.html) `
                          <br />
                      `}
                ${state.showPaginationInfo === true
            ? (0, lit_html_1.html) `
                          <div class="jo-table--results-count-container">
                              <div>
                                  ${state.totalItems > 0
                ? (0, lit_html_1.html) `
                                            Showing ${firstItemNumber}-${lastItemNumber} of
                                            ${state.totalItems} results
                                        `
                : 'Showing 0 of 0 results'}
                              </div>
                              <div
                                  ?hidden=${state.showLoadingText === false}
                                  style="padding-left: 2rem;"
                              >
                                  <jo-loading-text></jo-loading-text>
                              </div>
                          </div>
                      `
            : `Showing ${state.totalItems} of ${state.totalItems} results`}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-table', JOTable);
function getDropdownArrowHTML(column, stateSelectedColumn) {
    if (isSelectedColumn(column, stateSelectedColumn)) {
        return (0, lit_html_1.html) `
            <i class="material-icons jo-table--sort-arrow-selected">
                ${column.sortDirection === 'ASC' ? 'arrow_drop_up' : 'arrow_drop_down'}
            </i>
        `;
    }
    else if (isSelectableColum(column)) {
        return (0, lit_html_1.html) `
            <i class="material-icons">arrow_drop_down</i>
        `;
    }
    else {
        return (0, lit_html_1.html) ``;
    }
}
function isSelectedColumn(currentColumn, stateSelectedColumn) {
    if (stateSelectedColumn === 'NOT_SET' || currentColumn.sortFieldName === undefined) {
        return false;
    }
    return stateSelectedColumn.title === currentColumn.title;
}
function isSelectableColum(column) {
    if (column.sortFieldName === undefined) {
        return false;
    }
    return true;
}
function updateColumns(columns, newColumn) {
    return columns.map((column) => {
        if (column.title === newColumn.title) {
            return newColumn;
        }
        return {
            ...column,
            sortDirection: 'NOT_SET',
        };
    });
}
function calculateFirstItemNumberForPage(currentPage, totalItems, maxItemsPerPage) {
    if (totalItems === 0)
        return 0;
    return (currentPage - 1) * maxItemsPerPage + 1;
}
function calculateLastItemNumberForPage(firstItemNumber, totalItems, maxItemsPerPage) {
    if (totalItems === 0)
        return 0;
    if (totalItems > firstItemNumber + maxItemsPerPage - 1) {
        return firstItemNumber + maxItemsPerPage - 1;
    }
    return totalItems;
}
