"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateLastSeenOnCallForUser = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function updateLastSeenOnCallForUser(userId, timestamp) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $timestamp: DateTime!) {
                    updateUsers(input: {id: $userId, last_seen_on_a_call: $timestamp}) {
                        id
                    }
                }
            `, {
            userId,
            timestamp,
        });
    }
    catch (error) {
        console.error('ERROR jo-call-screen -> utilities -> update-last-seen-on-call-for-user.ts', error);
    }
}
exports.updateLastSeenOnCallForUser = updateLastSeenOnCallForUser;
