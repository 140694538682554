"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntervalRateLimiter = void 0;
/**
 * IntervalRateLimiter's internal code uses setInterval in a really weird way but, in the end, this
 * IS indeed rate limiting "callback" to only get triggered on ".trigger()" calls if it's been
 * "millisecondsPerInterval" since the last ".trigger()" call.
 *
 * However, randomly, it might also automatically retrigger the callback after
 * "millisecondsPerInterval" seconds due to slight variations in timers.
 */
class IntervalRateLimiter {
    constructor(params) {
        this.interval = null;
        this.lastEventTime = null;
        this.callback = () => { };
        this.millisecondsPerInterval = 0;
        this.callback = params.callback;
        this.millisecondsPerInterval = params.millisecondsPerInterval ?? 5000;
    }
    trigger() {
        this.updateLastEventTime();
        this.startIntervalIfNecessary();
    }
    updateLastEventTime(date) {
        this.lastEventTime = date ?? new Date();
    }
    async startIntervalIfNecessary() {
        if (this.interval !== null) {
            return;
        }
        this.createInterval();
        await this.callback();
    }
    createInterval() {
        this.interval = setInterval(this.intervalCallback.bind(this), this.millisecondsPerInterval);
    }
    async intervalCallback() {
        if (this.lastEventWasWithinLastIntervalWindow()) {
            await this.callback();
        }
        else {
            this.clearAndDeleteInterval();
        }
    }
    lastEventWasWithinLastIntervalWindow() {
        return this.milliseconsSinceLastEvent() < this.millisecondsPerInterval;
    }
    clearAndDeleteInterval() {
        clearInterval(this.interval);
        this.interval = null;
    }
    milliseconsSinceLastEvent() {
        if (this.lastEventTime === null) {
            return Infinity;
        }
        return new Date().getTime() - this.lastEventTime.getTime();
    }
}
exports.IntervalRateLimiter = IntervalRateLimiter;
