"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateFormErrors = exports.JOInput = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
require("./jo-phone-number");
const utilities_1 = require("../../services/utilities");
const constants_1 = require("../../services/constants");
require("./jo-email-verifier");
require("./jo-phone-number");
const no_scroll_on_focus_1 = require("../../utilities/input-event-listeners/no-scroll-on-focus");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const test_id_directive_1 = require("../directives/test-id.directive");
const InitialState = {
    allowPastingEvenIfNotYetSet: false,
    checkedValue: false,
    clearSelectedValueOnSelectionMade: false,
    dateValue: new Date().toISOString(),
    debounce: 0,
    defaultOption: '',
    disableDefaultOption: false,
    errorMessage: '',
    fontSize: '0.9rem',
    formErrors: [],
    hasBegunTyping: false,
    hideError: false,
    htmlId: '',
    initialInputSet: false,
    inputValue: '',
    label: '',
    logState: false,
    passwordToCompareTo: '',
    placeholder: '',
    readonly: false,
    resize: false,
    required: false,
    requiredIconType: 'SUBTLE',
    rows: 'NOT_SET',
    selectedValue: '',
    selectOptions: [],
    shouldGenerateError: true,
    showEmailVerifier: false,
    showError: false,
    showErrorParentOverride: false,
    showLabel: true,
    showPassword: false,
    showRequiredIcon: false,
    type: 'NOT_SET',
    useEmailVerifier: false,
    valueSet: false,
};
class JOInput extends HTMLElement {
    constructor() {
        super(...arguments);
        this.inputChangeDebouncer = (0, utilities_1.createDebouncer)();
        this.valueChangedDebouncer = (0, utilities_1.createDebouncer)();
        this.searchTextChangedDebouncer = (0, utilities_1.createDebouncer)();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    async connectedCallback() {
        this.generateAndSetErrorMessage();
        // We need this timeout to let the event listener on the parent register in time
        setTimeout(() => this.handleRaiseInputChangeEvent());
    }
    get fontSize() {
        return this.store.fontSize;
    }
    get label() {
        return this.store.label;
    }
    get placeholder() {
        return this.store.placeholder;
    }
    get inputValue() {
        return this.store.inputValue;
    }
    get selectedValue() {
        return this.store.selectedValue;
    }
    set type(type) {
        this.store.type = type;
    }
    set fontSize(fontSize) {
        if (fontSize === this.store.fontSize) {
            return;
        }
        this.store.fontSize = fontSize;
    }
    set label(label) {
        if (label === this.store.label) {
            return;
        }
        this.store.label = label;
    }
    set placeholder(placeholder) {
        if (placeholder === this.store.placeholder) {
            return;
        }
        this.store.placeholder = placeholder;
    }
    set inputValue(inputValue) {
        this.store.initialInputSet = true;
        if (inputValue === this.store.inputValue) {
            return;
        }
        this.store.inputValue = inputValue;
        this.generateAndSetErrorMessage();
        this.handleRaiseInputChangeEvent();
    }
    set selectedValue(selectedValue) {
        this.store.initialInputSet = true;
        if (selectedValue === this.store.selectedValue) {
            return;
        }
        this.store.selectedValue = selectedValue;
        this.generateAndSetErrorMessage();
        this.handleRaiseInputChangeEvent();
    }
    set required(required) {
        if (required === this.store.required) {
            return;
        }
        this.store.required = required;
        this.generateAndSetErrorMessage();
        this.handleRaiseInputChangeEvent();
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (props.errorMessage === null || props.errorMessage === undefined) {
            this.generateAndSetErrorMessage();
        }
        this.handleRaiseInputChangeEvent();
    }
    generateAndSetErrorMessage() {
        if (this.store.shouldGenerateError === false) {
            return;
        }
        const existingErrorMessage = this.store.errorMessage;
        const errorMessage = generateErrorMessage(this.store.type, this.store.label, this.store.inputValue, this.store.selectedValue, this.store.required, this.store.passwordToCompareTo);
        if (errorMessage === existingErrorMessage) {
            return;
        }
        this.store.errorMessage = errorMessage;
        const formError = {
            label: this.store.label,
            errorMessage,
        };
        (0, utilities_1.raiseCustomEvent)(this, 'inputErrorChanged', formError);
    }
    handleRaiseInputChangeEvent() {
        if (this.store.debounce !== 0) {
            this.inputChangeDebouncer(this.store.debounce, () => {
                this.raiseInputChangeEvent();
            });
        }
        else {
            this.raiseInputChangeEvent();
        }
    }
    raiseInputChangeEvent() {
        (0, utilities_1.raiseCustomEvent)(this, 'inputchange', this.getDataForInputChangedEvent());
    }
    raiseValueChangedEvent() {
        if (!this.store.valueSet && !this.store.allowPastingEvenIfNotYetSet) {
            return;
        }
        if (this.store.debounce !== 0) {
            this.valueChangedDebouncer(this.store.debounce, () => {
                this.raiseValueChangedCustomEvent();
            });
        }
        else {
            this.raiseValueChangedCustomEvent();
        }
    }
    raiseValueChangedCustomEvent() {
        (0, utilities_1.raiseCustomEvent)(this, 'valueChanged', this.getDataForInputChangedEvent());
    }
    textOrEmailInputChangedEvent(e) {
        this.store.hasBegunTyping = true;
        this.store.inputValue = e.target.value;
        this.generateAndSetErrorMessage();
        this.handleRaiseInputChangeEvent();
        this.raiseValueChangedEvent();
        this.markValueAsSet();
    }
    datePickerInputChangedEvent(e) {
        this.store.inputValue = e.target.value;
        this.generateAndSetErrorMessage();
        this.handleRaiseInputChangeEvent();
        this.raiseValueChangedEvent();
        this.markValueAsSet();
    }
    textAreaInputChangedEvent(e) {
        this.store.hasBegunTyping = true;
        this.store.inputValue = e.target.value;
        this.generateAndSetErrorMessage();
        this.handleRaiseInputChangeEvent();
        this.markValueAsSet();
        this.raiseValueChangedEvent();
    }
    dateInputChangedEvent(e) {
        this.store.inputValue = e.target.value;
        this.generateAndSetErrorMessage();
        this.handleRaiseInputChangeEvent();
        this.markValueAsSet();
        this.raiseValueChangedEvent();
    }
    markValueAsSet() {
        if (!this.store.valueSet) {
            this.store.valueSet = true;
        }
    }
    phoneInputChangedEvent(e) {
        this.store.inputValue = (0, utilities_1.formatPhoneNumberToE164)(e.detail.phoneNumber);
        this.generateAndSetErrorMessage();
        this.handleRaiseInputChangeEvent();
    }
    selectChangedEvent(e) {
        this.store.showError = false;
        this.store.selectedValue = e.target.value;
        this.generateAndSetErrorMessage();
        this.handleRaiseInputChangeEvent();
        this.markValueAsSet();
        this.raiseValueChangedEvent();
        if (this.store.clearSelectedValueOnSelectionMade) {
            this.store.selectedValue = '';
        }
        if (this.store.initialInputSet === true) {
            (0, utilities_1.raiseCustomEvent)(this, 'inputAltered', this.getDataForInputChangedEvent());
        }
    }
    getDataForInputChangedEvent() {
        const data = {
            checkedValue: this.store.checkedValue,
            date: new Date(this.store.dateValue),
            errorMessage: this.store.errorMessage,
            fontSize: this.store.fontSize,
            formErrors: updateFormErrors(this.store.formErrors, this.store.label, this.store.errorMessage),
            inputValue: this.store.inputValue,
            label: this.store.label,
            placeholder: this.store.placeholder,
            searchText: this.store.inputValue,
            selectedValue: this.store.selectedValue,
            type: this.store.type,
        };
        return data;
    }
    passwordChangedEvent(e) {
        if (this.store.type === 'PASSWORD_CONFIRM') {
            this.store.showError = true;
        }
        this.store.hasBegunTyping = true;
        this.store.inputValue = e.target.value;
        if (this.store.hideError === false) {
            this.generateAndSetErrorMessage();
        }
        this.handleRaiseInputChangeEvent();
    }
    handleCheckboxContainerClicked() {
        if (this.store.readonly) {
            return;
        }
        this.store.checkedValue = !this.store.checkedValue;
        this.handleRaiseInputChangeEvent();
        this.markValueAsSet();
        this.raiseValueChangedEvent();
    }
    showErrorIfHasBegunTypingAndIsNecessary() {
        if (this.store.hasBegunTyping === true &&
            (isRequiredButBlank(this.store.type, this.store.required, this.store.inputValue) ===
                true ||
                isBadlyFormattedInput(this.store.type, this.store.inputValue) === true)) {
            this.store.showError = true;
        }
    }
    showEmailVerifierIfNecessary() {
        if (this.store.useEmailVerifier) {
            this.store.showEmailVerifier = true;
        }
    }
    raiseJoInputBlurEvent() {
        (0, utilities_1.raiseCustomEvent)(this, 'joInputBlur');
    }
    displayState(state) {
        console.info(`jo-input: ${this.store.type} ${this.store.label} state:`, state);
    }
    handleSearchTextChanged(value) {
        this.searchTextChangedDebouncer(this.store.debounce, () => {
            (0, utilities_1.raiseCustomEvent)(this, 'searchTextChanged', {
                searchText: value,
            });
        });
        this.store.inputValue = value;
        this.handleRaiseInputChangeEvent();
        this.markValueAsSet();
        this.raiseValueChangedEvent();
    }
    render(state) {
        if (this.store.logState) {
            this.displayState(state);
        }
        return (0, lit_html_1.html) `
            <style>
                .jo-input--main-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }

                .jo-input--error {
                    color: red;
                }

                .jo-input--select {
                    padding: 0.25rem;
                }

                .jo-input--label-container {
                    display: flex;
                }

                .jo-input--label {
                    box-sizing: border-box;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding-right: 0.25rem;
                    font-size: 0.9rem;
                }

                /* TODO find out why this gets overridden when used on the select component directly, when listed immediately after jo-input--input class*/
                .jo-input--search-container {
                    display: flex;
                    height: 100%;
                }

                .jo-input--input {
                    font-family: sans-serif;
                    font-size: ${state.fontSize};
                    color: rgb(60, 60, 60);
                    padding: 0.3rem;
                    border: 1px solid rgb(180, 180, 180);
                    border-radius: 3px;
                    background-color: ${state.readonly ? '' : 'white'};
                    transition: 0.175s ease;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                }

                .jo-input--input:focus {
                    outline: none;
                    box-shadow: 0 0 3px rgba(81, 203, 238, 1);
                    border: 1px solid #9897b4;
                }

                .jo-input--error-message {
                    box-sizing: border-box;
                    color: red;
                }

                .jo-input--readonly {
                    background-color: var(--jo-accent-background-light);
                    opacity: 0.7;
                }

                .jo-input--checkbox-container:hover {
                    cursor: pointer;
                }

                .text-area-resize-none {
                    resize: none;
                }

                @media screen and (max-width: 1500px) {
                    .jo-global--search-bar {
                        width: calc(80% + 5vw);
                    }
                }

                .material-icons.explicit-required-icon {
                    color: red;
                    font-size: 1.4rem;
                }
            </style>

            <div class="jo-input--main-container">
                <div class="jo-input--label-container">
                    <div
                        class="jo-input--label"
                        ?hidden=${state.type === 'CHECKBOX' || state.showLabel === false}
                        style="font-size: ${state.fontSize};"
                    >
                        ${state.label}
                    </div>

                    ${state.showRequiredIcon === true && state.requiredIconType === 'SUBTLE'
            ? (0, lit_html_1.html) `
                              <abbr title="Required" style="cursor: help">*</abbr>
                          `
            : ''}
                    ${state.showRequiredIcon === true && state.requiredIconType === 'EXPLICIT'
            ? (0, lit_html_1.html) `
                              <i class="material-icons explicit-required-icon">error</i>
                          `
            : ''}
                </div>

                ${state.type === 'CHECKBOX'
            ? (0, lit_html_1.html) `
                          <div>
                              <span
                                  class="jo-input--checkbox-container"
                                  @click=${() => this.handleCheckboxContainerClicked()}
                              >
                                  <input
                                      type="checkbox"
                                      .checked=${state.checkedValue}
                                      ?disabled=${state.readonly}
                                  />
                                  &nbsp;&nbsp;${state.label}
                              </span>
                          </div>
                      `
            : ''}
                ${state.type === 'TEXT_INPUT' ||
            state.type === 'URL' ||
            state.type === 'CREDIT_CARD_NUMBER' ||
            state.type === 'CREDIT_CARD_EXPIRY'
            ? (0, lit_html_1.html) `
                          <input
                              ?disabled=${state.readonly === true}
                              id=${state.htmlId}
                              class="jo-input--input ${state.readonly === true
                ? 'jo-input--readonly'
                : ''}"
                              style="font-size: ${state.fontSize}"
                              type="text"
                              placeholder=${state.placeholder}
                              autocomplete="off"
                              @blur=${() => {
                this.raiseJoInputBlurEvent();
                this.showErrorIfHasBegunTypingAndIsNecessary();
            }}
                              .value=${state.inputValue}
                              @input=${(e) => {
                this.textOrEmailInputChangedEvent(e);
            }}
                          />
                      `
            : ''}
                ${state.type === 'DATE'
            ? (0, lit_html_1.html) `
                          <input
                              ?disabled=${state.readonly === true}
                              class="jo-input--input ${state.readonly === true
                ? 'jo-input--readonly'
                : ''}"
                              style="font-size: ${state.fontSize}"
                              type="date"
                              placeholder=${state.placeholder}
                              autocomplete="off"
                              @blur=${() => {
                this.raiseJoInputBlurEvent();
                this.showErrorIfHasBegunTypingAndIsNecessary();
            }}
                              .value=${state.dateValue}
                              @change=${(e) => {
                this.dateInputChangedEvent(e);
            }}
                          />
                      `
            : ''}
                ${state.type === 'EMAIL'
            ? (0, lit_html_1.html) `
                          <input
                              ?disabled=${state.readonly === true}
                              type="text"
                              class="jo-input--input ${state.readonly === true
                ? 'jo-input--readonly'
                : ''}"
                              placeholder=${state.placeholder}
                              autocomplete="off"
                              @blur=${() => {
                this.raiseJoInputBlurEvent();
                this.showEmailVerifierIfNecessary();
                this.showErrorIfHasBegunTypingAndIsNecessary();
            }}
                              .value=${state.inputValue}
                              @input=${(e) => {
                this.textOrEmailInputChangedEvent(e);
                this.store.showEmailVerifier = false;
            }}
                          />
                          ${state.showEmailVerifier
                ? (0, lit_html_1.html) `
                                    <jo-email-verifier
                                        .props=${{
                    inputEmail: state.inputValue,
                }}
                                    ></jo-email-verifier>
                                `
                : ''}
                      `
            : ''}
                ${state.type === 'NUMBER'
            ? (0, lit_html_1.html) `
                          <input
                              ?disabled=${state.readonly === true}
                              class="jo-input--input ${state.readonly === true
                ? 'jo-input--readonly'
                : ''}"
                              style="font-size: ${state.fontSize}"
                              type="number"
                              placeholder=${state.placeholder}
                              autocomplete="off"
                              @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                              @blur=${() => {
                this.raiseJoInputBlurEvent();
                this.showErrorIfHasBegunTypingAndIsNecessary();
            }}
                              .value=${state.inputValue}
                              @input=${(e) => this.textOrEmailInputChangedEvent(e)}
                          />
                      `
            : ''}
                ${state.type === 'TEXT_AREA'
            ? (0, lit_html_1.html) `
                          <textarea
                              ?disabled=${state.readonly === true}
                              class="
                            jo-input--input 
                            ${state.resize ? '' : 'text-area-resize-none'} 
                            ${state.readonly === true
                ? 'jo-input--readonly'
                : ''}                            
                        "
                              type="text"
                              placeholder=${state.placeholder}
                              autocomplete="off"
                              rows="${state.rows !== 'NOT_SET' ? state.rows : ''}"
                              @blur=${() => {
                this.raiseJoInputBlurEvent();
                this.showErrorIfHasBegunTypingAndIsNecessary();
            }}
                              .value=${state.inputValue}
                              @input=${(e) => this.textAreaInputChangedEvent(e)}
                          ></textarea>
                      `
            : ''}
                ${state.type === 'PHONE_NUMBER'
            ? (0, lit_html_1.html) `
                          <jo-phone-number
                              .readonly=${state.readonly === true}
                              .phoneNumber=${(0, utilities_1.formatE164PhoneNumberForDisplay)(state.inputValue)}
                              .mode=${'EDIT'}
                              @phoneinputblur=${() => {
                this.raiseJoInputBlurEvent();
                this.showErrorIfHasBegunTypingAndIsNecessary();
            }}
                              @phoneinputkeydown=${() => (this.store.hasBegunTyping = true)}
                              @phoneinputchanged=${(e) => this.phoneInputChangedEvent(e)}
                          ></jo-phone-number>
                      `
            : ''}
                ${state.type === 'SELECT'
            ? (0, lit_html_1.html) `
                          <select
                              ?disabled=${state.readonly === true}
                              class="jo-input--input ${state.readonly === true
                ? 'jo-input--readonly'
                : ''}"
                              style="padding: 0.25rem;"
                              @change=${(e) => this.selectChangedEvent(e)}
                              .value=${state.selectedValue}
                          >
                              ${state.defaultOption !== ''
                ? (0, lit_html_1.html) `
                                        <option
                                            ?disabled=${state.required === true ||
                    state.disableDefaultOption === true}
                                            ?selected=${isUnselectedValue(state.selectedValue) ===
                    true}
                                            value=""
                                        >
                                            ${state.defaultOption}
                                        </option>
                                    `
                : ''}
                              ${state.selectOptions.map((option) => (0, lit_html_1.html) `
                                      <option ?selected=${state.selectedValue === option}>
                                          ${option}
                                      </option>
                                  `)}
                          </select>
                      `
            : ''}
                ${state.type === 'TIMEZONE_SELECT'
            ? (0, lit_html_1.html) `
                          <select
                              ?disabled=${state.readonly === true}
                              class="jo-input--input ${state.readonly === true
                ? 'jo-input--readonly'
                : ''}"
                              style="padding: 0.25rem"
                              @change=${(e) => this.selectChangedEvent(e)}
                              .value=${state.selectedValue}
                          >
                              <option
                                  disabled
                                  ?selected=${isUnselectedValue(state.selectedValue) === true}
                              >
                                  Please select a timezone...
                              </option>

                              ${constants_1.TimezonesForDisplay.map(timezoneForDisplay => (0, lit_html_1.html) `
                                      <option
                                          ?selected=${constants_1.TimezonesValuesToDisplayMap[state.selectedValue] === timezoneForDisplay}
                                          .value=${constants_1.TimezonesDisplayToValuesMap[timezoneForDisplay]}
                                      >
                                          ${timezoneForDisplay}
                                      </option>
                                  `)}
                          </select>
                      `
            : ''}
                ${state.type === 'PASSWORD' ||
            state.type === 'PASSWORD_CONFIRM' ||
            state.type === 'CREDIT_CARD_CVV'
            ? (0, lit_html_1.html) `
                          <input
                              ?disabled=${state.readonly === true}
                              class="jo-input--input ${state.readonly === true
                ? 'jo-input--readonly'
                : ''}"
                              id="password-input"
                              type=${state.showPassword ? 'text' : 'password'}
                              placeholder=${state.placeholder}
                              autocomplete="new-password"
                              .value=${state.inputValue}
                              @blur=${() => {
                this.raiseJoInputBlurEvent();
                if (this.store.hideError === false) {
                    this.store.showError = true;
                }
            }}
                              @input=${(e) => this.passwordChangedEvent(e)}
                          />
                      `
            : ''}
                ${state.type === 'SEARCH'
            ? (0, lit_html_1.html) `
                          <div class="jo-input--search-container">
                              <i class="material-icons jo-global--search-icon">search</i>
                              <input
                                  ${(0, test_id_directive_1.testId)('search-input')}
                                  type="text"
                                  class="jo-global--search-bar"
                                  placeholder="Search..."
                                  @input=${(event) => {
                this.handleSearchTextChanged(event.currentTarget.value);
            }}
                              />
                          </div>
                      `
            : ''}

                <small
                    ?hidden=${state.showError === false && state.showErrorParentOverride === false}
                    class="jo-input--error-message"
                >
                    ${state.errorMessage}
                </small>
            </div>
        `;
    }
}
exports.JOInput = JOInput;
(0, define_custom_element_1.defineCustomElement)('jo-input', JOInput);
function generateErrorMessage(type, label, inputValue, selectValue, isRequired, passwordToCompareTo) {
    if (isRequiredButBlankOrUnseleted(type, isRequired, inputValue, selectValue) === true) {
        const errorMessage = type === 'SELECT' ? `${label} must have a selected value` : `${label} cannot be blank`;
        return errorMessage;
    }
    if (type === 'PHONE_NUMBER' && isPhoneNumberValid(inputValue) === false) {
        const errorMessage = `${label} must be a valid phone #`;
        return errorMessage;
    }
    if (type === 'EMAIL' && isEmailValid(inputValue) === false) {
        const errorMessage = `${label} must be a valid address`;
        return errorMessage;
    }
    if (type === 'URL' && isURLValid(inputValue) === false) {
        const errorMessage = `${label} must be a valid URL starting with http:// or https://`;
        return errorMessage;
    }
    if (type === 'CREDIT_CARD_NUMBER' && isCreditCardNumberValid(inputValue) === false) {
        const errorMessage = `${label} is invalid`;
        return errorMessage;
    }
    if (type === 'CREDIT_CARD_EXPIRY' && isCreditCardExpiryValid(inputValue) === false) {
        const errorMessage = `${label} is invalid`;
        return errorMessage;
    }
    if (type === 'PASSWORD' && inputValue.length < 12) {
        const errorMessage = `${label} must be at least 12 characters`;
        return errorMessage;
    }
    if (type === 'PASSWORD_CONFIRM' && inputValue !== passwordToCompareTo) {
        return 'Passwords do not match';
    }
    return '';
}
function isRequiredButBlankOrUnseleted(type, isRequired, inputValue, selectValue) {
    if (isRequired === false) {
        return false;
    }
    if (type === 'SELECT' && isUnselectedValue(selectValue)) {
        return true;
    }
    if (type !== 'SELECT' && isRequiredButBlank(type, isRequired, inputValue)) {
        return true;
    }
    return false;
}
function isUnselectedValue(selectedValue) {
    if (selectedValue === '' || selectedValue === 'NOT_SET' || selectedValue === 'NO_ROLE') {
        return true;
    }
    return false;
}
// TODO this is copied verbatim between jills-office-web and jills-office-node
function isEmailValid(email) {
    return /\S+@\S+\.\S+$/.test(email) === true || email.length === 0;
}
function isPhoneNumberValid(e164phoneNumber) {
    const phoneNumberWithoutCountryExtension = e164phoneNumber.replace('+1', '');
    return (phoneNumberWithoutCountryExtension.length === 10 ||
        phoneNumberWithoutCountryExtension.length === 0);
}
function isURLValid(url) {
    var pattern = new RegExp('((http|https)://)(www.)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))|' + // OR ip (v4) address
        'localhost' + // OR localhost
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(url) || url === '';
}
// TODO I do not think we need to have the formerrors state in this element
// TODO the formErrors are completely a concept of the consumer of a jo-input
// TODO and storing the entire form errors from the consumer in jo-input
// TODO might not make sense. Keeping it in sync with setting props is causing major issues
function updateFormErrors(formErrors, label, errorMessage) {
    const formErrorsMinusCurrentFormError = formErrors.filter((error) => error.label !== label);
    if (errorMessage === '') {
        return formErrorsMinusCurrentFormError;
    }
    const newError = { label, errorMessage };
    const newErrors = [
        ...formErrorsMinusCurrentFormError,
        newError,
    ];
    return newErrors;
}
exports.updateFormErrors = updateFormErrors;
function isRequiredButBlank(type, isRequired, inputValue) {
    if (type === 'PHONE_NUMBER') {
        const phoneNumberWithoutCountryExtension = inputValue.replace('+1', '');
        return isRequired && phoneNumberWithoutCountryExtension.length === 0;
    }
    else {
        return isRequired && inputValue.length === 0;
    }
}
function isCreditCardNumberValid(creditCardNumber) {
    const regexExpression = '(^4[0-9]{12}(?:[0-9]{3})?$)|(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)|(3[47][0-9]{13})|(^3(?:0[0-5]|[68][0-9])[0-9]{11}$)|(^6(?:011|5[0-9]{2})[0-9]{12}$)|(^(?:2131|1800|35d{3})d{11}$)';
    const pattern = new RegExp(regexExpression);
    return pattern.test(creditCardNumber) || creditCardNumber === '';
}
function isCreditCardExpiryValid(creditCardExpiry) {
    if (creditCardExpiry === '')
        return true;
    if (/\d\d\/\d\d/.test(creditCardExpiry) && (creditCardExpiry.split('/').length === 2) === false)
        return false;
    const month = Number(creditCardExpiry.split('/')[0]);
    const year = Number(creditCardExpiry.split('/')[1]);
    if (month > 12 || month < 1)
        return false;
    const currentYear = new Date().getFullYear();
    if (year + 2000 < currentYear)
        return false;
    return true;
}
function isBadlyFormattedInput(type, inputValue) {
    if (type === 'CREDIT_CARD_NUMBER' && isCreditCardNumberValid(inputValue) === false) {
        return true;
    }
    if (type === 'CREDIT_CARD_EXPIRY' && isCreditCardExpiryValid(inputValue) === false) {
        return true;
    }
    if (type === 'EMAIL' && isEmailValid(inputValue) === false) {
        return true;
    }
    if (type === 'URL' && isURLValid(inputValue) === false) {
        return true;
    }
    if (type === 'PHONE_NUMBER' && isPhoneNumberValid(inputValue) === false) {
        return true;
    }
    return false;
}
