"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchJillStats = exports.fetchJill = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchJill(jillId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($jillId: Int!) {
                    getUsers(id: $jillId) {
                        first_name
                        last_name
                    }
                }
            `, {
            jillId,
        });
        if (gqlResult.data === null || gqlResult.data.getUsers === null) {
            return {
                succeeded: false,
                userMessage: 'Error getting Jill',
                developerMessage: 'jills-office-web/elements/jo-home/jo-home-jill-stats-card/queries.ts fetchJill() data was null',
            };
        }
        return {
            succeeded: true,
            value: gqlResult.data.getUsers,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchJill', error);
    }
}
exports.fetchJill = fetchJill;
async function fetchJillStats(jillId, startDate, endDate) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($jillId: Int!, $startDate: String!, $endDate: String!) {
                    jillHighLevelStatses(
                        jillId: $jillId
                        startDate: $startDate
                        endDate: $endDate
                    ) {
                        highLevelStatses {
                            highLevelStats {
                                numCalls
                                actualCallDuration
                                billableCallDuration
                                averageActualCallDuration
                                averageBillableCallDuration
                                averageEfficiencyPerDay
                                averageTimeBetweenCalls
                                averageActionItemTime
                                numScheduledAppointments
                            }
                        }
                    }
                }
            `, {
            jillId,
            startDate,
            endDate,
        });
        if (gqlResult.data === null || gqlResult.data.jillHighLevelStatses === null) {
            return {
                succeeded: false,
                userMessage: 'Error getting Jill stats',
                developerMessage: 'jills-office-web/elements/jo-home/jo-home-jill-stats-card/queries.ts fetchJillStats() data was null',
            };
        }
        return {
            succeeded: true,
            value: gqlResult.data.jillHighLevelStatses,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Error getting Jill stats',
            developerMessage: 'jills-office-web/elements/jo-home/jo-home-jill-stats-card/queries.ts fetchJillStats() ' +
                error,
        };
    }
}
exports.fetchJillStats = fetchJillStats;
