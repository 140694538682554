"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
const state_1 = require("./redux/state");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const cssNames = (0, css_utils_1.cssPrefixer)('jo-call-screen-custom-fields');
const InitialState = {
    callScreenMode: state_1.InitialJOCallScreenState.callScreenMode,
    customFieldResponses: [],
    inputValues: [],
    selectedServices: state_1.InitialJOCallScreenState.selectedServices,
};
class JOCallScreenCustomFields extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const originalCustomFieldResponses = this.store.customFieldResponses;
        const originalSelectedServices = this.store.selectedServices;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.extractAndSetInputValuesIfNecessary(originalCustomFieldResponses, originalSelectedServices);
    }
    extractAndSetInputValuesIfNecessary(originalCustomFieldResponses, originalSelectedServices) {
        if (this.store.customFieldResponses !== originalCustomFieldResponses ||
            this.store.selectedServices !== originalSelectedServices) {
            const generatedDefaultInputValues = getInputValuesFromServices(this.store.selectedServices, this.store.customFieldResponses);
            const finalInputValues = produceFinalExtractedInputValues(generatedDefaultInputValues, this.store.inputValues);
            this.inputValues = finalInputValues;
        }
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'inputvalueschanged', inputValues);
    }
    shouldHideServiceName(service) {
        return (service.custom_fields.length === 0 ||
            service.custom_fields.every(customField => {
                return customField.call_screen_appear === false;
            }));
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssNames('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssNames('services-selected-title')} {
                    font-weight: bold;
                    font-size: 20px;
                    color: var(--jo-text-default);
                    font-family: sans-serif;
                }

                .${cssNames('fields-container')} {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                }

                .${cssNames('field-container')} {
                    width: 50%;
                    padding: 15px;
                    box-sizing: border-box;
                }

                .${cssNames('field-inner-container')} {
                }

                .${cssNames('continue-button-container')} {
                    padding-top: 2rem;
                }

                .${cssNames('service-name-container')} {
                    font-weight: bold;
                    padding-top: 1rem;
                }
            </style>

            <div class="${cssNames('main-container')}">
                ${state.selectedServices.map((service) => {
            return (0, lit_html_1.html) `
                        <div
                            class="${cssNames('service-name-container')}"
                            ?hidden=${this.shouldHideServiceName(service)}
                        >
                            ${service.name}
                        </div>

                        <div class="${cssNames('fields-container')}">
                            ${getUniqueCustomFields(service).map((customField) => {
                const inputValue = state.inputValues.find((inputValue) => {
                    return (inputValue.service.id === service.id &&
                        inputValue.customField.id === customField.id);
                });
                if (inputValue === undefined) {
                    return '';
                }
                if (inputValue.customField.type === 'TEXT') {
                    return (0, lit_html_1.html) `
                                        <div
                                            class="${cssNames('field-container')}"
                                            ?hidden=${inputValue.customField.call_screen_appear ===
                        false}
                                        >
                                            <div class="${cssNames('field-inner-container')}">
                                                <jo-input
                                                    .label=${inputValue.customField.text_value}
                                                    .type=${'TEXT_INPUT'}
                                                    .showRequiredIcon=${inputValue.customField
                        .call_screen_require === true}
                                                    .requiredIconType=${'EXPLICIT'}
                                                    .inputValue=${inputValue.response}
                                                    @inputchange=${(e) => {
                        this.inputValues = updateInputValues(this.store.inputValues, inputValue, e.detail.inputValue);
                    }}
                                                ></jo-input>
                                            </div>
                                        </div>
                                    `;
                }
                if (inputValue.customField.type === 'SELECT') {
                    return (0, lit_html_1.html) `
                                        <div
                                            class="${cssNames('field-container')}"
                                            ?hidden=${inputValue.customField.call_screen_appear ===
                        false}
                                        >
                                            <div class="${cssNames('field-inner-container')}">
                                                <jo-input
                                                    .label=${inputValue.customField.text_value}
                                                    .type=${'SELECT'}
                                                    .showRequiredIcon=${inputValue.customField
                        .call_screen_require === true}
                                                    .requiredIconType=${'EXPLICIT'}
                                                    .selectOptions=${inputValue.customField.select_values.map((selectValue) => selectValue.text_value)}
                                                    .defaultOption=${'Select an option...'}
                                                    .selectedValue=${inputValue.response}
                                                    @inputchange=${(e) => {
                        this.inputValues = updateInputValues(this.store.inputValues, inputValue, e.detail.selectedValue);
                    }}
                                                ></jo-input>
                                            </div>
                                        </div>
                                    `;
                }
                return (0, lit_html_1.html) `
                                    <div class="${cssNames('field-container')}">
                                        <div class="${cssNames('field-inner-container')}">
                                            Custom field type is not supported
                                        </div>
                                    </div>
                                `;
            })}
                        </div>
                    `;
        })}

                <div class="${cssNames('continue-button-container')}">
                    <jo-button
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'Continue'}
                        @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'continued')}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-custom-fields', JOCallScreenCustomFields);
function getInputValuesFromServices(services, customFieldResponses) {
    return services.reduce((result, service) => {
        const inputValues = getInputValuesFromService(service, customFieldResponses);
        return [
            ...result,
            ...inputValues,
        ];
    }, []);
}
function getInputValuesFromService(service, customFieldResponses) {
    const uniqueCustomFields = getUniqueCustomFields(service);
    return uniqueCustomFields.map((customField) => {
        const customFieldResponse = getExistingResponseForServiceAndCustomField(service, customField, customFieldResponses);
        return createResponseInputValuesFromServiceAndCustomFieldAndResponse(service, customField, customFieldResponse);
    });
}
function getUniqueCustomFields(service) {
    return service.custom_fields.filter((customField, currentIdx) => {
        const firstIndexOfCustomField = service.custom_fields.findIndex(c => c.id === customField.id);
        return currentIdx === firstIndexOfCustomField;
    });
}
function getExistingResponseForServiceAndCustomField(service, customField, customFieldResponses) {
    return customFieldResponses.find((customFieldResponse) => {
        return (customFieldResponse.service.id === service.id &&
            customFieldResponse.custom_field.id === customField.id);
    });
}
function createResponseInputValuesFromServiceAndCustomFieldAndResponse(service, customField, response) {
    return {
        symbol: Symbol(),
        customFieldResponseId: response?.id ?? 'NOT_SET',
        customField: {
            id: customField.id,
            call_screen_appear: customField.call_screen_appear,
            call_screen_require: customField.call_screen_require,
            select_values: customField.select_values,
            text_value: customField.text_value,
            type: customField.type,
        },
        response: response?.response ?? '',
        service: {
            id: service.id,
        },
    };
}
function updateInputValues(inputValues, inputValue, response) {
    return inputValues.map((customFieldResponseInputValues) => {
        if (customFieldResponseInputValues.symbol === inputValue.symbol) {
            return {
                ...customFieldResponseInputValues,
                response,
            };
        }
        else {
            return customFieldResponseInputValues;
        }
    });
}
function produceFinalExtractedInputValues(generatedDefauleInputValues, existingInputValues) {
    const mapOfExistingInputValues = makeMapOfInputValues(existingInputValues);
    const generatedInputValuesNotFoundInExistingInputValues = getGeneratedDefaultInputValuesNotFoundInExistingInputValues(generatedDefauleInputValues, mapOfExistingInputValues);
    const mapOfGeneratedInputValues = makeMapOfInputValues(generatedDefauleInputValues);
    const finalInputValues = makeFinalInputValues(generatedInputValuesNotFoundInExistingInputValues, existingInputValues, mapOfGeneratedInputValues);
    return finalInputValues;
}
function makeMapOfInputValues(inputValues) {
    const map = {};
    for (const inputValue of inputValues) {
        const uniqueIdentifier = inputValue.service.id + '-' + inputValue.customField.id;
        map[uniqueIdentifier] = inputValue;
    }
    return map;
}
function getGeneratedDefaultInputValuesNotFoundInExistingInputValues(generatedDefauleInputValues, mapOfExistingInputValues) {
    const generatedInputValuesNotInExistingInputValues = [];
    for (const inputValue of generatedDefauleInputValues) {
        const uniqueIdentifier = inputValue.service.id + '-' + inputValue.customField.id;
        if (!(uniqueIdentifier in mapOfExistingInputValues)) {
            generatedInputValuesNotInExistingInputValues.push(inputValue);
        }
    }
    return generatedInputValuesNotInExistingInputValues;
}
function makeFinalInputValues(generatedInputValuesNotFoundInExistingInputValues, existingInputValues, mapOfGeneratedInputValues) {
    const finalInputValues = [
        ...generatedInputValuesNotFoundInExistingInputValues,
    ];
    for (const inputValue of existingInputValues) {
        const uniqueIdentifier = inputValue.service.id + '-' + inputValue.customField.id;
        if (uniqueIdentifier in mapOfGeneratedInputValues) {
            finalInputValues.push(inputValue);
        }
    }
    return finalInputValues;
}
