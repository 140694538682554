"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
const InitialState = {
    endOfScriptText: '',
};
class JOCallScreenEndOfScript extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 15px;
                }

                .text {
                    font-family: sans-serif;
                    font-size: 18px;
                    color: var(--jo-text-default);
                    padding: 15px;
                }

                .continue-button-container {
                    padding-top: 2rem;
                }
            </style>

            <div class="main-container">
                <div class="text">${state.endOfScriptText ? `"${state.endOfScriptText}"` : ''}</div>

                <div class="continue-button-container">
                    <jo-button
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'Continue'}
                        @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'continued')}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-end-of-script', JOCallScreenEndOfScript);
