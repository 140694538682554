"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialState = void 0;
const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const startOfTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
exports.InitialState = {
    authenticatedUser: 'NOT_SET',
    jill: 'NOT_SET',
    jillHighLevelStatses: 'NOT_SET',
    loading: true,
    shouldLoadStats: false,
    statsStartDate: startOfToday.toISOString(),
    statsEndDate: startOfTomorrow.toISOString(),
};
