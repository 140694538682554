"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrSetInMap = exports.getOrSetInWeakMap = void 0;
/**
 * Gets a value by "key" from the given "map". If "key" does not exist yet in "map", "defaultValue"
 * will be set to "key" inside "map" and then "defaultValue" will be returned as if it had been
 * retrieved directly from "map".
 *
 * This version is only for WeakMaps as the "key" type is slightly different. For normal Map
 * instances, use "getOrSetInMap".
 */
function getOrSetInWeakMap(map, key, defaultValue) {
    return innerUntypedGetOrSetInMap(map, key, defaultValue);
}
exports.getOrSetInWeakMap = getOrSetInWeakMap;
/**
 * Gets a value by "key" from the given "map". If "key" does not exist yet in "map", "defaultValue"
 * will be set to "key" inside "map" and then "defaultValue" will be returned as if it had been
 * retrieved directly from "map".
 *
 * This version is only for normal Map instances. For normal WeakMap instances, use
 * "getOrSetInWeakMap".
 */
function getOrSetInMap(map, key, defaultValue) {
    return innerUntypedGetOrSetInMap(map, key, defaultValue);
}
exports.getOrSetInMap = getOrSetInMap;
/**
 * @deprecated Do not export this! It it not typed well. (In order to account for the differences
 *   between a WeakMap and a normal Map.
 */
function innerUntypedGetOrSetInMap(map, key, defaultValue) {
    if (!map.has(key)) {
        map.set(key, defaultValue);
    }
    return map.get(key);
}
