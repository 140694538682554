"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCompanyCurrentlyUsingCrewCal = void 0;
const common_1 = require("@augment-vir/common");
function isCompanyCurrentlyUsingCrewCal(companyStatusEvents) {
    if (companyStatusEvents.length === 0) {
        return false;
    }
    const sortedStatusEvents = sortStatusEventsDesc(companyStatusEvents);
    (0, common_1.assertLengthAtLeast)(sortedStatusEvents, 1);
    const mostRecentStatusEvent = sortedStatusEvents[0];
    if (isOnHoldOrCancelled(mostRecentStatusEvent)) {
        return false;
    }
    const mostRecentCrewCalStatusEvent = getMostRecentCrewCalStatusEvent(sortedStatusEvents);
    if (!mostRecentCrewCalStatusEvent) {
        return false;
    }
    return mostRecentCrewCalStatusEvent.type === 'STARTED_USING_CREW_CAL';
}
exports.isCompanyCurrentlyUsingCrewCal = isCompanyCurrentlyUsingCrewCal;
function getMostRecentCrewCalStatusEvent(sortedStatusEvents) {
    const crewCalStatusEvents = getCrewCalStatusEvents(sortedStatusEvents);
    const mostRecentCrewCalStatusEvent = crewCalStatusEvents[0];
    return mostRecentCrewCalStatusEvent;
}
function isOnHoldOrCancelled(mostRecentStatusEvent) {
    return (mostRecentStatusEvent.type === 'WENT_ON_HOLD' || mostRecentStatusEvent.type === 'CANCELED');
}
function getCrewCalStatusEvents(sortedStatusEvents) {
    return sortedStatusEvents.filter(s => s.type === 'STARTED_USING_CREW_CAL' || s.type === 'STOPPED_USING_CREW_CAL');
}
function sortStatusEventsDesc(companyStatusEvents) {
    return [...companyStatusEvents].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
}
