"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const helper_utils_1 = require("./helper-utils");
const InitialState = {
    actionItem: 'NOT_SET',
    authenticatedUser: 'NOT_SET',
    isMobileDevice: false,
};
class JOActionItemMessages extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        if (state.actionItem === 'NOT_SET') {
            return (0, lit_html_1.html) ``;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .action-item-note-and-image-container {
                    display: flex;
                    flex-direction: row;
                }

                .action-item-note-image-container {
                    flex: 0.5;
                }

                .action-item-note-container {
                    flex: 2;
                    display: flex;
                    padding-bottom: 0.5rem;
                }

                .action-item-note {
                    padding: ${state.isMobileDevice ? '0.5rem' : '1rem'};
                    max-width: ${state.isMobileDevice ? '75%' : '60%'};
                    display: flex;
                    flex-direction: column;
                    word-break: break-word;
                }

                .action-item-note-meta {
                    padding-bottom: 0.5rem;
                    font-size: 0.9rem;
                    display: flex;
                    justify-content: flex-end;
                }

                .action-item-note-body {
                    padding: ${state.isMobileDevice ? '0.5rem' : '1rem'};
                    border-radius: 1.5rem;
                    box-shadow: 2px 4px 8px rgb(0, 0, 0, 0.4);
                    font-size: 0.95rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .flex-start {
                    justify-content: flex-start;
                }

                .flex-end {
                    justify-content: flex-end;
                }

                .light-orange {
                    background-color: var(--jo-primary-light);
                }

                .light-gray {
                    background-color: lightgray;
                }

                .recording-link {
                    color: var(--jo-primary);
                }

                .recording-link:hover {
                    text-shadow: 0 0 10px var(--jo-primary);
                    cursor: pointer;
                }
            </style>

            <div class="main-container">
                ${state.actionItem.action_item_notes.map(actionItemNote => {
            const callRecordingUrl = actionItemNote.call?.recording_url ?? '';
            return (0, lit_html_1.html) `
                        <div class="action-item-note-and-image-container">
                            <div
                                class="action-item-note-image-container"
                                ?hidden=${(0, helper_utils_1.shouldHideImageContainer)(state.authenticatedUser)}
                            >
                                ${(0, utilities_1.isUserAMemberOfJillsOffice)(actionItemNote.author)
                ? (0, lit_html_1.html) `
                                          <img
                                              src="${(0, helper_utils_1.getJillProfilePictureUrl)(actionItemNote.author?.profile_picture_url)}"
                                              style="max-height: 66px; max-width: 66px;"
                                          />
                                      `
                : ``}
                            </div>

                            <div
                                class="action-item-note-container ${getFlexPosition(state, actionItemNote)}"
                            >
                                <div
                                    class="action-item-note ${getFlexPosition(state, actionItemNote)}"
                                >
                                    <div class="action-item-note-meta">
                                        ${(0, helper_utils_1.formatActionItemNoteMetaInfo)(actionItemNote)}
                                    </div>

                                    <div
                                        class="action-item-note-meta recording-link"
                                        ?hidden=${shouldHideRecordingLink(callRecordingUrl, state.authenticatedUser)}
                                        @click=${() => {
                (0, utilities_1.joAudioPopUp)(callRecordingUrl);
            }}
                                    >
                                        Listen to recording
                                    </div>

                                    <div
                                        class="action-item-note-body ${getBodyColor(state, actionItemNote)}"
                                    >
                                        ${actionItemNote.body}
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;
        })}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-action-item-messages', JOActionItemMessages);
function shouldHideRecordingLink(callRecordingUrl, authenticatedUser) {
    return (callRecordingUrl === '' ||
        !(0, utilities_1.isInAuthRole)([
            'JILL_OUTBOUND',
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], authenticatedUser));
}
function getBodyColor(state, actionItemNote) {
    return (0, helper_utils_1.isActionItemAuthorTheUser)(state.authenticatedUser, actionItemNote.author)
        ? 'light-orange'
        : 'light-gray';
}
function getFlexPosition(state, actionItemNote) {
    return (0, helper_utils_1.isActionItemAuthorTheUser)(state.authenticatedUser, actionItemNote.author)
        ? 'flex-end'
        : 'flex-start';
}
