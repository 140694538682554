"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gql = exports.gqlQueryInputToGql = void 0;
const common_1 = require("@augment-vir/common");
function gqlQueryInputToGql(queryInput) {
    const formedGqlString = (0, common_1.getObjectTypedKeys)(queryInput).reduce((accumQueryString, currentKey) => {
        const currentValue = queryInput[currentKey];
        const nextAppendage = getNextGqlQueryString(currentKey, currentValue);
        return `${accumQueryString}${nextAppendage}`;
    }, '');
    return `{\n${formedGqlString}}`;
}
exports.gqlQueryInputToGql = gqlQueryInputToGql;
function getNextGqlQueryString(currentKey, currentValue) {
    if (typeof currentKey !== 'string') {
        throw new Error(`Only string keys are allowed for GQL query building.`);
    }
    if (Array.isArray(currentValue)) {
        const arrayQuery = currentValue[0];
        const nestedGql = gqlQueryInputToGql(arrayQuery.fields);
        const orderBy = arrayQuery.orderBy
            ? `(orderBy: {field: "${arrayQuery.orderBy.field}", order: ${arrayQuery.orderBy.order}})`
            : '';
        return `${currentKey}${orderBy} ${nestedGql}`;
    }
    else if (typeof currentValue === 'object') {
        const nestedGql = gqlQueryInputToGql(currentValue);
        return `${currentKey} ${nestedGql}\n`;
    }
    else {
        return `${currentKey}\n`;
    }
}
function gql(strings, ...inputValues) {
    return strings.reduce((joinedString, currentString, index) => {
        const currentValue = inputValues[index];
        const valueString = currentValue ? gqlQueryInputToGql(currentValue) : '';
        return joinedString + currentString + valueString;
    }, '');
}
exports.gql = gql;
