"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrewCal = void 0;
class CrewCal {
    constructor(view, iframeUrl) {
        this.view = view;
        this.iframeUrl = iframeUrl;
        this.eventListeners = {
            open: [],
            close: [],
            selected: [],
            no_appointment_available: [],
            appointments_loaded: [],
        };
        this.crewCalId = 'crew-cal-scheduling-iframe';
    }
    on(eventType, callback) {
        if (this.eventListeners.hasOwnProperty(eventType)) {
            this.eventListeners[eventType].push(callback);
        }
    }
    trigger(eventType, data) {
        if (this.eventListeners.hasOwnProperty(eventType)) {
            this.eventListeners[eventType].forEach(function (callback) {
                callback(data);
            });
        }
    }
    notifyIframeOfNewAppointmentData(iframe, schedulingData) {
        if (!iframe || !iframe.contentWindow) {
            return;
        }
        iframe.contentWindow.postMessage({ type: 'RC_NEW_APPOINTMENT_DATA', data: schedulingData?.new_appointment }, '*');
    }
    notifyIframeOfMaxDisplayHeight(iframe, schedulingData) {
        if (!iframe || !iframe.contentWindow) {
            return;
        }
        iframe.contentWindow.postMessage({ type: 'RC_HEIGHT_ADJUST', data: schedulingData?.height }, '*');
    }
    render(targetElement, schedulingData) {
        this.throwErrorForInvalidParameters();
        const iframe = this.makeIframeElement();
        this.appendIframeElementToTargetElement(targetElement, iframe);
        this.setupIframeConfiguration(iframe, schedulingData);
    }
    throwErrorForInvalidParameters() {
        if ([
            'manage',
            'scheduling',
            'customer',
            'status',
        ].indexOf(this.view.toLowerCase()) === -1) {
            throw 'This view is not registered as a valid type: ' + this.view;
        }
    }
    setupIframeConfiguration(iframe, schedulingData) {
        // Listen to message from child window
        iframe.onload = () => {
            this.notifyIframeOfNewAppointmentData(iframe, schedulingData);
            if (schedulingData && schedulingData.height) {
                this.notifyIframeOfMaxDisplayHeight(iframe, schedulingData);
            }
        };
    }
    appendIframeElementToTargetElement(targetElement, iframe) {
        if (!targetElement) {
            throw 'targetElement is required';
        }
        while (targetElement.hasChildNodes()) {
            if (targetElement.lastChild) {
                targetElement.removeChild(targetElement.lastChild);
            }
        }
        targetElement.appendChild(iframe);
    }
    makeIframeElement() {
        const iframe = document.createElement('iframe');
        iframe.src = this.iframeUrl;
        const style = 'height: 831px;width: 100%;border:3px solid black;';
        iframe.setAttribute('style', style);
        iframe.setAttribute('id', this.crewCalId);
        return iframe;
    }
}
exports.CrewCal = CrewCal;
