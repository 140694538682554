"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortByAndApplyOrderNumberToItems = void 0;
const is_defined_1 = require("../../is-defined");
function sortByAndApplyOrderNumberToItems(items) {
    const itemsWithOrderNumber = items.filter((item) => (0, is_defined_1.isDefined)(item.order));
    const itemsWithoutOrderNumber = items.filter(item => !(0, is_defined_1.isDefined)(item.order));
    const sortedItemsWithOrderNumbers = [...itemsWithOrderNumber].sort((a, b) => a.order - b.order);
    return applyAndSortByOrderNumber({
        itemsWithoutOrderNumber,
        sortedItemsWithOrderNumbers,
    });
}
exports.sortByAndApplyOrderNumberToItems = sortByAndApplyOrderNumberToItems;
function applyAndSortByOrderNumber({ itemsWithoutOrderNumber, sortedItemsWithOrderNumbers, }) {
    const lastSortedItem = sortedItemsWithOrderNumbers[sortedItemsWithOrderNumbers.length - 1];
    const lastOrderNumber = lastSortedItem?.order ?? 0;
    const newlyAppliedOrderItems = itemsWithoutOrderNumber.map((itemWithoutOrder, index) => {
        const orderNumberToApply = lastOrderNumber + index + 1;
        const itemWithAppliedOrderNumber = {
            ...itemWithoutOrder,
            order: orderNumberToApply,
        };
        return itemWithAppliedOrderNumber;
    });
    return sortedItemsWithOrderNumbers.concat(newlyAppliedOrderItems);
}
