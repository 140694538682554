"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createElementBoundInterval = void 0;
const common_1 = require("@augment-vir/common");
const handle_error_1 = require("../../services/logging/error-handling/handle-error");
const map_1 = require("../map");
/** Use a WeakMap here so that the elements can still be garbage collected if possible. */
const intervalMap = new WeakMap();
function getIntervalRecord(parent, intervalName) {
    return intervalMap.get(parent)?.[intervalName];
}
function clearExistingInterval(parent, intervalName) {
    const previousIntervalId = getIntervalRecord(parent, intervalName)?.id;
    if (previousIntervalId != undefined) {
        globalThis.clearInterval(previousIntervalId);
    }
}
function setIntervalDetails(newDetails, parent, intervalName) {
    const existingElementRecord = (0, map_1.getOrSetInWeakMap)(intervalMap, parent, {});
    if (!existingElementRecord[intervalName]) {
        existingElementRecord[intervalName] = {
            id: undefined,
            wrappedPromise: undefined,
        };
    }
    const currentDetails = existingElementRecord[intervalName];
    if (currentDetails == null)
        throw new Error('currentDetails is null');
    existingElementRecord[intervalName] = {
        ...currentDetails,
        ...newDetails,
    };
}
/**
 * Uses setInterval with the following characteristics:
 *
 * - If the given parent HTMLElement is disconnected from the DOM, the interval clears itself
 * - Each iteration of the interval is skipped if any previous iteration has not finished yet
 * - Automatically clears previous intervals set with the same parent HTMLElement and interval name
 *   (so we don't have memory leaks from duplicate intervals running)
 *
 * @returns An object which contains, under key "clearInterval", a function that can be used to
 *   manually clear the interval.
 */
function createElementBoundInterval(inputs) {
    const { element: parent, intervalName, intervalMs } = inputs;
    clearExistingInterval(parent, intervalName);
    const intervalId = globalThis.setInterval(async () => {
        await runElementBoundInterval(inputs);
    }, intervalMs);
    setIntervalDetails({ id: intervalId }, parent, intervalName);
    return {
        clearInterval: () => {
            clearExistingInterval(parent, intervalName);
        },
    };
}
exports.createElementBoundInterval = createElementBoundInterval;
async function runElementBoundInterval({ element, intervalName, callback, }) {
    if (!element.isConnected) {
        clearExistingInterval(element, intervalName);
        return;
    }
    const lastIntervalPromise = getIntervalRecord(element, intervalName)?.wrappedPromise;
    if (lastIntervalPromise && !lastIntervalPromise.isSettled()) {
        return;
    }
    const newWrappedPromise = (0, common_1.createDeferredPromiseWrapper)();
    setIntervalDetails({ wrappedPromise: newWrappedPromise }, element, intervalName);
    try {
        await callback();
    }
    catch (thrownError) {
        const errorToHandle = (0, common_1.ensureError)(thrownError);
        errorToHandle.message = `createInterval callback failed: ${(0, common_1.extractErrorMessage)(thrownError)}`;
        (0, handle_error_1.handleError)(errorToHandle, {
            extraData: {
                element: element.tagName,
                intervalName,
            },
        });
    }
    newWrappedPromise.resolve();
}
