"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-input");
const InitialState = {
    titles: [],
    title: '',
    subtitle: '',
    formErrors: [],
    titleInputValues: {
        title: '',
        subtitle: '',
    },
};
class JOCallScreenTitle extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const originalTitle = this.store.title;
        const originalSubtitle = this.store.subtitle;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.title !== originalTitle || this.store.subtitle !== originalSubtitle) {
            this.titleInputValues = {
                ...this.store.titleInputValues,
                title: this.store.title,
                subtitle: this.store.subtitle,
            };
        }
    }
    set titleInputValues(titleInputValues) {
        this.store.titleInputValues = titleInputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'titleinputvalueschanged', titleInputValues);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                }

                .input-container {
                    padding-right: 2vmin;
                    padding-bottom: 15px;
                    box-sizing: border-box;
                    flex: 50%;
                }
            </style>

            <div class="main-container">
                <div class="input-container">
                    <jo-input
                        .label=${'Title'}
                        .type=${'SELECT'}
                        .selectedValue=${state.titleInputValues.title}
                        .required=${true}
                        .selectOptions=${this.store.titles}
                        .defaultOption=${'Select a title...'}
                        @inputchange=${(e) => {
            this.titleInputValues = {
                ...this.store.titleInputValues,
                title: e.detail.selectedValue,
            };
            (0, utilities_1.raiseCustomEvent)(this, 'actionItemTitleChanged', e.detail.selectedValue);
        }}
                    ></jo-input>
                </div>

                <div class="input-container">
                    <jo-input
                        .label=${'Subtitle'}
                        .type=${'TEXT_INPUT'}
                        .inputValue=${state.titleInputValues.subtitle}
                        .formErrors=${state.formErrors}
                        @inputchange=${(e) => {
            this.titleInputValues = {
                ...this.store.titleInputValues,
                subtitle: e.detail.inputValue,
            };
            (0, utilities_1.raiseCustomEvent)(this, 'actionItemSubTitleChanged', e.detail.inputValue);
            this.store.formErrors = e.detail.formErrors;
        }}
                    ></jo-input>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-title', JOCallScreenTitle);
