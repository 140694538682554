"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchJillsToTransferTo = void 0;
const graphql_1 = require("../../../../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../../../../utilities/environment/mapped-env-variables");
async function fetchJillsToTransferTo(currentJillId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($currentJillId: Int!) {
                JillsForTransfer: findUsers(
                    filter: {
                        id: {ne: $currentJillId}
                        auth_role: {
                            in: [
                                "JILL"
                                "JILL_OUTBOUND"
                                "JILL_MANAGER"
                                "JILL_BUSINESS_DEVELOPMENT"
                                "JILL_EXECUTIVE"
                            ]
                        }
                        is_active: {eq: true}
                    }
                    orderBy: {
                        field: "first_name" #TODO order by first AND last name when SQL builder allows for it
                        order: ASC
                    }
                ) {
                    items {
                        id
                        auth_role
                        first_name
                        last_name
                        phone_number
                    }
                }
            }
        `, {
        currentJillId,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    const jillsBesidesCurrentJill = gqlResult.value.data.JillsForTransfer.items;
    return {
        succeeded: true,
        value: jillsBesidesCurrentJill,
    };
}
exports.fetchJillsToTransferTo = fetchJillsToTransferTo;
