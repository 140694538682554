"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteCalendarEvent = void 0;
const graphql_1 = require("../../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../../utilities/environment/mapped-env-variables");
async function deleteCalendarEvent(companyId, calendarId, eventId) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $calendarId: String!, $eventId: String!) {
                    deleteEventOnCompanyGoogleCalendar(
                        companyId: $companyId
                        calendarId: $calendarId
                        eventId: $eventId
                    )
                }
            `, {
            companyId,
            calendarId,
            eventId,
        });
    }
    catch (error) {
        throw new Error(`deleteCalendarEvent error: ${error}`);
    }
}
exports.deleteCalendarEvent = deleteCalendarEvent;
