"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const test_id_directive_1 = require("../directives/test-id.directive");
const InitialState = {
    mode: 'READ',
    phoneNumber: '',
    readonly: false,
};
class JOPhoneNumber extends HTMLElement {
    constructor() {
        super(...arguments);
        this.id = generateRandomPhoneInputId();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    handleError(text) {
        alert(text);
        throw new Error(text);
    }
    set phoneNumber(phoneNumber) {
        this.store.phoneNumber = formatPhoneNumber(phoneNumber);
        // setTimeout required to update containing component's state at the proper time when setting phone number
        // otherwise, phone number in containing component's state will not be formatted correctly
        setTimeout(() => this.raisePhoneInputChangedEvent());
    }
    keyPressEvent(e) {
        const input = this.querySelector(`#${this.id}`);
        (0, utilities_1.assertIsDefined)(input, 'input', this.handleError);
        (0, utilities_1.assertIsDefined)(input.selectionStart, 'this.selectionStart', this.handleError);
        if (isValidKeyPress(e.key, input.selectionStart) === false) {
            e.preventDefault();
        }
    }
    keyDownEvent(e) {
        const input = this.querySelector(`#${this.id}`);
        (0, utilities_1.assertIsDefined)(input, 'input', this.handleError);
        (0, utilities_1.assertIsDefined)(input.selectionStart, 'input.selectionStart', this.handleError);
        (0, utilities_1.assertIsDefined)(input.selectionEnd, 'input.selectionEnd', this.handleError);
        if (isValidKeyPress(e.key, input.selectionStart) === false)
            return;
        this.preventBackspaceDeleteOnSpecialCharacters(input, e, input.selectionStart, input.selectionEnd);
        this.formatInputAndSetCursorPosition(input, e.key);
        (0, utilities_1.raiseCustomEvent)(this, 'phoneinputkeydown', {});
    }
    raisePhoneInputChangedEvent() {
        this.dispatchEvent(new CustomEvent('phoneinputchanged', {
            detail: {
                phoneNumber: this.store.phoneNumber,
            },
        }));
    }
    formatInputAndSetCursorPosition(input, inputKey) {
        (0, utilities_1.assertIsDefined)(input.selectionStart, 'input.selectionStart', this.handleError);
        (0, utilities_1.assertIsDefined)(input.selectionEnd, 'input.selectionEnd', this.handleError);
        const selectionStart = input.selectionStart;
        const selectionEnd = input.selectionEnd;
        const inputLengthBeforeFormatting = input.value.length;
        const newInputValue = formatPhoneNumber(input.value);
        const inputLengthAfterFormatting = newInputValue.length;
        this.setNewCursorPosition(input, selectionStart, selectionEnd, inputLengthBeforeFormatting, inputLengthAfterFormatting);
    }
    pasteEvent(e) {
        e.preventDefault();
        (0, utilities_1.assertIsDefined)(e.clipboardData, 'e.clipboardData', this.handleError);
        const pastedInput = e.clipboardData.getData('text/plain');
        this.validateAndFormatPastedInput(pastedInput);
    }
    validateAndFormatPastedInput(pastedInput) {
        const phoneNumberMatchResult = /(\+?1)?\s*\(?[2-9]{1}\d{2}\)?\s*-?\s*\d{3}\s*-?\s*\d{4}/.exec(pastedInput);
        (0, utilities_1.assertIsDefined)(phoneNumberMatchResult, 'phoneNumberMatchResult', () => alert('Please paste a valid phone number'));
        const rawPhoneNumber = phoneNumberMatchResult[0];
        this.store.phoneNumber = formatPhoneNumber(rawPhoneNumber);
        this.raisePhoneInputChangedEvent();
    }
    preventBackspaceDeleteOnSpecialCharacters(input, e, selectionStart, selectionEnd) {
        if (e.key === 'Backspace' && isBackspaceAllowed(selectionStart, selectionEnd) === false) {
            e.preventDefault();
            input.setSelectionRange(selectionStart - 1, selectionStart - 1);
            return;
        }
    }
    setNewCursorPosition(input, selectionStart, selectionEnd, inputLengthBeforeFormatting, inputLengthAfterFormatting) {
        const newSelections = generateNewSelectionStartAndEnd(inputLengthBeforeFormatting, inputLengthAfterFormatting, selectionStart, selectionEnd);
        input.setSelectionRange(newSelections.selectionStart, newSelections.selectionEnd);
    }
    handleInputEvent() {
        const input = this.querySelector(`#${this.id}`);
        if (!input) {
            return;
        }
        this.store.phoneNumber = input.value;
        this.raisePhoneInputChangedEvent();
    }
    render(state) {
        if (state.mode === 'READ') {
            return (0, lit_html_1.html) `
                <div>${state.phoneNumber}</div>
            `;
        }
        return (0, lit_html_1.html) `
            <style>
                .jo-phone-number--main-container {
                }

                .jo-phone-number--disabled {
                    background-color: var(--jo-accent-background-light);
                    opacity: 0.7;
                }
            </style>

            <input
                ${(0, test_id_directive_1.testId)('jo-phone-number-input')}
                id="${this.id}"
                ?disabled=${state.readonly === true}
                id="phone-input"
                class="jo-global--input ${state.readonly === true
            ? 'jo-phone-number--disabled'
            : ''}"
                type="tel"
                autocomplete="off"
                .value=${state.phoneNumber}
                placeholder="(000) 000-0000"
                @keydown=${(e) => this.keyDownEvent(e)}
                @keypress=${(e) => this.keyPressEvent(e)}
                @paste=${(e) => this.pasteEvent(e)}
                @blur=${() => (0, utilities_1.raiseCustomEvent)(this, 'phoneinputblur', {})}
                @input=${(e) => this.handleInputEvent()}
                maxlength="14"
            />
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-phone-number', JOPhoneNumber);
function isValidKeyPress(key, selectionStart) {
    if (isKeyANumber(key) === true ||
        isSpecialCharacterAllowed(key, selectionStart) ||
        key === 'Backspace') {
        return true;
    }
    return false;
}
function isKeyANumber(key) {
    return /[0-9]/.test(key) === true ? true : false;
}
function isSpecialCharacterAllowed(key, selectionStart) {
    if ((key === '(' && selectionStart === 0) ||
        (key === ')' && selectionStart === 4) ||
        (key === ' ' && selectionStart === 5) ||
        (key === '-' && selectionStart === 9)) {
        return true;
    }
    return false;
}
function isInputBeginningOneOrZero(selectionStart, key) {
    if ((selectionStart === 0 || selectionStart === 1) && (key === '0' || key === '1')) {
        return true;
    }
    return false;
}
function isBackspaceAllowed(selectionStart, selectionEnd) {
    if (selectionStart === selectionEnd && isBackspaceOnSpecialCharacter(selectionStart)) {
        return false;
    }
    return true;
}
function isBackspaceOnSpecialCharacter(selectionStart) {
    if (selectionStart === 10 ||
        selectionStart === 6 ||
        selectionStart === 5 ||
        selectionStart === 1) {
        return true;
    }
    return false;
}
function formatPhoneNumber(phoneNumber) {
    const phoneNumberAsArray = generatePhoneNumberAsArray(phoneNumber);
    const phoneNumberArrayWithoutCountryCode = removeCountryCodeFromPhoneNumberArray(phoneNumberAsArray);
    const formattedPhoneNumber = generateFormattedPhoneNumberFromArray(phoneNumberArrayWithoutCountryCode);
    return formattedPhoneNumber;
}
function removeCountryCodeFromPhoneNumberArray(phoneNumberAsNumberArray) {
    if (phoneNumberAsNumberArray[0] === '1') {
        const newPhoneNumberArray = phoneNumberAsNumberArray.slice(1);
        return newPhoneNumberArray;
    }
    return phoneNumberAsNumberArray;
}
function generatePhoneNumberAsArray(inputValue) {
    const ignoredCharacters = '+() -';
    const inputValueCharArray = inputValue.split('');
    return inputValueCharArray.filter((character) => {
        return !ignoredCharacters.includes(character);
    });
}
function generateFormattedPhoneNumberFromArray(numberArray) {
    return numberArray.reduce((result, inputNumber, index) => {
        if (index === 0) {
            return '(' + inputNumber;
        }
        if (index === 3) {
            return result + ') ' + inputNumber;
        }
        if (index === 6) {
            return result + '-' + inputNumber;
        }
        return result + inputNumber;
    }, '');
}
function generateNewSelectionStartAndEnd(inputLengthBeforeFormatting, inputLengthAfterFormatting, selectionStart, selectionEnd) {
    if (needsCursorTwoSpacesToTheRight(inputLengthBeforeFormatting, inputLengthAfterFormatting, selectionEnd) === true) {
        return {
            selectionStart: selectionStart + 2,
            selectionEnd: selectionEnd + 2,
        };
    }
    if (needsCursorOneSpaceToTheRight(inputLengthBeforeFormatting, inputLengthAfterFormatting, selectionEnd) == true) {
        return {
            selectionStart: selectionStart + 1,
            selectionEnd: selectionEnd + 1,
        };
    }
    return {
        selectionStart,
        selectionEnd,
    };
}
function needsCursorTwoSpacesToTheRight(inputLengthBeforeFormatting, inputLengthAfterFormatting, selectionEnd) {
    if (inputLengthAfterFormatting === inputLengthBeforeFormatting + 2 &&
        isSelectionEndAtClosingParenthesis(selectionEnd)) {
        return true;
    }
    return false;
}
function needsCursorOneSpaceToTheRight(inputLengthBeforeFormatting, inputLengthAfterFormatting, selectionEnd) {
    if (inputLengthAfterFormatting === inputLengthBeforeFormatting + 1 &&
        isSelectionEndAtOpeningParenthesesOrSpaceOrDash(selectionEnd)) {
        return true;
    }
    return false;
}
function isSelectionEndAtOpeningParenthesesOrSpaceOrDash(selectionEnd) {
    if (selectionEnd === 1 || selectionEnd === 6 || selectionEnd === 10) {
        return true;
    }
    return false;
}
function isSelectionEndAtClosingParenthesis(selectionEnd) {
    return selectionEnd === 5 ? true : false;
}
function generateRandomPhoneInputId() {
    return 'phone-input' + Math.floor(Math.random() * 100000);
}
