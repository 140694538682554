"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateTextListForCompany = exports.fetchTextListForCompany = exports.updateGlobalTextList = exports.fetchGlobalTextList = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchGlobalTextList(globalConfigField) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute(`
            query{
                getGlobal_configs(id:1){
                    id
                    ${globalConfigField}
                }
            }
        `);
        if (gqlResult.succeeded === false)
            return gqlResult;
        const globalConfig = gqlResult.value.data.getGlobal_configs;
        const fieldValue = globalConfig[globalConfigField];
        const textList = fieldValue.split('~');
        return {
            succeeded: true,
            value: textList,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchGlobalTextList', error);
    }
}
exports.fetchGlobalTextList = fetchGlobalTextList;
async function updateGlobalTextList(globalConfigField, textList) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute(`    
            mutation {
                updateGlobal_configs(input:{
                    id:1,
                    ${globalConfigField}:"${textList.join('~')}"
                }){
                    id
                }            
            }
        `);
        if (!gqlResult.succeeded) {
            return gqlResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateGlobalTextList', error);
    }
}
exports.updateGlobalTextList = updateGlobalTextList;
async function fetchTextListForCompany(companyId, columnName) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute(`
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        ${columnName}                      
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const company = gqlResult.value.data.getCompanies;
        const textList = company[columnName]?.split('~');
        return {
            succeeded: true,
            value: textList && textList[0] !== '' ? textList : [],
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-quick-text --> getTextListForCompany', error);
    }
}
exports.fetchTextListForCompany = fetchTextListForCompany;
async function updateTextListForCompany(companyId, textList, columnName) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute(`    
            mutation {
                updateCompanies(input:{
                    id:${companyId},                    
                    ${columnName}:"${textList.join('~')}"
                }){
                    id
                }          
            }
        `);
        if (!gqlResult.succeeded) {
            return gqlResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateTextListForCompany', error);
    }
}
exports.updateTextListForCompany = updateTextListForCompany;
