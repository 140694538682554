"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const css_utils_1 = require("../../utilities/css-utils");
const is_defined_1 = require("../../utilities/is-defined");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-input");
const InitialState = {
    actionItemNote: 'NOT_SET',
    authenticatedUser: 'NOT_SET',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-action-item-note');
class JOActionItemNote extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        if (state.actionItemNote === 'NOT_SET') {
            return (0, lit_html_1.html) ``;
        }
        const recordingUrl = state.actionItemNote.call?.recording_url;
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssName('note-body-container')} {
                    padding: 1rem;
                    background-color: white;
                    border-radius: var(--jo-narrow-border-radius-1);
                }

                .${cssName('note-body-text')} {
                    white-space: pre-line;
                    color: var(--jo-text-default);
                }

                .${cssName('note-meta')} {
                    padding-left: 0.5rem;
                    padding-top: 0.5rem;
                    color: var(--jo-text-default);
                    font-size: 0.75rem;
                }

                .${cssName('recording-link')}:hover {
                    color: var(--jo-primary);
                    cursor: pointer;
                }
            </style>

            <div class="${cssName('main-container')}">
                <div>
                    <div class="${cssName('note-body-container')}">
                        <div class="${cssName('note-body-text')}">${state.actionItemNote.body}</div>
                    </div>
                    <div class="${cssName('note-meta')}">
                        ${new Date(state.actionItemNote.created_at).toLocaleString()}${getAuthorDisplayName(state.actionItemNote.author)}
                        <span
                            class="${cssName('recording-link')}"
                            ?hidden=${(0, is_defined_1.isDefined)(recordingUrl) === false || recordingUrl === ''}
                            @click=${() => (0, utilities_1.joAudioPopUp)(recordingUrl)}
                        >
                            - listen to call
                        </span>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-action-item-note', JOActionItemNote);
function getAuthorDisplayName(author) {
    if (author === null || author === undefined) {
        return '';
    }
    if (author.auth_role === 'JILL' ||
        author.auth_role === 'JILL_OUTBOUND' ||
        author.auth_role === 'JILL_MANAGER' ||
        author.auth_role === 'JILL_BUSINESS_DEVELOPMENT' ||
        author.auth_role === 'JILL_EXECUTIVE') {
        return ` - ${author.first_name}`;
    }
    if (author.auth_role === 'COMPANY_OWNER' || author.auth_role === 'COMPANY_EMPLOYEE') {
        return ` - ${author.email}`;
    }
    return '';
}
