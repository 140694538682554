"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchGoogleCalendarEventsForCompany = exports.fetchCalendarEvents = exports.fetchCalendarEventsResults = exports.fetchAllCalendarEvents = void 0;
const graphql_1 = require("../../../../../services/graphql");
const dates_and_times_1 = require("../../../../../utilities/dates-and-times/dates-and-times");
const is_JOFailure_1 = require("../../../../../utilities/is-JOFailure");
require("../../../jo-button.element");
require("../../../jo-input");
require("../../../jo-loading-text");
const helper_utils_1 = require("./helper-utils");
const mapped_env_variables_1 = require("../../../../../utilities/environment/mapped-env-variables");
async function fetchAllCalendarEvents(params) {
    const { companyId, companyCalendarConnections, startDate, endDate } = params;
    const calendarEventResults = await fetchCalendarEventsResults(companyCalendarConnections, companyId, startDate, endDate);
    const failedResult = calendarEventResults.find(result => result.succeeded === false);
    if ((0, is_JOFailure_1.isJoFailure)(failedResult)) {
        return failedResult;
    }
    const calendarEvents = (0, helper_utils_1.extractFlattenedCalendarEvents)(calendarEventResults);
    const sortedCalendarEvents = getSortedCalendarEvents(calendarEvents);
    return {
        succeeded: true,
        value: sortedCalendarEvents,
    };
}
exports.fetchAllCalendarEvents = fetchAllCalendarEvents;
function getSortedCalendarEvents(calendarEvents) {
    return calendarEvents.sort((a, b) => {
        return (new Date(a.start?.dateTime ?? 0).getTime() - new Date(b.start?.dateTime ?? 0).getTime());
    });
}
async function fetchCalendarEventsResults(companyCalendarConnections, companyId, startDate, endDate) {
    return await Promise.all(companyCalendarConnections.map(async (calendarConnection) => {
        const calendarId = calendarConnection.google_calendar_id;
        const calendarName = calendarConnection.google_calendar_name;
        return await fetchCalendarEvents(companyId, calendarId, startDate, endDate, calendarName);
    }));
}
exports.fetchCalendarEventsResults = fetchCalendarEventsResults;
async function fetchCalendarEvents(companyId, calendarId, startDate, endDate, calendarName) {
    const calendarEventResult = await fetchGoogleCalendarEventsForCompany({
        companyId,
        calendarId,
        startDate,
        endDate,
    });
    if (calendarEventResult.succeeded === false) {
        return calendarEventResult;
    }
    const calendarEvents = calendarEventResult.value;
    const formattedCalendarEvents = calendarEvents.map(ce => {
        return {
            calendarId,
            calendarName,
            ...ce,
        };
    });
    return {
        succeeded: true,
        value: formattedCalendarEvents,
    };
}
exports.fetchCalendarEvents = fetchCalendarEvents;
async function fetchGoogleCalendarEventsForCompany(params) {
    try {
        const { companyId, calendarId, startDate, endDate } = params;
        if (startDate === 'NOT_SET' && endDate === 'NOT_SET')
            return {
                succeeded: false,
                developerMessage: 'Both Start Date and End Date not set',
                userMessage: 'Both Start Date and End Date not set',
            };
        const processedStartDate = startDate === 'NOT_SET' ? getTodaysDate() : startDate;
        const processedEndDate = endDate === 'NOT_SET' || endDate.getTime() === processedStartDate.getTime()
            ? getDateAWeekFromToday()
            : endDate;
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query (
                    $companyId: Int!
                    $calendarId: String!
                    $startDate: DateTime!
                    $endDate: DateTime!
                ) {
                    getGoogleCalendarEventsForCompany(
                        companyId: $companyId
                        calendarId: $calendarId
                        startDate: $startDate
                        endDate: $endDate
                    ) {
                        id
                        location
                        description
                        summary
                        status
                        organizer {
                            displayName
                        }
                        start {
                            dateTime
                            timeZone
                        }
                        end {
                            dateTime
                            timeZone
                        }
                    }
                }
            `, {
            companyId,
            calendarId,
            startDate: processedStartDate,
            endDate: processedEndDate,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const calendarEvents = gqlResult.value.data.getGoogleCalendarEventsForCompany;
        return {
            succeeded: true,
            value: calendarEvents,
        };
    }
    catch (error) {
        throw new Error(`fetchGoogleCalendarEventsForCompany error: ${error}`);
    }
}
exports.fetchGoogleCalendarEventsForCompany = fetchGoogleCalendarEventsForCompany;
function getTodaysDate() {
    return new Date();
}
function getDateAWeekFromToday() {
    return new Date(new Date().getTime() + 7 * dates_and_times_1.oneDayInMilliseconds);
}
