"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOGoogleCalendarEventsForCompany = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../../services/utilities");
const css_utils_1 = require("../../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../../utilities/define-custom-element");
require("../../../jo-button.element");
require("../../../jo-input");
require("../../../jo-loading-text");
const get_columns_for_google_calendar_events_table_1 = require("./get-columns-for-google-calendar-events-table");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const InitialState = {
    companyId: 'NOT_SET',
    eventEndDate: 'NOT_SET',
    eventStartDate: 'NOT_SET',
    companyCalendarEvents: [],
    companyCalendarConnections: [],
    loading: false,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-google-calendar-events-for-company');
class JOGoogleCalendarEventsForCompany extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    set companyId(companyId) {
        this.store.companyId = companyId;
        if (companyId !== 'NOT_SET') {
            this.fetchAndSetUpcomingEventsForCompany();
        }
    }
    async fetchAndSetUpcomingEventsForCompany() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        if (this.store.companyCalendarConnections.length === 0) {
            return;
        }
        this.store.loading = true;
        const allCalendarEventsForCompanyResult = await (0, queries_1.fetchAllCalendarEvents)({
            companyId: this.store.companyId,
            companyCalendarConnections: this.store.companyCalendarConnections,
            startDate: this.store.eventStartDate,
            endDate: this.store.eventEndDate,
        });
        (0, utilities_1.assertSucceeded)(allCalendarEventsForCompanyResult, utilities_1.handleError, this, 'allCalendarEventsForCompanyResult');
        this.store.companyCalendarEvents = allCalendarEventsForCompanyResult.value;
        this.store.loading = false;
    }
    async deleteGoogleCalendarEventForCompany(eventId, calendarId) {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        await (0, mutations_1.deleteCalendarEvent)(this.store.companyId, calendarId, eventId);
    }
    async triggerTableReload() {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        this.fetchAndSetUpcomingEventsForCompany();
    }
    handleEndDateChanged(endDateInputValue) {
        if (endDateInputValue === '') {
            return;
        }
        if (this.store.eventStartDate === 'NOT_SET') {
            return;
        }
        const endDate = new Date(endDateInputValue);
        this.store.eventEndDate = endDate;
        this.triggerTableReload();
    }
    handleStartDateChanged(startDateInputValue) {
        if (startDateInputValue === '') {
            return;
        }
        const startDate = new Date(startDateInputValue);
        this.store.eventStartDate = startDate;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .${cssName('upcoming-events-title')} {
                    font-weight: bold;
                    font-size: 16px;
                    color: var(--jo-text-default);
                    font-family: sans-serif;
                }
            </style>

            <div class="${cssName('main-container')}">
                <div class="${cssName('upcoming-events-title')}">
                    <h3>Upcoming appointments</h3>
                </div>
                <div>
                    ${state.loading === true
            ? (0, lit_html_1.html) `
                              <jo-loading-text></jo-loading-text>
                          `
            : ''}
                    <jo-table
                        ?hidden=${state.loading === true}
                        .maxItemsPerPage=${10}
                        .manuallySuppliedItems=${this.store.companyCalendarEvents}
                        .totalItems=${state.companyCalendarEvents.length}
                        .columns=${(0, get_columns_for_google_calendar_events_table_1.getColumnsForGoogleCalendarEventsTable)(this, state.companyCalendarConnections)}
                        .showSearchBar=${false}
                        .showReloadButton=${true}
                        .showEndDatePicker=${true}
                        .showStartDatePicker=${true}
                        .showTableTitle=${false}
                        @triggerTableReload=${() => {
            this.triggerTableReload();
        }}
                        @endDateChanged=${(e) => {
            this.handleEndDateChanged(e.detail.inputValue);
        }}
                        @startDateChanged=${(e) => {
            this.handleStartDateChanged(e.detail.inputValue);
        }}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
exports.JOGoogleCalendarEventsForCompany = JOGoogleCalendarEventsForCompany;
(0, define_custom_element_1.defineCustomElement)('jo-google-calendar-events-for-company', JOGoogleCalendarEventsForCompany);
