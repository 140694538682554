"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const auth_rules_1 = require("../../../../utilities/auth-rules");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
require("../../jo-button.element");
require("../../jo-fade-in-overlay");
require("../../jo-flag-addressal-form/jo-flag-addressal-form");
require("../../jo-input");
const helper_utils_1 = require("./helper-utils");
const styles_1 = require("./styles");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    actionItemFlag: 'NOT_SET',
    showFlagAddressalForm: false,
};
class JOActionItemFlagCard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        if (state.actionItemFlag === 'NOT_SET') {
            return (0, lit_html_1.html) ``;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.JOActionItemFlagCardStyles}

            <div class="main-container">
                <section class="header-row">
                    <div style="color: red"><strong>FLAGGED</strong></div>

                    <jo-button
                        ?hidden=${shouldHideMarkAsAddressedButton(state)}
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Mark as Addressed'}
                        .inlineStyle=${'font-size: 0.8rem'}
                        @joButtonClick=${() => {
            this.store.showFlagAddressalForm = true;
        }}
                    ></jo-button>

                    <div
                        ?hidden=${state.actionItemFlag.addressals.length === 0}
                        class="addressed-container"
                    >
                        Addressed &nbsp;
                        <span class="material-icons">done_outline</span>
                    </div>
                </section>

                <section>
                    <div>
                        <i>
                            <strong>Title:</strong>
                            ${state.actionItemFlag.title}
                        </i>
                    </div>
                    <div>
                        <i>
                            <strong>Description:</strong>
                            ${state.actionItemFlag.description}
                        </i>
                    </div>
                    <div>
                        <i>
                            <strong>Jill:</strong>
                            ${(0, helper_utils_1.fullName)(state.actionItemFlag.user)}
                        </i>
                    </div>
                    <div>
                        <i>
                            <strong>Flagged by:</strong>
                            ${(0, helper_utils_1.fullName)(state.actionItemFlag.author)},
                            ${(0, helper_utils_1.dispalyDate)(state.actionItemFlag.created_at)}
                        </i>
                    </div>
                </section>

                <section>
                    ${state.actionItemFlag.addressals.map(addressal => (0, lit_html_1.html) `
                            <div class="addressal-info">
                                <div>
                                    <strong>Addressed by:</strong>
                                    ${(0, helper_utils_1.fullName)(addressal.user)}
                                </div>
                                <div>
                                    <strong>Notes:</strong>
                                    ${addressal.description}
                                </div>
                            </div>
                        `)}
                </section>

                <section>
                    <jo-fade-in-overlay
                        .props=${{
            child: (0, lit_html_1.html) `
                                <div class="jo-global--width-50-vw">
                                    <jo-flag-addressal-form
                                        .props=${{
                authenticatedUser: state.authenticatedUser,
                flagId: state.actionItemFlag.id,
            }}
                                    ></jo-flag-addressal-form>
                                </div>
                            `,
            showChild: this.store.showFlagAddressalForm,
        }}
                        @closeoverlay=${() => (this.store.showFlagAddressalForm = false)}
                    ></jo-fade-in-overlay>
                </section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-action-item-flag-card', JOActionItemFlagCard);
function shouldHideMarkAsAddressedButton(state) {
    if (state.actionItemFlag === 'NOT_SET')
        return true;
    return (state.actionItemFlag.addressals.length > 0 ||
        !(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_MANAGER'));
}
