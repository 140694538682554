"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.joContactMergeStyles = void 0;
const lit_html_1 = require("lit-html");
/**
 * The host styles below are very similar to jo-activity-log they should be consolidated to a
 * generic "modal" or "dialog" or "popup" element so that these styles don't need to be duplicated.
 * https://jillsoffice.atlassian.net/browse/JWA-1405
 */
exports.joContactMergeStyles = (0, lit_html_1.html) `
    <link rel="stylesheet" href="/styles/jo-styles.css" />
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <style>
        :host {
            display: flex;
            max-width: 80vw;
            max-height: 100vh;
            flex-direction: column;
            box-sizing: border-box;
            background-color: white;
            border-radius: var(--jo-wide-border-radius-1);
            box-shadow: var(--jo-card-shadow-1);
            padding: 32px;
            --cell-border-width: 2px;
            overflow: auto;
        }

        .activity-log-trigger {
            padding: 3px;
            background-color: var(--jo-primary);
            color: white;
            border-radius: 2px;
            cursor: pointer;
            font-size: 18px;
        }

        .icon-cell {
            text-align: center;
            padding: 8px 0;
            /* so the absolutely positioned item count is positioned relative to this container */
            position: relative;
        }

        .table-grid {
            display: grid;
            /*
                The grid column templates are set in the HTML directly because they depend on how
                many contacts the element has been given
            */
            column-gap: 8px;
        }

        .row-header {
            padding: 8px 16px;
            text-align: right;
            font-weight: bold;
        }

        .contact-data {
            cursor: pointer;
            border: 0 solid var(--jo-accent-background-light);
            border-left-width: var(--cell-border-width);
            border-right-width: var(--cell-border-width);
            transition: border-color 100ms, background-color 100ms;
        }

        .prompt {
            margin-bottom: 24px;
        }

        .table-wrapper {
            align-self: center;
            /* this helps offset the header cells on the left */
            padding-right: 64px;
            max-width: 100%;
        }

        .cell-wrapper,
        .row-header {
            padding: 16px 24px;
            min-width: 100px;
            max-width: 300px;
        }

        .cell-wrapper {
            margin: 0 4px;
            font-size: 1.1em;
        }

        .first-row.contact-data {
            border-top-width: var(--cell-border-width);
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
        }

        .last-row.contact-data {
            border-bottom-width: var(--cell-border-width);
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        .activity-log-count {
            position: absolute;
            margin-top: 3px;
            margin-left: 4px;
            font-family: monospace;
        }

        .contact-data.selected-contact {
            border-color: var(--jo-primary);
            background-color: var(--jo-primary-light);
        }

        jo-button {
            margin: 0 16px;
        }

        .save-error-message {
            color: var(--jo-danger);
            font-weight: bold;
            text-overflow: ellipsis;
            flex-grow: 1;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        footer {
            margin-top: 32px;
            display: flex;
        }
    </style>
`;
