"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areThereNoActionItemNotes = exports.getActionItemLastUpdatedAtTime = void 0;
function getActionItemLastUpdatedAtTime(actionItem) {
    const lastActionItemNote = actionItem.action_item_notes[0];
    if (lastActionItemNote === undefined) {
        return actionItem.updated_at;
    }
    return lastActionItemNote.updated_at;
}
exports.getActionItemLastUpdatedAtTime = getActionItemLastUpdatedAtTime;
function areThereNoActionItemNotes(actionItems) {
    if (actionItems.length === 0)
        return true;
    return actionItems.reduce((result, actionItem) => {
        if (actionItem.action_item_notes.length > 0) {
            return false;
        }
        return result;
    }, true);
}
exports.areThereNoActionItemNotes = areThereNoActionItemNotes;
