"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchAndFormatPreviousResponses = void 0;
const queries_1 = require("./queries");
async function fetchAndFormatPreviousResponses(contactId, currentActionItemId, currentCompanyServices) {
    const results = await Promise.all([
        (0, queries_1.fetchPreviousCustomFieldResponses)(contactId, currentActionItemId),
        (0, queries_1.fetchPreviousLineItemResponses)(contactId, currentActionItemId),
        (0, queries_1.fetchPreviousServiceResponses)(contactId, currentActionItemId),
    ]);
    const previousCustomFieldResponsesResult = results[0];
    if (previousCustomFieldResponsesResult.succeeded === false) {
        return previousCustomFieldResponsesResult;
    }
    const previousCustomFieldResponses = previousCustomFieldResponsesResult.value;
    const previousLineItemResponsesResult = results[1];
    if (previousLineItemResponsesResult.succeeded === false) {
        return previousLineItemResponsesResult;
    }
    const previousLineItemResponses = previousLineItemResponsesResult.value;
    const previousServiceResponsesResult = results[2];
    if (previousServiceResponsesResult.succeeded === false) {
        return previousServiceResponsesResult;
    }
    const previousServiceResponses = previousServiceResponsesResult.value;
    // add filter for archived
    const previousCustomFieldResponsesForCurrentServices = previousCustomFieldResponses.filter(previousCustomFieldResponse => currentCompanyServices.some(currentCompanyService => currentCompanyService.id === previousCustomFieldResponse.service.id));
    const previousLineItemResponsesForCurrentServices = previousLineItemResponses.filter(previousLineItemResponse => currentCompanyServices.some(currentCompanyService => currentCompanyService.id ===
        previousLineItemResponse.service_line_item.service.id));
    const previousServiceResponsesForCurrentServices = previousServiceResponses.filter(previousServiceResponse => currentCompanyServices.some(currentCompanyService => currentCompanyService.id === previousServiceResponse.service.id));
    return {
        succeeded: true,
        value: {
            previousCustomFieldResponses: previousCustomFieldResponsesForCurrentServices,
            previousLineItemResponses: previousLineItemResponsesForCurrentServices,
            previousServiceResponses: previousServiceResponsesForCurrentServices,
        },
    };
}
exports.fetchAndFormatPreviousResponses = fetchAndFormatPreviousResponses;
