"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
require("../jo-input");
require("../jo-alert");
require("./jo-call-screen-transfer-initiated-box");
const state_1 = require("./redux/state");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: state_1.InitialJOCallScreenState.authenticatedUser,
    callAnswered: state_1.InitialJOCallScreenState.callAnswered,
    callEnded: state_1.InitialJOCallScreenState.callEnded,
    callId: state_1.InitialJOCallScreenState.callId,
    callScreenMode: state_1.InitialJOCallScreenState.callScreenMode,
    canInitiateNewTransfer: state_1.InitialJOCallScreenState.canInitiateNewTransfer,
    companyForTransferInstructions: state_1.InitialJOCallScreenState.companyForTransferInstructions,
    direction: state_1.InitialJOCallScreenState.direction,
    externalTransferPhoneNumberInputValue: state_1.InitialJOCallScreenState.externalTransferPhoneNumberInputValue,
    incomingCallType: state_1.InitialJOCallScreenState.incomingCallType,
    incomingTransferCompleted: state_1.InitialJOCallScreenState.incomingTransferCompleted,
    isTransferRecipientJillOnACall: state_1.InitialJOCallScreenState.isTransferRecipientJillOnACall,
    jillCallSid: state_1.InitialJOCallScreenState.jillCallSid,
    jillsToTransferTo: state_1.InitialJOCallScreenState.jillsToTransferTo,
    selectedJillForTransfer: state_1.InitialJOCallScreenState.selectedJillForTransfer,
    showTransferInitiatedBox: state_1.InitialJOCallScreenState.showTransferInitiatedBox,
    transferCallSid: state_1.InitialJOCallScreenState.transferCallSid,
    transferCallStatus: state_1.InitialJOCallScreenState.transferCallStatus,
    transferId: state_1.InitialJOCallScreenState.transferId,
    transferRecipientName: state_1.InitialJOCallScreenState.transferRecipientName,
    transferState: state_1.InitialJOCallScreenState.transferState,
    transferTo: state_1.InitialJOCallScreenState.transferTo,
    userCallSid: state_1.InitialJOCallScreenState.userCallSid,
    userCallStatus: state_1.InitialJOCallScreenState.userCallStatus,
};
const cssNames = (0, css_utils_1.cssPrefixer)('jo-call-screen-transfer-instructions');
class JOCallScreenTransferInstructions extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.isManualTransferEnabled = false;
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState())) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    transferToCompanyEmployeeClicked(employee) {
        (0, utilities_1.assertIsDefined)(employee.phone_number, 'companyUser.phone_number', utilities_1.handleError);
        const transferTo = employee.phone_number;
        (0, utilities_1.raiseCustomEvent)(this, 'transferinitiated', {
            transferTo,
            transferRecipientName: `${employee.first_name} ${employee.last_name}`,
        });
    }
    async transferToJillClicked() {
        (0, utilities_1.assertIsSet)(this.store.selectedJillForTransfer, utilities_1.handleError);
        const transferTo = `client:${this.store.selectedJillForTransfer.id}`;
        (0, utilities_1.raiseCustomEvent)(this, 'transferinitiated', {
            transferTo,
            transferRecipientName: `${this.store.selectedJillForTransfer.first_name} ${this.store.selectedJillForTransfer.last_name}`,
        });
    }
    transferToExternalNumberClicked() {
        if ((0, utilities_1.isPhoneNumberInE164Format)(this.store.externalTransferPhoneNumberInputValue) === false) {
            (0, utilities_1.joAlert)('Notice', 'Invalid phone number');
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'transferinitiated', {
            transferTo: this.store.externalTransferPhoneNumberInputValue,
            transferRecipientName: this.store.externalTransferPhoneNumberInputValue,
        });
    }
    renderManualTransfer(state) {
        if (!this.isManualTransferEnabled) {
            return '';
        }
        return (0, lit_html_1.html) `
            <hr />
            <div class="${cssNames('row')}">
                <div class="${cssNames('transfer-instruction-container')}">Transfer to Jill</div>
                <div class="${cssNames('transfer-instruction-container')}">
                    <jo-input
                        .type=${'SELECT'}
                        .selectOptions=${state.jillsToTransferTo.map((jill) => `${jill.first_name} ${jill.last_name}`)}
                        .defaultOption=${'Select Jill...'}
                        .selectedValue=${getselectedJillForTransferForJOInput(state.selectedJillForTransfer)}
                        @inputchange=${(e) => {
            const selectedJill = getJillFromFullName(e.detail.selectedValue, this.store.jillsToTransferTo);
            (0, utilities_1.raiseCustomEvent)(this, 'jillselectedfortransfer', selectedJill);
        }}
                    ></jo-input>
                </div>
                <div class="${cssNames('transfer-button-container')}">
                    <jo-button
                        ?hidden=${shouldHideTransferButtons(state)}
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Transfer'}
                        .disabled=${state.canInitiateNewTransfer === false ||
            state.selectedJillForTransfer === 'NOT_SET'}
                        @joButtonClick=${() => this.transferToJillClicked()}
                    ></jo-button>
                </div>
            </div>
            <div class="${cssNames('jill-or-external-transfer-container')}">
                <div class="${cssNames('transfer-instruction-container')}">
                    Transfer to External Number
                </div>
                <div class="${cssNames('transfer-instruction-container')}">
                    <jo-input
                        .type=${'PHONE_NUMBER'}
                        .inputValue=${state.externalTransferPhoneNumberInputValue}
                        @inputchange=${(e) => {
            const externalTransferPhoneNumberInputValue = e.detail.inputValue;
            (0, utilities_1.raiseCustomEvent)(this, 'externaltransferphonenumberinputvalue', externalTransferPhoneNumberInputValue);
        }}
                    ></jo-input>
                </div>
                <div class="${cssNames('transfer-button-container')}">
                    <jo-button
                        ?hidden=${shouldHideTransferButtons(state)}
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Transfer'}
                        .disabled=${state.canInitiateNewTransfer === false ||
            (0, utilities_1.isPhoneNumberInE164Format)(state.externalTransferPhoneNumberInputValue) ===
                false}
                        @joButtonClick=${() => this.transferToExternalNumberClicked()}
                    ></jo-button>
                </div>
            </div>
        `;
    }
    render(state) {
        const usersWhoCanReceiveTransfers = getUsersWhoCanReceiveTransfers(state.companyForTransferInstructions);
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />

            <style>
                .${cssNames('main-container')} {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    position: relative;
                }

                .${cssNames('titles-container')} {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                }

                .${cssNames('title-container')} {
                    flex: 3;
                    text-align: center;
                    font-family: sans-serif;
                    font-size: 1.1rem;
                    color: var(--jo-text-default);
                    font-weight: bold;
                }

                .${cssNames('transfer-uninitiated-container')} {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                }

                .${cssNames('transfer-instructions-container')} {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    white-space: pre-line;
                }
                .${cssNames('transfer-instruction-container')} {
                    flex: 3;
                    font-family: sans-serif;
                    text-align: center;
                    background-color: rgba(0, 0, 0, 0.1);
                    box-sizing: border-box;
                    padding: calc(5px + 1vmin);
                    border-radius: calc(1px + 1vmin);
                    margin: calc(5px + 1vmin);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .${cssNames('jill-or-external-transfer-container')} {
                    display: flex;
                    width: 100%;
                    justify-content: space-around;
                }

                .${cssNames('continue-buton-container')} {
                    padding-top: 2rem;
                }
                .${cssNames('transfer-buttons-container')} {
                    box-sizing: border-box;
                    padding-top: 1rem;
                }
                .${cssNames('transfer-button-container')} {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .${cssNames('row')} {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 1rem;
                }
                .${cssNames('transfer-initiated-box-container')} {
                    font-family: sans-serif;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgb(0, 0, 0, 0.2);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            </style>

            <div class="${cssNames('main-container')}">
                <div class="${cssNames('titles-container')}">
                    <div class="${cssNames('title-container')}">Situation</div>
                    <div class="${cssNames('title-container')}">Hours</div>
                    <div class="${cssNames('title-container')}">Person</div>
                    <div style="flex: 1"></div>
                </div>
                ${usersWhoCanReceiveTransfers.map((employee) => {
            if (employee.phone_number === null ||
                employee.phone_number === undefined ||
                !(0, utilities_1.isPhoneNumberInE164Format)(employee.phone_number)) {
                return;
            }
            return (0, lit_html_1.html) `
                        <div class="${cssNames('transfer-instructions-container')}">
                            <div class="${cssNames('transfer-instruction-container')}">
                                ${employee.call_transfer_situation}
                            </div>
                            <div class="${cssNames('transfer-instruction-container')}">
                                ${employee.hours_to_receive_transfer_calls}
                            </div>
                            <div class="${cssNames('transfer-instruction-container')}">
                                ${employee.first_name} ${employee.last_name}
                                ${formatTitle(employee.title)}
                            </div>
                            <div class="${cssNames('transfer-button-container')}">
                                <jo-button
                                    ?hidden=${shouldHideTransferButtons(state)}
                                    .type=${'ACTION_BUTTON_2'}
                                    .text=${'Transfer'}
                                    .disabled=${state.canInitiateNewTransfer === false}
                                    @joButtonClick=${() => this.transferToCompanyEmployeeClicked(employee)}
                                ></jo-button>
                            </div>
                        </div>
                    `;
        })}
                ${state.callScreenMode !== 'ACTION_ITEM'
            ? (0, lit_html_1.html) `
                          ${this.renderManualTransfer(state)}
                          <div
                              class="${cssNames('transfer-initiated-box-container')}"
                              ?hidden=${state.showTransferInitiatedBox === false}
                          >
                              <jo-call-screen-transfer-initiated-box
                                  .props=${{
                isTransferRecipientJillOnACall: state.isTransferRecipientJillOnACall,
                transferCallSid: state.transferCallSid,
                transferCallStatus: state.transferCallStatus,
                transferRecipientName: state.transferRecipientName,
                transferState: state.transferState,
                transferTo: state.transferTo,
                userCallStatus: state.userCallStatus,
            }}
                              ></jo-call-screen-transfer-initiated-box>
                          </div>
                      `
            : (0, lit_html_1.html) ``}

                <div class="${cssNames('continue-buton-container')}">
                    <jo-button
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'Continue'}
                        @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'continued')}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-transfer-instructions', JOCallScreenTransferInstructions);
function getUsersWhoCanReceiveTransfers(company) {
    if (company === 'NOT_SET')
        return [];
    return company.users.filter(user => user.can_receive_call_transfer);
}
function getJillFromFullName(jillFullName, jills) {
    const selectedJillForTransfer = jills.find((jill) => {
        return (jillFullName.includes(jill.first_name || 'NOT_FOUND') &&
            jillFullName.includes(jill.last_name || 'NOT_FOUND'));
    });
    if (selectedJillForTransfer === undefined) {
        return 'NOT_SET';
    }
    else {
        return selectedJillForTransfer;
    }
}
function getselectedJillForTransferForJOInput(selectedJillForTransfer) {
    if (selectedJillForTransfer === 'NOT_SET') {
        return '';
    }
    else {
        return `${selectedJillForTransfer.first_name} ${selectedJillForTransfer.last_name}`;
    }
}
function formatTitle(title) {
    if (title === '') {
        return '';
    }
    else {
        return `(${title})`;
    }
}
function shouldHideTransferButtons(state) {
    if (state.callAnswered === false ||
        state.callEnded === true ||
        state.showTransferInitiatedBox === true ||
        state.userCallStatus === 'COMPLETED') {
        return true;
    }
    return false;
}
