"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryExecuteAsync = void 0;
async function tryExecuteAsync(errorHandler, fn, ...args) {
    try {
        return await fn(...args);
    }
    catch (error) {
        await errorHandler(error);
        return 'FAILED';
    }
}
exports.tryExecuteAsync = tryExecuteAsync;
