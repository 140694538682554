"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchAddressByOccupantIdFromCompanyId = void 0;
const graphql_1 = require("../../../../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../../../../utilities/environment/mapped-env-variables");
/**
 * This function will be used only if the address cannot be found by address_id In the old system,
 * address_id on type companies is never used, rather occupant_id on the addresses type is used We
 * will not be doing that going forward, but this is for backward compatibility sake
 *
 * @param companyId
 */
async function fetchAddressByOccupantIdFromCompanyId(companyId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($companyId: Int!) {
                getCompanyAddressByOccupantId(companyId: $companyId) {
                    id
                    city
                    line_1
                    line_2
                    postal_code
                    state
                    zip_code
                }
            }
        `, {
        companyId,
    });
    if (gqlResult.succeeded === false) {
        return {
            ...gqlResult,
            developerMessage: `getAddressByOccupantIdFromCompanyId error: ${gqlResult.developerMessage}`,
        };
    }
    const address = gqlResult.value.data.getCompanyAddressByOccupantId;
    if (address === null || address === undefined) {
        return {
            succeeded: true,
            value: 'NOT_FOUND',
        };
    }
    return {
        succeeded: true,
        value: address,
    };
}
exports.fetchAddressByOccupantIdFromCompanyId = fetchAddressByOccupantIdFromCompanyId;
