"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSetPreviousResponsesAction = void 0;
const utilities_1 = require("../../../../../../services/utilities");
const helper_utils_1 = require("./helper-utils");
const queries_1 = require("./queries");
async function createSetPreviousResponsesAction(contactId, currentActionItemId, companyId) {
    (0, utilities_1.assertIsSet)(companyId, utilities_1.handleError);
    const currentCompanyServicesResult = await (0, queries_1.getCurrentCompanyServices)(companyId);
    (0, utilities_1.assertSucceeded)(currentCompanyServicesResult, utilities_1.handleError);
    const previousResponsesResult = await (0, helper_utils_1.fetchAndFormatPreviousResponses)(contactId, currentActionItemId, currentCompanyServicesResult.value);
    if (previousResponsesResult.succeeded === false) {
        return previousResponsesResult;
    }
    const previousResponses = previousResponsesResult.value;
    return {
        succeeded: true,
        value: {
            type: 'SET_PREVIOUS_RESPONSES',
            ...previousResponses,
        },
    };
}
exports.createSetPreviousResponsesAction = createSetPreviousResponsesAction;
