"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-input");
const InitialState = {
    child: (0, lit_html_1.html) ``,
    showChild: false,
};
class JOFadeInOverlay extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <style>
                .jo-fade-in-overlay--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    position: relative;
                }

                .jo-fade-in-overlay--overlay {
                    position: fixed;
                    background-color: rgba(1, 1, 1, 0.5);
                    box-sizing: border-box;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                    transition: opacity 0.15s ease-in-out;
                }

                .jo-fade-in-overlay--child-container {
                    pointer-events: all;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .jo-fade-in-overlay--hide {
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity 0.1s ease-out;
                }
            </style>

            <div class="jo-fade-in-overlay--main-container">
                <div
                    class="jo-fade-in-overlay--overlay ${state.showChild === false
            ? 'jo-fade-in-overlay--hide'
            : ''}"
                    @click=${() => {
            (0, utilities_1.raiseCustomEvent)(this, 'closeoverlay');
        }}
                >
                    <div
                        class="jo-fade-in-overlay--child-container ${state.showChild === false
            ? 'jo-fade-in-overlay--hide'
            : ''}"
                        @click=${(event) => event.stopPropagation()}
                    >
                        ${state.child}
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-fade-in-overlay', JOFadeInOverlay);
