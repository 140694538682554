"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInACallMode = void 0;
function isInACallMode(callScreenMode) {
    return (callScreenMode === 'INCOMING_EXISTING_ACTION_ITEM' ||
        callScreenMode === 'INCOMING_NEW_ACTION_ITEM' ||
        callScreenMode === 'OUTGOING_EXISTING_ACTION_ITEM' ||
        callScreenMode === 'OUTGOING_NEW_ACTION_ITEM');
}
exports.isInACallMode = isInACallMode;
