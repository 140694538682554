"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEndCallAction = void 0;
const twilio_1 = require("../../../../../../services/twilio");
const assign_jill_to_call_1 = require("../../../utilities/assign-jill-to-call");
async function createEndCallAction(state) {
    if (state.incomingConnection === 'NOT_SET' ||
        state.authenticatedUser === 'NOT_SET' ||
        state.callId === 'NOT_SET') {
        return {
            succeeded: false,
            userMessage: 'This call has major issues',
            developerMessage: 'This call has major issues',
        };
    }
    const durationEventInfo = (0, twilio_1.getTimestampCallEndDurationEventInfo)(new Date(), state.authenticatedUser.id, state.callId);
    // only record the ending of the call if you are the final Jill
    if (state.internalTransferSent === false) {
        (0, twilio_1.setDurationEventLocal)(durationEventInfo);
    }
    state.incomingConnection.disconnect();
    // only record the ending of the call if you are the final Jill
    if (state.internalTransferSent === false) {
        (0, twilio_1.setDurationEventRemote)(durationEventInfo);
        (0, assign_jill_to_call_1.assignJillToCall)(state.callId, state.authenticatedUser.id);
    }
    return {
        succeeded: true,
        value: {
            type: 'END_CALL',
            callEnded: true,
        },
    };
}
exports.createEndCallAction = createEndCallAction;
