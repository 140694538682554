"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAccountHelpTicket = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function createAccountHelpTicket({ companyId, userId, title, description, }) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($companyId: Int!, $userId: Int!, $title: String!, $description: String!) {
                createAccount_help_tickets(
                    input: {
                        company: {connect: {id: $companyId}}
                        user: {connect: {id: $userId}}
                        title: $title
                        description: $description
                        current_status: PENDING_REVIEW
                    }
                ) {
                    id
                }
            }
        `, {
        companyId,
        userId,
        title,
        description,
    });
    return gqlResult.data.createAccount_help_tickets.id;
}
exports.createAccountHelpTicket = createAccountHelpTicket;
