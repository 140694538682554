"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addMessageToTwilioConversationIfNecessary = exports.addJillAsParticipantToTwilioConversation = void 0;
const utilities_1 = require("../../services/utilities");
const get_twilio_conversation_sid_1 = require("./get-twilio-conversation-sid");
async function addJillAsParticipantToTwilioConversation(conversationSid, userId) {
    const formData = new FormData();
    formData.append('userId', userId.toString());
    formData.append('conversationSid', conversationSid);
    await (0, utilities_1.sendRouterRequest)('/conversation/addParticipant', formData);
}
exports.addJillAsParticipantToTwilioConversation = addJillAsParticipantToTwilioConversation;
async function addMessageToTwilioConversationIfNecessary(args) {
    const conversationSid = await (0, get_twilio_conversation_sid_1.getConversationSidFromChat)(args.chatId);
    if (conversationSid) {
        const formData = new FormData();
        formData.append('userId', args.userId.toString());
        formData.append('conversationSid', conversationSid);
        formData.append('body', args.body);
        await (0, utilities_1.sendRouterRequest)('/conversation/addNewMessage', formData);
    }
}
exports.addMessageToTwilioConversationIfNecessary = addMessageToTwilioConversationIfNecessary;
