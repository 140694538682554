"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchActionItems = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchActionItems(contactId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($contactId: Int!) {
                    Action_items__JOActivityLog: findAction_items(
                        filter: {contact_id: {eq: $contactId}}
                        orderBy: {field: "updated_at", order: DESC}
                    ) {
                        items {
                            id
                            created_at
                            updated_at
                            title
                            call {
                                id
                                recording_url
                            }
                            contact {
                                first_name
                                last_name
                            }
                            action_item_notes(orderBy: {field: "updated_at", order: DESC}) {
                                id
                                created_at
                                updated_at
                                body
                                author {
                                    id
                                    first_name
                                    last_name
                                    auth_role
                                    email
                                    profile_picture_url
                                }
                                call {
                                    id
                                    recording_url
                                }
                            }
                            chat {
                                id
                                messages(orderBy: {field: "id", order: ASC}) {
                                    id
                                    source
                                    body
                                    from_origin
                                    created_at
                                    user {
                                        first_name
                                        last_name
                                    }
                                }
                            }
                        }
                    }
                }
            `, {
            contactId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const actionItems = gqlResult.value.data.Action_items__JOActivityLog.items;
        return {
            succeeded: true,
            value: actionItems,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchActionItems', error);
    }
}
exports.fetchActionItems = fetchActionItems;
