"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchContact = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchContact(contactId) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($contactId: Int!) {
                    Contacts__JOContact: getContacts(id: $contactId) {
                        id
                        addresses(orderBy: {field: "created_at", order: ASC}) {
                            id
                            line_1
                            line_2
                            city
                            county
                            postal_code
                            province
                            state
                            zip_code
                        }
                        company {
                            id
                        }
                        company_name
                        email
                        first_name
                        last_name
                        phone_number
                        phone_numbers(orderBy: {field: "id", order: ASC}) {
                            id
                            number
                        }
                        sales
                        status
                        where_did_you_hear_about_us
                    }
                }
            `, {
            contactId,
        });
        if (result.succeeded === false) {
            return result;
        }
        const contact = result.value.data.Contacts__JOContact;
        return {
            succeeded: true,
            value: contact,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-contact fetchContact', error);
    }
}
exports.fetchContact = fetchContact;
