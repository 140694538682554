"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
require("../../jo-button.element");
require("../../jo-input");
const helper_utils_1 = require("../jo-chat-sidebar/utils/helper-utils");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    message: 'NOT_SET',
    author: '',
    isIncoming: true,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-message');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOChatMessage extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssNames('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                }

                .${cssNames('incoming')} {
                    align-items: start;
                }

                .${cssNames('outgoing')} {
                    align-items: end;
                }

                .${cssNames('author')} {
                    font-size: 0.8rem;
                }

                .${cssNames('body')} {
                    border-radius: 0.4rem;
                    padding: 0rem 0.8rem 1rem 0.8rem;
                    background-color: var(--jo-primary-light);
                    white-space: pre-line;
                    max-width: 12rem;
                    overflow-wrap: break-word;
                }

                .${cssNames('outgoing-author')} {
                    text-align: right;
                }

                .${cssNames('incoming-body')} {
                    background-color: var(--jo-accent-background-light);
                }

                .${cssNames('date')} {
                    font-size: 0.8rem;
                    text-align: right;
                }
            </style>

            <div
                class="${cssNames('main-container')} ${state.isIncoming
            ? cssNames('incoming')
            : cssNames('outgoing')}"
            >
                ${this.store.message !== 'NOT_SET'
            ? (0, lit_html_1.html) `
                          <div
                              class="${cssNames('author')} ${state.isIncoming === false
                ? cssNames('outgoing-author')
                : ''}"
                          >
                              ${this.store.author}
                          </div>
                          <div
                              class="${cssNames('body')} ${state.isIncoming
                ? cssNames('incoming-body')
                : ''}"
                          >
                              ${this.store.message.body}
                          </div>
                          <div class="${cssNames('date')}">
                              ${(0, helper_utils_1.getDateTimeFormatForCard)(this.store.message.created_at)}
                          </div>
                      `
            : ''}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-message', JOChatMessage);
