"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFullName = void 0;
function getFullName(person) {
    if (!person)
        return '';
    const firstName = person.first_name ?? '';
    const lastName = person.last_name ?? '';
    if (!firstName && !lastName)
        return '';
    return person.first_name + ' ' + person.last_name;
}
exports.getFullName = getFullName;
