"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createJillAndUserReconnectedAfterTransferDeclineAction = void 0;
function createJillAndUserReconnectedAfterTransferDeclineAction(jillAndUserReconnectedAfterTransferDeclineSubscription) {
    if (jillAndUserReconnectedAfterTransferDeclineSubscription !== 'NOT_SET') {
        jillAndUserReconnectedAfterTransferDeclineSubscription.close();
    }
    return {
        succeeded: true,
        value: {
            type: 'JILL_AND_USER_RECONNECTED_AFTER_TRANSFER_DECLINE',
        },
    };
}
exports.createJillAndUserReconnectedAfterTransferDeclineAction = createJillAndUserReconnectedAfterTransferDeclineAction;
