"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTransferCompleteAction = void 0;
const twilio_1 = require("../../../../../../services/twilio");
async function createTransferCompleteAction(transferId, userCallSid, transferType) {
    const transferCompleteResult = await (0, twilio_1.twilioTransferComplete)(transferId, userCallSid);
    if (transferCompleteResult.succeeded === false) {
        return transferCompleteResult;
    }
    return {
        succeeded: true,
        value: {
            type: 'TRANSFER_COMPLETE',
            externalTransferSent: transferType === 'EXTERNAL_TRANSFER',
            internalTransferSent: transferType === 'INTERNAL_TRANSFER',
        },
    };
}
exports.createTransferCompleteAction = createTransferCompleteAction;
