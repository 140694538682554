"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchActionItem = void 0;
const graphql_1 = require("../../../../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../../../../utilities/environment/mapped-env-variables");
async function fetchActionItem(actionItemId) {
    // NOTE: the orderBy for each sub selection set is being handled on the backend
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($actionItemId: Int!) {
                getActionItemForCallScreen(actionItemId: $actionItemId) {
                    id
                    action_item_flags {
                        id
                        addressals {
                            id
                            created_at
                            description
                            user {
                                id
                                first_name
                                last_name
                            }
                        }
                        description
                        title
                        created_at
                        updated_at
                        author {
                            id
                            first_name
                            last_name
                        }
                        user {
                            id
                            first_name
                            last_name
                        }
                    }
                    action_item_notes {
                        id
                        action_item {
                            id
                        }
                        author {
                            id
                            auth_role
                            email
                            first_name
                            last_name
                        }
                        body
                        created_at
                    }
                    action_item_status_type {
                        id
                    }
                    call {
                        id
                        contact {
                            id
                        }
                        recording_url
                    }
                    company {
                        id
                        same_caller_id
                    }
                    contact {
                        id
                    }
                    custom_field_responses {
                        id
                        custom_field {
                            id
                            select_values {
                                id
                                text_value
                            }
                            text_value
                            type
                        }
                        response
                        service {
                            id
                        }
                    }
                    followup_request_date
                    price_quote
                    service_line_item_responses {
                        id
                        quantity
                        service_line_item {
                            id
                            service {
                                id
                            }
                        }
                    }
                    service_responses {
                        id
                        service {
                            id
                            created_at
                            custom_fields {
                                id
                                call_screen_appear
                                call_screen_require
                                select_values {
                                    id
                                    text_value
                                }
                                text_value
                                type
                            }
                            description
                            estimates
                            jobs
                            line_items {
                                id
                                calculate
                                description
                                price
                                service {
                                    id
                                    name
                                }
                            }
                            name
                            price_minimum
                            pricing
                        }
                    }
                    slug
                    subtitle
                    title
                }
            }
        `, {
        actionItemId,
    });
    if (gqlResult.succeeded === false) {
        return {
            ...gqlResult,
            developerMessage: `set-action-item-id -> fetch-action-items.ts -> fetchActionItem error: ${gqlResult.developerMessage}`,
        };
    }
    const actionItem = gqlResult.value.data.getActionItemForCallScreen;
    return {
        succeeded: true,
        value: actionItem,
    };
}
exports.fetchActionItem = fetchActionItem;
