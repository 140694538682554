"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUpdateDurationsAction = void 0;
function createUpdateDurationsAction(state) {
    if (state.collectingDuration === false) {
        return {
            type: 'UPDATE_DURATIONS',
            actionItemDurationMinutes: state.actionItemDurationMinutes,
            actionItemDurationSeconds: state.actionItemDurationSeconds,
            actionItemDurationTotal: state.actionItemDurationTotal,
            callDurationMinutes: state.callDurationMinutes,
            callDurationSeconds: state.callDurationSeconds,
            callDurationTotal: state.callDurationTotal,
        };
    }
    if ((state.callAnswered === true ||
        state.callScreenMode === 'OUTGOING_EXISTING_ACTION_ITEM' ||
        state.callScreenMode === 'OUTGOING_NEW_ACTION_ITEM') &&
        state.callEnded === false) {
        const callDurationTotal = state.callDurationTotal + 1;
        const callDurationMinutes = Math.floor(callDurationTotal / 60);
        const callDurationSeconds = callDurationTotal % 60;
        return {
            type: 'UPDATE_DURATIONS',
            actionItemDurationMinutes: state.actionItemDurationMinutes,
            actionItemDurationSeconds: state.actionItemDurationSeconds,
            actionItemDurationTotal: state.actionItemDurationTotal,
            callDurationMinutes,
            callDurationSeconds,
            callDurationTotal,
        };
    }
    if (state.callAnswered === true && state.callEnded === true) {
        const actionItemDurationTotal = state.actionItemDurationTotal + 1;
        const actionItemDurationMinutes = Math.floor(actionItemDurationTotal / 60);
        const actionItemDurationSeconds = actionItemDurationTotal % 60;
        return {
            type: 'UPDATE_DURATIONS',
            actionItemDurationMinutes,
            actionItemDurationSeconds,
            actionItemDurationTotal,
            callDurationMinutes: state.callDurationMinutes,
            callDurationSeconds: state.callDurationSeconds,
            callDurationTotal: state.callDurationTotal,
        };
    }
    return {
        type: 'UPDATE_DURATIONS',
        actionItemDurationMinutes: state.actionItemDurationMinutes,
        actionItemDurationSeconds: state.actionItemDurationSeconds,
        actionItemDurationTotal: state.actionItemDurationTotal,
        callDurationMinutes: state.callDurationMinutes,
        callDurationSeconds: state.callDurationSeconds,
        callDurationTotal: state.callDurationTotal,
    };
}
exports.createUpdateDurationsAction = createUpdateDurationsAction;
