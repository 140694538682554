"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
require("../jo-input");
const state_1 = require("./redux/state");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    isTransferRecipientJillOnACall: state_1.InitialJOCallScreenState.isTransferRecipientJillOnACall,
    transferCallSid: state_1.InitialJOCallScreenState.transferCallSid,
    transferCallStatus: state_1.InitialJOCallScreenState.transferCallStatus,
    transferRecipientName: state_1.InitialJOCallScreenState.transferRecipientName,
    transferState: state_1.InitialJOCallScreenState.transferState,
    transferTo: state_1.InitialJOCallScreenState.transferTo,
    userCallStatus: state_1.InitialJOCallScreenState.userCallStatus,
};
const futileTransferCallStatuses = [
    'FAILED',
    'NO_ANSWER',
    'BUSY',
    'COMPLETED',
];
class JOCallScreenTransferInitiatedBox extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState())) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />

            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    font-family: sans-serif;
                    box-sizing: border-box;
                    padding: 1rem;
                    background-color: white;
                    box-shadow: var(--jo-big-shadow-1);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    justify-content: space-evenly;
                }

                .transfer-state-info-container {
                    box-sizing: border-box;
                    padding-bottom: 1rem;
                    text-align: center;
                    font-weight: bold;
                    font-size: 1.1rem;
                }

                .transfer-recipient-info-container {
                }

                .user-disconnected-container {
                    padding-top: 1rem;
                }

                .button-container {
                    padding-top: 1rem;
                }
            </style>

            <div class="main-container">
                <div class="transfer-state-info-container">
                    ${futileTransferCallStatuses.includes(state.transferCallStatus) ||
            state.isTransferRecipientJillOnACall === true
            ? (0, lit_html_1.html) `
                              <div class="transfer-recipient-info-container">
                                  <div>
                                      ${generateReasonTextForAutomaticTransferDecline(state.transferTo, state.isTransferRecipientJillOnACall, state.transferCallStatus, state.transferState, state.transferRecipientName)}
                                  </div>
                                  <div>Reconnecting to client/customer...</div>
                              </div>
                          `
            : (0, lit_html_1.html) `
                              <div class="transfer-recipient-info-container">
                                  <div>Transfering to ${state.transferRecipientName}...</div>
                              </div>
                          `}
                    ${state.userCallStatus === 'COMPLETED'
            ? (0, lit_html_1.html) `
                              <div class="user-disconnected-container">
                                  <div>The client/customer disconnected.</div>
                                  <div>Cannot complete transfer.</div>
                              </div>
                          `
            : ''}
                </div>

                <div class="button-container">
                    <jo-button
                        .type=${'ACTION_BUTTON_GREEN'}
                        .text=${'Complete Transfer'}
                        .disabled=${state.transferCallSid === 'NOT_SET' ||
            state.userCallStatus === 'COMPLETED'}
                        @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'completetransferclicked')}
                    ></jo-button>
                </div>

                <div class="button-container">
                    <jo-button
                        .type=${'DELETE_BUTTON_1'}
                        .text=${'Decline Transfer'}
                        .disabled=${state.transferCallSid === 'NOT_SET'}
                        @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'declinetransferclicked')}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-transfer-initiated-box', JOCallScreenTransferInitiatedBox);
function generateReasonTextForAutomaticTransferDecline(to, isTransferRecipientJillOnACall, transferCallStatus, transferState, transferRecipientName) {
    if (isInternalTransferAndRecipientJillIsOnACall(to, isTransferRecipientJillOnACall)) {
        return `${transferRecipientName} is on another call.`;
    }
    if (isInternalTransferAndRecipientJillNotOnline(to, transferCallStatus)) {
        return `${transferRecipientName} is not online`;
    }
    if (isInternalTransferAndRecipientJillPrematurelyDisconnected(to, transferCallStatus)) {
        return `${transferRecipientName} accidentally disconnected.`;
    }
    if (transferRecipientDisconnected(transferCallStatus, transferState)) {
        return `${transferRecipientName} disconnected.`;
    }
    return '';
}
function isInternalTransfer(to) {
    return to.startsWith('client:');
}
function isInternalTransferAndRecipientJillIsOnACall(to, isTransferRecipientJillOnACall) {
    return isInternalTransfer(to) && isTransferRecipientJillOnACall === true;
}
function isInternalTransferAndRecipientJillNotOnline(to, transferCallStatus) {
    return isInternalTransfer(to) && transferCallStatus === 'NO_ANSWER';
}
function isInternalTransferAndRecipientJillPrematurelyDisconnected(to, transferCallStatus) {
    return isInternalTransfer(to) && transferCallStatus === 'BUSY';
}
function transferRecipientDisconnected(transferCallStatus, transferState) {
    return transferCallStatus === 'COMPLETED' && transferState !== 'COMPLETED';
}
