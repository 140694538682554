"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignJillToCall = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function assignJillToCall(callId, jillId) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($callId: Int!, $jillId: Int!) {
                updateCalls(input: {id: $callId, user: {connect: {id: $jillId}}}) {
                    id
                }
            }
        `, {
        callId,
        jillId,
    });
    if (result.succeeded === false) {
        return result;
    }
    return {
        succeeded: true,
    };
}
exports.assignJillToCall = assignJillToCall;
