"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendJillTyping = exports.sendChatMessage = exports.getSortedMessages = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function getSortedMessages(chatId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($chatId: Int) {
                    findChat_messages(
                        filter: {chat_id: {eq: $chatId}}
                        orderBy: {field: "created_at", order: ASC}
                    ) {
                        items {
                            id
                            body
                            updated_at
                            created_at
                            status
                            contact {
                                id
                                first_name
                                last_name
                            }
                            user {
                                id
                                first_name
                                last_name
                            }
                            source
                        }
                    }
                }
            `, {
            chatId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultList = gqlResult.value.data.findChat_messages.items;
        return {
            succeeded: true,
            value: resultList,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getSortedMessages', error);
    }
}
exports.getSortedMessages = getSortedMessages;
async function sendChatMessage(params) {
    try {
        const { chatId, userId, body, companyId } = params;
        await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreateChat_messagesInput!) {
                    createChat_messages(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                chat: {
                    connect: {
                        id: chatId,
                    },
                },
                company: {
                    connect: {
                        id: companyId,
                    },
                },
                user: {
                    connect: {
                        id: userId,
                    },
                },
                body: body,
                source: 'JILL_DASHBOARD',
                status: 'NEW_MESSAGE',
            },
        });
    }
    catch (error) {
        throw new Error('sendChatMessage Error:' + error);
    }
}
exports.sendChatMessage = sendChatMessage;
async function sendJillTyping(chatId, isTyping) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($chatId: Int!, $isTyping: Boolean!) {
                    jillTypingInChat(chatId: $chatId, isTyping: $isTyping)
                }
            `, {
            chatId,
            isTyping,
        });
    }
    catch (error) {
        throw new Error('sendJillTyping Error:' + error);
    }
}
exports.sendJillTyping = sendJillTyping;
