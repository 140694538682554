"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const constants_1 = require("../../../services/constants");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
const state_1 = require("./redux/state");
const does_company_accept_transfers_1 = require("../../../utilities/companies/does-company-accept-transfers/does-company-accept-transfers");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const create_element_bound_interval_1 = require("../../../utilities/timeouts/create-element-bound-interval");
const dates_and_times_1 = require("../../../utilities/dates-and-times/dates-and-times");
const get_full_name_1 = require("../../../utilities/get-full-name");
const InitialState = {
    company: state_1.InitialJOCallScreenState.companyForCompanyInfo,
    callScreenMode: state_1.InitialJOCallScreenState.callScreenMode,
    companyId: state_1.InitialJOCallScreenState.companyId,
    companyTime: 'Unknown',
    intervalId: -1,
};
class JOCallScreenCompanyInfo extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const originalCompany = this.store.company;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (originalCompany !== props.company) {
            if (this.store.company !== 'NOT_SET') {
                this.store.companyTime = getCompanyTime(this.store.company);
            }
            (0, create_element_bound_interval_1.createElementBoundInterval)({
                element: this,
                callback: () => {
                    if (this.store.company !== 'NOT_SET') {
                        this.store.companyTime = getCompanyTime(this.store.company);
                    }
                },
                intervalMs: dates_and_times_1.oneSecondInMilliseconds,
                intervalName: '',
            });
        }
    }
    render(state) {
        if (state.company === 'NOT_SET') {
            return (0, lit_html_1.html) `
                Loading...
            `;
        }
        const employeesString = getEmployeesString(state.company);
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    font-family: sans-serif;
                    padding-right: 0.5rem;
                }

                .items-container {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }

                .hover {
                    cursor: help;
                }

                .name {
                    font-size: 21px;
                    color: var(--jo-secondary);
                    font-weight: bold;
                    align-self: flex-start;
                }

                .name a:hover {
                    color: var(--jo-text-default);
                    cursor: pointer;
                }

                .items-item {
                    padding-left: 0.1rem;
                    padding-right: 0.1rem;
                    font-size: 17px;
                    padding-bottom: 0.2rem;
                }

                .items-item-link {
                    text-decoration: none;
                    color: black;
                }
                .items-item-link:hover {
                    color: var(--jo-secondary);
                }

                .items-item-title {
                    font-weight: bold;
                }

                .items-item-divider {
                    padding-left: 0.2rem;
                    font-weight: bold;
                    color: #fc943c;
                }

                [data-title]:hover:after {
                    opacity: 1;
                    transition: all 0.1s ease 0.5s;
                    visibility: visible;
                    cursor: help;
                }

                [data-title]:after {
                    content: attr(data-title);
                    position: absolute;
                    top: 80%;
                    left: 0;
                    padding: 4px 4px 4px 8px;
                    font-size: 1rem;
                    white-space: pre-line;
                    width: 450px;
                    border-radius: 5px;
                    box-shadow: 0px 0px 4px #222;
                    background-color: #f1f1f1;
                    opacity: 0;
                    z-index: 99;
                    visibility: hidden;
                    cursor: help;
                }
                [data-title] {
                    position: relative;
                    cursor: help;
                }

                .enabled {
                    color: var(--jo-primary);
                }

                .disabled {
                    color: var(--jo-accent-background-light);
                }

                .action-buttons {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding-top: ${shouldAddButtonPadding(state) === true ? '0.3rem' : ''};
                }

                .transfer-acceptance-icon {
                    padding-left: 0.5rem;
                }

                .company-basics {
                    display: flex;
                    align-items: center;
                    padding-bottom: 0.3rem;
                }

                .company-time {
                    padding-left: 2rem;
                    font-size: 0.9rem;
                }

                .pt1 {
                    padding: 0.5rem;
                }

                .action-button {
                    padding-left: 0.5rem;
                    padding-right: 0.1rem;
                    padding-bottom: 0.2rem;
                }

                .company-info-container {
                    font-size: 0.8rem;
                }

                .details {
                    font-size: 0.9rem;
                }

                .hover-button {
                    box-sizing: border-box;
                    font-size: 0.7rem;
                    background-color: var(--jo-accent-background-light);
                    border-radius: 2px;
                    padding: 0.25rem 0.5rem;
                    box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.5);
                    text-decoration: none;
                    outline: none;
                    border: none;
                    white-space: nowrap;
                }

                .hover-button:hover {
                    background-color: #e0e0e0;
                    cursor: help;
                }

                .click-button {
                    box-sizing: border-box;
                    font-size: 0.8rem;
                    color: white;
                    background-color: var(--jo-secondary);
                    border-radius: 2px;
                    padding: 0.25rem 0.5rem;
                    box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.5);
                    text-decoration: none;
                    outline: none;
                    border: none;
                    white-space: nowrap;
                }

                .click-button:hover {
                    box-shadow: none;
                    background-color: var(--jo-secondary-light);
                    text-decoration: none;
                }

                .no-click-button {
                    box-sizing: border-box;
                    font-size: 0.8rem;
                    color: white;
                    background-color: var(--jo-secondary);
                    border-radius: 2px;
                    padding: 0.25rem 0.5rem;
                    box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.5);
                    text-decoration: none;
                    outline: none;
                    border: none;
                    white-space: nowrap;
                }

                .no-click-button:hover {
                    cursor: not-allowed;
                }
            </style>

            <div class="main-container">
                <div class="flex-row">
                    <div class="f3 flex-col">
                        <div class="company-basics">
                            <div class="name">
                                ${state.callScreenMode === 'ACTION_ITEM'
            ? (0, lit_html_1.html) `
                                          <a
                                              title="View Company Details"
                                              @click=${() => (0, utilities_1.navigate)(`/company/company-details?companyId=${state.companyId}`)}
                                          >
                                              ${state.company.name}
                                          </a>
                                      `
            : state.company.name}
                            </div>
                            <div class="company-time" title="Company Time">
                                ${state.companyTime.substring(0, 17)}
                                ${state.companyTime.substring(20, 22)} - ${state.company.timezone}
                            </div>
                        </div>

                        <div class="action-buttons">
                            <div
                                class="action-button"
                                ?hidden=${state.company.estimates_enabled === false}
                            >
                                <a
                                    class="${hasUrl(state, 'estimates_url')
            ? 'click-button'
            : 'no-click-button'}"
                                    @click=${(e) => {
            preventUrlButtonClickActionIfNecessary(state, e, 'estimates_url');
        }}
                                    href=${state.company.estimates_url}
                                    target=${'_blank'}
                                >
                                    Estimates
                                </a>
                            </div>
                            <div
                                class="action-button"
                                ?hidden=${state.company.jobs_enabled === false}
                            >
                                <a
                                    class="${hasUrl(state, 'jobs_url')
            ? 'click-button'
            : 'no-click-button'}"
                                    @click=${(e) => {
            preventUrlButtonClickActionIfNecessary(state, e, 'jobs_url');
        }}
                                    href=${state.company.jobs_url}
                                    target=${'_blank'}
                                >
                                    Jobs
                                </a>
                            </div>
                            <div
                                class="action-button"
                                ?hidden=${state.company.save_without_scheduling_enabled === false}
                            >
                                <a
                                    class="${hasUrl(state, 'save_without_scheduling_url')
            ? 'click-button'
            : 'no-click-button'}"
                                    @click=${(e) => {
            preventUrlButtonClickActionIfNecessary(state, e, 'save_without_scheduling_url');
        }}
                                    href=${state.company.save_without_scheduling_url}
                                    target=${'_blank'}
                                >
                                    Save Without Scheduling
                                </a>
                            </div>
                            <div
                                class="action-button"
                                ?hidden=${state.company.pricing_enabled === false}
                            >
                                <a
                                    class="${hasUrl(state, 'pricing_url')
            ? 'click-button'
            : 'no-click-button'}"
                                    @click=${(e) => {
            preventUrlButtonClickActionIfNecessary(state, e, 'pricing_url');
        }}
                                    href=${state.company.pricing_url}
                                    target=${'_blank'}
                                >
                                    Pricing
                                </a>
                            </div>
                            <div
                                class="action-button"
                                ?hidden=${state.company.forms_enabled === false}
                            >
                                <a
                                    class="${hasUrl(state, 'forms_url')
            ? 'click-button'
            : 'no-click-button'}"
                                    @click=${(e) => {
            preventUrlButtonClickActionIfNecessary(state, e, 'forms_url');
        }}
                                    href=${state.company.forms_url}
                                    target=${'_blank'}
                                >
                                    Form
                                </a>
                            </div>
                            <div class="transfer-acceptance-icon action-button">
                                ${makeTransferAcceptanceIcon(state.company)}
                            </div>
                        </div>
                        <div style="display: flex">
                            <div class="action-buttons">
                                <div
                                    class="action-button hover items-item"
                                    data-title="${state.company.description?.trim()}"
                                    ?hidden=${state.company.description === ''}
                                >
                                    <button class="hover-button">Description</button>
                                </div>
                                <div
                                    class="action-button hover items-item"
                                    data-title="${state.company.directions?.trim()}"
                                    ?hidden=${state.company.directions === ''}
                                >
                                    <button class="hover-button">Directions</button>
                                </div>
                                <div
                                    class="action-button hover items-item"
                                    data-title="${employeesString?.trim()}"
                                    ?hidden=${employeesString === ''}
                                >
                                    <button class="hover-button">Employees</button>
                                </div>
                                <div
                                    class="action-button hover items-item"
                                    data-title="${state.company.website_url?.trim()}"
                                    ?hidden=${state.company.website_url === ''}
                                >
                                    <button class="hover-button">Website</button>
                                </div>
                                <div
                                    class="action-button hover items-item"
                                    data-title="${state.company.business_hours?.trim()}"
                                    ?hidden=${state.company.business_hours === ''}
                                >
                                    <button class="hover-button">Business Hours</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pt1 flex-col details" ?hidden=${shouldHideOwnerInfoContainer()}>
                        <div
                            class="f1"
                            ?hidden=${state.company.organization?.owner?.first_name === '' &&
            state.company.organization?.owner?.last_name === ''}
                        >
                            <strong>Owner:</strong>
                            ${state.company.organization?.owner?.first_name ?? ''}
                            ${state.company.organization?.owner?.last_name ?? ''}
                        </div>

                        <div class="f1" ?hidden=${state.company.public_email === ''}>
                            <strong>Email:</strong>
                            ${state.company.public_email}
                        </div>

                        <div class="f1" ?hidden=${state.company.business_number === ''}>
                            <strong>Company Number:</strong>
                            ${state.company.business_number
            ? (0, utilities_1.formatE164PhoneNumberForDisplay)(state.company.business_number)
            : ''}
                        </div>
                    </div>

                    <div class="pt1 flex-col details" ?hidden=${shouldHideTwilioPodFaxContainer()}>
                        <div class="f1" ?hidden=${state.company.twilio_phone_number === ''}>
                            <strong>Callback Number:</strong>
                            ${(0, utilities_1.formatE164PhoneNumberForDisplay)(state.company.twilio_phone_number)}
                        </div>
                        <div class="f1" ?hidden=${state.company.pod?.name === ''}>
                            <strong>Pod:</strong>
                            ${state.company.pod?.name ?? ''}
                        </div>

                        <div class="f1" ?hidden=${state.company.fax === ''}>
                            <strong>Fax:</strong>
                            ${state.company.fax !== ''
            ? (0, utilities_1.formatE164PhoneNumberForDisplay)(state.company.fax)
            : ''}
                        </div>
                    </div>

                    <div class="f2 pt1 flex-col details" ?hidden=${shouldHideAddressContainer()}>
                        <div class="f1" ?hidden=${state.company.address?.line_1 === ''}>
                            <strong>Address:</strong>
                            ${state.company.address?.line_1 ?? ''}
                        </div>

                        <div class="f1" ?hidden=${state.company.address?.line_2 === ''}>
                            ${state.company.address?.line_2 ?? ''},
                        </div>

                        <div class="f1" ?hidden=${shouldHideAddressCityStateZip()}>
                            ${state.company.address?.city ?? ''},
                            ${state.company.address?.state ?? ''}
                            ${state.company.address?.zip_code ?? ''}
                        </div>
                    </div>
                </div>
            </div>
        `;
        function shouldAddButtonPadding(state) {
            if (state.company !== 'NOT_SET') {
                return (state.company.estimates_enabled ||
                    state.company.jobs_enabled ||
                    state.company.save_without_scheduling_enabled ||
                    state.company.pricing_enabled ||
                    state.company.forms_enabled);
            }
            return true;
        }
        function shouldHideAddressCityStateZip() {
            if (state.company !== 'NOT_SET') {
                if (state.company.address) {
                    return (state.company.address.city === '' &&
                        state.company.address.state === '' &&
                        state.company.address.zip_code === '');
                }
            }
            return false;
        }
        function shouldHideAddressContainer() {
            if (state.company !== 'NOT_SET') {
                if (state.company.address) {
                    return (state.company.address?.line_1 === '' &&
                        state.company.address?.line_2 === '' &&
                        shouldHideAddressCityStateZip() === true);
                }
            }
            return false;
        }
        function shouldHideTwilioPodFaxContainer() {
            if (state.company !== 'NOT_SET') {
                if (state.company.address) {
                    return (state.company.twilio_phone_number === '' &&
                        state.company.pod?.name === '' &&
                        state.company.fax === '');
                }
            }
            return false;
        }
        function shouldHideOwnerInfoContainer() {
            if (state.company !== 'NOT_SET') {
                if (state.company.address) {
                    return (state.company.organization?.owner?.first_name === '' &&
                        state.company.organization?.owner?.last_name === '' &&
                        state.company.public_email === '' &&
                        state.company.verified_business_number === '');
                }
            }
            return false;
        }
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-company-info', JOCallScreenCompanyInfo);
function makeTransferAcceptanceIcon(company) {
    return (0, does_company_accept_transfers_1.doesCompanyAcceptTransfers)(company)
        ? (0, lit_html_1.html) `
              <div class="no-click-button">Transfers</div>
          `
        : (0, lit_html_1.html) ``;
}
function getCompanyTime(company) {
    if (company.timezone === undefined || company.timezone === null) {
        return 'Unknown';
    }
    else {
        return new Date().toLocaleString('en-US', {
            timeZone: constants_1.TimezonesValuesToInternationalStandardsMap[company.timezone],
        });
    }
}
function getEmployeesString(company) {
    const usersVisibleOnCallScreen = company.users.filter(user => user.visible_on_call_screen === true);
    return usersVisibleOnCallScreen
        .map(user => `${getEmployeeTitle(user)}: ${(0, get_full_name_1.getFullName)(user)}`)
        .join('\n');
}
function getEmployeeTitle(user) {
    if (user.auth_role === 'COMPANY_OWNER') {
        return 'Owner';
    }
    if (user.title !== '') {
        return user.title;
    }
    else {
        return 'Employee';
    }
}
function preventUrlButtonClickActionIfNecessary(state, clickEvent, urlProperty) {
    if (hasUrl(state, urlProperty) === false) {
        clickEvent.preventDefault();
    }
}
function hasUrl(state, urlProperty) {
    return state.company !== 'NOT_SET' && state.company[urlProperty] !== '';
}
