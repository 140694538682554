"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPropsForOverlayToDisplayModal = void 0;
const lit_html_1 = require("lit-html");
const jo_call_screen_utils_1 = require("./jo-call-screen-utils");
const store_1 = require("../../../../services/store");
require("../../jo-activity-log/jo-activity-log");
require("../../jo-contact-merge/jo-contact-merge-element");
function getPropsForOverlayToDisplayModal(state) {
    const elementToShow = getElementToShowInOverlay(state);
    return {
        child: (0, lit_html_1.html) `
            <div
                style="max-width: 100vw; display: flex; align-items: center; justify-content: center;"
            >
                ${elementToShow}
            </div>
        `,
        showChild: !!elementToShow,
    };
}
exports.getPropsForOverlayToDisplayModal = getPropsForOverlayToDisplayModal;
function getElementToShowInOverlay(state) {
    if (state.showContactActivityLog) {
        const activityLogProps = {
            authenticatedUser: state.authenticatedUser,
            contact: state.contactForActivityLog,
            allowActionItemSelect: (0, jo_call_screen_utils_1.getCanAssignNewContactToActionItem)(state) === true,
            isMobileDevice: store_1.GlobalStore.getState().isMobileDevice,
        };
        return (0, lit_html_1.html) `
            <jo-activity-log
                style="display: block;"
                class="jo-global--width-50-vw"
                .props=${activityLogProps}
            ></jo-activity-log>
        `;
    }
    else if (state.mergeContactDetails) {
        const contactMergeInputs = state.mergeContactDetails;
        return (0, lit_html_1.html) `
            <jo-contact-merge .props=${contactMergeInputs}></jo-contact-merge>
        `;
    }
    else {
        return undefined;
    }
}
