"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCanAssignNewContactToActionItem = void 0;
function getCanAssignNewContactToActionItem(state) {
    if (state.callScreenMode === 'ACTION_ITEM' && state.actionItem === 'NOT_SET') {
        return true;
    }
    if (state.callScreenMode === 'INCOMING_NEW_ACTION_ITEM' ||
        state.callScreenMode === 'OUTGOING_NEW_ACTION_ITEM' ||
        state.callScreenMode === 'CHAT') {
        return true;
    }
    return false;
}
exports.getCanAssignNewContactToActionItem = getCanAssignNewContactToActionItem;
