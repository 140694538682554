"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.connectUserToCall = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function connectUserToCall(userId, callId) {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($input: UpdateCallsInput!) {
                updateCalls(input: $input) {
                    id
                }
            }
        `, {
        input: makeConnectUserToCallInput(callId, userId),
    });
}
exports.connectUserToCall = connectUserToCall;
function makeConnectUserToCallInput(callId, userId) {
    return {
        id: callId,
        user: {
            connect: {
                id: userId,
            },
        },
    };
}
