"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTransferDeclineAction = void 0;
const twilio_1 = require("../../../../../../services/twilio");
async function createTransferDeclineAction(transferId, userCallSid) {
    const transferDeclineResult = await (0, twilio_1.twilioTransferDecline)(transferId, userCallSid);
    if (transferDeclineResult.succeeded === false) {
        return transferDeclineResult;
    }
    return {
        succeeded: true,
        value: {
            type: 'TRANSFER_DECLINE',
        },
    };
}
exports.createTransferDeclineAction = createTransferDeclineAction;
