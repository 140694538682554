"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderCallInstructions = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const html_sanitization_1 = require("../../../utilities/html-sanitization");
require("../jo-company/jo-company-call-instruction-editor");
const state_1 = require("./redux/state");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    company: state_1.InitialJOCallScreenState.companyForCallInstructions,
    loading: false,
    visibleCallInstruction: 'NOT_SET',
};
class JOCallScreenCallInstructions extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const originalCompany = this.store.company;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.company !== originalCompany && this.store.company !== 'NOT_SET') {
            const firstCallInstruction = this.store.company.call_instructions[0];
            if (firstCallInstruction === undefined) {
                this.store.visibleCallInstruction = 'NOT_SET';
            }
            else {
                this.store.visibleCallInstruction = firstCallInstruction;
            }
        }
    }
    instructionTabClick(callInstruction) {
        this.store.visibleCallInstruction = callInstruction;
    }
    render(state) {
        if (state.company === 'NOT_SET') {
            return (0, lit_html_1.html) `
                <div>Loading...</div>
            `;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                }

                .tabs {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                }

                .tab {
                    font-weight: bold;
                    flex: 1;
                    cursor: pointer;
                    font-size: calc(7px + 1vmin);
                    background-color: var(--jo-accent-background-light);
                    border-radius: 4px;
                    border: solid 1px #979797;
                    color: var(--jo-text-default);
                }

                .tab:focus {
                    background-color: var(--jo-primary);
                    color: white;
                }

                .body {
                    height: 100%;
                    overflow: hidden;
                    padding-left: 0.5rem;
                    padding-top: 0.5rem;
                }

                .instructions {
                    height: 100%;
                    overflow-y: scroll;
                    font-size: calc(5px + 1vmin);
                }

                @media (max-width: 1400px) {
                    .tab {
                        font-size: 0.7rem;
                    }
                }

                @media (max-width: 1000px) {
                    .tab {
                        font-size: 0.6rem;
                    }
                }
            </style>

            <div class="main-container">
                <div class="tabs">
                    ${state.company.call_instructions.map((callInstruction) => {
            return (0, lit_html_1.html) `
                                <button
                                    class="tab"
                                    @click=${() => this.instructionTabClick(callInstruction)}
                                >
                                    ${getInstructionsTitle(callInstruction.title)}
                                </button>
                            `;
        })}
                </div>

                <div class="body">
                    ${state.company.call_instructions.map((callInstruction) => {
            return (0, lit_html_1.html) `
                                <div
                                    class="instructions"
                                    ?hidden=${state.visibleCallInstruction === 'NOT_SET' ||
                state.visibleCallInstruction.id !== callInstruction.id}
                                >
                                    ${renderCallInstructions(callInstruction.instructions)}
                                </div>
                            `;
        })}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-call-instructions', JOCallScreenCallInstructions);
function renderCallInstructions(instructionsHTML) {
    const formattedHtml = removeNegativeLeftMargin(instructionsHTML);
    return (0, html_sanitization_1.renderHTMLStringSafely)(formattedHtml);
}
exports.renderCallInstructions = renderCallInstructions;
function removeNegativeLeftMargin(instructionsHtml) {
    return instructionsHtml.replace(/margin-left: -.*;/g, 'margin: 0;');
}
function getInstructionsTitle(rawTitle) {
    return rawTitle.replace(/ instructions/i, '');
}
