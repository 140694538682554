"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNoteBody = exports.doNoPreviousNotesContainThisGeneratedText = exports.doesActionItemNeedNewGeneratedCustomResponsesText = exports.joinResponseTexts = exports.getResponseTexts = exports.createCustomResponsesText = void 0;
const utilities_1 = require("../../../../../../../services/utilities");
function createCustomResponsesText(selectedServices, customFieldResponsesInputValues, pricingInputValues) {
    const responseTexts = getResponseTexts(selectedServices, customFieldResponsesInputValues, pricingInputValues);
    return joinResponseTexts(responseTexts);
}
exports.createCustomResponsesText = createCustomResponsesText;
function getResponseTexts(selectedServices, customFieldResponsesInputValues, pricingInputValues) {
    const selectedServicesText = getSelectedServicesText(selectedServices);
    const customFieldsText = getCustomFieldsText(selectedServices, customFieldResponsesInputValues);
    const serviceLineItemsText = getServiceLineItemsText(pricingInputValues);
    const priceQuotedText = getPriceQuotedText(pricingInputValues);
    return [
        selectedServicesText,
        customFieldsText,
        serviceLineItemsText,
        priceQuotedText,
    ];
}
exports.getResponseTexts = getResponseTexts;
function joinResponseTexts(responseTexts) {
    return responseTexts.filter(text => text !== '')?.join('\n\n\n\n') ?? '';
}
exports.joinResponseTexts = joinResponseTexts;
function getPriceQuotedText(pricingInputValues) {
    if (pricingInputValues.serviceLineItemResponsesInputValues.length === 0 &&
        pricingInputValues.priceQuote === 0)
        return '';
    return `Price Quoted: ${(0, utilities_1.formatUSDCentsForDisplay)(pricingInputValues.priceQuote)}`;
}
function getSelectedServicesText(selectedServices) {
    if (selectedServices.length === 0)
        return '';
    const selectedServicesResponsesText = selectedServices
        .map(service => `- ${service.name}`)
        .join('\n');
    const selectedServicesText = `Selected Services:\n\n${selectedServicesResponsesText}`;
    return selectedServicesText;
}
function getServiceLineItemsText(pricingInputValues) {
    if (pricingInputValues.serviceLineItemResponsesInputValues.length === 0)
        return '';
    const serviceLineItemResponsesText = getTextForLineItemResponse(pricingInputValues);
    const serviceLineItemsText = `Service Line Items:\n\n${serviceLineItemResponsesText}`;
    return serviceLineItemsText;
}
function getTextForLineItemResponse(pricingInputValues) {
    return pricingInputValues.serviceLineItemResponsesInputValues
        .map(lineItemResponse => `${lineItemResponse.service_line_item.description}: ${lineItemResponse.quantity}`)
        .join('\n');
}
function getCustomFieldsText(selectedServices, customFieldResponsesInputValues) {
    if (customFieldResponsesInputValues.length === 0 || selectedServices.length === 0)
        return '';
    let customFieldResponseTextBlocks = getTextBlocksForCustomFieldResponses(selectedServices, customFieldResponsesInputValues);
    return 'Custom Fields:\n\n' + customFieldResponseTextBlocks.join('\n\n');
}
function getTextBlocksForCustomFieldResponses(selectedServices, customFieldResponsesInputValues) {
    let customFieldTexts = [];
    for (const service of selectedServices) {
        const customFieldText = getCustomFieldResponseTextForService(service, customFieldResponsesInputValues);
        customFieldTexts.push(customFieldText);
    }
    return customFieldTexts;
}
function getCustomFieldResponseTextForService(service, customFieldResponsesInputValues) {
    const customFieldInputsForService = customFieldResponsesInputValues.filter(c => c.service.id === service.id);
    const customFieldResponsesText = customFieldInputsForService
        .map(c => `${c.customField.text_value}: ${c.response}`)
        .join('\n');
    const customFieldText = `- ${service.name}\n${customFieldResponsesText}`;
    return customFieldText;
}
function doesActionItemNeedNewGeneratedCustomResponsesText(previousActionItemNotes, generatedResponseText) {
    if (generatedResponseText === '')
        return false;
    return doNoPreviousNotesContainThisGeneratedText(previousActionItemNotes, generatedResponseText);
}
exports.doesActionItemNeedNewGeneratedCustomResponsesText = doesActionItemNeedNewGeneratedCustomResponsesText;
function doNoPreviousNotesContainThisGeneratedText(previousActionItemNotes, generatedNoteText) {
    return !previousActionItemNotes.some(note => doesNoteBodyIncludeGeneratedText(note, generatedNoteText));
}
exports.doNoPreviousNotesContainThisGeneratedText = doNoPreviousNotesContainThisGeneratedText;
function doesNoteBodyIncludeGeneratedText(note, generatedNoteText) {
    const noteBody = note.body ?? '';
    return noteBody.includes(generatedNoteText);
}
function getNoteBody(noteText, generatedResponsesText) {
    return noteText.length === 0
        ? generatedResponsesText
        : noteText + '\n\n\n\n\n' + generatedResponsesText;
}
exports.getNoteBody = getNoteBody;
