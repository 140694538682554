"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForJoChatMessengerJill = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-messenger-jill');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
exports.stylesForJoChatMessengerJill = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssNames('main-container')} {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
            }

            .${cssNames('conversation-container')} {
                flex: 1;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            }

            .${cssNames('text-container')} {
                display: flex;
                flex-direction: row;
                background-color: var(--jo-accent-background-light);
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                padding-bottom: 2rem;
                padding-top: 0.2rem;
            }

            .${cssNames('text-area')} {
                flex: 1;
                resize: none;
            }

            .${cssNames('action-icon')} {
                color: var(--jo-primary);
                cursor: pointer;
            }

            .${cssNames('action-icon')}:hover {
                color: var(--jo-primary-light);
            }

            .${cssNames('icon-container')} {
                padding: 0.5rem;
                display: flex;
                flex-direction: column;
            }

            .${cssNames('incoming-message')} {
                width: 80%;
                display: flex;
            }

            .${cssNames('outgoing-message')} {
                width: 80%;
                display: flex;
                margin-left: auto;
                margin-right: 0;
                justify-content: flex-end;
            }

            .${cssNames('dots-cont')} {
                width: 2rem;
                border-radius: 0.4rem;
                padding: 0.5rem 0.5rem 0.5rem 0.5rem;
                background-color: var(--jo-accent-background-light);
            }
            .${cssNames('dot')} {
                width: 5px;
                height: 5px;
                background-color: var(--jo-text-default);
                display: inline-block;
                border-radius: 50%;
                right: 0px;
                bottom: 0px;
                margin: 0px 0px;
                position: relative;
                animation: jump 1s infinite;
            }
            .${cssNames('dot-1')} {
                -webkit-animation-delay: 100ms;
                animation-delay: 100ms;
            }
            .${cssNames('dot-2')} {
                -webkit-animation-delay: 200ms;
                animation-delay: 200ms;
            }
            .${cssNames('dot-3')} {
                -webkit-animation-delay: 300ms;
                animation-delay: 300ms;
            }
            @keyframes jump {
                0% {
                    bottom: 0px;
                }
                20% {
                    bottom: 5px;
                }
                40% {
                    bottom: 0px;
                }
            }
        </style>
    `;
})();
