"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchGlobalTextList = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function fetchGlobalTextList(globalConfigField) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute(`
            query{
                getGlobal_configs(id:1){
                    id
                    ${globalConfigField}
                }
            }
        `);
        const globalConfig = gqlResult.data.getGlobal_configs;
        const fieldValue = globalConfig[globalConfigField];
        const textList = fieldValue.split('~');
        return textList;
    }
    catch (error) {
        throw new Error(`fetchGlobalTextList error: ${error}`);
    }
}
exports.fetchGlobalTextList = fetchGlobalTextList;
