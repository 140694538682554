"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
require("./jo-action-item-note");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-call-screen/jo-call-screen-quick-notes");
require("./jo-input");
const InitialState = {
    actionItemNotes: [],
    authenticatedUser: 'NOT_SET',
    displayCopyButton: false,
    noteText: '',
    quickNotes: [],
};
class JOActionItemNotes extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    copyButtonClicked() {
        const copyButtonIcon = document.getElementById('jo-action-item-notes--copy-clipboard-button');
        if (copyButtonIcon) {
            copyButtonIcon.classList.add('jo-action-item-notes--notes-copy-clipboard-button-clicked');
            copyButtonIcon.title = 'Copied!';
            (0, utilities_1.raiseCustomEvent)(this, 'copyActionItemToClipboard');
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-action-item-notes--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    word-break: break-word;
                }

                .jo-action-item-notes--notes-container {
                    display: flex;
                    flex: auto;
                }

                .jo-action-item-notes--notes-textarea-container {
                    flex: 9;
                    padding-bottom: 0.5rem;
                }

                .jo-action-item-notes--notes-textarea {
                    width: 100%;
                    height: 100%;
                    resize: none;
                    flex: 1;
                    padding: 0.5rem;
                    box-sizing: border-box;
                    font-size: 1rem;
                }

                .jo-action-item-notes--quick-notes-container {
                    flex: 0.5;
                    align-self: center;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    align-items: center;
                }

                .jo-action-item-notes--old-notes-container {
                    padding-right: 0.3rem;
                    padding-left: 0.3rem;
                }

                .jo-action-item-notes--note-container {
                    padding-top: 1rem;
                    padding-bottom: 0.5rem;
                }

                .jo-action-item-notes--notes-copy-clipboard-button {
                    color: var(--jo-text-default);
                    cursor: pointer;
                }

                .jo-action-item-notes--notes-copy-clipboard-button-clicked:hover {
                    transform: scale(0.8);
                }
            </style>

            <div class="jo-action-item-notes--main-container">
                <div class="jo-action-item-notes--notes-container">
                    <div class="jo-action-item-notes--notes-textarea-container">
                        <textarea
                            class="jo-action-item-notes--notes-textarea jo-global--text-area"
                            placeholder="Notes"
                            @input=${(e) => {
            this.store.noteText = e.target.value;
            (0, utilities_1.raiseCustomEvent)(this, 'notetextchanged', e.target.value);
        }}
                            .value=${this.store.noteText}
                        ></textarea>
                    </div>

                    <div class="jo-action-item-notes--quick-notes-container">
                        <i
                            ?hidden=${!state.displayCopyButton}
                            id="jo-action-item-notes--copy-clipboard-button"
                            class="material-icons jo-action-item-notes--notes-copy-clipboard-button"
                            title="Copy action item info to clipboard for CRM"
                            @click=${() => {
            this.copyButtonClicked();
        }}
                            @mouseleave=${() => {
            resetClassesOnCopyButtonIcon();
        }}
                        >
                            content_copy
                        </i>
                        <jo-call-screen-quick-notes
                            .notes=${state.quickNotes}
                            @quicknote=${(e) => {
            const noteText = `${this.store.noteText}${e.detail}`;
            this.store.noteText = noteText;
            (0, utilities_1.raiseCustomEvent)(this, 'notetextchanged', noteText);
        }}
                        ></jo-call-screen-quick-notes>
                    </div>
                </div>

                <div class="jo-action-item-notes--old-notes-container">
                    ${state.actionItemNotes.map((actionItemNote) => {
            return (0, lit_html_1.html) `
                                <div class="jo-action-item-notes--note-container">
                                    <jo-action-item-note
                                        .props=${{
                actionItemNote,
                authenticatedUser: state.authenticatedUser,
            }}
                                    ></jo-action-item-note>
                                </div>
                            `;
        })}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-action-item-notes', JOActionItemNotes);
function resetClassesOnCopyButtonIcon() {
    const copyButtonIcon = document.getElementById('jo-action-item-notes--copy-clipboard-button');
    if (copyButtonIcon) {
        copyButtonIcon.classList.remove('jo-action-item-notes--notes-copy-clipboard-button-clicked');
        copyButtonIcon.title = 'Copy action item info to clipboard for CRM';
    }
}
