"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {};
class JOTrainingTipOfTheWeek extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    connectedCallback() {
        (0, utilities_1.raiseCustomEvent)(this, 'loaded', true);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-training-tip-main-container {
                    box-shadow: 0 0 2px rgb(0, 0, 0, 0.5);
                    border-radius: 4px;
                    font-family: sans-serif;

                    color: var(--jo-text-default);
                    background-color: var(--jo-secondary);
                    color: white;
                    font-size: 0.9rem;

                    width: 20rem;
                }

                .jo-training-tip-header-container {
                    display: flex;
                }

                .jo-training-tip-header {
                    margin-left: 0.5rem;
                }

                .jo-training-tip-icon {
                    margin-left: 1rem;
                    margin-top: 0.5rem;
                    font-size: 3rem;
                }

                .jo-training-tip-content-container {
                    margin: 0.5rem;
                }
            </style>

            <div class="jo-training-tip-main-container">
                <div class="jo-training-tip-header-container">
                    <h3 class="jo-training-tip-header">TRAINING TIP OF THE WEEK</h3>
                    <span class="material-icons jo-training-tip-icon">school</span>
                </div>
                <div class="jo-training-tip-content-container">
                    <p>${`Avoid saying "I don't know"`}</p>
                    <p>
                        ${`Instead say "Great question! Let me have __ give you a call back about that!"`}
                    </p>
                </div>
                <br />
                <br />
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-training-tip-of-the-week', JOTrainingTipOfTheWeek);
