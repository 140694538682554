"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMainContactMergeChildTemplate = void 0;
const lit_html_1 = require("lit-html");
const common_1 = require("@augment-vir/common");
const utilities_1 = require("../../../services/utilities");
const rowTitles = {
    first_name: 'First Name',
    last_name: 'Last Name',
    addresses: 'Addresses',
    phone_numbers: 'Phone Numbers',
    email: 'Email',
    where_did_you_hear_about_us: 'Where they heard about us',
};
function contactPropertyToDisplayString(loadedContact, propertyName) {
    if (propertyName === 'addresses') {
        return loadedContact.addresses.map((address) => {
            const addressString = [
                address.line_1,
                address.line_2,
                address.city ? `${address.city},` : '',
                address.state,
                address.zip_code,
            ]
                .filter(common_1.isTruthy)
                .join(' ');
            return (0, lit_html_1.html) `
                ${addressString}
                <br />
            `;
        });
    }
    else if (propertyName === 'phone_numbers') {
        return loadedContact.phone_numbers.map(phoneNumber => {
            const formattedNumber = (0, utilities_1.formatE164PhoneNumberForDisplay)(phoneNumber.number);
            return (0, lit_html_1.html) `
                ${formattedNumber}
                <br />
            `;
        });
    }
    else {
        return String(loadedContact[propertyName]);
    }
}
function arrangeDataForContactsTable(loadedContacts) {
    const justEmptyArraysForStarters = (0, common_1.getObjectTypedKeys)(rowTitles).reduce((allData, currentRowKey) => {
        allData[currentRowKey] = [];
        return allData;
    }, {});
    loadedContacts.forEach(loadedContact => {
        (0, common_1.getObjectTypedKeys)(justEmptyArraysForStarters).forEach(dataKey => {
            justEmptyArraysForStarters[dataKey].push(contactPropertyToDisplayString(loadedContact, dataKey));
        });
    });
    return justEmptyArraysForStarters;
}
var RowStatusEnum;
(function (RowStatusEnum) {
    RowStatusEnum["first"] = "first-row";
    RowStatusEnum["last"] = "last-row";
    RowStatusEnum["none"] = "";
})(RowStatusEnum || (RowStatusEnum = {}));
function getContactsComparisonTable({ rootElement, selectedIndex, loadedContacts, clickCallback, actionItemLengths, }) {
    const contactsTableData = arrangeDataForContactsTable(loadedContacts);
    const rowKeys = (0, common_1.getObjectTypedKeys)(contactsTableData);
    return (0, lit_html_1.html) `
        <div
            class="table-grid"
            style="grid-template-columns: max-content repeat(${loadedContacts.length}, max-content)"
        >
            ${rowKeys.map((tableKey, index) => {
        const rowTitle = rowTitles[tableKey];
        const rowValues = contactsTableData[tableKey];
        const rowStatus = index === 0
            ? RowStatusEnum.first
            : index === rowKeys.length - 1
                ? RowStatusEnum.last
                : RowStatusEnum.none;
        return getContactTableRow(rowTitle, rowValues, rowStatus, selectedIndex, clickCallback);
    })}
            <div></div>
            ${loadedContacts.map((contact, contactIndex) => {
        const actionItemLength = actionItemLengths?.[contactIndex];
        return (0, lit_html_1.html) `
                    <td class="icon-cell">
                        <i
                            class="material-icons activity-log-trigger"
                            title="Open activity log"
                            @click=${() => {
            (0, utilities_1.raiseCustomEvent)(rootElement, 'contactactivitylogselected', contact);
        }}
                        >
                            list
                        </i>
                        <span class="activity-log-count">
                            ${actionItemLength == undefined ? '' : `(${actionItemLength})`}
                        </span>
                    </td>
                `;
    })}
        </div>
    `;
}
function getContactTableRow(rowTitle, rowValues, rowStatus, selectedIndex, clickCallback) {
    return (0, lit_html_1.html) `
        <div class="row-header ${rowStatus}">${rowTitle}</div>
        ${rowValues.map((rowValue, index) => {
        return (0, lit_html_1.html) `
                <div
                    class="contact-data ${rowStatus} ${selectedIndex === index
            ? 'selected-contact'
            : ''}"
                    @click=${() => {
            clickCallback(index);
        }}
                >
                    <p class="cell-wrapper">
                        ${rowValue ||
            (0, lit_html_1.html) `
                            &nbsp;
                        `}
                    </p>
                </div>
            `;
    })}
    `;
}
function getMainContactMergeChildTemplate({ elementRoot, state, store, }) {
    if (state.loadError) {
        return state.loadError.errorMessage;
    }
    else if ((0, common_1.isPromiseLike)(state.loadedContacts)) {
        return 'Loading...';
    }
    else {
        const actionItemLengths = state.actionItemLengths?.actionItemLength &&
            !(0, common_1.isPromiseLike)(state.actionItemLengths?.actionItemLength)
            ? state.actionItemLengths.actionItemLength
            : undefined;
        return getContactsComparisonTable({
            rootElement: elementRoot,
            selectedIndex: state.selectedContactIndex,
            loadedContacts: state.loadedContacts,
            actionItemLengths: actionItemLengths,
            clickCallback: clickedIndex => {
                store.selectedContactIndex = clickedIndex;
            },
        });
    }
}
exports.getMainContactMergeChildTemplate = getMainContactMergeChildTemplate;
