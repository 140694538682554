"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEventOnGoogleCalendar = void 0;
const graphql_1 = require("../../../../services/graphql");
const utilities_1 = require("../../../../services/utilities");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function createEventOnGoogleCalendar(companyId, eventDetails) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $eventDetails: GoogleCalendarEventDetails!) {
                    createEventOnCompanyGoogleCalendar(
                        companyId: $companyId
                        eventDetails: $eventDetails
                    )
                }
            `, {
            companyId,
            eventDetails,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        (0, utilities_1.handleError)(`createEventOnGoogleCalendar error: ${error}`);
        return {
            succeeded: false,
            userMessage: `Something went wrong in createEventOnGoogleCalendar`,
            developerMessage: `createEventOnGoogleCalendar error: ${error}`,
        };
    }
}
exports.createEventOnGoogleCalendar = createEventOnGoogleCalendar;
