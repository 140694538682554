"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPhoneNumbersInputValues = exports.getAddressesInputValues = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const get_default_contact_input_values_1 = require("../../../utilities/contacts/get-default-contact-input-values");
require("../jo-address/jo-address");
require("../jo-button.element");
const state_1 = require("../jo-call-screen/redux/state");
require("../jo-email-verifier");
require("../jo-input");
const mutations_1 = require("./mutations");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../directives/test-id.directive");
const common_1 = require("@augment-vir/common");
// TODO consider changing the names of the properties inside of contactInputValues...consider getting rid of the inputValue suffix
// TODO it is redundant. I think this pattern might serve us well everywhere, if we embrace smart and dumb components
const InitialState = {
    authenticatedUser: 'NOT_SET',
    actionItemId: 'NOT_SET',
    addressAppear: true,
    addressRequire: false,
    checkCounty: false,
    checkZipCode: false,
    cityAppear: true,
    cityRequire: false,
    countyAppear: true,
    countyRequire: false,
    emailAppear: true,
    emailRequire: false,
    useEmailVerifier: true,
    hideClearContactButton: true,
    isMobileDevice: false,
    mode: 'CONTACT',
    newestAddressId: -1,
    newestPhoneNumberId: -1,
    postalCodeAppear: true,
    postalCodeRequire: false,
    provinceAppear: true,
    provinceRequire: false,
    serviceAreaCounties: '',
    serviceAreaDescriptionAppear: false,
    serviceAreaDescription: state_1.InitialJOCallScreenState.serviceAreaDescription,
    serviceAreaZipCodes: '',
    stateAppear: true,
    stateRequire: false,
    whereDidYouHearAppear: true,
    whereDidYouHearRequire: false,
    zipCodeAppear: true,
    zipCodeRequire: false,
    contact: Symbol('NOT_SET'),
    contactInputValues: (0, get_default_contact_input_values_1.getDefaultContactInputValues)(),
    requiredIconType: 'SUBTLE',
};
class JOContact extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        const originalContact = this.store.contact;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.contact !== originalContact) {
            (0, utilities_1.raiseCustomEvent)(this, 'contactchanged', this.store.contact);
            if (typeof this.store.contact === 'symbol') {
                this.store.contactInputValues = InitialState.contactInputValues;
            }
            else {
                this.contactInputValues = {
                    contactId: this.store.contact.id,
                    addressesInputValues: getAddressesInputValues(this.store.contact.addresses, this.store.serviceAreaZipCodes),
                    addressIdsToDisconnect: [],
                    businessNameInputValue: this.store.contact.company_name ?? '',
                    emailInputValue: this.store.contact.email ?? '',
                    firstNameInputValue: this.store.contact.first_name ?? '',
                    formErrors: [],
                    lastNameInputValue: this.store.contact.last_name ?? '',
                    phoneNumbersInputValues: getPhoneNumbersInputValues(this.store.contact.phone_numbers, this.store.contact.phone_number),
                    phoneNumberIdsToDisconnect: [],
                    sales: this.store.contact.sales,
                    status: this.store.contact.status === 3 ? 'BLOCKED' : 'UNBLOCKED',
                    whereDidYouHearAboutUsInputValue: this.store.contact.where_did_you_hear_about_us,
                };
            }
        }
    }
    set contactInputValues(contactInputValues) {
        this.store.contactInputValues = {
            ...contactInputValues,
        };
        (0, utilities_1.raiseCustomEvent)(this, 'inputvalueschanged', contactInputValues);
    }
    raiseJoContactInpuValueBlurEvent() {
        (0, utilities_1.raiseCustomEvent)(this, 'joContactInputBlur', this.store.contactInputValues);
    }
    addPhoneNumber() {
        this.store.newestPhoneNumberId = this.store.newestPhoneNumberId - 1;
        this.contactInputValues = {
            ...this.store.contactInputValues,
            phoneNumbersInputValues: [
                ...this.store.contactInputValues.phoneNumbersInputValues,
                {
                    phoneNumberId: this.store.newestPhoneNumberId,
                    phoneNumber: '',
                },
            ],
        };
    }
    deletePhoneNumberClicked(phoneNumberId) {
        this.removePhoneNumber(phoneNumberId);
        this.addPhoneNumberIdToDisconnect(phoneNumberId);
    }
    addPhoneNumberIdToDisconnect(phoneNumberId) {
        this.contactInputValues = {
            ...this.store.contactInputValues,
            phoneNumberIdsToDisconnect: [
                ...this.store.contactInputValues.phoneNumberIdsToDisconnect,
                phoneNumberId,
            ],
        };
    }
    removePhoneNumber(phoneNumberId) {
        this.contactInputValues = {
            ...this.store.contactInputValues,
            phoneNumbersInputValues: this.store.contactInputValues.phoneNumbersInputValues.filter(phoneNumbersInputValue => {
                return phoneNumbersInputValue.phoneNumberId !== phoneNumberId;
            }),
        };
    }
    addAddress() {
        this.store.newestAddressId = this.store.newestAddressId - 1;
        this.contactInputValues = {
            ...this.store.contactInputValues,
            addressesInputValues: [
                ...this.store.contactInputValues.addressesInputValues,
                {
                    addressId: this.store.newestAddressId,
                    line1InputValue: '',
                    line2InputValue: '',
                    cityInputValue: '',
                    countyInputValue: '',
                    postalCodeInputValue: '',
                    provinceInputValue: '',
                    stateInputValue: '',
                    zipCodeInputValue: '',
                    zipCodeValid: (0, utilities_1.checkIfZipCodeIsValid)('', this.store.serviceAreaZipCodes),
                    formErrors: [],
                },
            ],
        };
    }
    deleteAddressClicked(addressId) {
        this.removeAddress(addressId);
        this.addAddressIdToDisconnect(addressId);
    }
    removeAddress(addressId) {
        this.contactInputValues = {
            ...this.store.contactInputValues,
            addressesInputValues: this.store.contactInputValues.addressesInputValues.filter(address => address.addressId !== addressId),
        };
    }
    addAddressIdToDisconnect(addressId) {
        this.contactInputValues = {
            ...this.store.contactInputValues,
            addressIdsToDisconnect: [
                ...this.store.contactInputValues.addressIdsToDisconnect,
                addressId,
            ],
        };
    }
    async blockOrUnblockAndSetContact() {
        (0, utilities_1.assertIsSet)(this.store.contact, utilities_1.handleError, 'this.store.contact');
        const result = await (0, mutations_1.blockOrUnblockContact)(this.store.contact, this.store.contactInputValues.status === 'BLOCKED' ? 'UNBLOCK' : 'BLOCK');
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        const updatedContact = result.value;
        this.contactInputValues = {
            ...this.store.contactInputValues,
            status: updatedContact.status === 3 ? 'BLOCKED' : 'UNBLOCKED',
        };
    }
    async phoneIconClicked(phoneNumber) {
        if (this.store.isMobileDevice)
            navigateToNativeDialPadForMobileView(phoneNumber);
        else
            this.raiseJoContactDialPhoneNumberClickedEvent(phoneNumber);
    }
    raiseJoContactDialPhoneNumberClickedEvent(phoneNumber) {
        (0, utilities_1.assertIsSet)(this.store.contact, utilities_1.handleError, 'this.store.contact');
        (0, utilities_1.assertIsSet)(this.store.contact.company, utilities_1.handleError, 'this.store.contact.company');
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        (0, utilities_1.raiseCustomEvent)(this, 'joContactDialPhoneNumberClicked', {
            joContactState: this.store.getState(),
            phoneNumber: phoneNumber,
        });
    }
    getMobileIconInlineStyle() {
        if (!this.store.isMobileDevice)
            return '';
        return 'font-size: 1.35rem !important;';
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-contact--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                }

                .jo-contact--main-inputs-container {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    flex-direction: ${state.isMobileDevice ? `column` : `row`};
                }

                .jo-contact--column {
                    display: flex;
                    flex-direction: column;
                    width: ${state.isMobileDevice ? `100%` : `50%`};
                    box-sizing: border-box;
                    padding: ${state.isMobileDevice ? '5px' : '10px'};
                }

                .jo-contact--input-container {
                    padding: ${state.isMobileDevice ? '0' : '5px'};
                    padding-top: 0px;
                    box-sizing: border-box;
                    width: 100%;
                }

                .jo-contact--clear-contact-icon {
                    color: var(--jo-primary);
                    cursor: pointer;
                }

                .jo-contact--clear-contact-padding-bottom {
                    padding-bottom: 0.2rem;
                }

                .jo-contact--block-button-container {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .jo-contact--clear-and-block-container {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding-bottom: 1rem;
                    padding-top: 0.5rem;
                }

                .jo-contact--phone-number-container {
                    display: flex;
                    align-items: flex-end;
                }

                .jo-contact--read-only-title {
                    margin-top: 1rem;
                    margin-left: 1rem;
                    margin-right: 1rem;
                    font-size: 1.2rem;
                    font-weight: bold;
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                }

                .text-area-height {
                    height: 5rem;
                }

                .activityLogIcon {
                    position: relative;
                    float: right;
                    padding: 2px;
                    background-color: var(--jo-primary);
                    color: white;
                    border-radius: 2px;
                    cursor: pointer;
                }
            </style>

            <div class="jo-contact--main-container">
                <div class="jo-contact--main-inputs-container">
                    ${this.store.mode == 'CONTACT_READ_ONLY'
            ? (0, lit_html_1.html) `
                              <!-- TODO: ADD READ ONLY MODE -->
                              <div>
                                  <h3 class="jo-contact--read-only-title">Contact Info</h3>
                              </div>
                          `
            : (0, lit_html_1.html) `
                              <div class="jo-contact--column">
                                  <div
                                      class="jo-contact--input-container"
                                      ?hidden=${shouldHideBlockCallbacksInput(state) === true}
                                  >
                                      <input
                                          type="checkbox"
                                          .checked=${state.contactInputValues.sales}
                                          @change=${(e) => {
                this.contactInputValues = {
                    ...this.store.contactInputValues,
                    sales: e.target.checked,
                };
            }}
                                      />
                                      Block Callbacks

                                      <i
                                          ?hidden=${shouldHideBlockButton(state)}
                                          class="material-icons activityLogIcon"
                                          @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'contactactivitylogselected', state.contact)}
                                      >
                                          list
                                      </i>
                                  </div>

                                  <div class="jo-contact--input-container">
                                      <jo-input
                                          ${(0, test_id_directive_1.testId)('jo-contact-first-name-input')}
                                          .label=${'First Name'}
                                          .type=${'TEXT_INPUT'}
                                          .showRequiredIcon=${state.contactInputValues
                .firstNameInputValue === ''}
                                          .requiredIconType=${state.requiredIconType}
                                          .inputValue=${state.contactInputValues
                .firstNameInputValue}
                                          .formErrors=${state.contactInputValues.formErrors}
                                          @inputchange=${(e) => {
                this.contactInputValues = {
                    ...this.store.contactInputValues,
                    firstNameInputValue: e.detail.inputValue,
                    formErrors: e.detail.formErrors,
                };
            }}
                                          @joInputBlur=${() => this.raiseJoContactInpuValueBlurEvent()}
                                      ></jo-input>
                                  </div>

                                  <div class="jo-contact--input-container">
                                      <jo-input
                                          ${(0, test_id_directive_1.testId)('jo-contact-last-name-input')}
                                          .label=${'Last Name'}
                                          .type=${'TEXT_INPUT'}
                                          .showRequiredIcon=${state.contactInputValues
                .lastNameInputValue === ''}
                                          .requiredIconType=${state.requiredIconType}
                                          .inputValue=${state.contactInputValues.lastNameInputValue}
                                          .formErrors=${state.contactInputValues.formErrors}
                                          @inputchange=${(e) => {
                this.contactInputValues = {
                    ...this.store.contactInputValues,
                    lastNameInputValue: e.detail.inputValue,
                    formErrors: e.detail.formErrors,
                };
            }}
                                          @joInputBlur=${() => this.raiseJoContactInpuValueBlurEvent()}
                                      ></jo-input>
                                  </div>

                                  <div class="jo-contact--input-container">
                                      <jo-input
                                          ${(0, test_id_directive_1.testId)('jo-contact-business-name-input')}
                                          .label=${'Business Name'}
                                          .type=${'TEXT_INPUT'}
                                          .inputValue=${state.contactInputValues
                .businessNameInputValue}
                                          .formErrors=${state.contactInputValues.formErrors}
                                          @inputchange=${(e) => {
                this.contactInputValues = {
                    ...this.store.contactInputValues,
                    businessNameInputValue: e.detail.inputValue,
                    formErrors: e.detail.formErrors,
                };
            }}
                                          @joInputBlur=${() => this.raiseJoContactInpuValueBlurEvent()}
                                      ></jo-input>
                                  </div>

                                  <div
                                      class="jo-contact--input-container"
                                      ?hidden=${state.emailAppear === false}
                                  >
                                      <jo-input
                                          ${(0, test_id_directive_1.testId)('jo-contact-email-input')}
                                          .label=${'Email'}
                                          .type=${'EMAIL'}
                                          .showRequiredIcon=${state.emailRequire &&
                state.contactInputValues.emailInputValue === ''}
                                          .requiredIconType=${state.requiredIconType}
                                          .inputValue=${state.contactInputValues.emailInputValue}
                                          .formErrors=${state.contactInputValues.formErrors}
                                          .useEmailVerifier=${state.useEmailVerifier}
                                          @inputchange=${(e) => {
                this.contactInputValues = {
                    ...this.store.contactInputValues,
                    emailInputValue: e.detail.inputValue,
                    formErrors: e.detail.formErrors,
                };
            }}
                                          @joInputBlur=${() => this.raiseJoContactInpuValueBlurEvent()}
                                      ></jo-input>
                                  </div>

                                  <div
                                      class="jo-contact--input-container"
                                      ?hidden=${state.whereDidYouHearAppear === false}
                                  >
                                      <jo-input
                                          ${(0, test_id_directive_1.testId)('jo-contact-where-did-you-hear-about-us-input')}
                                          .label=${'Where did you hear about us?'}
                                          .type=${'TEXT_INPUT'}
                                          .showRequiredIcon=${state.whereDidYouHearRequire &&
                state.contactInputValues
                    .whereDidYouHearAboutUsInputValue === ''}
                                          .requiredIconType=${state.requiredIconType}
                                          .inputValue=${state.contactInputValues
                .whereDidYouHearAboutUsInputValue}
                                          .formErrors=${state.contactInputValues.formErrors}
                                          @inputchange=${(e) => {
                this.contactInputValues = {
                    ...this.store.contactInputValues,
                    whereDidYouHearAboutUsInputValue: e.detail.inputValue,
                    formErrors: e.detail.formErrors,
                };
            }}
                                          @joInputBlur=${() => this.raiseJoContactInpuValueBlurEvent()}
                                      ></jo-input>
                                  </div>
                                  <div
                                      class="jo-contact--input-container"
                                      ?hidden=${state.serviceAreaDescriptionAppear === false}
                                  >
                                      <jo-input
                                          ${(0, test_id_directive_1.testId)('jo-contact-service-area-input')}
                                          .readonly=${true}
                                          .label=${'Service Area'}
                                          .type=${'TEXT_AREA'}
                                          .rows=${5}
                                          .inputValue=${state.serviceAreaDescription}
                                      ></jo-input>
                                  </div>
                              </div>

                              <div class="jo-contact--column">
                                  <div class="jo-contact--clear-and-block-container">
                                      <div class="jo-contact--clear-contact-padding-bottom">
                                          <jo-button
                                              ?hidden=${state.hideClearContactButton}
                                              .type=${'ACTION_BUTTON_2'}
                                              .text=${'Clear Contact'}
                                              @joButtonClick=${() => {
                this.props = {
                    contact: Symbol('NOT_SET'),
                };
                (0, utilities_1.raiseCustomEvent)(this, 'clearcontact');
            }}
                                          ></jo-button>
                                      </div>

                                      <div
                                          class="jo-contact--block-button-container"
                                          ?hidden=${shouldHideBlockButton(state)}
                                      >
                                          <jo-button
                                              .type=${state.contactInputValues.status === 'BLOCKED'
                ? 'ACTION_BUTTON_GREEN_2'
                : 'DELETE_BUTTON_2'}
                                              .text=${state.contactInputValues.status === 'BLOCKED'
                ? 'UNBLOCK'
                : 'BLOCK'}
                                              @joButtonClick=${() => this.blockOrUnblockAndSetContact()}
                                          ></jo-button>
                                      </div>
                                  </div>

                                  <div class="jo-contact--input-container">
                                      ${state.contactInputValues.phoneNumbersInputValues.map((phoneNumberInputValues, outerIndex) => {
                if (state.contactInputValues.phoneNumberIdsToDisconnect.includes(phoneNumberInputValues.phoneNumberId)) {
                    return (0, lit_html_1.html) ``;
                }
                return (0, lit_html_1.html) `
                                                  <div
                                                      class="jo-contact--input-container jo-contact--phone-number-container"
                                                  >
                                                      <jo-input
                                                          ${(0, test_id_directive_1.testId)('jo-contact-phone-number')}
                                                          .label=${`Phone ${outerIndex + 1}`}
                                                          .type=${'PHONE_NUMBER'}
                                                          .required=${outerIndex === 0}
                                                          .showRequiredIcon=${outerIndex === 0 &&
                    phoneNumberInputValues.phoneNumber === ''}
                                                          .requiredIconType=${state.requiredIconType}
                                                          .inputValue=${phoneNumberInputValues.phoneNumber}
                                                          .formErrors=${state.contactInputValues
                    .formErrors}
                                                          @inputchange=${(e) => {
                    this.contactInputValues = {
                        ...this.store.contactInputValues,
                        phoneNumbersInputValues: this.store.contactInputValues.phoneNumbersInputValues.map((phoneNumberInputValues, innerIndex) => {
                            if (outerIndex ===
                                innerIndex) {
                                return {
                                    ...phoneNumberInputValues,
                                    phoneNumber: e.detail
                                        .inputValue,
                                };
                            }
                            return phoneNumberInputValues;
                        }),
                        formErrors: e.detail.formErrors,
                    };
                }}
                                                          @joInputBlur=${() => this.raiseJoContactInpuValueBlurEvent()}
                                                      ></jo-input>

                                                      <jo-button
                                                          ?hidden=${shouldHidePhoneNumberButton(state, phoneNumberInputValues)}
                                                          style="padding-left: .5rem"
                                                          .type=${'ICON'}
                                                          .icon=${'phone'}
                                                          .inlineStyle=${this.getMobileIconInlineStyle()}
                                                          @joButtonClick=${() => {
                    if (state.isMobileDevice) {
                        navigateToNativeDialPadForMobileView(phoneNumberInputValues.phoneNumber);
                        return;
                    }
                    (0, utilities_1.assertIsSet)(state.contact, utilities_1.handleError, 'state.contact');
                    (0, utilities_1.assertIsSet)(state.contact.company, utilities_1.handleError, 'state.contact.company');
                    (0, utilities_1.assertIsSet)(state.authenticatedUser, utilities_1.handleError, 'state.authenticatedUser');
                    (0, utilities_1.raiseCustomEvent)(this, 'joContactDialPhoneNumberClicked', {
                        joContactState: state,
                        phoneNumber: phoneNumberInputValues.phoneNumber,
                    });
                }}
                                                      ></jo-button>

                                                      ${isPrimaryPhoneNumber(outerIndex) === false
                    ? (0, lit_html_1.html) `
                                                                <jo-button
                                                                    style="padding-left: 0.5rem"
                                                                    .type=${'ICON'}
                                                                    .icon=${'delete'}
                                                                    .inlineStyle=${this.getMobileIconInlineStyle()}
                                                                    @joButtonClick=${() => this.deletePhoneNumberClicked(phoneNumberInputValues.phoneNumberId)}
                                                                ></jo-button>
                                                            `
                    : ''}
                                                  </div>
                                              `;
            })}

                                      <div class="jo-contact--input-container">
                                          <jo-button
                                              .type=${'ICON'}
                                              .text=${'add'}
                                              .inlineStyle=${this.getMobileIconInlineStyle()}
                                              @joButtonClick=${() => this.addPhoneNumber()}
                                          ></jo-button>
                                      </div>
                                  </div>

                                  <br />

                                  <div
                                      class="jo-contact--input-container"
                                      ?hidden=${areAllAddressFieldsHidden(state)}
                                  >
                                      ${state.contactInputValues.addressesInputValues.map((addressInputValues, outerIndex) => {
                if (state.contactInputValues.addressIdsToDisconnect.includes(addressInputValues.addressId)) {
                    return (0, lit_html_1.html) ``;
                }
                const address = {
                    id: addressInputValues.addressId,
                    line_1: addressInputValues.line1InputValue,
                    line_2: addressInputValues.line2InputValue,
                    city: addressInputValues.cityInputValue,
                    county: addressInputValues.countyInputValue,
                    occupant_type: 'Contact',
                    postal_code: addressInputValues.postalCodeInputValue,
                    province: addressInputValues.provinceInputValue,
                    zip_code: addressInputValues.zipCodeInputValue,
                    state: addressInputValues.stateInputValue,
                    created_at: new Date(),
                    updated_at: new Date(),
                };
                return (0, lit_html_1.html) `
                                                  <div class="jo-contact--input-container">
                                                      ${outerIndex !== 0
                    ? (0, lit_html_1.html) `
                                                                <div
                                                                    style="display: flex; justify-content: flex-end"
                                                                >
                                                                    <jo-button
                                                                        style=""
                                                                        .type=${'ICON'}
                                                                        .text=${'delete'}
                                                                        @joButtonClick=${() => this.deleteAddressClicked(address.id)}
                                                                    ></jo-button>
                                                                </div>
                                                            `
                    : ''}

                                                      <jo-address
                                                          .props=${(0, common_1.ensureType)({
                    address,
                    authenticatedUser: state.authenticatedUser ===
                        'NOT_SET'
                        ? undefined
                        : state.authenticatedUser,
                    addressAppear: state.addressAppear,
                    addressRequire: state.addressRequire,
                    checkCounty: state.checkCounty,
                    checkZipCode: state.checkZipCode,
                    cityAppear: state.cityAppear,
                    cityRequire: state.cityRequire,
                    countyAppear: state.countyAppear,
                    countyRequire: state.countyRequire,
                    postalCodeAppear: state.postalCodeAppear,
                    postalCodeRequire: state.postalCodeRequire,
                    provinceAppear: state.provinceAppear,
                    provinceRequire: state.provinceRequire,
                    serviceAreaCounties: state.serviceAreaCounties,
                    serviceAreaZipCodes: state.serviceAreaZipCodes,
                    stateAppear: state.stateAppear,
                    stateRequire: state.stateRequire,
                    zipCodeAppear: state.zipCodeAppear,
                    zipCodeRequire: state.zipCodeRequire,
                })}
                                                          @inputvalueschanged=${(e) => {
                    e.stopPropagation();
                    this.contactInputValues = {
                        ...this.store.contactInputValues,
                        addressesInputValues: this.store.contactInputValues.addressesInputValues.map((addressInputValues, innerIndex) => {
                            if (outerIndex ===
                                innerIndex) {
                                return e.detail;
                            }
                            return addressInputValues;
                        }),
                    };
                }}
                                                          @joInputBlur=${() => this.raiseJoContactInpuValueBlurEvent()}
                                                      ></jo-address>
                                                  </div>
                                                  ${state.contactInputValues.addressesInputValues
                    .length >
                    outerIndex + 1
                    ? (0, lit_html_1.html) `
                                                            <hr style="width: 50%" />
                                                        `
                    : ''}
                                              `;
            })}

                                      <div class="jo-contact--input-container">
                                          <jo-button
                                              .type=${'ICON'}
                                              .text=${'add'}
                                              @joButtonClick=${() => this.addAddress()}
                                          ></jo-button>
                                      </div>
                                  </div>
                              </div>
                          `}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-contact', JOContact);
function shouldHideBlockButton(state) {
    if (state.authenticatedUser === 'NOT_SET') {
        return true;
    }
    if (typeof state.contact === 'symbol') {
        return true;
    }
    if (state.authenticatedUser.auth_role === 'JILL_OUTBOUND' ||
        state.authenticatedUser.auth_role === 'JILL_MANAGER' ||
        state.authenticatedUser.auth_role === 'JILL_BUSINESS_DEVELOPMENT' ||
        state.authenticatedUser.auth_role === 'JILL_EXECUTIVE') {
        return false;
    }
    return true;
}
function shouldHideBlockCallbacksInput(state) {
    if (state.authenticatedUser != 'NOT_SET') {
        return (0, auth_rules_1.isCompanyUser)(state.authenticatedUser);
    }
    return false;
}
function getAddressesInputValues(addresses, serviceAreaZipCodes) {
    return addresses.length === 0
        ? [
            {
                addressId: -1,
                line1InputValue: '',
                line2InputValue: '',
                cityInputValue: '',
                countyInputValue: '',
                postalCodeInputValue: '',
                provinceInputValue: '',
                stateInputValue: '',
                zipCodeInputValue: '',
                zipCodeValid: (0, utilities_1.checkIfZipCodeIsValid)('', serviceAreaZipCodes),
                formErrors: [],
            },
        ]
        : addresses.map((address) => {
            return {
                addressId: address.id,
                line1InputValue: address.line_1,
                line2InputValue: address.line_2,
                cityInputValue: address.city,
                countyInputValue: address.county,
                postalCodeInputValue: address.postal_code,
                provinceInputValue: address.province,
                stateInputValue: address.state,
                zipCodeInputValue: address.zip_code,
                zipCodeValid: (0, utilities_1.checkIfZipCodeIsValid)(address.zip_code, serviceAreaZipCodes),
                formErrors: [], // TODO I think we can get rid of this...actually, maybe not
            };
        });
}
exports.getAddressesInputValues = getAddressesInputValues;
function getPhoneNumbersInputValues(phoneNumbers, phoneNumber) {
    return phoneNumbers.length === 0
        ? [
            {
                phoneNumberId: -1,
                phoneNumber: (0, utilities_1.formatPhoneNumberToE164)(phoneNumber || ''),
            },
        ]
        : phoneNumbers.map((phoneNumber) => {
            return {
                phoneNumberId: phoneNumber.id,
                phoneNumber: (0, utilities_1.formatPhoneNumberToE164)(phoneNumber.number),
            };
        });
}
exports.getPhoneNumbersInputValues = getPhoneNumbersInputValues;
function shouldHidePhoneNumberButton(state, phoneNumberInputValues) {
    return (state.authenticatedUser === 'NOT_SET' ||
        (0, auth_rules_1.isCompanyUser)(state.authenticatedUser) ||
        (0, utilities_1.isPhoneNumberInE164Format)(phoneNumberInputValues.phoneNumber) === false ||
        (typeof state.contact === 'symbol' && state.contact.toString() === 'Symbol(NOT_SET)') ||
        state.mode === 'ACTIVE_CALL_SCREEN');
}
function isPrimaryPhoneNumber(phoneNumberIndex) {
    return phoneNumberIndex === 0;
}
function areAllAddressFieldsHidden(state) {
    return (state.addressAppear === false &&
        state.cityAppear === false &&
        state.countyAppear === false &&
        state.postalCodeAppear === false &&
        state.provinceAppear === false &&
        state.stateAppear === false &&
        state.zipCodeAppear === false);
}
function navigateToNativeDialPadForMobileView(phoneNumber) {
    const link = document.createElement('a');
    link.setAttribute('href', `tel:${phoneNumber}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
