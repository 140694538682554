"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHoldClickedAction = void 0;
const twilio_1 = require("../../../../../../services/twilio");
const utilities_1 = require("../../../../../../services/utilities");
async function createHoldClickedAction({ authenticatedUser, callId, previouslyOnHold, timestamp, userCallSid, }) {
    const holdRequestResult = await toggleHold(previouslyOnHold, callId, userCallSid);
    if (holdRequestResult.succeeded === false) {
        return holdRequestResult;
    }
    await sendHoldDurationEvent(previouslyOnHold, timestamp, authenticatedUser, callId);
    return {
        succeeded: true,
        value: {
            type: 'HOLD_CLICKED',
            onHold: !previouslyOnHold,
            collectingDuration: previouslyOnHold === true,
        },
    };
}
exports.createHoldClickedAction = createHoldClickedAction;
async function toggleHold(previouslyOnHold, callId, userCallSid) {
    if (previouslyOnHold) {
        return await endHold(callId, userCallSid);
    }
    else {
        return await beginHold(callId, userCallSid);
    }
}
async function sendHoldDurationEvent(previouslyOnHold, timestamp, authenticatedUser, callId) {
    const localDurationEventInfo = getDurationEventInfo(previouslyOnHold, timestamp, authenticatedUser, callId);
    (0, twilio_1.setDurationEventLocal)(localDurationEventInfo);
    await (0, twilio_1.setDurationEventRemote)(localDurationEventInfo);
}
function getDurationEventInfo(previouslyOnHold, timestamp, authenticatedUser, callId) {
    if (previouslyOnHold) {
        return (0, twilio_1.getTimestampUnholdDurationEventInfo)(timestamp, authenticatedUser.id, callId);
    }
    else {
        return (0, twilio_1.getTimestampHoldDurationEventInfo)(timestamp, authenticatedUser.id, callId);
    }
}
async function beginHold(callId, userCallSid) {
    const response = await (0, utilities_1.sendRouterRequest)('/twilio/conferences/begin-hold', JSON.stringify({
        callId,
        userCallSid,
    }));
    if (response.ok === false) {
        return {
            succeeded: false,
            userMessage: 'Error initiating hold',
            developerMessage: `Error initiating hold: ${response.status} ${response.statusText}`,
        };
    }
    return { succeeded: true };
}
async function endHold(callId, userCallSid) {
    const response = await (0, utilities_1.sendRouterRequest)('/twilio/conferences/end-hold', JSON.stringify({
        callId,
        userCallSid,
    }));
    if (response.ok === false) {
        return {
            succeeded: false,
            userMessage: 'Error ending hold',
            developerMessage: `Error ending hold: ${response.status} ${response.statusText}`,
        };
    }
    return { succeeded: true };
}
