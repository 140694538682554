"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEventOnCalendar = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const crew_cal_1 = require("../../../../classes/crew-cal");
const utilities_1 = require("../../../../services/utilities");
const get_crew_cal_iframe_URL_1 = require("../../../../utilities/crew-cal/get-crew-cal-iframe-URL");
const sync_company_with_crew_cal_1 = require("../../../../utilities/crew-cal/sync-company-with-crew-cal");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
require("../../jo-button.element");
require("../../jo-input");
const state_1 = require("../redux/state");
const helper_utils_1 = require("./helper-utils");
require("./jo-google-calendar-events-for-company/jo-google-calendar-events-for-company");
const mutation_1 = require("./mutation");
const InitialState = {
    viewType: 'NOT_SET',
    appointmentSelectionResults: 'NOT_SET',
    companyForCollection: 'NOT_SET',
    contactInputValues: state_1.InitialJOCallScreenState.contactInputValues,
    companyId: state_1.InitialJOCallScreenState.companyId,
    selectedServicesWithCalculatedPrice: state_1.InitialJOCallScreenState.selectedServices,
    companyCalendar: 'NOT_SET',
    companyCalendarConnections: [],
    selectedAppointmentCalendarId: 'NOT_SET',
    pricingInputValues: state_1.InitialJOCallScreenState.pricingInputValues,
    customFieldResponsesInputValues: state_1.InitialJOCallScreenState.customFieldResponsesInputValues,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-crew-cal-scheduling');
class JOCrewCalScheduling extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    async initiateCrewCalScheduler() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        (0, utilities_1.assertIsSet)(this.store.companyForCollection, utilities_1.handleError, 'this.store.companyForCollection');
        (0, utilities_1.assertIsSet)(this.store.contactInputValues, utilities_1.handleError, 'this.store.contactInputValues');
        (0, utilities_1.assertIsSet)(this.store.viewType, utilities_1.handleError, 'this.store.viewType');
        await (0, sync_company_with_crew_cal_1.syncCompanyWithCrewCal)(this.store.companyId);
        const iframeURLResult = await (0, get_crew_cal_iframe_URL_1.getIframeURL)(this.store.companyId, this.store.viewType);
        if (iframeURLResult.succeeded === false) {
            return;
        }
        const iframeURL = iframeURLResult.value;
        const schedulingData = (0, helper_utils_1.formatSchedulingDataForCrewCal)({
            contact: this.store.contactInputValues,
            services: this.store.selectedServicesWithCalculatedPrice,
            company: this.store.companyForCollection,
        });
        this.createAndAddIframeToDOM(schedulingData, iframeURL);
    }
    createAndAddIframeToDOM(schedulingData, iframeURL) {
        (0, utilities_1.assertIsSet)(this.store.viewType, utilities_1.handleError, 'this.store.viewType');
        const crewCalDiv = (0, helper_utils_1.createCrewCalDiv)();
        const crewCal = new crew_cal_1.CrewCal(this.store.viewType, iframeURL);
        crewCal.render(crewCalDiv, schedulingData);
        (0, helper_utils_1.addCrewCalEventListeners)(crewCal);
        this.appendCrewCalListeners(crewCal, crewCalDiv);
    }
    appendCrewCalListeners(crewCal, crewCalDiv) {
        crewCal.on('selected', (selectionResults) => {
            document.body.removeChild(crewCalDiv);
            this.handleAppointmentSelection(selectionResults);
        });
        crewCal.on('close', () => {
            document.body.removeChild(crewCalDiv);
        });
    }
    async handleAppointmentSelection(selectionResults) {
        this.store.appointmentSelectionResults = selectionResults;
        if (!selectionResults.calendar.remote_id) {
            return;
        }
        const scheduledCalendarId = parseInt(selectionResults.calendar.remote_id);
        const calendarId = (0, helper_utils_1.getCalendarIdForScheduledCalendar)(scheduledCalendarId, this.store.companyCalendarConnections);
        this.store.selectedAppointmentCalendarId = calendarId;
        await this.scheduleJobOnCompanyCalendar(this.store.appointmentSelectionResults);
    }
    async scheduleJobOnCompanyCalendar(selectedAppointment) {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        (0, utilities_1.assertIsSet)(this.store.companyCalendar, utilities_1.handleError, 'this.store.companyCalendar');
        (0, utilities_1.assertIsSet)(selectedAppointment, utilities_1.handleError, 'selectedAppointment');
        if (this.isSelectedCalendarValid(selectedAppointment) === false) {
            return;
        }
        const companyId = this.store.companyId;
        const companyCalendarType = this.store.companyCalendar.type;
        const createEventOnCalendarResult = await this.createEventOnCompanyCalendar(selectedAppointment, companyId, companyCalendarType);
        if (createEventOnCalendarResult.succeeded === false) {
            (0, utilities_1.handleError)(`Something went wrong while scheduling the event ${createEventOnCalendarResult.developerMessage}`);
            (0, utilities_1.joAlert)('Failure!', 'Something went wrong while scheduling the event');
            return;
        }
        (0, utilities_1.joAlert)('Success!', 'Event Scheduled successfully');
    }
    isSelectedCalendarValid(selectedAppointment) {
        const isReadOnlyCalendar = (0, helper_utils_1.isCalendarReadOnly)(selectedAppointment.calendar.source_description);
        if (isReadOnlyCalendar) {
            (0, utilities_1.handleError)(`Selected Crew-Cal Calendar ${selectedAppointment.calendar.name} is READ-ONLY`);
            return false;
        }
        const selectedCalendarRemoteId = selectedAppointment.calendar.remote_id;
        if (!selectedCalendarRemoteId) {
            (0, utilities_1.handleError)(`Remote Id is null for ${selectedAppointment.calendar.name}`);
            return false;
        }
        const isSelectedCalendarPresentInSystem = (0, helper_utils_1.isCalendarPresentInSystem)(selectedCalendarRemoteId, this.store.companyCalendarConnections);
        if (!isSelectedCalendarPresentInSystem) {
            (0, utilities_1.handleError)(`Selected Crew-Cal Calendar ${selectedAppointment.calendar.name} is not present in system`);
            return false;
        }
        return true;
    }
    async createEventOnCompanyCalendar(selectedAppointment, companyId, companyCalendarType) {
        (0, utilities_1.assertIsSet)(this.store.selectedAppointmentCalendarId, utilities_1.handleError, 'this.store.selectedAppointmentCalendarId');
        const eventDetails = this.makeEventDetails(this.store.selectedAppointmentCalendarId, selectedAppointment);
        const createEventOnCalendarResult = await createEventOnCalendar(companyId, companyCalendarType, eventDetails);
        (0, utilities_1.raiseCustomEvent)(this, 'appointmentScheduled', selectedAppointment);
        return createEventOnCalendarResult;
    }
    makeEventDetails(calendarId, selectedAppointment) {
        (0, utilities_1.assertIsSet)(this.store.contactInputValues, utilities_1.handleError, 'this.store.contactInputValues');
        const pricingInputValues = this.getPricingInputValuesForEventDescription();
        return {
            calendarId,
            summary: `${(0, helper_utils_1.makeFormattedCustomerName)(this.store.contactInputValues)}`,
            description: (0, helper_utils_1.makeFormattedEventDescription)({
                selectedServices: this.store.selectedServicesWithCalculatedPrice,
                phoneNumbers: this.store.contactInputValues.phoneNumbersInputValues,
                pricingInputValues,
                customFieldResponsesInputValues: this.store.customFieldResponsesInputValues,
            }),
            location: (0, helper_utils_1.makeFormattedLocation)(this.store.contactInputValues),
            start: {
                dateTime: selectedAppointment.start_datetime,
                timeZone: selectedAppointment.timezone,
            },
            end: {
                dateTime: selectedAppointment.end_datetime,
                timeZone: selectedAppointment.timezone,
            },
        };
    }
    getPricingInputValuesForEventDescription() {
        return this.store.pricingInputValues === 'NOT_SET'
            ? helper_utils_1.defaultPricingInputValues
            : this.store.pricingInputValues;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                }

                .${cssName('selected-appointment-title')} {
                    font-weight: bold;
                    font-size: 16px;
                    color: var(--jo-text-default);
                    font-family: sans-serif;
                }

                .${cssName('selected-appointment-container')} {
                    font-size: 14px;
                    color: var(--jo-text-default);
                    font-family: sans-serif;
                    display: flex;
                    justify-content: space-evenly;
                }
            </style>

            <div ?hidden=${state.companyCalendarConnections.length === 0} style="padding: 1.5rem;">
                <jo-google-calendar-events-for-company
                    .companyId=${this.store.companyId}
                    .companyCalendarConnections=${this.store.companyCalendarConnections}
                ></jo-google-calendar-events-for-company>
            </div>

            <div class="${cssName('main-container')}">
                <jo-button
                    .type=${'ACTION_BUTTON_1'}
                    .text=${'View Available Time Slots'}
                    @joButtonClick=${() => {
            this.initiateCrewCalScheduler();
        }}
                ></jo-button>
            </div>

            <div ?hidden=${state.appointmentSelectionResults === 'NOT_SET'}>
                ${state.appointmentSelectionResults === 'NOT_SET'
            ? (0, lit_html_1.html) `
                          <div style="display:flex; justify-content: center">
                              No Appointment Selected
                          </div>
                      `
            : (0, lit_html_1.html) `
                          <div class="${cssName('selected-appointment-title')}">
                              <h3>Appointment Details:</h3>
                          </div>
                          <div class="${cssName('selected-appointment-container')}">
                              <div>
                                  <h4>Job:</h4>
                                  <ul>
                                      <li>
                                          <span>
                                              <strong>Crew Name:</strong>
                                              ${state.appointmentSelectionResults.slot.calendar
                .name}
                                          </span>
                                      </li>
                                      <li>
                                          <span>
                                              <strong>Date:</strong>
                                              ${(0, helper_utils_1.getDateAndTimeFromTime)(state.appointmentSelectionResults
                .start_datetime_string).localeDateString}
                                          </span>
                                      </li>

                                      <li>
                                          <span>
                                              <strong>Start Time:</strong>
                                              ${(0, helper_utils_1.getDateAndTimeFromTime)(state.appointmentSelectionResults
                .start_datetime_string).localeTimeString}
                                          </span>
                                      </li>

                                      <li>
                                          <span>
                                              <strong>End Time:</strong>
                                              ${(0, helper_utils_1.getDateAndTimeFromTime)(state.appointmentSelectionResults
                .end_datetime_string).localeTimeString}
                                          </span>
                                      </li>
                                  </ul>
                              </div>
                              <div>
                                  <h4>Services:</h4>
                                  <ul>
                                      ${state.selectedServicesWithCalculatedPrice.map((service) => {
                return (0, lit_html_1.html) `
                                                  <li>
                                                      <span>
                                                          ${service.name}:
                                                          $${service.calculatedPrice ?? 0}
                                                      </span>
                                                  </li>
                                              `;
            })}
                                  </ul>
                              </div>
                          </div>
                      `}
            </div>

            <div class="continue-button-container">
                <jo-button
                    .type=${'ACTION_BUTTON_1'}
                    .text=${'Continue'}
                    @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'continued')}
                ></jo-button>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-crew-cal-scheduling', JOCrewCalScheduling);
async function createEventOnCalendar(companyId, calendarType, eventDetails) {
    try {
        if (calendarType === 'GOOGLE_CALENDAR') {
            const createEventOnGoogleCalendarResult = await (0, mutation_1.createEventOnGoogleCalendar)(companyId, eventDetails);
            if (createEventOnGoogleCalendarResult.succeeded === false) {
                return createEventOnGoogleCalendarResult;
            }
            return { succeeded: true };
        }
        return {
            succeeded: false,
            developerMessage: `Invalid Calendar Type: ${calendarType}`,
            userMessage: 'Something went Wrong',
        };
    }
    catch (error) {
        (0, utilities_1.handleError)(`createEventOnCalendar error: ${error}`);
        return {
            succeeded: false,
            userMessage: 'Something went Wrong',
            developerMessage: `createEventOnCalendar error: ${error}`,
        };
    }
}
exports.createEventOnCalendar = createEventOnCalendar;
