"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultContactInputValues = void 0;
const utilities_1 = require("../../services/utilities");
function getDefaultContactInputValues() {
    return {
        contactId: -1,
        addressesInputValues: [
            {
                addressId: -1,
                line1InputValue: '',
                line2InputValue: '',
                cityInputValue: '',
                countyInputValue: '',
                postalCodeInputValue: '',
                provinceInputValue: '',
                stateInputValue: '',
                zipCodeInputValue: '',
                zipCodeValid: (0, utilities_1.checkIfZipCodeIsValid)('', ''),
                formErrors: [],
            },
        ],
        addressIdsToDisconnect: [],
        businessNameInputValue: '',
        emailInputValue: '',
        firstNameInputValue: '',
        formErrors: [],
        lastNameInputValue: '',
        phoneNumbersInputValues: [
            {
                phoneNumberId: -1,
                phoneNumber: '',
            },
        ],
        phoneNumberIdsToDisconnect: [],
        sales: false,
        status: 'UNBLOCKED',
        whereDidYouHearAboutUsInputValue: '',
    };
}
exports.getDefaultContactInputValues = getDefaultContactInputValues;
