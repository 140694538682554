"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createActionItemIdSetAction = void 0;
const queries_1 = require("../../../../jo-contact/queries");
const helper_utils_1 = require("./helper-utils");
const fetch_action_item_1 = require("./queries/fetch-action-item");
const fetch_company_data_1 = require("./queries/fetch-company-data");
const fetch_jills_to_transfer_to_1 = require("./queries/fetch-jills-to-transfer-to");
async function createActionItemIdSetAction(actionItemId, authenticatedUser, companyId, callScreenMode, activeCallId, contact) {
    if (actionItemId === 'NOT_SET') {
        if (companyId === 'NOT_SET') {
            return {
                succeeded: false,
                userMessage: 'There was an error',
                developerMessage: 'companyId must be set',
            };
        }
        else if (authenticatedUser === 'NOT_SET') {
            return {
                succeeded: false,
                userMessage: 'There was an error',
                developerMessage: 'authenticatedUser must be set',
            };
        }
        else {
            const fetchCompaniesForCallScreenComponentsResult = await fetchCompaniesForCallScreenComponents(companyId);
            if (fetchCompaniesForCallScreenComponentsResult.succeeded === false) {
                return fetchCompaniesForCallScreenComponentsResult;
            }
            const { companyForCallInstructions, companyForCollection, companyForCompanyInfo, companyForTransferInstructions, } = fetchCompaniesForCallScreenComponentsResult.value;
            const fetchJillsForTransferResult = await (0, fetch_jills_to_transfer_to_1.fetchJillsToTransferTo)(authenticatedUser.id);
            if (fetchJillsForTransferResult.succeeded === false) {
                return fetchJillsForTransferResult;
            }
            const jillsToTransferTo = fetchJillsForTransferResult.value;
            return {
                succeeded: true,
                value: {
                    type: 'ACTION_ITEM_ID_SET',
                    actionItem: 'NOT_SET',
                    actionItemId: 'NOT_SET',
                    collectingDuration: callScreenMode === 'OUTGOING_NEW_ACTION_ITEM' ||
                        callScreenMode === 'OUTGOING_EXISTING_ACTION_ITEM',
                    companyForCallInstructions,
                    companyForCollection,
                    companyForCompanyInfo,
                    companyForTransferInstructions,
                    companyId,
                    contact: contact,
                    followupRequestDateInputValue: new Date().toISOString(),
                    jillsToTransferTo,
                    previousCustomFieldResponses: [],
                    previousLineItemResponses: [],
                    previousServiceResponses: [],
                    priceQuote: 0,
                },
            };
        }
    }
    const actionItemResult = await (0, fetch_action_item_1.fetchActionItem)(actionItemId);
    if (actionItemResult.succeeded === false) {
        return actionItemResult;
    }
    const actionItem = actionItemResult.value;
    const contactId = actionItem.contact?.id ?? actionItem.call?.contact?.id;
    if (contactId === null || contactId === undefined) {
        return {
            succeeded: false,
            userMessage: 'There was an error',
            developerMessage: `contact is not defined on actionItem or on call for action item: ${actionItem.id}`,
        };
    }
    const contactResult = await (0, queries_1.fetchContact)(contactId);
    if (contactResult.succeeded === false) {
        return contactResult;
    }
    contact = contactResult.value;
    if (actionItem.company === null || actionItem.company === undefined) {
        return {
            succeeded: false,
            userMessage: 'There was an error',
            developerMessage: `company is not defined on action item ${actionItem.id}`,
        };
    }
    const fetchCompaniesForCallScreenComponentsResult = await fetchCompaniesForCallScreenComponents(actionItem.company.id);
    if (fetchCompaniesForCallScreenComponentsResult.succeeded === false) {
        return fetchCompaniesForCallScreenComponentsResult;
    }
    const { companyForCallInstructions, companyForCollection, companyForCompanyInfo, companyForTransferInstructions, } = fetchCompaniesForCallScreenComponentsResult.value;
    if (authenticatedUser === 'NOT_SET') {
        return {
            succeeded: false,
            userMessage: 'There was an error',
            developerMessage: 'authenticated user is not set',
        };
    }
    const fetchJillsForTransferResult = await (0, fetch_jills_to_transfer_to_1.fetchJillsToTransferTo)(authenticatedUser.id);
    if (fetchJillsForTransferResult.succeeded === false) {
        return fetchJillsForTransferResult;
    }
    const jillsToTransferTo = fetchJillsForTransferResult.value;
    return {
        succeeded: true,
        value: {
            type: 'ACTION_ITEM_ID_SET',
            actionItem,
            actionItemId,
            collectingDuration: callScreenMode === 'OUTGOING_NEW_ACTION_ITEM' ||
                callScreenMode === 'OUTGOING_EXISTING_ACTION_ITEM',
            companyForCallInstructions,
            companyForCollection,
            companyForCompanyInfo,
            companyForTransferInstructions,
            companyId: actionItem.company.id,
            contact: (0, helper_utils_1.getContactForSameCallerId)(actionItem, contact, callScreenMode),
            followupRequestDateInputValue: actionItem.followup_request_date ?? new Date().toISOString(),
            jillsToTransferTo: jillsToTransferTo,
            previousCustomFieldResponses: actionItem.custom_field_responses,
            previousLineItemResponses: actionItem.service_line_item_responses,
            previousServiceResponses: actionItem.service_responses,
            priceQuote: actionItem.price_quote,
        },
    };
}
exports.createActionItemIdSetAction = createActionItemIdSetAction;
async function fetchCompaniesForCallScreenComponents(companyId) {
    const companyResult = await (0, fetch_company_data_1.fetchCompanyForCallScreen)(companyId);
    if (companyResult.succeeded === false) {
        return companyResult;
    }
    const company = companyResult.value;
    return {
        succeeded: true,
        value: {
            companyForCallInstructions: company,
            companyForCollection: company,
            companyForCompanyInfo: company,
            companyForTransferInstructions: company,
        },
    };
}
