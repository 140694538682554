"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchContactsById = void 0;
const graphql_1 = require("../../services/graphql");
const typed_gql_query_1 = require("../gql/typed-gql-query/typed-gql-query");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function fetchContactsById(contactIds, queryShape) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, typed_gql_query_1.gql) `
            query ($contactIds: [Int!]) {
                findContacts(
                    filter: {and: [
                        {id: {in: $contactIds}},
                        {marked_as_duplicate_at: {eq: null}}
                    ]}
                    orderBy: {field: "id", order: DESC, in: $contactIds}
                ) {
                    items ${queryShape}
                }
            }
        `, {
        contactIds,
    });
    return gqlResult.data.findContacts.items;
}
exports.fetchContactsById = fetchContactsById;
