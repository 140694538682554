"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeContactsOnServer = exports.contactsQueryShape = void 0;
const graphql_1 = require("../../../services/graphql");
const common_1 = require("@augment-vir/common");
const typed_gql_query_input_1 = require("../../../utilities/gql/typed-gql-query/typed-gql-query-input");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
exports.contactsQueryShape = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    id: true,
    email: true,
    where_did_you_hear_about_us: true,
    addresses: [
        {
            fields: {
                id: true,
                line_1: true,
                line_2: true,
                city: true,
                county: true,
                state: true,
                zip_code: true,
                postal_code: true,
                province: true,
            },
            orderBy: {
                field: 'id',
                order: typed_gql_query_input_1.SortOrderEnum.Ascending,
            },
        },
    ],
    first_name: true,
    last_name: true,
    company_name: true,
    sales: true,
    status: true,
    phone_numbers: [
        {
            fields: {
                id: true,
                number: true,
            },
            orderBy: {
                field: 'id',
                order: typed_gql_query_input_1.SortOrderEnum.Ascending,
            },
        },
    ],
});
async function mergeContactsOnServer({ keepThisContactId, discardThisContactId, }) {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($keepThisContactId: Int!, $discardThisContactId: Int!) {
                mergeContacts(
                    idOfContactToKeep: $keepThisContactId
                    idOfContactToDiscard: $discardThisContactId
                )
            }
        `, {
        keepThisContactId,
        discardThisContactId,
    });
}
exports.mergeContactsOnServer = mergeContactsOnServer;
