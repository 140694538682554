"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchPods = exports.fetchIncomingQueueItems = exports.subscribeToIncomingQueueItemsHandled = exports.subscribeToIncomingQueueItemsCreated = exports.IncomingQueueItemsManager = void 0;
const interval_rate_limiter_1 = require("../../classes/interval-rate-limiter");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
class IncomingQueueItemsManager {
    constructor() {
        this.subscriptions = makeDefaultSubscriptions();
        this.incomingQueueItems = [];
        this.intervalManager = this.makeRateLimiter();
        this.pods = [];
        this.subscribers = new Set();
        this.initiated = false;
    }
    publish() {
        this.subscribers.forEach(subscriberFn => subscriberFn());
    }
    subscribe(callback) {
        this.subscribers.add(callback);
    }
    get totalQueueItems() {
        return this.incomingQueueItems.length;
    }
    get numWaitingQueueItems() {
        return this.incomingQueueItems.filter(q => q.incoming_call_type === 'USER').length;
    }
    get numCallbacks() {
        return this.incomingQueueItems.filter(q => q.incoming_call_type === 'CALLBACK').length;
    }
    get longestWaitTime() {
        return getLongestWaitTime(this.incomingQueueItems);
    }
    get podNamesWithCounts() {
        const result = this.getPodsWithCounts()
            .sort((a, b) => b.count - a.count || a.name.localeCompare(b.name))
            .map(pod => this.makeTextForPodInfo(pod))
            .join('\n');
        return result;
    }
    makeTextForPodInfo(pod) {
        const incomingQueueItems = this.getIncomingQueueItemsForPod(pod.id);
        const averageWaitTime = calculateAverageWaitTime(incomingQueueItems);
        const longestWaitTime = getLongestWaitTime(incomingQueueItems);
        if (longestWaitTime === 0) {
            return `${pod.name}: ${pod.count}`;
        }
        return (`${pod.name}: ${pod.count}` +
            `\n\tlongest waiting: ${(0, utilities_1.millisecondsToHoursMinutesSeconds)(longestWaitTime)}` +
            `\n\taverage waiting: ${(0, utilities_1.millisecondsToHoursMinutesSeconds)(averageWaitTime)}`);
    }
    getIncomingQueueItemsForPod(podId) {
        return this.incomingQueueItems.filter(q => q.pod.id === podId);
    }
    get highestPodCount() {
        return this.getPodsWithCounts().sort((a, b) => b.count - a.count)[0]?.count ?? 0;
    }
    get urgencyLevel() {
        if (this.isInHighestUrgency()) {
            return 'HIGHEST';
        }
        if (this.isInVeryHighUrgency()) {
            return 'VERY_HIGH';
        }
        if (this.isInHighUrgency()) {
            return 'HIGH';
        }
        if (this.isInMediumUrgency()) {
            return 'MEDIUM';
        }
        return 'LOW';
    }
    isInHighestUrgency() {
        return this.totalQueueItems >= 65 || this.highestPodCount >= 35;
    }
    isInVeryHighUrgency() {
        return this.totalQueueItems >= 55 || this.highestPodCount >= 25;
    }
    isInHighUrgency() {
        return this.totalQueueItems >= 45 || this.highestPodCount >= 20;
    }
    isInMediumUrgency() {
        return this.totalQueueItems >= 35 || this.highestPodCount >= 15;
    }
    closeSubscriptions() {
        Object.values(this.subscriptions).forEach(subscription => {
            if (subscription) {
                subscription.close();
            }
        });
    }
    async initiate() {
        if (this.initiated === true) {
            return;
        }
        this.initiated = true;
        await this.fetchAndSetPods();
        await this.fetchAndSetIncomingQueueItems();
        this.subscribeToIncomingQueueItems();
        this.publish();
    }
    async subscribeToIncomingQueueItems() {
        await this.createIncomingQueueItemCreatedSubscription();
        await this.createIncomingQueueItemHandledSubscription();
    }
    async createIncomingQueueItemHandledSubscription() {
        this.subscriptions.incomingQueueItemsHandled =
            (await subscribeToIncomingQueueItemsHandled(() => {
                this.intervalManager.trigger();
            })) ?? null;
    }
    async createIncomingQueueItemCreatedSubscription() {
        this.subscriptions.incomingQueueItemsCreated =
            (await subscribeToIncomingQueueItemsCreated(() => {
                this.intervalManager.trigger();
            })) ?? null;
    }
    makeRateLimiter() {
        return new interval_rate_limiter_1.IntervalRateLimiter({
            callback: this.fetchAndSetIncomingQueueItems.bind(this),
            millisecondsPerInterval: 5000,
        });
    }
    async fetchAndSetIncomingQueueItems() {
        this.incomingQueueItems = await fetchIncomingQueueItems();
        this.publish();
    }
    async fetchAndSetPods() {
        this.pods = await fetchPods();
        this.publish();
    }
    getPodsWithCounts() {
        return this.pods.map(pod => {
            return {
                ...pod,
                count: getNumQueueItemsForPod(this.incomingQueueItems, pod),
            };
        });
    }
}
exports.IncomingQueueItemsManager = IncomingQueueItemsManager;
function makeDefaultSubscriptions() {
    return {
        incomingQueueItemsCreated: null,
        incomingQueueItemsHandled: null,
    };
}
function getNumQueueItemsForPod(incomingQueueItems, pod) {
    return incomingQueueItems.filter(incomingQueueItem => incomingQueueItem.pod.id === pod.id)
        .length;
}
function calculateWaitTimeForIncomingQueueItem(queueItem) {
    if (queueItem.handled_at)
        return 0;
    return new Date().getTime() - new Date(queueItem.created_at).getTime();
}
function calculateAverageWaitTime(incomingQueueItems) {
    return calcualteTotalWaitTime(incomingQueueItems) / incomingQueueItems.length;
}
function calcualteTotalWaitTime(incomingQueueItems) {
    return incomingQueueItems.reduce((sum, curr) => {
        return sum + calculateWaitTimeForIncomingQueueItem(curr);
    }, 0);
}
function getLongestWaitTime(incomingQueueItems) {
    const longestWaiting = [...incomingQueueItems].sort((a, b) => calculateWaitTimeForIncomingQueueItem(b) - calculateWaitTimeForIncomingQueueItem(a))[0];
    const amountOfTimeLongestHasBeenWaiting = longestWaiting !== undefined ? calculateWaitTimeForIncomingQueueItem(longestWaiting) : 0;
    return amountOfTimeLongestHasBeenWaiting;
}
async function subscribeToIncomingQueueItemsCreated(callback) {
    try {
        const websocket = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                subscription {
                    incomingQueueItemCreated
                }
            `, callback);
        return websocket;
    }
    catch (error) {
        throw new Error(`subscribeToIncomingQueueItemsCreated error: ${error}`);
    }
}
exports.subscribeToIncomingQueueItemsCreated = subscribeToIncomingQueueItemsCreated;
async function subscribeToIncomingQueueItemsHandled(callback) {
    try {
        const websocket = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                subscription {
                    incomingQueueItemHandled
                }
            `, callback);
        return websocket;
    }
    catch (error) {
        throw new Error(`subscribeToIncomingQueueItemsHandled error: ${error}`);
    }
}
exports.subscribeToIncomingQueueItemsHandled = subscribeToIncomingQueueItemsHandled;
async function fetchIncomingQueueItems() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findIncoming_queue_items(
                    orderBy: {field: "created_at", order: ASC}
                    filter: {handled_at: {eq: null}}
                ) {
                    items {
                        id
                        created_at
                        incoming_call_type
                        pod {
                            id
                        }
                    }
                }
            }
        `);
        return gqlResult.data.findIncoming_queue_items.items;
    }
    catch (error) {
        throw new Error(`jo-incoming-queue-status-box > fetchIncomingQueueItems error: ${error}`);
    }
}
exports.fetchIncomingQueueItems = fetchIncomingQueueItems;
async function fetchPods() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findPods(orderBy: {field: "name", order: ASC}) {
                    items {
                        id
                        name
                    }
                }
            }
        `);
        return gqlResult.data.findPods.items;
    }
    catch (error) {
        throw new Error(`jo-incoming-queue-status-box > fethPods error: ${error}`);
    }
}
exports.fetchPods = fetchPods;
