"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.blockOrUnblockContact = exports.saveContact = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const queries_1 = require("./queries");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function saveContact(companyId, contactInputValues, actionItemId, callId) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $contactId: Int
                    $addressesInputs: [UpsertContactsaddressesInput!]!
                    $addressIdsToDisconnect: [Int!]!
                    $actionItemId: Int
                    $callId: Int
                    $companyId: Int!
                    $companyName: String!
                    $email: String!
                    $firstName: String!
                    $lastName: String!
                    $phoneNumber: String!
                    $phoneNumbersInputs: [UpsertContactsphone_numbersInput!]!
                    $phoneNumberIdsToDisconnect: [Int!]!
                    $sales: Boolean!
                    $whereDidYouHearAboutUs: String!
                ) {
                    upsertContacts(
                        input: {
                            id: $contactId
                            addresses: {
                                upsertMany: $addressesInputs
                                disconnect: {ids: $addressIdsToDisconnect}
                            }
                            action_items: {connect: {id: $actionItemId}}
                            calls: {connect: {id: $callId}}
                            company: {connect: {id: $companyId}}
                            company_name: $companyName
                            email: $email
                            first_name: $firstName
                            last_name: $lastName
                            phone_number: $phoneNumber
                            phone_numbers: {
                                upsertMany: $phoneNumbersInputs
                                disconnect: {ids: $phoneNumberIdsToDisconnect}
                            }
                            sales: $sales
                            where_did_you_hear_about_us: $whereDidYouHearAboutUs
                        }
                    ) {
                        id
                    }
                }
            `, {
            contactId: contactInputValues.contactId === -1 ? null : contactInputValues.contactId,
            addressesInputs: contactInputValues.addressesInputValues.map((addressInputValues) => {
                return {
                    id: addressInputValues.addressId < 0
                        ? null
                        : addressInputValues.addressId,
                    city: addressInputValues.cityInputValue,
                    county: addressInputValues.countyInputValue,
                    line_1: addressInputValues.line1InputValue,
                    line_2: addressInputValues.line2InputValue,
                    occupant_type: 'Contact',
                    postal_code: addressInputValues.postalCodeInputValue,
                    province: addressInputValues.provinceInputValue,
                    state: addressInputValues.stateInputValue,
                    zip_code: addressInputValues.zipCodeInputValue,
                };
            }),
            addressIdsToDisconnect: contactInputValues.addressIdsToDisconnect,
            actionItemId: actionItemId === 'NOT_SET' ? null : actionItemId,
            callId: callId === 'NOT_SET' ? null : callId,
            companyId,
            companyName: contactInputValues.businessNameInputValue,
            email: contactInputValues.emailInputValue,
            firstName: contactInputValues.firstNameInputValue,
            lastName: contactInputValues.lastNameInputValue,
            phoneNumber: contactInputValues.phoneNumbersInputValues.length === 0
                ? ''
                : contactInputValues.phoneNumbersInputValues[0]?.phoneNumber,
            phoneNumbersInputs: contactInputValues.phoneNumbersInputValues.map((phoneNumberInputValues) => {
                return {
                    id: phoneNumberInputValues.phoneNumberId < 0
                        ? null
                        : phoneNumberInputValues.phoneNumberId,
                    number: phoneNumberInputValues.phoneNumber,
                };
            }),
            phoneNumberIdsToDisconnect: contactInputValues.phoneNumberIdsToDisconnect,
            sales: contactInputValues.sales,
            whereDidYouHearAboutUs: contactInputValues.whereDidYouHearAboutUsInputValue,
        });
        if (result.succeeded === false) {
            return result;
        }
        const contact = result.value.data.upsertContacts;
        const freshContactResult = await (0, queries_1.fetchContact)(contact.id);
        if (freshContactResult.succeeded === false) {
            return freshContactResult;
        }
        const freshContact = freshContactResult.value;
        return {
            succeeded: true,
            value: freshContact,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-contact saveContact', error);
    }
}
exports.saveContact = saveContact;
async function blockOrUnblockContact(contact, blockOrUnblock) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($contactId: Int!, $status: Int!) {
                    updateContacts(input: {id: $contactId, status: $status}) {
                        id
                    }
                }
            `, {
            contactId: contact.id,
            status: blockOrUnblock === 'BLOCK' ? 3 : 0,
        });
        if (result.succeeded === false) {
            return result;
        }
        const freshContactResult = await (0, queries_1.fetchContact)(contact.id);
        if (freshContactResult.succeeded === false) {
            return freshContactResult;
        }
        const freshContact = freshContactResult.value;
        return {
            succeeded: true,
            value: freshContact,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-contact blockOrUnblockContact', error);
    }
}
exports.blockOrUnblockContact = blockOrUnblockContact;
