"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUserUnavailable = exports.setUserAvailableAndKickOffQueue = exports.kickOffQueue = exports.setUserAvailable = void 0;
const graphql_1 = require("../services/graphql");
const mapped_env_variables_1 = require("./environment/mapped-env-variables");
async function setUserAvailable(userId) {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($userId: Int!, $availableSince: DateTime!) {
                updateUsers(
                    input: {id: $userId, is_available: true, available_since: $availableSince}
                ) {
                    id
                    email
                    auth_role
                    is_available
                }
            }
        `, {
        userId,
        availableSince: new Date().toISOString(),
    });
}
exports.setUserAvailable = setUserAvailable;
async function kickOffQueue() {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation {
                kickOffQueue
            }
        `);
}
exports.kickOffQueue = kickOffQueue;
async function setUserAvailableAndKickOffQueue(userId) {
    await setUserAvailable(userId);
    await kickOffQueue();
}
exports.setUserAvailableAndKickOffQueue = setUserAvailableAndKickOffQueue;
async function setUserUnavailable(userId) {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($userId: Int!) {
                updateUsers(input: {id: $userId, is_available: false, queue_state: NOT_IN_QUEUE}) {
                    id
                    email
                    auth_role
                    is_available
                }
            }
        `, {
        userId,
    });
}
exports.setUserUnavailable = setUserUnavailable;
