"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.joFlagActionItemFormStyles = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../../utilities/css-utils");
const cssNames = (0, css_utils_1.cssPrefixer)('jo-flag-action-item-form');
exports.joFlagActionItemFormStyles = (0, lit_html_1.html) `
    <style>
        .${cssNames('main-container')} {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background-color: white;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .${cssNames('section')} {
            padding-bottom: 2rem;
            width: 60%;
        }

        .${cssNames('red')} {
            color: red;
        }

        .${cssNames('center')} {
            display: flex;
            justify-content: center;
            width: 60%;
        }
    </style>
`;
