"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUserBackAvailableAutomaticallyIfNecessary = exports.setUserBackAvailableInXMilliseconds = exports.stopTimeoutToSetUserBackAvailable = void 0;
const store_1 = require("../../../../services/store");
const utilities_1 = require("../../../../services/utilities");
const auth_rules_1 = require("../../../../utilities/auth-rules");
const user_status_event_mutations_1 = require("../../../../utilities/logging-and-monitoring/user-status-event-mutations");
const user_availability_1 = require("../../../../utilities/user-availability");
const is_user_in_chat_mode_1 = require("../../../../utilities/users/is-user-in-chat-mode/is-user-in-chat-mode");
let setUserBackAvailableTimeout = null;
function stopTimeoutToSetUserBackAvailable() {
    clearTimeout(setUserBackAvailableTimeout);
    setUserBackAvailableTimeout = null;
}
exports.stopTimeoutToSetUserBackAvailable = stopTimeoutToSetUserBackAvailable;
function setUserBackAvailableInXMilliseconds(user, milliseconds) {
    if (user === 'NOT_SET' || setUserBackAvailableTimeout !== null)
        return;
    createTimeoutToSetUserBackAvailable(user, milliseconds);
}
exports.setUserBackAvailableInXMilliseconds = setUserBackAvailableInXMilliseconds;
function createTimeoutToSetUserBackAvailable(user, milliseconds) {
    setUserBackAvailableTimeout = setTimeout(async () => {
        await setUserBackAvailableAutomaticallyIfNecessary(user);
        setUserBackAvailableTimeout = null;
    }, milliseconds);
}
async function setUserBackAvailableAutomaticallyIfNecessary(user) {
    if (user === 'NOT_SET')
        return;
    if (await shouldSetUserBackAvailable(user)) {
        (0, user_availability_1.setUserAvailableAndKickOffQueue)(user.id);
        const now = new Date();
        (0, user_status_event_mutations_1.createUserStatusEvent)({
            userId: user.id,
            eventType: 'AUTOMATICALLY_SET_AVAILABLE',
            timestamp: now,
        });
    }
}
exports.setUserBackAvailableAutomaticallyIfNecessary = setUserBackAvailableAutomaticallyIfNecessary;
async function shouldSetUserBackAvailable(user) {
    return (user !== 'NOT_SET' &&
        !(0, is_user_in_chat_mode_1.isUserInChatMode)(user) &&
        !isOnActiveCallScreen() &&
        !(0, auth_rules_1.isJillAtOrAboveAuthRole)(user, 'JILL_OUTBOUND') &&
        (await userWasAutomaticallySetUnavailableWithinMillisecondsAgo(user.id, 2000)) === false &&
        (await userWentAvailableWithinMillisecondsAgo(user.id, 3000)) === false &&
        store_1.GlobalStore.authenticatedUser !== 'NOT_SET' &&
        store_1.GlobalStore.authenticatedUser.work_status === 'CLOCKED_IN' &&
        store_1.GlobalStore.authenticatedUser.is_available === false &&
        (0, utilities_1.isOnCallScreen)() === false);
}
function isActiveCallMode(callScreenMode) {
    return (callScreenMode === 'INCOMING_EXISTING_ACTION_ITEM' ||
        callScreenMode === 'INCOMING_NEW_ACTION_ITEM' ||
        callScreenMode === 'OUTGOING_EXISTING_ACTION_ITEM' ||
        callScreenMode === 'OUTGOING_NEW_ACTION_ITEM');
}
function isOnActiveCallScreen() {
    return (doesUrlIncludeCallScreenModeParam('INCOMING_EXISTING_ACTION_ITEM') ||
        doesUrlIncludeCallScreenModeParam('INCOMING_NEW_ACTION_ITEM') ||
        doesUrlIncludeCallScreenModeParam('OUTGOING_EXISTING_ACTION_ITEM') ||
        doesUrlIncludeCallScreenModeParam('OUTGOING_NEW_ACTION_ITEM'));
}
function doesUrlIncludeCallScreenModeParam(callScreenMode) {
    return window.location.href.includes(`callScreenMode=${callScreenMode}`);
}
async function userWentAvailableWithinMillisecondsAgo(userId, millisecondsAgo) {
    const mostRecentStatusEvent = await (0, user_status_event_mutations_1.fetchMostRecentUserStatusEventForUser)(userId);
    if (!mostRecentStatusEvent)
        return false;
    return (wasTimestampWithinMillisecondsAgo(mostRecentStatusEvent.timestamp, millisecondsAgo) &&
        statusEventWasToGoAvailable(mostRecentStatusEvent));
}
function statusEventWasToGoAvailable(mostRecentStatusEvent) {
    return (mostRecentStatusEvent.type === 'MANUALLY_WENT_AVAILABLE' ||
        mostRecentStatusEvent.type === 'AUTOMATICALLY_SET_AVAILABLE');
}
function wasTimestampWithinMillisecondsAgo(timestamp, millisecondsAgo) {
    return new Date().getTime() - new Date(timestamp).getTime() < millisecondsAgo;
}
async function userWasAutomaticallySetUnavailableWithinMillisecondsAgo(userId, millisecondsAgo) {
    const mostRecentStatusEvent = await (0, user_status_event_mutations_1.fetchMostRecentUserStatusEventForUser)(userId);
    if (!mostRecentStatusEvent)
        return false;
    return (wasTimestampWithinMillisecondsAgo(mostRecentStatusEvent.timestamp, millisecondsAgo) &&
        mostRecentStatusEvent.type === 'AUTOMATICALLY_SET_UNAVAILABLE');
}
