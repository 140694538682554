"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const jo_home_1 = require("../jo-home");
const constants_1 = require("./constants");
const queries_1 = require("./queries");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const replace_not_set_with_undefined_1 = require("../../../../utilities/type-helpers/replace-not-set-with-undefined/replace-not-set-with-undefined");
class JOHomeJillStatsCard extends HTMLElement {
    constructor() {
        super();
        this.store = (0, reduxular_1.createObjectStore)(constants_1.InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.subscribeToJOHomeSubscribable();
    }
    subscribeToJOHomeSubscribable() {
        const callback = (() => {
            this.resetDates();
            this.handleFetchAndSetData();
        }).bind(this);
        jo_home_1.JOHomeSubscribable.subscribe(callback);
    }
    resetDates() {
        this.store.statsStartDate = constants_1.InitialState.statsStartDate;
        this.store.statsEndDate = constants_1.InitialState.statsEndDate;
    }
    set authenticatedUser(authenticatedUser) {
        if (authenticatedUser === 'NOT_SET') {
            return;
        }
        if (this.store.authenticatedUser !== 'NOT_SET' &&
            this.store.authenticatedUser.id === authenticatedUser.id) {
            return;
        }
        this.store.authenticatedUser = authenticatedUser;
        this.handleFetchAndSetData();
    }
    async handleFetchAndSetData() {
        this.store.loading = true;
        await this.handleFetchAndSetJill();
        await this.fetchAndSetJillStats();
        (0, utilities_1.raiseCustomEvent)(this, 'loaded', true);
        this.store.loading = false;
    }
    async handleFetchAndSetJill() {
        if (this.store.jill !== 'NOT_SET') {
            return;
        }
        const user = (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(this.store.authenticatedUser);
        if (!user) {
            throw new Error('No authenticatedUser for jo-home-jill-stats-card');
        }
        const jillResult = await (0, queries_1.fetchJill)(user.id);
        if (!jillResult.succeeded) {
            throw new Error('fetchJill failed for jo-home-jill-stats-card');
        }
        this.store.jill = jillResult.value;
    }
    async fetchAndSetJillStats() {
        const user = (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(this.store.authenticatedUser);
        if (!user) {
            throw new Error('No user for fetching jill stats in jo-home-jill-stats-card');
        }
        const jillStatsResult = await (0, queries_1.fetchJillStats)(user.id, this.store.statsStartDate, this.store.statsEndDate);
        if (!jillStatsResult.succeeded) {
            throw new Error('fetchJillStats failed in jo-home-jill-stats-card');
        }
        this.store.jillHighLevelStatses = jillStatsResult.value;
    }
    async statsStartDateInputChanged(e) {
        const newStartDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.statsStartDate = newStartDate;
        await this.fetchAndSetJillStats();
    }
    async statsEndDateInputChanged(e) {
        const newEndDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.statsEndDate = newEndDate;
        await this.fetchAndSetJillStats();
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-home-jill-stats-card--main-container {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    min-width: 350px;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-narrow-border-radius-1);
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    font-weight: 200;
                    background-color: white;
                }

                .jo-home-jill-stats-card--upper-container {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    padding-top: 0.5rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    padding-bottom: 2rem;
                }

                .jo-home-jill-stats-card--jill-container {
                    box-sizing: border-box;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                }

                .jo-home-jill-stats-card--jill-header {
                    box-sizing: border-box;
                    font-weight: bold;
                    font-size: 1.2rem;
                }

                .jo-home-jill-stats-card--dates-container {
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 0.9rem;
                    flex: 1;
                }

                .jo-home-jill-stats-card--date-input {
                    box-sizing: border-box;
                    border: 1px solid rgb(160, 160, 160);
                    border-radius: 4px;
                    width: 93%;
                    font-size: 0.8rem;
                }
            </style>

            <div class="jo-home-jill-stats-card--main-container">
                <div class="jo-home-jill-stats-card--upper-container">
                    <div class="jo-home-jill-stats-card--jill-container">
                        <div class="jo-home-jill-stats-card--jill-header">JILL'N STATS</div>

                        <img src="/images/jill-waving.png" style="height: 4rem;" />

                        <div class="jo-global--text-align-center">
                            ${state.jill !== 'NOT_SET'
            ? `${state.jill.first_name} ${state.jill.last_name}`
            : 'Loading...'}
                        </div>
                    </div>
                    <div class="jo-home-jill-stats-card--dates-container">
                        <div>
                            <div>Start date</div>
                            <input
                                type="date"
                                class="jo-input--input jo-home-jill-stats-card--date-input"
                                .value=${state.statsStartDate.split('T')[0]}
                                @change=${(e) => this.statsStartDateInputChanged(e)}
                            />
                        </div>
                        <div class="jo-global--1rem-above">
                            <div>End date</div>
                            <input
                                type="date"
                                class="jo-input--input jo-home-jill-stats-card--date-input"
                                .value=${state.statsEndDate.split('T')[0]}
                                @change=${(e) => this.statsEndDateInputChanged(e)}
                            />
                        </div>
                    </div>
                </div>

                <div class="jo-global--home-card-stat-line">
                    <div>Total Calls</div>
                    <div>
                        ${state.jillHighLevelStatses !== 'NOT_SET' && state.loading === false
            ? state.jillHighLevelStatses.highLevelStatses.highLevelStats.numCalls
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Total Actual Call Duration</div>
                    <div>
                        ${state.jillHighLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getActualCallDurationString)(state.jillHighLevelStatses.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Total Billable Call Duration</div>
                    <div>
                        ${state.jillHighLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getTotalBillableCallDurationString)(state.jillHighLevelStatses.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Efficiency/Day</div>
                    <div>
                        ${state.jillHighLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageEfficiencyPerDayString)(state.jillHighLevelStatses.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Actual Call Duration</div>
                    <div>
                        ${state.jillHighLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageActualCallDurationString)(state.jillHighLevelStatses.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Billable Call Duration</div>
                    <div>
                        ${state.jillHighLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageBillableCallDurationString)(state.jillHighLevelStatses.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Time Between Calls</div>
                    <div>
                        ${state.jillHighLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageTimeBetweenCalls)(state.jillHighLevelStatses.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Action Item Time</div>
                    <div>
                        ${state.jillHighLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageActionItemTimeString)(state.jillHighLevelStatses.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-home-jill-stats-card', JOHomeJillStatsCard);
