"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIframeURL = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function getIframeURL(companyId, viewType) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $viewType: CrewCalViewTypes!) {
                    getCrewCalIframeURL(companyId: $companyId, view: $viewType)
                }
            `, {
            companyId,
            viewType,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const iframeURL = gqlResult.value.data.getCrewCalIframeURL;
        return {
            succeeded: true,
            value: iframeURL,
        };
    }
    catch (error) {
        throw new Error(`getIframeURL error: ${error}`);
    }
}
exports.getIframeURL = getIframeURL;
