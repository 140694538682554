"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActionItems = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function getActionItems(offset, limit, statusType) {
    const statusTypeId = statusType === 'ASSIGNED_TO_JILLS_OFFICE' ? 3 : 4; // 3: Assigned to Jills, 4: Callbacks
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($offset: Int!, $limit: Int!, $statusTypeId: Int!) {
                findAction_items(
                    filter: {and: [{action_item_status_type_id: {eq: $statusTypeId}}]}
                    page: {offset: $offset, limit: $limit}
                    orderBy: {field: "updated_at", order: ASC}
                ) {
                    items {
                        id
                        action_item_notes(orderBy: {field: "created_at", order: DESC}) {
                            id
                            action_item {
                                id
                            }
                            author {
                                id
                                auth_role
                                email
                                first_name
                            }
                            body
                            call {
                                id
                                recording_url
                            }
                            created_at
                        }
                        call {
                            id
                            new_direction
                            origin_phone
                            destination_phone
                            recording_url
                        }
                        company {
                            id
                            name
                            pod {
                                id
                                name
                            }
                            timezone
                        }
                        contact {
                            id
                            first_name
                            last_name
                            phone_number
                            phone_numbers(orderBy: {field: "created_at", order: ASC}) {
                                id
                                number
                                created_at
                            }
                        }
                        title
                        created_at
                        updated_at
                    }
                    total
                }
            }
        `, {
        offset,
        limit,
        statusTypeId,
    });
    const actionItems = gqlResult.data.findAction_items.items;
    const totalItems = gqlResult.data.findAction_items.total;
    return {
        items: actionItems,
        totalItems,
    };
}
exports.getActionItems = getActionItems;
