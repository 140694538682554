"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
require("../../jo-button.element");
require("../../jo-input");
const InitialState = {
    actionItemContact: null,
    authenticatedUser: 'NOT_SET',
    openButtonType: 'ACTION_BUTTON_2',
    openButtonText: 'View Chat Transcript',
    chat: null,
    displayButtonOnRight: false,
    icon: undefined,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-transcript');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOChatTranscript extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    displayMessages(state) {
        return (0, lit_html_1.html) `
            <section class="${cssNames('chat-transcript-container')}">
                Source: ${this.getChatSourceForDisplay()}
                <hr />
                ${this.store.chat?.messages.map(message => {
            if (message.source === 'CUSTOMER_WIDGET' || message.source === 'CUSTOMER_SMS') {
                return (0, lit_html_1.html) `
                            <div class="${cssNames('incoming-message')}">
                                <jo-chat-message
                                    .authenticatedUser=${state.authenticatedUser}
                                    .message=${message}
                                    .author=${this.getCustomerName()}
                                    .isIncoming=${true}
                                ></jo-chat-message>
                            </div>
                        `;
            }
            if (message.source === 'JILL_DASHBOARD') {
                return (0, lit_html_1.html) `
                            <div class="${cssNames('outgoing-message')}">
                                <jo-chat-message
                                    .authenticatedUser=${state.authenticatedUser}
                                    .message=${message}
                                    .author=${getJillName(message)}
                                    .isIncoming=${false}
                                ></jo-chat-message>
                            </div>
                        `;
            }
        })}

                <hr />
            </section>
        `;
    }
    getChatSourceForDisplay() {
        const initialMessage = this.store.chat?.messages[0];
        if (!initialMessage) {
            return 'No Messages in this chat conversation';
        }
        if (initialMessage.source === 'CUSTOMER_WIDGET' || initialMessage.source === 'SYSTEM') {
            return initialMessage.from_origin ?? 'Website Chat Widget';
        }
        return 'Texting SMS';
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssNames('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssNames('incoming-message')} {
                    width: 80%;
                    display: flex;
                }

                .${cssNames('outgoing-message')} {
                    width: 80%;
                    display: flex;
                    margin-left: auto;
                    margin-right: 0;
                    justify-content: flex-end;
                }

                .${cssNames('chat-transcript-container')} {
                    padding-top: 0.5rem;
                }

                .${cssNames('buttons-container')} {
                    display: flex;
                    justify-content: flex-end;
                }
            </style>

            <div class="${cssNames('main-container')}">
                <section class="${state.displayButtonOnRight ? cssNames('buttons-container') : ''}">
                    <jo-button
                        .type=${state.openButtonType}
                        .text=${state.openButtonText}
                        .icon=${state.icon != undefined ? state.icon : ''}
                        @joButtonClick=${() => {
            (0, utilities_1.joPopUp)({
                title: 'Chat Transcript',
                htmlComponents: this.displayMessages(state),
            });
        }}
                    ></jo-button>
                </section>
            </div>
        `;
    }
    getCustomerName() {
        if (this.store.actionItemContact &&
            this.store.actionItemContact.first_name &&
            this.store.actionItemContact.last_name) {
            return (this.store.actionItemContact.first_name +
                ' ' +
                this.store.actionItemContact.last_name?.substring(0, 1) +
                '.');
        }
        return 'Customer';
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-transcript', JOChatTranscript);
function getJillName(message) {
    if (message.user && message.user.last_name && message.user.first_name) {
        return message.user.first_name + ' ' + message.user.last_name.substring(0, 1) + '.';
    }
    return 'Jill';
}
