"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.doWeNeedToUpdateLoadedContacts = void 0;
const common_1 = require("@augment-vir/common");
function doWeNeedToUpdateLoadedContacts({ contactIds, loadedContacts, loadError, }) {
    if ((0, common_1.isPromiseLike)(loadedContacts)) {
        // don't try to load while we're still waiting for the last load to finish
        return false;
    }
    if (loadError && !(0, common_1.areJsonEqual)(loadError.idsForError, contactIds)) {
        return true;
    }
    else if (loadError) {
        /**
         * In the event that a load error exists and its an error for the current ids, do not try to
         * load again (otherwise we will continuously send invalid requests to the server).
         */
        return false;
    }
    return !(0, common_1.areJsonEqual)(contactIds, loadedContacts.map(contact => contact.id));
}
exports.doWeNeedToUpdateLoadedContacts = doWeNeedToUpdateLoadedContacts;
