"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const interval_rate_limiter_1 = require("../../../classes/interval-rate-limiter");
const ensure_type_1 = require("../../../utilities/types/ensure-type");
const auth_rules_1 = require("../../../utilities/auth-rules");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
require("./jo-contact-search-contact-card");
const InitialState = {
    addressCities: [],
    addressLines1: [],
    addressLines2: [],
    addressStates: [],
    addressZipCodes: [],
    authenticatedUser: 'NOT_SET',
    callScreenMode: 'NOT_SET',
    canAssignNewContactToActionItem: false,
    companyId: 'NOT_SET',
    contactId: -1,
    contacts: [],
    email: '',
    firstName: '',
    lastName: '',
    phoneNumbers: [],
};
class JOCallScreenContactSearch extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
        this.fetchContactsRateLimiter = new interval_rate_limiter_1.IntervalRateLimiter({
            callback: this.fetchAndSetContacts.bind(this),
            millisecondsPerInterval: 4000,
        });
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const originalContactId = this.store.contactId;
        const originalPhoneNumbers = this.store.phoneNumbers;
        const originalFirstName = this.store.firstName;
        const originalLastName = this.store.lastName;
        const originalEmail = this.store.email;
        const originalAddressLines1 = this.store.addressLines1;
        const originalAddressLines2 = this.store.addressLines2;
        const originalAddressCities = this.store.addressCities;
        const originalAddressZipCodes = this.store.addressZipCodes;
        const originalAddressStates = this.store.addressStates;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.contactId !== originalContactId ||
            !stringArraysEqual(this.store.phoneNumbers, originalPhoneNumbers) ||
            this.store.firstName !== originalFirstName ||
            this.store.lastName !== originalLastName ||
            this.store.email !== originalEmail ||
            !stringArraysEqual(this.store.addressLines1, originalAddressLines1) ||
            !stringArraysEqual(this.store.addressLines2, originalAddressLines2) ||
            !stringArraysEqual(this.store.addressCities, originalAddressCities) ||
            !stringArraysEqual(this.store.addressZipCodes, originalAddressZipCodes) ||
            !stringArraysEqual(this.store.addressStates, originalAddressStates)) {
            if (this.store.contactId !== -1 &&
                this.store.firstName === InitialState.firstName &&
                this.store.lastName === InitialState.lastName &&
                this.store.email === InitialState.email) {
                this.fetchAndSetSingleContact();
            }
            else if (this.store.canAssignNewContactToActionItem === true) {
                this.fetchContactsRateLimiter.trigger();
            }
            else if (this.store.contactId !== -1) {
                this.fetchAndSetSingleContact();
            }
        }
    }
    async fetchAndSetContacts() {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        const contactsResult = await fetchContacts(this.store.companyId, this.store.phoneNumbers, this.store.firstName, this.store.lastName, this.store.email, this.store.addressLines1, this.store.addressLines2, this.store.addressCities, this.store.addressZipCodes, this.store.addressStates);
        if (contactsResult.succeeded === false) {
            alert(contactsResult.userMessage);
            throw new Error(contactsResult.developerMessage);
        }
        const contacts = contactsResult.value;
        this.store.contacts = contacts;
    }
    async fetchAndSetSingleContact() {
        const contactResult = await fetchSingleContact(this.store.contactId);
        (0, utilities_1.assertSucceeded)(contactResult, utilities_1.handleError);
        const contact = contactResult.value;
        this.store.contacts = [contact];
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                @media screen and (max-width: 1300px) {
                    .title {
                        font-size: 1rem;
                    }
                }

                @media screen and (max-width: 1078px) {
                    .title {
                        font-size: 0.8rem;
                    }
                }

                .title {
                    font-weight: bold;
                    padding: 10px;
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    font-size: 1.1rem;
                    text-align: center;
                }

                .contact-cards {
                    display: flex;
                    flex-direction: column;
                }

                .contact-card {
                    padding: 10px;
                    color: #686968;
                    font-family: sans-serif;
                    font-size: 14px;
                }

                .contact-card-name {
                    font-weight: bold;
                    font-size: 14px;
                }

                .contact-card-phone-number {
                    padding-top: 3px;
                    padding-bottom: 3px;
                    font-size: 13px;
                }

                .contact-card-phone-number-title {
                    text-decoration: underline;
                    font-size: 14px;
                }

                .contact-card-address {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    font-size: 13px;
                }

                .contact-card-address-title {
                    text-decoration: underline;
                    font-size: 14px;
                }

                .contact-card-icons-container {
                    display: flex;
                }

                .contact-card-icon-container {
                    padding-top: 5px;
                }

                .contact-card-activity-log-icon-container {
                    padding-left: 10px;
                }

                @media screen and (max-width: 844px) {
                    .contact-card-icon {
                        font-size: 3vmin;
                    }
                }

                .contact-card-icon {
                    padding: 3px;
                    background-color: var(--jo-primary);
                    color: white;
                    border-radius: 2px;
                    cursor: pointer;
                    font-size: 18px;
                }
                .contact-phone-icon {
                    color: var(--jo-primary);
                    font-size: 12px;
                }
                .contact-address-icon {
                    color: var(--jo-primary);
                    font-size: 14px;
                }
                .jo-contact-email {
                    font-size: 13px;
                }
            </style>

            <div class="main-container">
                <div class="title">Contacts</div>
                <div class="contact-cards">
                    ${state.contacts.map(contact => {
            const thisContactIsSelected = state.contactId === contact.id;
            const canMergeThisContact = !thisContactIsSelected &&
                state.contactId !== -1 &&
                (0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_OUTBOUND');
            return (0, lit_html_1.html) `
                            <jo-contact-search-contact-card
                                .props=${(0, ensure_type_1.ensureType)({
                contact,
                canAssignNewContactToActionItem: state.canAssignNewContactToActionItem,
                contactIsSelected: thisContactIsSelected,
                canMergeContacts: canMergeThisContact,
            })}
                                @contactcardmergeclicked=${(event) => {
                const contactIds = [
                    state.contactId,
                    event.detail.id,
                ];
                (0, utilities_1.raiseCustomEvent)(this, 'showmergecontactsmodal', contactIds);
            }}
                            ></jo-contact-search-contact-card>
                        `;
        })}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-contact-search', JOCallScreenContactSearch);
// TODO it would be nice to make searchContacts return a Contacts__JOContact
// TODO I am not sure how to go about sharing the type on a custom resolver
async function fetchContacts(companyId, phoneNumbers, firstName, lastName, email, addressLines1, addressLines2, addressCities, addressZipCodes, addressStates) {
    const contactsResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $companyId: Int!
                $phoneNumbers: [String!]!
                $firstName: String!
                $lastName: String!
                $email: String!
                $addressLines1: [String!]!
                $addressLines2: [String!]!
                $addressCities: [String!]!
                $addressZipCodes: [String!]!
                $addressStates: [String!]!
            ) {
                searchContacts(
                    page: {offset: 0, limit: 20}
                    companyId: $companyId
                    phoneNumbers: $phoneNumbers
                    firstName: $firstName
                    lastName: $lastName
                    email: $email
                    addressLines1: $addressLines1
                    addressLines2: $addressLines2
                    addressCities: $addressCities
                    addressZipCodes: $addressZipCodes
                    addressStates: $addressStates
                ) {
                    contacts {
                        id
                        addresses(orderBy: {field: "created_at", order: ASC}) {
                            id
                            line_1
                            line_2
                            city
                            county
                            postal_code
                            province
                            state
                            zip_code
                        }
                        company {
                            id
                        }
                        company_name
                        email
                        first_name
                        last_name
                        phone_number
                        phone_numbers(orderBy: {field: "created_at", order: ASC}) {
                            id
                            number
                        }
                        sales
                        status
                        where_did_you_hear_about_us
                    }
                }
            }
        `, {
        companyId,
        phoneNumbers,
        firstName,
        lastName,
        email,
        addressLines1,
        addressLines2,
        addressCities,
        addressZipCodes,
        addressStates,
        offset: 0,
        limit: 20,
    });
    if (contactsResult.succeeded === false) {
        return contactsResult;
    }
    const contacts = contactsResult.value.data.searchContacts.contacts;
    return {
        succeeded: true,
        value: contacts,
    };
}
function stringArraysEqual(stringArray1, stringArray2) {
    return stringArray1.reduce((result, string1, index) => {
        if (result === false) {
            return result;
        }
        if (string1 === stringArray2[index]) {
            return true;
        }
        else {
            return false;
        }
    }, true);
}
async function fetchSingleContact(contactId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($contactId: Int!) {
                getContacts(id: $contactId) {
                    id
                    addresses(orderBy: {field: "created_at", order: ASC}) {
                        id
                        line_1
                        line_2
                        city
                        county
                        postal_code
                        province
                        state
                        zip_code
                    }
                    company {
                        id
                    }
                    company_name
                    email
                    first_name
                    last_name
                    phone_number
                    phone_numbers(orderBy: {field: "created_at", order: ASC}) {
                        id
                        number
                    }
                    sales
                    status
                    where_did_you_hear_about_us
                }
            }
        `, {
        contactId,
    });
    if (gqlResult.succeeded === false)
        return gqlResult;
    const contact = gqlResult.value.data.getContacts;
    return {
        succeeded: true,
        value: contact,
    };
}
