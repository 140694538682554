"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveAndSendActionItem = void 0;
const graphql_1 = require("../../../../../../services/graphql");
const utilities_1 = require("../../../../../../services/utilities");
const user_status_event_mutations_1 = require("../../../../../../utilities/logging-and-monitoring/user-status-event-mutations");
const get_action_item_note_mutation_inputs_1 = require("./get-action-item-note-inputs/get-action-item-note-mutation-inputs");
const mapped_env_variables_1 = require("../../../../../../utilities/environment/mapped-env-variables");
async function saveAndSendActionItem(actionItemId, actionItem, callId, chatId, companyId, contactId, titleInputValues, noteText, jillId, customFieldResponsesInputValues, pricingInputValues, actionItemStatusTypeName, originalActionItemStatusTypeId, selectedServices, serviceResponses, followupRequestDateInputValue, scheduledAppointmentDetails, accountHelpTicketId) {
    const actionItemStatusTypeNamesToIds = {
        Closed: 1,
        'Assigned to Company': 2,
        'Assigned to Jills Office': 3,
        Callback: 4,
        NOT_SET: originalActionItemStatusTypeId,
    };
    const shouldCloseActionItem = titleInputValues !== 'NOT_SET' && titleInputValues.title.startsWith('*');
    const previousActionItemNotes = actionItem === 'NOT_SET' ? [] : actionItem.action_item_notes;
    const actionItemResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation (
                $actionItemId: Int
                $actionItemNoteInputs: [CreateAction_itemsaction_item_notesInput!]!
                $actionItemStatusTypeId: Int!
                $callId: Int
                $chatId: Int
                $companyId: Int!
                $contactId: Int!
                $followupRequestDate: DateTime!
                $priceQuote: Int!
                $slug: String!
                $subtitle: String!
                $title: String!
                $urgent: Boolean!
                $jillId: Int!
                $customFieldResponsesInputs: [UpsertAction_itemscustom_field_responsesInput!]!
                $serviceLineItemResponsesInputs: [UpsertAction_itemsservice_line_item_responsesInput!]!
                $serviceResponseIds: [DeleteInput!]!
                $serviceResponsesInputs: [CreateAction_itemsservice_responsesInput!]!
            ) {
                upsertAction_items(
                    input: {
                        id: $actionItemId
                        action_item_notes: {createMany: $actionItemNoteInputs}
                        action_item_status_type: {connect: {id: $actionItemStatusTypeId}}
                        call: {connect: {id: $callId}}
                        chat: {connect: {id: $chatId}}
                        company: {connect: {id: $companyId}}
                        contact: {connect: {id: $contactId}}
                        custom_field_responses: {upsertMany: $customFieldResponsesInputs}
                        followup_request_date: $followupRequestDate
                        price_quote: $priceQuote
                        service_line_item_responses: {upsertMany: $serviceLineItemResponsesInputs}
                        service_responses: {
                            deleteMany: $serviceResponseIds
                            createMany: $serviceResponsesInputs
                        }
                        slug: $slug
                        subtitle: $subtitle
                        title: $title
                        urgent: $urgent
                        user: {connect: {id: $jillId}}
                    }
                ) {
                    id
                    action_item_notes {
                        id
                    }
                }
            }
        `, {
        actionItemId: actionItemId === 'NOT_SET' ? null : actionItemId,
        actionItemNoteInputs: (0, get_action_item_note_mutation_inputs_1.getCreateActionItemNoteMutationInputs)({
            callId,
            jillId,
            noteText,
            selectedServices,
            previousActionItemNotes,
            customFieldResponsesInputValues,
            pricingInputValues,
            scheduledAppointmentDetails,
        }),
        actionItemStatusTypeId: getActionItemStatusTypeId(shouldCloseActionItem, actionItemStatusTypeNamesToIds, actionItemStatusTypeName),
        callId: callId === 'NOT_SET' ? null : callId,
        chatId: chatId === 'NOT_SET' ? null : chatId,
        companyId,
        contactId,
        followupRequestDate: followupRequestDateInputValue,
        priceQuote: pricingInputValues.priceQuote,
        slug: getSlug(actionItem),
        subtitle: titleInputValues === 'NOT_SET' ? '' : titleInputValues.subtitle,
        title: getTitleForActionItem(titleInputValues),
        urgent: false,
        jillId,
        customFieldResponsesInputs: makeCustomFieldResponsesInputForUpsert(customFieldResponsesInputValues),
        serviceLineItemResponsesInputs: makeServiceLineItemResponsesInputForUpsert(pricingInputValues),
        serviceResponseIds: serviceResponses.map(serviceResponse => {
            return {
                id: serviceResponse.id,
            };
        }),
        serviceResponsesInputs: selectedServices.map(service => {
            return {
                service: {
                    connect: {
                        id: service.id,
                    },
                },
            };
        }),
    });
    if (actionItemResult.succeeded === false) {
        return actionItemResult;
    }
    const createdActionItem = actionItemResult.value.data.upsertAction_items;
    await (0, user_status_event_mutations_1.createUserStatusEvent)({
        userId: jillId,
        eventType: 'CREATED_ACTION_ITEM',
        companyId: companyId,
        actionItemId: createdActionItem.id,
    });
    await connectHelpTicketToActionItemIfNecessary(accountHelpTicketId, createdActionItem.id);
    sendActionItemNotificationsIfNecessary(shouldCloseActionItem, actionItemStatusTypeName, previousActionItemNotes, createdActionItem, companyId);
    return {
        succeeded: true,
    };
}
exports.saveAndSendActionItem = saveAndSendActionItem;
async function connectHelpTicketToActionItemIfNecessary(accountHelpTicketId, actionItemId) {
    if (accountHelpTicketId === undefined)
        return;
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($accountHelpTicketId: Int!, $actionItemId: Int!) {
                updateAccount_help_tickets(
                    input: {id: $accountHelpTicketId, action_item: {connect: {id: $actionItemId}}}
                ) {
                    id
                }
            }
        `, {
        accountHelpTicketId,
        actionItemId,
    });
}
function sendActionItemNotificationsIfNecessary(shouldCloseActionItem, actionItemStatusTypeName, previousActionItemNotes, createdActionItem, companyId) {
    if (shouldCloseActionItem === false && actionItemStatusTypeName !== 'Callback') {
        void (0, utilities_1.sendNewActionItemNoteEmails)(previousActionItemNotes, createdActionItem.action_item_notes);
        void (0, utilities_1.sendActionItemPushNotification)(companyId);
    }
}
function getTitleForActionItem(titleInputValues) {
    return titleInputValues === 'NOT_SET' ? '' : buildActionItemTitle(titleInputValues);
}
function buildActionItemTitle(titleInputValues) {
    return `${titleInputValues.title}${titleInputValues.subtitle !== '' ? ` -- ${titleInputValues.subtitle}` : ''}`;
}
function makeServiceLineItemResponsesInputForUpsert(pricingInputValues) {
    return pricingInputValues.serviceLineItemResponsesInputValues.map((serviceLineItemResponseInputValues) => {
        return {
            id: serviceLineItemResponseInputValues.serviceLineItemResponseId === 'NOT_SET' ||
                serviceLineItemResponseInputValues.serviceLineItemResponseId === -1
                ? null
                : serviceLineItemResponseInputValues.serviceLineItemResponseId,
            service_line_item: {
                connect: {
                    id: serviceLineItemResponseInputValues.service_line_item.id,
                },
            },
            quantity: serviceLineItemResponseInputValues.quantity,
        };
    });
}
function makeCustomFieldResponsesInputForUpsert(customFieldResponsesInputValues) {
    return customFieldResponsesInputValues.map((customFieldResponseInputValues) => {
        return {
            id: getCustomFieldResponseInputUpsertId(customFieldResponseInputValues),
            custom_field: {
                connect: {
                    id: customFieldResponseInputValues.customField.id,
                },
            },
            response: customFieldResponseInputValues.response,
            service: {
                connect: {
                    id: customFieldResponseInputValues.service.id,
                },
            },
        };
    });
}
function getCustomFieldResponseInputUpsertId(customFieldResponseInputValues) {
    return customFieldResponseInputValues.customFieldResponseId === 'NOT_SET' ||
        customFieldResponseInputValues.customFieldResponseId === -1
        ? null
        : customFieldResponseInputValues.customFieldResponseId;
}
function getActionItemStatusTypeId(shouldCloseActionItem, actionItemStatusTypeNamesToIds, actionItemStatusTypeName) {
    return shouldCloseActionItem === true
        ? actionItemStatusTypeNamesToIds['Closed']
        : actionItemStatusTypeNamesToIds[actionItemStatusTypeName];
}
function getSlug(actionItem) {
    if (actionItem === 'NOT_SET' || !actionItem.slug) {
        return (0, utilities_1.createActionItemSlug)();
    }
    return actionItem.slug;
}
