"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const InitialState = {
    titleText: '',
    child: (0, lit_html_1.html) ``,
    open: false,
};
class JODropReveal extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set open(open) {
        this.store.open = open;
        if (this.store.open === true) {
            this.scrollIntoView({
                behavior: 'smooth',
            });
            (0, utilities_1.raiseCustomEvent)(this, 'opened');
        }
        else {
            (0, utilities_1.raiseCustomEvent)(this, 'closed');
        }
    }
    get open() {
        return this.store.open;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-drop-reveal--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .jo-drop-reveal--title-text-container {
                    padding: calc(5px + 1vmin);
                    background-color: var(--jo-accent-background-light);
                    border-radius: var(--jo-wide-border-radius-1);
                    font-size: calc(10px + 0.8vmin);
                    font-weight: bold;
                    color: var(--jo-text-default);
                    cursor: pointer;
                    font-family: sans-serif;
                    border: solid 1px #979797;
                    z-index: 1;
                    flex: 1;
                    width: 96%;
                }

                .jo-drop-reveal--child-container {
                    margin-top: -10px;
                    z-index: 0;
                    width: 99%;
                }
            </style>

            <div class="jo-drop-reveal--main-container">
                <div
                    class="jo-drop-reveal--title-text-container"
                    @click=${() => (this.open = !this.open)}
                >
                    ${state.titleText}
                </div>
                <div
                    ?hidden=${state.open === false}
                    class="jo-drop-reveal--child-container jo-global--card"
                >
                    ${state.child}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-drop-reveal', JODropReveal);
