"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const create_element_bound_interval_1 = require("../../utilities/timeouts/create-element-bound-interval");
class JOLoadingText extends HTMLElement {
    constructor() {
        super(...arguments);
        this.text = 'Loading';
    }
    connectedCallback() {
        let counter = 3;
        (0, lit_html_1.render)((0, lit_html_1.html) `
                ${this.text}...
            `, this);
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: () => {
                const numPeriods = (counter % 5) + 1;
                const periodsText = ''.padEnd(numPeriods, '.');
                (0, lit_html_1.render)((0, lit_html_1.html) `
                        ${this.text}${periodsText}
                    `, this);
                counter++;
            },
            intervalMs: 375,
            intervalName: '',
            element: this,
        });
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-loading-text', JOLoadingText);
