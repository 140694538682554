"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
require("./jo-button.element");
require("./jo-input");
const error_handling_1 = require("../../utilities/error-handling");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    validityText: '',
    inputEmail: '',
    textColor: 'NOT_SET',
};
class JOEmailVerifier extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.inputEmail !== '') {
            this.handleFetchSetVerificationResult();
        }
    }
    async handleFetchSetVerificationResult() {
        if (isEmailFormattedCorrectly(this.store.inputEmail) === false) {
            return;
        }
        if (isEmailFromUnsupportedDomain(this.store.inputEmail)) {
            this.setDisplayMessage('indigo', 'Please verify email address verbally with customer');
            return;
        }
        await this.fetchAndSetVerificationResult(this.store.inputEmail);
    }
    async fetchAndSetVerificationResult(email) {
        this.setDisplayMessage('blue', 'Validating....');
        const isEmailValidResult = await fetchIsEmailValid(email);
        (0, utilities_1.assertSucceeded)(isEmailValidResult, utilities_1.handleError);
        this.setVerificationText(isEmailValidResult.value);
    }
    setVerificationText(verificationResult) {
        if (verificationResult === 'INVALID') {
            this.setDisplayMessage('red', 'Uh-oh.... Could not verify email address exists. Please verify with customer');
        }
        else {
            this.setDisplayMessage('green', 'Hurray! Email address is valid');
        }
    }
    setDisplayMessage(color, message) {
        this.store.validityText = message;
        this.store.textColor = color;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .verifier-text {
                    color: ${state.textColor};
                }
            </style>

            <div class="main-container">
                <span class="verifier-text">${state.validityText}</span>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-email-verifier', JOEmailVerifier);
async function fetchIsEmailValid(emailAddress) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($emailAddress: String!) {
                    isEmailAddressValid(emailAddress: $emailAddress)
                }
            `, {
            emailAddress,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.isEmailAddressValid,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchIsEmailValid', error);
    }
}
function isEmailFormattedCorrectly(email) {
    return /\S+@\S+\.\S+$/.test(email) === true;
}
function isEmailFromUnsupportedDomain(email) {
    const notSupportedDomains = ['msn.com'];
    const currentEmailDomain = email.trim().split('@')[1];
    if (currentEmailDomain == null)
        throw new Error('domain is null');
    return notSupportedDomains.includes(currentEmailDomain);
}
