"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const error_handling_1 = require("../../utilities/error-handling");
const user_status_event_mutations_1 = require("../../utilities/logging-and-monitoring/user-status-event-mutations");
require("./jo-action-item-notes");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
require("./jo-button.element");
require("./jo-input");
const InitialState = {
    actionItem: 'NOT_SET',
    authenticatedUser: 'NOT_SET',
    buttonClicked: false,
    noteText: '',
    quickNotes: [],
};
class JOActionItemPreview extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    async sendToCompany() {
        (0, utilities_1.assertIsSet)(this.store.actionItem, utilities_1.handleError, 'this.store.actionItem');
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        (0, utilities_1.assertIsSet)(this.store.actionItem.company, utilities_1.handleError, 'this.store.actionItem.company');
        if (this.store.noteText.trim() === '') {
            (0, utilities_1.joAlert)('Error', 'Must have a note to send to company.');
            return;
        }
        const result = await saveActionItem(this.store.actionItem, this.store.authenticatedUser.id, this.store.noteText, true, 'Assigned to Company');
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        const sendPushNotificationsResult = await (0, utilities_1.sendActionItemPushNotification)(this.store.actionItem.company.id);
        (0, utilities_1.assertSucceeded)(sendPushNotificationsResult, utilities_1.handleError);
        this.store.noteText = '';
        (0, utilities_1.raiseCustomEvent)(this, 'senttocompany');
    }
    async resolveActionItem() {
        (0, utilities_1.assertIsSet)(this.store.actionItem, utilities_1.handleError, 'this.store.actionItem');
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        const result = await saveActionItem(this.store.actionItem, this.store.authenticatedUser.id, '--Closed By Jill--', false, 'Assigned to Company');
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        this.store.noteText = '';
        await (0, user_status_event_mutations_1.createUserStatusEvent)({
            userId: this.store.authenticatedUser.id,
            eventType: 'RESOLVED_ACTION_ITEM',
            companyId: this.store.actionItem.company?.id,
            actionItemId: this.store.actionItem.id,
        });
        (0, utilities_1.raiseCustomEvent)(this, 'saved');
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-action-item-preview--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-action-item-preview--action-item-notes-container {
                    overflow-y: scroll;
                    max-height: 250px;
                }

                .jo-action-item-preview--buttons-container {
                    display: flex;
                    justify-content: center;
                    border-top: solid 1px var(--jo-accent-background-dark);
                }

                .jo-action-item-preview--send-to-company-button-container {
                    padding-top: 1rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                .jo-action-item-preview--save-button-container {
                    padding-top: 1rem;
                }
            </style>

            <div class="jo-action-item-preview--main-container">
                <div class="jo-action-item-preview--action-item-notes-container">
                    <jo-action-item-notes
                        .props=${{
            actionItemNotes: getActionItemNotes(state.actionItem),
            authenticatedUser: state.authenticatedUser,
            noteText: state.noteText,
            quickNotes: state.quickNotes,
        }}
                        @notetextchanged=${(e) => (this.store.noteText = e.detail)}
                    ></jo-action-item-notes>
                </div>

                <div class="jo-action-item-preview--buttons-container">
                    <div class="jo-action-item-preview--save-button-container">
                        <jo-button
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'Resolve'}
                            @joButtonClick=${() => {
            this.store.buttonClicked = true;
            this.resolveActionItem();
        }}
                        ></jo-button>
                    </div>

                    <div class="jo-action-item-preview--send-to-company-button-container">
                        <jo-button
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'Send to company'}
                            @joButtonClick=${() => {
            this.store.buttonClicked = true;
            this.sendToCompany();
        }}
                        ></jo-button>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-action-item-preview', JOActionItemPreview);
function getActionItemNotes(actionItem) {
    if (actionItem === 'NOT_SET')
        return [];
    return actionItem.action_item_notes;
}
async function saveActionItem(actionItem, jillId, noteText, shouldSendNotification, actionItemStatusTypeName = 'DO_NOT_CHANGE') {
    try {
        const actionItemStatusTypeNamesToIds = {
            Closed: 1,
            'Assigned to Company': 2,
            'Assigned to Jills Office': 3,
            Callback: 4,
            DO_NOT_CHANGE: null,
        };
        const actionItemStatusTypeId = actionItemStatusTypeNamesToIds[actionItemStatusTypeName];
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $actionItemId: Int!
                    $actionItemNotesInputs: [CreateAction_itemsaction_item_notesInput!]!
                    $actionItemStatusTypeId: Int
                ) {
                    updateAction_items(
                        input: {
                            id: $actionItemId
                            action_item_notes: {createMany: $actionItemNotesInputs}
                            action_item_status_type: {connect: {id: $actionItemStatusTypeId}}
                        }
                    ) {
                        id
                        action_item_notes {
                            id
                        }
                    }
                }
            `, {
            actionItemId: actionItem.id,
            actionItemNotesInputs: [
                {
                    author: {
                        connect: {
                            id: jillId,
                        },
                    },
                    body: noteText,
                },
            ],
            actionItemStatusTypeId,
        });
        if (result.succeeded === false) {
            return result;
        }
        const previousActionItemNotes = actionItem.action_item_notes;
        const freshActionItem = result.value.data.updateAction_items;
        if (shouldSendNotification) {
            const sendEmailsResult = await (0, utilities_1.sendNewActionItemNoteEmails)(previousActionItemNotes, freshActionItem.action_item_notes);
            if (sendEmailsResult.succeeded === false) {
                return sendEmailsResult;
            }
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('saveActionItem', error);
    }
}
