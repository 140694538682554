"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToIncomingQueueItemsHandled = exports.subscribeToIncomingQueueItemsCreated = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const build_try_execute_async_1 = require("../../../utilities/error-handling/build-try-execute-async");
const global_incoming_queue_items_manager_1 = require("../../../utilities/incoming-queue-items/global-incoming-queue-items-manager");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const create_element_bound_interval_1 = require("../../../utilities/timeouts/create-element-bound-interval");
const dates_and_times_1 = require("../../../utilities/dates-and-times/dates-and-times");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
require("../jo-button.element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    pods: [],
    user: null,
};
const executeAsync = (0, build_try_execute_async_1.buildtryExecuteAsync)(utilities_1.handleError);
const prefixName = (str) => `jo-home-call-queue-card > ${str}`;
class JOCallQueueCard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.interval = null;
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    triggerRender() {
        this.store.dispatch({
            type: 'RENDER',
        });
    }
    set authenticatedUser(authenticatedUser) {
        if (authenticatedUser === 'NOT_SET' || authenticatedUser === this.store.authenticatedUser) {
            return;
        }
        this.store.authenticatedUser = authenticatedUser;
        this.performSetup();
    }
    async performSetup() {
        await this.fetchAndSetData();
        this.subscribeToIncomingQueueItemsManager();
        this.startReRenderInterval();
    }
    startReRenderInterval() {
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: () => {
                this.triggerRender();
            },
            element: this,
            intervalMs: dates_and_times_1.oneSecondInMilliseconds,
            intervalName: 'trigger render',
        });
    }
    subscribeToIncomingQueueItemsManager() {
        global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.subscribe(this.triggerRender.bind(this));
        global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.initiate();
    }
    async fetchAndSetData() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'call queue card > authenticatedUser');
        this.store.pods = await executeAsync(fetchPods);
        this.store.user = await executeAsync(fetchUser, this.store.authenticatedUser.id);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-home-call-queue-card--main-container {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-narrow-border-radius-1);
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    background-color: white;
                    display: flex;
                    flex-direction: column;
                }

                .jo-home-call-queue-card--queue-logo-container {
                    box-sizing: border-box;
                    background-color: var(--jo-secondary);
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .jo-home-call-queue-card--queue-logo {
                    box-sizing: border-box;
                    transform: scaleX(-1);
                    font-size: 2.5rem;
                }

                .jo-home-call-queue-card--upper-container {
                    flex: 1;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0rem 1rem;
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.9rem;
                }

                .jo-home-call-queue-card--header {
                    box-sizing: border-box;
                    padding: 0.5rem;
                    margin: 0;
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .jo-home-call-queue-card--upper-left-container {
                    box-sizing: border-box;
                    padding-right: 2rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .jo-home-call-queue-card--upper-right-container {
                    box-sizing: border-box;
                    padding: 1rem;
                }

                .jo-home-call-queue-card--upper-right-container-row {
                    display: flex;
                    justify-content: space-between;
                }

                .jo-home-call-queue-card--call-category {
                    box-sizing: border-box;
                    padding-right: 1rem;
                }

                .jo-home-call-queue-card--line-break {
                    width: 90%;
                }

                .jo-home-call-queue-card--pod-totals-header {
                    box-sizing: border-box;
                    padding-left: 1rem;
                }

                .jo-home-call-queue-card--live-queue-pods-container {
                    box-sizing: border-box;
                    font-size: 0.9rem;
                }

                .jo-home-call-queue-card--pods-container {
                    box-sizing: border-box;
                    font-size: 0.9rem;
                    padding-left: 2rem;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                }

                .jo-home-call-queue-card--live-queue-button-container {
                    box-sizing: border-box;
                    display: flex;
                    height: 100%;
                    align-items: flex-end;
                    padding-bottom: 1rem;
                    padding-left: 1rem;
                }

                .jo-home-call-queue-card--assignedPod {
                    height: 5px;
                    width: 5px;
                    background-color: var(--jo-secondary) !important;
                    border-radius: 50%;
                    margin-bottom: 3px;
                    display: inline-block;
                }

                .jo-home-call-queue-card--floatPod {
                    height: 5px;
                    width: 5px;
                    background-color: var(--jo-text-default);
                    border-radius: 50%;
                    margin-bottom: 3px;
                    display: inline-block;
                }

                .jo-home-call-queue-card--notAssignedPod {
                    margin-left: 9px;
                }

                .jo-home-call-queue-card--podIndicatorKey {
                    font-size: 0.9rem;
                }
            </style>

            <div class="jo-home-call-queue-card--main-container">
                <div class="jo-home-call-queue-card--upper-container">
                    <div class="jo-home-call-queue-card--upper-left-container">
                        <div class="jo-home-call-queue-card--header">CALL QUEUE</div>
                        <div class="jo-home-call-queue-card--queue-logo-container">
                            <span class="material-icons jo-home-call-queue-card--queue-logo">
                                history
                            </span>
                        </div>
                    </div>

                    <div class="jo-home-call-queue-card--upper-right-container">
                        <div class="jo-home-call-queue-card--upper-right-container-row">
                            <div
                                class="jo-home-call-queue-card--call-category jo-global--underlined"
                            >
                                Total
                            </div>
                            <div class="jo-global--underlined">
                                ${global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.totalQueueItems}
                            </div>
                        </div>
                        <br />
                        <div class="jo-home-call-queue-card--upper-right-container-row">
                            <div class="jo-home-call-queue-card--call-category">Waiting</div>
                            <div>${global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.numWaitingQueueItems}</div>
                        </div>
                        <div class="jo-home-call-queue-card--upper-right-container-row">
                            <div class="jo-home-call-queue-card--call-category">Callbacks</div>
                            <div>${global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.numCallbacks}</div>
                        </div>
                    </div>
                </div>

                <hr class="jo-home-call-queue-card--line-break" />

                <div class="jo-home-call-queue-card--live-queue-pods-container">
                    <div class="jo-home-call-queue-card--pod-totals-header">Pod Totals</div>

                    <div class="jo-home-call-queue-card--pods-container">
                        ${state.pods.map((pod) => (0, lit_html_1.html) `
                                <div
                                    class="${shouldHidePodAssignmentIndicator(state.user, pod)
            ? 'jo-home-call-queue-card--notAssignedPod'
            : ''}"
                                >
                                    <div
                                        ?hidden=${shouldHidePodAssignmentIndicator(state.user, pod)}
                                        class="
                                        ${isUserAssignedToFloatPod(state.user, pod)
            ? 'jo-home-call-queue-card--floatPod'
            : ''}
                                        ${isUserAssignedToPod(state.user, pod)
            ? 'jo-home-call-queue-card--assignedPod'
            : ''}
                                        "
                                    ></div>

                                    ${pod.name}:
                                    ${global_incoming_queue_items_manager_1.globalIncomingQueueItemsManager.getIncomingQueueItemsForPod(pod.id).length}
                                </div>
                            `)}
                    </div>
                </div>

                <hr class="jo-home-call-queue-card--line-break" />

                <div class="jo-home-call-queue-card--podIndicatorKey">
                    <div
                        style="margin-left: 1rem"
                        class="jo-home-call-queue-card--assignedPod"
                    ></div>
                    <span>Main Pods</span>
                    <div style="margin-left: 1rem" class="jo-home-call-queue-card--floatPod"></div>
                    <span>Float Pods</span>
                </div>

                <div class="jo-home-call-queue-card--live-queue-button-container">
                    <jo-button
                        ?hidden=${!(0, utilities_1.isInAuthRole)([
            'JILL_OUTBOUND',
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser)}
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'View Live Queue'}
                        @joButtonClick=${() => (0, utilities_1.navigate)('/incoming-queue-report')}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-queue-card', JOCallQueueCard);
async function fetchPods() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findPods(orderBy: {field: "name", order: ASC}) {
                        items {
                            id
                            name
                        }
                    }
                }
            `, {});
        return gqlResult.data.findPods.items;
    }
    catch (error) {
        throw new Error(prefixName(`fetchPods error: ${error}`));
    }
}
async function fetchUser(userId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($userId: Int!) {
                    getUsers(id: $userId) {
                        id
                        pods {
                            id
                        }
                        float_pods {
                            id
                        }
                    }
                }
            `, {
            userId,
        });
        return gqlResult.data.getUsers;
    }
    catch (error) {
        throw new Error(prefixName(`fetchUser error: ${error}`));
    }
}
function shouldHidePodAssignmentIndicator(user, pod) {
    return (isUserAssignedToPod(user, pod) === false && isUserAssignedToFloatPod(user, pod) === false);
}
function isUserAssignedToPod(user, pod) {
    return user?.pods?.some(userPod => userPod.id === pod.id) ?? false;
}
function isUserAssignedToFloatPod(user, pod) {
    return user?.float_pods?.some(userFloatPod => userFloatPod.id === pod.id) ?? false;
}
async function subscribeToIncomingQueueItemsCreated(callback) {
    const websocket = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription {
                incomingQueueItemCreated
            }
        `, callback);
    return websocket;
}
exports.subscribeToIncomingQueueItemsCreated = subscribeToIncomingQueueItemsCreated;
async function subscribeToIncomingQueueItemsHandled(callback) {
    const websocket = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription {
                incomingQueueItemHandled
            }
        `, callback);
    return websocket;
}
exports.subscribeToIncomingQueueItemsHandled = subscribeToIncomingQueueItemsHandled;
