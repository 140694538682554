"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
require("../../jo-button.element");
require("../../jo-input");
const queries_and_mutations_1 = require("../../jo-quick-text/queries-and-mutations");
const helper_utils_1 = require("./helper-utils");
const mutations_1 = require("./mutations");
const styles_1 = require("./styles");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    actionItem: 'NOT_SET',
    description: '',
    selectedJill: 'NOT_SET',
    title: '',
    titles: [],
};
const cssNames = (0, css_utils_1.cssPrefixer)('jo-flag-action-item-form');
class JOFlagActionItemForm extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.titles.length === 0) {
            this.fetchAndSetQuickFlagTitles();
        }
    }
    async submitButtonClicked() {
        if (this.store.selectedJill === 'NOT_SET') {
            (0, utilities_1.joAlert)('Alert', 'Jill must be selected');
            return;
        }
        if (this.store.title === '') {
            (0, utilities_1.joAlert)('Alert', 'Title must be selected');
            return;
        }
        if (this.store.description === '') {
            (0, utilities_1.joAlert)('Alert', 'Description cannot be empty');
            return;
        }
        await this.createActionItemFlag();
        window.location.reload();
    }
    async fetchAndSetQuickFlagTitles() {
        const result = await (0, queries_and_mutations_1.fetchGlobalTextList)('flag_quick_titles');
        (0, utilities_1.assertSucceeded)(result);
        this.store.titles = result.value;
    }
    async createActionItemFlag() {
        (0, utilities_1.assertIsSet)(this.store.selectedJill);
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser);
        (0, utilities_1.assertIsSet)(this.store.actionItem);
        const result = await (0, mutations_1.createActionItemFlag)({
            actionItemId: this.store.actionItem.id,
            authorId: this.store.authenticatedUser.id,
            jillId: this.store.selectedJill.id,
            description: this.store.description,
            title: this.store.title,
        });
        (0, utilities_1.assertSucceeded)(result);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.joFlagActionItemFormStyles}

            <div class=${cssNames('main-container')}>
                <section class="section red center">
                    <h2>Flag Action Item</h2>
                </section>

                <section class=${cssNames('section')}>
                    <jo-input
                        .type=${'SELECT'}
                        .label=${'Title'}
                        .selectOptions=${this.store.titles}
                        .defaultOption=${'Select title...'}
                        .disableDefaultOption=${true}
                        @valueChanged=${(e) => {
            this.store.title = e.detail.selectedValue;
        }}
                    ></jo-input>
                </section>

                <section class=${cssNames('section')}>
                    <jo-input
                        .type=${'TEXT_AREA'}
                        .label=${'Description'}
                        .rows=${6}
                        @valueChanged=${(e) => {
            this.store.description = e.detail.inputValue;
        }}
                    ></jo-input>
                </section>

                <section class=${cssNames('section')}>
                    <jo-input
                        .type=${'SELECT'}
                        .label=${'Jill at fault'}
                        .selectOptions=${(0, helper_utils_1.getJillNamesWhoHaveTouchedThisActionItem)(state.actionItem)}
                        .defaultOption=${'Select jill...'}
                        .disableDefaultOption=${true}
                        @valueChanged=${(e) => {
            this.handleJillSelected(e);
        }}
                    ></jo-input>
                </section>

                <section class=${cssNames('section center')}>
                    <jo-button
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Submit flag'}
                        .materialIconName=${'flag'}
                        @joButtonClick=${() => {
            this.submitButtonClicked();
        }}
                    ></jo-button>
                </section>
            </div>
        `;
    }
    handleJillSelected(e) {
        (0, utilities_1.assertIsSet)(this.store.actionItem, utilities_1.handleError);
        const selectedJill = (0, helper_utils_1.getSelectedJill)(e.detail.selectedValue, this.store.actionItem);
        this.store.selectedJill = selectedJill;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-flag-action-item-form', JOFlagActionItemForm);
