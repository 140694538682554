"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.markActionItemFlagAsMistake = exports.addressActionItemFlag = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function addressActionItemFlag(flagId, userId, noteText) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($flagId: Int!, $userId: Int!, $noteText: String!) {
                    createAction_item_flag_addressals(
                        input: {
                            flag: {connect: {id: $flagId}}
                            user: {connect: {id: $userId}}
                            description: $noteText
                        }
                    ) {
                        id
                    }
                }
            `, {
            flagId,
            userId,
            noteText,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('addressActionItemFlag', error);
    }
}
exports.addressActionItemFlag = addressActionItemFlag;
async function markActionItemFlagAsMistake(flagId, userId, noteText) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($flagId: Int!, $userId: Int!, $noteText: String!) {
                    createMistakenly_flagged_action_items(
                        input: {
                            flag: {connect: {id: $flagId}}
                            user: {connect: {id: $userId}}
                            description: $noteText
                        }
                    ) {
                        id
                    }
                }
            `, {
            flagId,
            userId,
            noteText,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('markActionItemFlagAsMistake', error);
    }
}
exports.markActionItemFlagAsMistake = markActionItemFlagAsMistake;
