"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-fade-in-overlay");
const InitialState = {
    showNotes: false,
    notes: [],
    iconSizePx: '50px',
};
class JOCallScreenQuickNotes extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this);
    }
    quickNoteSelected(noteText) {
        (0, utilities_1.raiseCustomEvent)(this, 'quicknote', noteText);
        this.store.showNotes = false;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: var(--jo-primary);
                    position: relative;
                }

                .icon:hover {
                    color: var(--jo-primary-light);
                }
            </style>

            <div class="main-container">
                <span
                    class="material-icons icon"
                    style="font-size: ${state.iconSizePx}"
                    @click=${() => (this.store.showNotes = !this.store.showNotes)}
                >
                    assignment
                </span>

                <jo-fade-in-overlay
                    .props=${{
            child: (0, lit_html_1.html) `
                            <style>
                                .notes-container {
                                    width: 25vw;
                                    height: 80vh;
                                    z-index: 3;
                                    border-radius: var(--jo-wide-border-radius-1);
                                    background-color: var(--jo-accent-background-light);
                                    overflow-y: scroll;
                                }

                                .note {
                                    padding: 1rem;
                                    font-size: 1rem;
                                    box-sizing: border-box;
                                    color: var(--jo-text-default);
                                    font-family: sans-serif;
                                }

                                .note:hover {
                                    background-color: var(--jo-primary-light);
                                }
                            </style>

                            <div class="notes-container">
                                ${state.notes.map((note) => (0, lit_html_1.html) `
                                        <div
                                            class="note"
                                            @click=${() => this.quickNoteSelected(note)}
                                        >
                                            ${note}
                                        </div>
                                    `)}
                            </div>
                        `,
            showChild: state.showNotes,
        }}
                    @closeoverlay=${(e) => {
            e.stopPropagation();
            this.store.showNotes = !this.store.showNotes;
        }}
                ></jo-fade-in-overlay>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-quick-notes', JOCallScreenQuickNotes);
