"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
const state_1 = require("./redux/state");
const css_utils_1 = require("../../../utilities/css-utils");
const no_scroll_on_focus_1 = require("../../../utilities/input-event-listeners/no-scroll-on-focus");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
// TODO rethink the way we are doing the input values
// TODO rethink it before you start optimizing it with the function that does the hookup
// TODO right now we have to create an inputValues value, setter, and we have to convert the original input thing
// TODO think if there is a better way
// TODO I really like how we separate the values to be updated and changed from the originally set values
// TODO I think that concept should stay. But is there a way to automatically create the input values object from the original object?
const InitialState = {
    calculatedPrice: state_1.InitialJOCallScreenState.calculatedPrice,
    inputValues: {
        priceQuote: 0,
        serviceLineItemResponsesInputValues: [],
    },
    minimumPriceApplied: state_1.InitialJOCallScreenState.minimumPriceApplied,
    priceQuote: state_1.InitialJOCallScreenState.priceQuote,
    selectedServices: state_1.InitialJOCallScreenState.selectedServices,
    serviceLineItemResponses: [],
};
const cssNames = (0, css_utils_1.cssPrefixer)('jo-call-screen-pricing');
class JOCallScreenPricing extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const originalPriceQuote = this.store.priceQuote;
        const originalSelectedServices = this.store.selectedServices;
        const originalServiceLineItemResponses = this.store.serviceLineItemResponses;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.priceQuote !== originalPriceQuote ||
            this.store.selectedServices !== originalSelectedServices ||
            this.store.serviceLineItemResponses !== originalServiceLineItemResponses) {
            const companyServiceLineItems = getCompanyServiceLineItems(this.store.selectedServices);
            this.inputValues = getInputValues(this.store.inputValues, this.store.priceQuote, companyServiceLineItems, this.store.serviceLineItemResponses);
        }
    }
    set inputValues(pricingInputValues) {
        this.store.inputValues = pricingInputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'pricinginputvalueschanged', pricingInputValues);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            <style>
                .${cssNames('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssNames('services-selected-title')} {
                    font-weight: bold;
                    font-size: 16px;
                    color: var(--jo-text-default);
                    font-family: sans-serif;
                    padding-bottom: 1rem;
                }

                .${cssNames('service-item-title')} {
                    font-size: 14px;
                    font-weight: normal;
                    padding-top: 0.2rem;
                }

                .${cssNames('subtitle')} {
                    font-weight: bold;
                }

                .${cssNames('calc-row')} {
                    display: flex;
                    color: var(--jo-text-default);
                    font-family: sans-serif;
                    font-size: 15px;
                    padding: 10px;
                }

                .${cssNames('calc-col')} {
                    padding: 5px;
                }

                .${cssNames('calc-col-inner')} {
                    border-radius: 5px;
                    padding: 5px;
                }

                .${cssNames('calc-col-service')} {
                    flex: 2;
                    font-weight: bold;
                }

                .${cssNames('calc-col-description')} {
                    flex: 3;
                }

                .${cssNames('calc-col-price')} {
                    flex: 1;
                }

                .${cssNames('calc-col-qty')} {
                    flex: 1;
                }

                .${cssNames('qty-input')} {
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssNames('totals-container')} {
                    display: flex;
                    justify-content: flex-end;
                }

                .${cssNames('totals-labels-container')} {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-end;
                }

                .${cssNames('totals-total-label')} {
                    padding: 10px;
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    font-weight: bold;
                }

                .${cssNames('totals-quoted-label')} {
                    padding: 10px;
                    font-family: sans-serif;
                    color: var(--jo-primary);
                    font-weight: bold;
                }

                .${cssNames('totals-inputs-container')} {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    width: auto;
                }

                .${cssNames('totals-price-container')} {
                    padding: 5px;
                    min-width: 6.5rem;
                    box-sizing: border-box;
                }

                .${cssNames('totals-price')} {
                    position: relative;
                    border: solid 1px black;
                    overflow: hidden;
                    padding: 5px;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssNames('totals-quoted-input-container')} {
                    padding: 5px;
                }

                .${cssNames('totals-quoted-input')} {
                    width: 6rem;
                    float: right;
                    box-sizing: border-box;
                    padding: 5px;
                }

                .${cssNames('continue-button-container')} {
                    padding-top: 2rem;
                }

                .${cssNames('price-minimum-applied-icon')} {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    color: red;
                    cursor: default;
                }
            </style>

            <div class="${cssNames('main-container')}'">
                <div class="${cssNames('services-selected-title')}">
                    Services Selected:
                    ${state.selectedServices.length === 0
            ? 'None'
            : state.selectedServices.map((service) => {
                return (0, lit_html_1.html) `
                                      <div class="${cssNames('service-item-title')}">
                                          ${service.name}:
                                          ${(0, utilities_1.formatUSDCentsForDisplay)(service.price_minimum)} minimum
                                      </div>
                                  `;
            })}
                </div>

                <div>
                    <div class="${cssNames('calc-row')} ${cssNames('subtitle')}">
                        <div class="${cssNames('calc-col-service')}">Service</div>
                        <div class="${cssNames('calc-col-description')}">Description</div>
                        <div class="${cssNames('calc-col-price')}">Price</div>
                        <div class="${cssNames('calc-col-qty')}">Qty</div>
                    </div>

                    ${state.inputValues.serviceLineItemResponsesInputValues.map((serviceLineItemResponseInputValues, outerIndex) => {
            return (0, lit_html_1.html) `
                                <div class="${cssNames('calc-row')}">
                                    <div
                                        class="${cssNames('calc-col')} ${cssNames('calc-col-service')}"
                                    >
                                        <div class="${cssNames('calc-col-inner')}">
                                            ${serviceLineItemResponseInputValues.service_line_item
                .service.name}
                                        </div>
                                    </div>
                                    <div
                                        class="${cssNames('calc-col')} ${cssNames('calc-col-description')}"
                                    >
                                        <div
                                            class="${cssNames('calc-col-inner')}"
                                            style="white-space:  pre-line"
                                        >
                                            ${serviceLineItemResponseInputValues.service_line_item
                .description}
                                        </div>
                                    </div>
                                    <div
                                        class="${cssNames('calc-col')} ${cssNames('calc-col-price')}"
                                    >
                                        <div class="${cssNames('calc-col-inner')}">
                                            ${serviceLineItemResponseInputValues.service_line_item
                .calculate === false
                ? 'N/A'
                : (0, utilities_1.formatUSDCentsForDisplay)(serviceLineItemResponseInputValues
                    .service_line_item.price)}
                                        </div>
                                    </div>
                                    <div
                                        class="${cssNames('calc-col')} ${cssNames('calc-col-qty')}"
                                    >
                                        <div class="${cssNames('calc-col-inner')}">
                                            <input
                                                id="line-item-qty-input-${serviceLineItemResponseInputValues
                .service_line_item.id}"
                                                type="number"
                                                class="${cssNames('qty-input')}"
                                                min="0"
                                                .value=${serviceLineItemResponseInputValues.quantity.toString()}
                                                @focus=${(e) => {
                e.target?.addEventListener('wheel', (e) => {
                    e.preventDefault();
                });
            }}
                                                @input=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    serviceLineItemResponsesInputValues: this.store.inputValues.serviceLineItemResponsesInputValues.map((serviceLineItemResponseInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceLineItemResponseInputValues,
                                quantity: parseInt(e.target.value),
                            };
                        }
                        else {
                            return serviceLineItemResponseInputValues;
                        }
                    }),
                };
            }}
                                                ?disabled=${serviceLineItemResponseInputValues
                .service_line_item.calculate === false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            `;
        })}

                    <div class="${cssNames('totals-container')}">
                        <div class="${cssNames('totals-labels-container')}">
                            <div class="${cssNames('totals-total-label')}">TOTAL</div>
                            <div class="${cssNames('totals-quoted-label')}">
                                Total You Quoted Customer
                            </div>
                        </div>

                        <div class="${cssNames('totals-inputs-container')}">
                            <div class="${cssNames('totals-price-container')}">
                                <div class="${cssNames('totals-price')}">
                                    ${(0, utilities_1.formatUSDCentsForDisplay)(state.calculatedPrice)}
                                    <div
                                        class="material-icons ${cssNames('price-minimum-applied-icon')}"
                                        style="visibility: ${state.minimumPriceApplied === false
            ? 'hidden'
            : 'visible'}"
                                        title="Price minimum applied"
                                    >
                                        money_off
                                    </div>
                                </div>
                            </div>
                            <div class="${cssNames('totals-quoted-input-container')}">
                                <input
                                    type="number"
                                    class="${cssNames('totals-quoted-input')}"
                                    min="0"
                                    .value=${(state.inputValues.priceQuote / 100).toString()}
                                    @focus=${(e) => {
            (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
        }}
                                    @input=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                priceQuote: (0, utilities_1.formatMoneyInUSDCents)(Number(e.target.value)), // TODO we should probably defend against negative numbers and maybe NaN
            };
        }}
                                />
                            </div>
                        </div>
                    </div>

                    <div class="${cssNames('continue-button-container')}">
                        <jo-button
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'Continue'}
                            @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'continued')}
                        ></jo-button>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-pricing', JOCallScreenPricing);
function getCompanyServiceLineItems(companyServices) {
    const companyServiceLineItems = companyServices.reduce((result, service) => {
        return [
            ...result,
            ...service.line_items,
        ];
    }, []);
    return companyServiceLineItems;
}
function getInputValues(inputValues, priceQuote, companyServiceLineItems, serviceLineItemResponses) {
    return {
        ...inputValues,
        priceQuote,
        serviceLineItemResponsesInputValues: companyServiceLineItems.map((companyServiceLineItem) => {
            const serviceLineItemResponseInputvalue = inputValues.serviceLineItemResponsesInputValues.find((serviceLineItemResponseInputValue) => {
                return (serviceLineItemResponseInputValue.service_line_item.id ===
                    companyServiceLineItem.id);
            });
            const serviceLineItemResponse = serviceLineItemResponses.find((serviceLineItemResponse) => {
                return (serviceLineItemResponse.service_line_item.id ===
                    companyServiceLineItem.id);
            });
            return {
                serviceLineItemResponseId: serviceLineItemResponseInputvalue?.serviceLineItemResponseId ??
                    serviceLineItemResponse?.id ??
                    'NOT_SET',
                service_line_item: {
                    id: companyServiceLineItem.id,
                    calculate: companyServiceLineItem.calculate,
                    description: companyServiceLineItem.description,
                    price: companyServiceLineItem.price,
                    service: {
                        id: companyServiceLineItem.service.id,
                        name: companyServiceLineItem.service.name,
                    },
                },
                quantity: serviceLineItemResponseInputvalue?.quantity ??
                    serviceLineItemResponse?.quantity ??
                    0,
            };
        }),
    };
}
