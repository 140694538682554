"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchChatStateInfoFromStorageAndSaveToDB = exports.removeChatStateInfoSessionStorage = exports.getChatStateInfoSessionStorage = exports.saveTemporaryChatStateInSessionStorage = exports.setChatStateInfoSessionStorage = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
const currentChatStateInfo = 'currentChatStateInfo_';
function setChatStateInfoSessionStorage(chatId, state) {
    window.sessionStorage.setItem(currentChatStateInfo + chatId, JSON.stringify(state));
}
exports.setChatStateInfoSessionStorage = setChatStateInfoSessionStorage;
function saveTemporaryChatStateInSessionStorage(chatId, temporaryStateJSON) {
    if (!temporaryStateJSON) {
        throw new Error('jo-call-screen/utilities/chat-info-for-session-storage.ts -> saveTemporaryChatStateInSessionStorage() temporaryState is not defined');
    }
    window.sessionStorage.setItem(currentChatStateInfo + chatId, temporaryStateJSON);
}
exports.saveTemporaryChatStateInSessionStorage = saveTemporaryChatStateInSessionStorage;
function getChatStateInfoSessionStorage(chatId) {
    const jsonStateFromSessionStorage = window.sessionStorage.getItem(currentChatStateInfo + chatId);
    if (jsonStateFromSessionStorage === null)
        return 'NOT_FOUND';
    const rawState = JSON.parse(jsonStateFromSessionStorage);
    const finalState = {
        ...rawState,
        contact: rawState.contact ?? Symbol('NOT_SET'),
        contactForActivityLog: rawState.contactForActivityLog ?? Symbol('NOT_SET'),
    };
    return finalState;
}
exports.getChatStateInfoSessionStorage = getChatStateInfoSessionStorage;
function removeChatStateInfoSessionStorage(chatId) {
    window.sessionStorage.removeItem(currentChatStateInfo + chatId);
}
exports.removeChatStateInfoSessionStorage = removeChatStateInfoSessionStorage;
async function fetchChatStateInfoFromStorageAndSaveToDB(chatId) {
    const chatInfo = getChatStateInfoSessionStorage(chatId);
    if (chatInfo !== 'NOT_FOUND') {
        await updateTemporaryStateOnChat(chatId, JSON.stringify(chatInfo));
    }
}
exports.fetchChatStateInfoFromStorageAndSaveToDB = fetchChatStateInfoFromStorageAndSaveToDB;
async function updateTemporaryStateOnChat(chatId, temporaryState) {
    try {
        const result = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($chatId: Int!, $temporaryState: String) {
                    chat: updateChats(input: {id: $chatId, temporary_chat_state: $temporaryState}) {
                        id
                    }
                }
            `, {
            chatId,
            temporaryState,
        });
        return result.data;
    }
    catch (error) {
        throw new Error('updateTemporaryStateOnChat Error: ' + error);
    }
}
