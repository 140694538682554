"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoSubmitHelpTicket = void 0;
const element_vir_1 = require("element-vir");
require("../jo-button.element");
require("../jo-input");
const create_account_help_ticket_1 = require("./create-account-help-ticket");
const utilities_1 = require("../../../services/utilities");
exports.JoSubmitHelpTicket = (0, element_vir_1.defineElement)()({
    tagName: 'jo-submit-help-ticket',
    events: {
        submitted: (0, element_vir_1.defineElementEvent)(),
        cancel: (0, element_vir_1.defineElementEvent)(),
    },
    styles: (0, element_vir_1.css) `
        :host {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background-color: white;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
        }
    `,
    stateInit: {
        description: '',
        title: '',
    },
    renderCallback({ state, dispatch, events, updateState, inputs }) {
        async function submitTicketClicked() {
            (0, utilities_1.assertIsDefined)(inputs.companyId, 'companyId');
            (0, utilities_1.assertIsDefined)(inputs.userId, 'userId');
            if (state.title === '') {
                await (0, utilities_1.joAlert)('Notice', 'Please select a title');
                return;
            }
            if (state.description === '') {
                await (0, utilities_1.joAlert)('Notice', 'Please enter a description');
                return;
            }
            const accountHelpTicketId = await (0, create_account_help_ticket_1.createAccountHelpTicket)({
                companyId: inputs.companyId,
                userId: inputs.userId,
                title: state.title,
                description: state.description,
            });
            dispatch(new events.submitted(accountHelpTicketId));
            await (0, utilities_1.joAlert)('Success', 'Your help ticket has been submitted and will be reviewed shortly');
        }
        return (0, element_vir_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />

            <h2>Submit Help Ticket</h2>

            <jo-input
                .type=${'SELECT'}
                .label=${'Title'}
                .selectOptions=${inputs.quickTitles}
                .defaultOption=${'Select a title'}
                .disableDefaultOption=${true}
                @inputchange=${(event) => {
            updateState({
                title: event.detail.selectedValue,
            });
        }}
            ></jo-input>

            <jo-input
                style="width: 60%;"
                .type=${'TEXT_AREA'}
                .label=${'Description'}
                .rows=${7}
                @inputchange=${(event) => {
            updateState({
                description: event.detail.inputValue,
            });
        }}
            ></jo-input>

            <jo-button
                .type=${'ACTION_BUTTON_GREEN'}
                .text=${'Submit'}
                @joButtonClick=${async () => {
            await submitTicketClicked();
        }}
            ></jo-button>

            <jo-button
                .type=${'SECONDARY_BUTTON_1'}
                .text=${'Cancel'}
                @joButtonClick=${() => {
            dispatch(new events.cancel());
        }}
            ></jo-button>
        `;
    },
});
