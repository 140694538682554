"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCompanyForCallScreen = void 0;
const graphql_1 = require("../../../../../../../services/graphql");
const helper_utils_1 = require("../helper-utils");
const mapped_env_variables_1 = require("../../../../../../../utilities/environment/mapped-env-variables");
const sort_by_and_apply_order_number_to_items_1 = require("../../../../../../../utilities/data-structures/lists/sort-by-and-apply-order-number-to-items");
async function fetchCompanyForCallScreen(companyId) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($companyId: Int!) {
                fetchCompanyForCallScreen(companyId: $companyId) {
                    id
                    address {
                        id
                        line_1
                        line_2
                        city
                        state
                        zip_code
                    }
                    alerts
                    alerts_expiration_date
                    business_hours
                    business_number
                    calendar {
                        id
                        name
                        type
                    }
                    calendar_connections(
                        filter: {and: [{is_visible: {eq: true}}, {archived_at: {eq: null}}]}
                    ) {
                        id
                        google_calendar_name
                        google_calendar_id
                        can_delete_events
                        can_reschedule_events
                    }
                    call_instructions(orderBy: {field: "order", order: ASC}) {
                        id
                        instructions
                        title
                    }
                    call_screen_general_information_address_appear
                    call_screen_general_information_address_require
                    call_screen_general_information_check_county
                    call_screen_general_information_check_zip_code
                    call_screen_general_information_city_appear
                    call_screen_general_information_city_require
                    call_screen_general_information_county_appear
                    call_screen_general_information_county_require
                    call_screen_general_information_email_appear
                    call_screen_general_information_email_require
                    call_screen_general_information_postal_code_appear
                    call_screen_general_information_postal_code_require
                    call_screen_general_information_province_appear
                    call_screen_general_information_province_require
                    call_screen_general_information_state_appear
                    call_screen_general_information_state_require
                    call_screen_general_information_where_did_you_hear_appear
                    call_screen_general_information_where_did_you_hear_require
                    call_screen_general_information_zip_code_appear
                    call_screen_general_information_zip_code_require
                    call_screen_section_notes {
                        id
                        transfer_notes
                        custom_questions_notes
                        services_notes
                        pricing_notes
                    }
                    default_estimate_duration
                    default_job_duration
                    description
                    directions
                    end_of_script_text
                    estimates_enabled
                    estimates_url
                    fax
                    forms_enabled
                    forms_url
                    jobs_enabled
                    jobs_url
                    name
                    organization {
                        id
                        owner {
                            id
                            first_name
                            last_name
                        }
                    }
                    pod {
                        id
                        name
                    }
                    pricing_enabled
                    pricing_url
                    public_email
                    save_without_scheduling_enabled
                    save_without_scheduling_url
                    same_caller_id
                    service_area_counties
                    service_area_description
                    service_area_zip_codes
                    services_not_provided
                    services(
                        orderBy: {field: "created_at", order: ASC}
                        filter: {archived: {eq: false}}
                    ) {
                        id
                        created_at
                        custom_fields(
                            orderBy: {field: "created_at", order: ASC}
                            filter: {archived: {eq: false}}
                        ) {
                            id
                            call_screen_appear
                            call_screen_require
                            order
                            select_values(orderBy: {field: "created_at", order: ASC}) {
                                id
                                text_value
                            }
                            text_value
                            type
                        }
                        display_position
                        estimates
                        forms
                        jobs
                        line_items(
                            orderBy: {field: "created_at", order: ASC}
                            filter: {archived: {eq: false}}
                        ) {
                            id
                            calculate
                            description
                            price
                            service {
                                id
                                name
                            }
                        }
                        name
                        price_minimum
                        pricing
                        save_without_scheduling
                        description
                    }
                    status_events {
                        id
                        timestamp
                        type
                    }
                    timezone
                    twilio_phone_number
                    users(orderBy: {field: "created_at", order: ASC}) {
                        id
                        auth_role
                        call_transfer_situation
                        can_receive_call_transfer
                        first_name
                        hours_to_receive_transfer_calls
                        last_name
                        phone_number
                        title
                        visible_on_call_screen
                    }
                    verified_business_number
                    website_url
                }
            }
        `, {
        companyId,
    });
    if (result.succeeded === false) {
        return result;
    }
    const company = orderCustomFieldsOnCompany(result.value.data.fetchCompanyForCallScreen);
    if (company.address === null || company.address === undefined) {
        return await (0, helper_utils_1.getCompanyWithAddressFoundByOccupantId)(company);
    }
    return {
        succeeded: true,
        value: company,
    };
}
exports.fetchCompanyForCallScreen = fetchCompanyForCallScreen;
function orderCustomFieldsOnCompany(company) {
    const servicesWithOrderedCustomFields = company.services.map(service => {
        return {
            ...service,
            custom_fields: (0, sort_by_and_apply_order_number_to_items_1.sortByAndApplyOrderNumberToItems)(service.custom_fields),
        };
    });
    const companyWithOrderedCustomFields = {
        ...company,
        services: servicesWithOrderedCustomFields,
    };
    return companyWithOrderedCustomFields;
}
