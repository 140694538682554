"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPredictionTermTemplate = void 0;
const class_map_js_1 = require("lit/directives/class-map.js");
const element_vir_1 = require("element-vir");
/**
 * This creates a template from a given prediction term substring that highlights (makes bold) each
 * prediction term. Each PredictionSubstring correlates to sections of the prediction that exactly
 * match the given query.
 *
 * For example:
 *
 * If I search for "abs" and one of the predictions is "absolute", then fullString will be
 * "absolute" and predictionTerms will be [{length: 3, offset: 0}] and this function will thus
 * create a template where the first three letters ("abs") are bold.
 */
function createPredictionTermTemplate(fullString, predictionTerms) {
    const entries = [];
    let latestEntry = {
        substring: '',
        isInsidePredictionTerm: false,
    };
    function pushLatestEntry() {
        if (latestEntry.isInsidePredictionTerm) {
            entries.push((0, element_vir_1.html) `
                    <b>${latestEntry.substring}</b>
                `);
        }
        else {
            entries.push((0, element_vir_1.html) `
                    <span
                        class=${(0, class_map_js_1.classMap)({
                'space-before': latestEntry.substring.startsWith(' '),
                'space-after': latestEntry.substring.endsWith(' '),
            })}
                    >
                        ${latestEntry.substring}
                    </span>
                `);
        }
    }
    for (let letterIndex = 0; letterIndex < fullString.length; letterIndex++) {
        const letter = fullString[letterIndex];
        if (letter == null)
            throw new Error('letter is null');
        const isInsidePredictionTerm = predictionTerms.some(predictionTerm => {
            return (letterIndex >= predictionTerm.offset &&
                letterIndex < predictionTerm.offset + predictionTerm.length);
        });
        const needsToPushLatestEntry = latestEntry.isInsidePredictionTerm !== isInsidePredictionTerm && latestEntry.substring;
        if (needsToPushLatestEntry) {
            pushLatestEntry();
            latestEntry = {
                isInsidePredictionTerm,
                substring: letter,
            };
        }
        else {
            latestEntry.substring += letter;
            latestEntry.isInsidePredictionTerm = isInsidePredictionTerm;
        }
    }
    pushLatestEntry();
    return (0, element_vir_1.html) `
        ${entries}
    `;
}
exports.createPredictionTermTemplate = createPredictionTermTemplate;
