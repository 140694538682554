"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialJOCallScreenState = void 0;
const store_1 = require("../../../../services/store");
const get_default_contact_input_values_1 = require("../../../../utilities/contacts/get-default-contact-input-values");
exports.InitialJOCallScreenState = {
    accountHelpTicketState: {
        accountHelpTicketId: undefined,
        quickTitlesForAccountHelpTicket: [],
    },
    actionItemDurationMinutes: 0,
    actionItemDurationSeconds: 0,
    actionItemDurationTotal: 0,
    actionItem: 'NOT_SET',
    actionItemId: 'NOT_SET',
    settingUpInitialCallScreenInfoHasInitiated: false,
    actionItemTitles: {
        title: '',
        subtitle: '',
    },
    actionItemQuickNotes: [],
    answerCallButtonClicked: false,
    authenticatedUser: 'NOT_SET',
    callAnswered: store_1.InitialGlobalState.currentCallInformation.callAnswered,
    calculatedPrice: 0,
    selectedServicesWithCalculatedPrice: [],
    callDurationMinutes: 0,
    callDurationSeconds: 0,
    callDurationTotal: 0,
    callEnded: false,
    callId: 'NOT_SET',
    callIncoming: store_1.InitialGlobalState.currentCallInformation.callIncoming,
    callScreenMode: 'NOT_SET',
    canInitiateNewTransfer: store_1.InitialGlobalState.currentCallInformation.canInitiateNewTransfer,
    chatId: 'NOT_SET',
    chatMessengerInput: '',
    chatMessengerQuickNotes: [],
    collectingDuration: false,
    companyId: 'NOT_SET',
    companyForCallInstructions: 'NOT_SET',
    companyForCollection: 'NOT_SET',
    companyForCompanyInfo: 'NOT_SET',
    companyForTransferInstructions: 'NOT_SET',
    contact: Symbol('NOT_SET'),
    contactForActivityLog: Symbol('NOT_SET'),
    contactId: 'NOT_SET',
    contactInputValues: (0, get_default_contact_input_values_1.getDefaultContactInputValues)(),
    contactInputValuesForSearch: getDefaultContactInputValuesForSearch(),
    customFieldResponsesInputValues: [],
    direction: store_1.InitialGlobalState.currentCallInformation.direction,
    endCallActionHasBeenInitiated: false,
    externalTransferPhoneNumberInputValue: '',
    externalTransferSent: false,
    followupRequestDateInputValue: new Date().toISOString(),
    hideSearchContainer: false,
    holdButtonEnabled: false,
    incomingCallType: store_1.InitialGlobalState.currentCallInformation.incomingCallType,
    incomingConnection: store_1.InitialGlobalState.currentCallInformation.incomingConnection,
    incomingTransferCompleted: false,
    internalTransferSent: false,
    isTransferRecipientJillOnACall: false,
    jillCallSid: store_1.InitialGlobalState.currentCallInformation.jillCallSid,
    jillInteractionMode: 'ACTION_ITEM',
    jillsToTransferTo: [],
    loading: false,
    mergeContactDetails: undefined,
    minimumPriceApplied: false,
    noteText: '',
    onHold: false,
    outboundAttemptId: 'NOT_SET',
    preserveState: false,
    previousCustomFieldResponses: [],
    previousLineItemResponses: [],
    previousServiceResponses: [],
    priceQuote: 0,
    pricingInputValues: 'NOT_SET',
    quickTitlesForActionItem: [],
    redirectingIncomingCallbackToOutgoing: false,
    returnPath: store_1.InitialGlobalState.currentCallInformation.returnPath,
    scheduledAppointmentDetails: 'NOT_SET',
    selectedJillForTransfer: 'NOT_SET',
    selectedServices: [],
    saveActionItemInitiated: false,
    serviceAreaDescription: '',
    serviceLineItemResponses: [],
    showContactActivityLog: false,
    showFlagActionItemForm: false,
    showDialpad: false,
    showSubmitHelpTicketForm: false,
    showTransferInitiatedBox: false,
    subscriptions: {
        jillAndUserReconnectedAfterTransferDeclineSubscription: 'NOT_SET',
        incomingTransferResolvedSubscription: 'NOT_SET',
        transferCallStatusSubscription: 'NOT_SET',
        userCallStatusSubscription: 'NOT_SET',
    },
    titleInputValues: 'NOT_SET',
    transferCallSid: 'NOT_SET',
    transferCallStatus: 'NOT_SET',
    transferId: 'NOT_SET',
    transferRecipientName: '',
    transferState: 'NOT_STARTED',
    transferTo: '',
    twilioDevice: 'NOT_SET',
    userCallSid: store_1.InitialGlobalState.currentCallInformation.userCallSid,
    userCallStatus: store_1.InitialGlobalState.currentCallInformation.userCallStatus,
};
function getDefaultContactInputValuesForSearch() {
    return {
        addressesInputValues: [],
        addressIdsToDisconnect: [],
        businessNameInputValue: '',
        contactId: -1,
        emailInputValue: '',
        firstNameInputValue: '',
        formErrors: [],
        lastNameInputValue: '',
        phoneNumbersInputValues: [],
        phoneNumberIdsToDisconnect: [],
        sales: false,
        status: 'UNBLOCKED',
        whereDidYouHearAboutUsInputValue: '',
    };
}
