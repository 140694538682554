"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTransferInitiateAction = void 0;
const common_1 = require("@augment-vir/common");
const twilio_1 = require("../../../../../../services/twilio");
const utilities_1 = require("../../../../../../services/utilities");
const queries_1 = require("./queries");
async function createTransferInitiateAction(authenticatedUserId, callId, companyForTransferInstructions, companyId, jillCallSid, originalCallDirection, transferTo, transferRecipientName, userCallSid) {
    if ((0, utilities_1.isInternalTransfer)(transferTo)) {
        const recipientJillId = parseInt(transferTo.replace('client:', ''));
        const isTransferRecipientJillOnACallResult = await (0, queries_1.fetchIsRecipientJillOnACall)(recipientJillId);
        if (isTransferRecipientJillOnACallResult.succeeded === false) {
            return isTransferRecipientJillOnACallResult;
        }
        const isTransferRecipientJillOnACall = isTransferRecipientJillOnACallResult.value;
        if (isTransferRecipientJillOnACall === true) {
            return {
                succeeded: true,
                value: {
                    type: 'TRANSFER_INITIATE_CANCEL_JILL_ON_ANOTHER_CALL',
                },
            };
        }
    }
    const transferInitiateResult = await (0, twilio_1.twilioTransferInitiate)({
        callId,
        companyId,
        originalCallDirection,
        jillId: authenticatedUserId,
        jillCallSid,
        to: transferTo,
        twilioPhoneNumber: companyForTransferInstructions.twilio_phone_number,
        userCallSid,
        verifiedBusinessNumber: companyForTransferInstructions.verified_business_number ?? '',
    });
    if (transferInitiateResult.succeeded === false) {
        return transferInitiateResult;
    }
    const fetchTransferResult = await (0, queries_1.fetchTransferFromUserCallSid)(userCallSid);
    if (fetchTransferResult.succeeded === false) {
        return fetchTransferResult;
    }
    const transfer = fetchTransferResult.value;
    (0, common_1.assertLengthAtLeast)(transfer.transfer_attempts, 1);
    const newTransferAttempt = transfer.transfer_attempts[0];
    return {
        succeeded: true,
        value: {
            type: 'TRANSFER_INITIATE',
            transferCallSid: newTransferAttempt?.transfer_call_sid,
            transferId: transfer.id,
            transferRecipientName,
            transferState: 'STARTED',
            transferTo,
        },
    };
}
exports.createTransferInitiateAction = createTransferInitiateAction;
