"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {};
class JOCallScreenDialpad extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .column {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .row {
                    display: flex;
                    width: 100%;
                }

                .button-container {
                    padding: 0.5rem;
                    flex: 1;
                }

                .button {
                    color: var(--jo-primary);
                    font-family: sans-serif;
                    font-size: 1.5rem;
                    cursor: pointer;
                    padding: 0.5rem;
                    text-align: center;
                    border-radius: 100vw;
                    border: solid 1px var(--jo-accent-background-light);
                }

                .button:hover {
                    background-color: var(--jo-accent-background-light);
                }
            </style>

            <div class="main-container">
                <div class="column">
                    <div class="row">
                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '1')}
                            >
                                1
                            </div>
                        </div>

                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '2')}
                            >
                                2
                            </div>
                        </div>

                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '3')}
                            >
                                3
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '4')}
                            >
                                4
                            </div>
                        </div>

                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '5')}
                            >
                                5
                            </div>
                        </div>

                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '6')}
                            >
                                6
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '7')}
                            >
                                7
                            </div>
                        </div>

                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '8')}
                            >
                                8
                            </div>
                        </div>

                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '9')}
                            >
                                9
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '*')}
                            >
                                *
                            </div>
                        </div>

                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '0')}
                            >
                                0
                            </div>
                        </div>

                        <div class="button-container">
                            <div
                                class="button"
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'digit', '#')}
                            >
                                #
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-dialpad', JOCallScreenDialpad);
