"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSendActionItemAction = void 0;
const graphql_1 = require("../../../../../../services/graphql");
const twilio_1 = require("../../../../../../services/twilio");
const error_handling_1 = require("../../../../../../utilities/error-handling");
const assign_jill_to_call_1 = require("../../../utilities/assign-jill-to-call");
const call_screen_mode_utils_1 = require("../../../utilities/call-screen-mode-utils");
const mutations_1 = require("./mutations");
const save_and_send_action_item_1 = require("./save-and-send-action-item");
const mapped_env_variables_1 = require("../../../../../../utilities/environment/mapped-env-variables");
async function createSendActionItemAction(actionItem, actionItemId, actionItemStatusTypeName, callScreenMode, authenticatedUser, callId, chatId, companyForCollection, contact, customFieldResponsesInputValues, followupRequestDateInputValue, incomingCallType, incomingConnection, internalTransferSent, noteText, pricingInputValues, selectedServices, titleInputValues, userIsInPersonalAdminMode, scheduledAppointmentDetails, accountHelpTicketId) {
    try {
        if ((0, call_screen_mode_utils_1.isInACallMode)(callScreenMode)) {
            if (callId === 'NOT_SET') {
                return {
                    succeeded: false,
                    userMessage: 'There was an error',
                    developerMessage: 'callId must be set',
                };
            }
            if (incomingConnection !== 'NOT_SET') {
                const now = new Date();
                const durationEventInfo = (0, twilio_1.getTimestampActionItemSavedDurationEventInfo)(now, authenticatedUser.id, callId);
                (0, twilio_1.setDurationEventLocal)(durationEventInfo);
                incomingConnection.disconnect();
                if (incomingCallType !== 'NOT_SET' && internalTransferSent === false) {
                    await (0, assign_jill_to_call_1.assignJillToCall)(callId, authenticatedUser.id);
                }
                saveRemoteInformationForCall(durationEventInfo, authenticatedUser, now, callId, userIsInPersonalAdminMode);
            }
        }
        if (internalTransferSent === false) {
            const saveActionItemResult = await (0, save_and_send_action_item_1.saveAndSendActionItem)(actionItemId, actionItem, callId, chatId, companyForCollection.id, contact.id, titleInputValues, noteText, authenticatedUser.id, customFieldResponsesInputValues, pricingInputValues === 'NOT_SET'
                ? {
                    priceQuote: 0,
                    serviceLineItemResponsesInputValues: [],
                }
                : pricingInputValues, actionItemStatusTypeName, actionItem === 'NOT_SET'
                ? 'NOT_SET'
                : actionItem.action_item_status_type?.id ?? 'NOT_SET', selectedServices, actionItem === 'NOT_SET' ? [] : actionItem.service_responses, followupRequestDateInputValue, scheduledAppointmentDetails, accountHelpTicketId);
            if (saveActionItemResult.succeeded === false) {
                return saveActionItemResult;
            }
            const assignJillToActionItemResult = await (0, mutations_1.assignJillToActionItem)(actionItemId, authenticatedUser.id);
            if (assignJillToActionItemResult.succeeded === false) {
                return assignJillToActionItemResult;
            }
        }
        return {
            succeeded: true,
            value: {
                type: 'SEND_ACTION_ITEM',
            },
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-call-screen createSendActionItemAction', error);
    }
}
exports.createSendActionItemAction = createSendActionItemAction;
async function saveRemoteInformationForCall(durationEventInfo, authenticatedUser, now, callId, userIsInPersonalAdminMode) {
    updateLastCallCompletedAtForJill(authenticatedUser.id, now);
    await (0, twilio_1.setDurationEventRemote)(durationEventInfo);
    await (0, twilio_1.calculateAndApplyCallDuration)(callId, userIsInPersonalAdminMode);
}
async function updateLastCallCompletedAtForJill(userId, time) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $completedAtTime: DateTime!) {
                    updateUsers(input: {id: $userId, last_call_completed_at: $completedAtTime}) {
                        id
                    }
                }
            `, {
            userId,
            completedAtTime: time.toISOString(),
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateLastCallCompletedAtForJill', error);
    }
}
