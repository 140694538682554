"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoAddressAutoCompleteOptions = void 0;
const element_vir_1 = require("element-vir");
const web_client_interface_1 = require("../../../services/web-client-interface/web-client-interface");
const handle_error_1 = require("../../../services/logging/error-handling/handle-error");
const common_1 = require("@augment-vir/common");
const google_prediction_term_template_1 = require("../../utilities/google-maps/google-prediction-term-template");
const class_map_js_1 = require("lit/directives/class-map.js");
const add_data_to_error_1 = require("../../../services/logging/error-handling/add-data-to-error");
const address_parts_1 = require("./address-parts");
const auth_rules_1 = require("../../../utilities/auth-rules");
const submitKeyDownCodes = [
    'Tab',
    'Enter',
    'Return',
];
const autocompleteInputThrottleMilliseconds = 250;
exports.JoAddressAutoCompleteOptions = (0, element_vir_1.defineElement)()({
    tagName: 'jo-address-auto-complete-options',
    hostClasses: {
        hide: ({ state, inputs }) => state.inputBlurred || !inputs.query || inputs.query === state.lastSubmittedQuery,
    },
    styles: ({ hostClassSelectors }) => (0, element_vir_1.css) `
        :host {
            background-color: white;
            border-radius: 4px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        }

        ${hostClassSelectors.hide} {
            visibility: hidden;
        }

        img {
            display: block;
            height: 14px;
        }

        .powered-by-google {
            justify-content: flex-end;
            display: flex;
            align-items: center;
            gap: 2px;
        }

        .powered-by-google span {
            font-size: 12px;
            opacity: 0.5;
            white-space: nowrap;
        }

        .option {
            padding: 8px 4px;
            overflow: hidden;
            display: flex;
            font-size: 14px;
            cursor: pointer;
        }

        .option.selected {
            background-color: #ebf2fe;
        }

        .option span {
            white-space: nowrap;
        }

        .option .main {
            display: flex;
            margin-right: 4px;
        }

        .space-before {
            margin-left: 4px;
        }

        .space-after {
            margin-right: 4px;
        }
    `,
    events: {
        optionSubmitted: (0, element_vir_1.defineElementEvent)(),
    },
    stateInit: {
        autocompleteOptions: (0, element_vir_1.asyncState)(),
        /**
         * This is separate from autocompleteOptions so we can keep the last results open while new
         * ones are loading
         */
        lastAutocompleteOptions: [],
        inputElementKeyDownListener: undefined,
        inputElementBlurListener: undefined,
        inputElementFocusListener: undefined,
        selectedIndex: undefined,
        inputBlurred: true,
        lastSubmittedQuery: '',
        loadingSubmittedPlaceOption: false,
    },
    renderCallback({ inputs, state, updateState, host, dispatch, events }) {
        if (!inputs.authenticatedUser || (0, auth_rules_1.isCompanyUser)(inputs.authenticatedUser)) {
            return '';
        }
        if (inputs.query !== state.lastSubmittedQuery) {
            updateState({ lastSubmittedQuery: '' });
        }
        async function submitByIndex(optionIndex, event) {
            updateState({ loadingSubmittedPlaceOption: true });
            // unfocus the inputElement so that the auto-complete dropdown closes
            inputs.inputElement?.blur();
            const selectedPlaceId = state.lastAutocompleteOptions[optionIndex]?.placeId;
            const optionsExtraDataForErrors = { options: state.lastAutocompleteOptions, optionIndex };
            if (!selectedPlaceId) {
                (0, add_data_to_error_1.addErrorDataAndThrow)(new Error(`Failed to submit auto completion option by index`), { extraData: optionsExtraDataForErrors });
            }
            event.preventDefault();
            event.stopImmediatePropagation();
            const googleMapsClient = await (0, web_client_interface_1.getWebClientInterface)().googleMapsClient;
            if (!googleMapsClient) {
                const error = new Error('No google maps client');
                (0, handle_error_1.handleError)(error, {
                    extraData: { element: host.tagName },
                });
                return error;
            }
            const placeDetails = await googleMapsClient.requestPlaceDetails(selectedPlaceId);
            if (!placeDetails) {
                (0, add_data_to_error_1.addErrorDataAndThrow)(new Error('Failed to find place details from Google Maps Iframe.'), { extraData: { ...optionsExtraDataForErrors, selectedPlaceId } });
            }
            const combinedAddressParts = (0, address_parts_1.combineAddressValuesFromAddressParts)(placeDetails.addressParts);
            dispatch(new events.optionSubmitted(combinedAddressParts));
            updateState({
                lastSubmittedQuery: combinedAddressParts.addressLine1,
                loadingSubmittedPlaceOption: false,
                selectedIndex: undefined,
            });
        }
        if (!state.inputElementKeyDownListener && inputs.inputElement) {
            function keyDownListener(event) {
                if (!state.lastAutocompleteOptions.length) {
                    return;
                }
                if (event.code === 'ArrowDown') {
                    const nextIndex = (state.selectedIndex ?? -1) + 1;
                    updateState({
                        selectedIndex: nextIndex >= state.lastAutocompleteOptions.length ? 0 : nextIndex,
                    });
                }
                else if (event.code === 'ArrowUp') {
                    const previousIndex = (state.selectedIndex ?? state.lastAutocompleteOptions.length) - 1;
                    updateState({
                        selectedIndex: previousIndex < 0
                            ? state.lastAutocompleteOptions.length - 1
                            : previousIndex,
                    });
                }
                else if (submitKeyDownCodes.includes(event.code)) {
                    submitByIndex(state.selectedIndex ?? 0, event);
                }
            }
            function blurListener() {
                updateState({
                    inputBlurred: true,
                });
            }
            function focusListener() {
                updateState({
                    inputBlurred: false,
                });
            }
            updateState({
                inputElementKeyDownListener: keyDownListener,
                inputElementBlurListener: blurListener,
                inputElementFocusListener: focusListener,
            });
            inputs.inputElement.addEventListener('keydown', keyDownListener);
            inputs.inputElement.addEventListener('blur', blurListener);
            inputs.inputElement.addEventListener('focus', focusListener);
        }
        updateState({
            autocompleteOptions: {
                async createPromise() {
                    const originalQuery = inputs.query;
                    /**
                     * Debounce inputs so we're not sending tons of requests to Google (which will
                     * skyrocket our bill).
                     */
                    await (0, common_1.wait)(autocompleteInputThrottleMilliseconds);
                    /**
                     * Prevent sending more requests to Google while we're already submitting a
                     * previously selected autocomplete option.
                     */
                    if (state.loadingSubmittedPlaceOption) {
                        return [];
                    }
                    if (originalQuery !== inputs.query) {
                        /**
                         * It will be okay to just return an empty array from this promise promise
                         * because a new one will already have been created in its place.
                         */
                        return [];
                    }
                    const googleMapsClient = await (0, web_client_interface_1.getWebClientInterface)().googleMapsClient;
                    if (!googleMapsClient) {
                        const error = new Error('No google maps client');
                        (0, add_data_to_error_1.addErrorDataAndThrow)(error, {
                            extraData: { element: host.tagName },
                        });
                    }
                    if (!inputs.query) {
                        return [];
                    }
                    const results = await googleMapsClient.requestPlaceAutocomplete(inputs.query);
                    if (!results) {
                        (0, add_data_to_error_1.addErrorDataAndThrow)(new Error('requestPlaceAutocomplete gave no results'), {
                            extraData: { element: host.tagName, query: inputs.query },
                        });
                    }
                    updateState({ lastAutocompleteOptions: results });
                    return results;
                },
                trigger: inputs.query,
            },
        });
        if (!state.lastAutocompleteOptions.length) {
            return '';
        }
        function createOptionTemplate(option, optionIndex) {
            const selected = state.selectedIndex === optionIndex;
            return (0, element_vir_1.html) `
                <div
                    class=${(0, class_map_js_1.classMap)({
                option: true,
                selected,
            })}
                    ${(0, element_vir_1.listen)('mouseenter', () => {
                updateState({
                    selectedIndex: optionIndex,
                });
            })}
                    ${(0, element_vir_1.listen)('click', event => {
                submitByIndex(optionIndex, event);
            })}
                    ${(0, element_vir_1.listen)('mousedown', event => {
                /**
                 * We must block the mousedown event to prevent it from blurring the input
                 * element (which blurring would cause this whole thing to go away).
                 */
                event.preventDefault();
                event.stopImmediatePropagation();
            })}
                >
                    <span class="main">
                        ${(0, google_prediction_term_template_1.createPredictionTermTemplate)(option.structuredFormatting.main_text, option.structuredFormatting.main_text_matched_substrings)}
                    </span>
                    <span class="secondary">${option.structuredFormatting.secondary_text}</span>
                </div>
            `;
        }
        return (0, element_vir_1.html) `
            ${state.lastAutocompleteOptions.map(createOptionTemplate)}
            ${requiredPoweredByGoogleTemplate}
        `;
    },
    cleanupCallback({ state, inputs }) {
        if (inputs.inputElement) {
            if (state.inputElementKeyDownListener) {
                inputs.inputElement.removeEventListener('input', state.inputElementKeyDownListener);
            }
            if (state.inputElementBlurListener) {
                inputs.inputElement.removeEventListener('blur', state.inputElementBlurListener);
            }
            if (state.inputElementFocusListener) {
                inputs.inputElement.removeEventListener('focus', state.inputElementFocusListener);
            }
        }
    },
});
/** Google's terms and policies require us to show this. */
const requiredPoweredByGoogleTemplate = (0, element_vir_1.html) `
    <span class="powered-by-google">
        <span>powered by</span>
        <img src="/images/third-party-logos/google_on_white.png" />
    </span>
`;
