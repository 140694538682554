"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spaceAndCapitalize = void 0;
function spaceAndCapitalize(val) {
    return val
        .split(/[_\s-]/)
        .map(capitalize)
        .join(' ');
}
exports.spaceAndCapitalize = spaceAndCapitalize;
function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
