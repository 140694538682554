"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.joCallScreenStyles = void 0;
const lit_html_1 = require("lit-html");
exports.joCallScreenStyles = (0, lit_html_1.html) `
    <style>
        .jo-call-screen--main-container {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            padding: 5px 7px;
            overflow: auto;
            display: flex;
            flex-direction: column;
        }
        .top-row {
            padding-bottom: 0.5rem;
        }
        .jo-call-screen--main-body--container {
            overflow: hidden;
            padding-top: 0.5rem;
            background-color: #f9fafc;
            height: 100%;
        }

        .jo-call-screen--call-controls-container {
            padding: 0 0.2rem 0 0.2rem;
            overflow: hidden;
        }

        @media screen and (max-width: 928px) {
            .jo-call-screen--instructions-and-collection-container {
                grid-template-columns: 1fr 2fr;
            }
        }

        @media screen and (min-width: 929px) {
            .jo-call-screen--instructions-and-collection-container {
                grid-template-columns: 1fr 1fr;
            }
        }

        .jo-call-screen--instructions-and-collection-container {
            display: grid;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
        }

        .jo-call-screen--collection-container {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            overflow: auto;
        }

        .jo-call-screen--contact-search-container {
            border: solid 1px #979797;
            border-radius: var(--jo-wide-border-radius-1);
            background-color: var(--jo-accent-background-light);
            box-sizing: border-box;
            word-break: break-word;
            overflow-x: hidden;
            overflow-y: scroll;
            max-height: 100%;
            width: 100%;
        }

        .jo-call-screen--call-action-button-container {
            box-sizing: border-box;
            padding: 0.5rem 1rem;
            display: flex;
        }

        .jo-call-screen--dialpad-container {
            background-color: white;
            border-radius: var(--jo-wide-border-radius-1);
            padding: 0.5rem;
            z-index: 9;
            position: absolute;
            right: 0;
            margin-right: 1rem;
            margin-top: 0.5rem;
            box-shadow: 0px 0px 1px black;
        }

        .jo-call-screen--timers-container {
            font-size: 0.8rem;
        }

        .jo-call-screen--endCallTimer-container {
            padding-bottom: 0.2rem;
        }

        @media screen and (max-width: 1600px) {
            .jo-call-screen--timers-container {
                font-size: calc(7px + 1vmin);
            }
        }

        .jo-call-screen--flag-info-container {
            margin-top: auto;
            height: 100%;
            overflow-y: auto;
            padding-top: 0.5rem;
        }

        .jo-call-screen--flag-button {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .jo-call-screen--account-help-button {
            position: absolute;
            right: 0.5rem;
            bottom: 0.5rem;
        }

        .material-icons.collapse-icon {
            color: var(--jo-text-default);
            font-size: 2rem;
            cursor: pointer;
            padding-top: 0.5rem;
        }
        .material-icons.expand-icon {
            color: var(--jo-text-default);
            font-size: 2rem;
            cursor: pointer;
            z-index: 1;
        }
        .material-icons:hover {
            color: var(--jo-primary);
        }
        #column-2 {
            margin-left: 0.5rem;
        }
        .split {
            height: 100vh;
            width: 100%;
            display: flex;
            justify-items: center;
            align-items: center;
            flex-direction: column;
        }
        .gutter {
            height: 100%;
            background: no-repeat;
        }
        .gutter:hover {
            background-color: var(--jo-primary);
        }
        .gutter.gutter-vertical {
            cursor: ns-resize;
            width: 98%;
            z-index: 0;
            background-image: url('../images/vertical-gutter.png');
            background-position: center;
        }
        .gutter.gutter-vertical:hover {
            background-image: url('../images/vertical-gutter-hover.png');
        }
        .gutter.gutter-horizontal {
            cursor: ew-resize;
            background-image: url('../images/horizontal-gutter.png');
            background-position: center;
        }
        .gutter.gutter-:horizontal:hover {
            background-image: url('../images/horizontal-gutter-hover.png');
        }
        .jo-call-screen--contact-container {
            height: 50%;
            width: 100%;
            overflow: auto;
        }
        .jo-call-screen--instructions-container {
            height: 50%;
            width: 100%;
            box-sizing: border-box;
            padding: 0 0.5rem;
            background-color: #f9fafc;
            z-index: 0;
        }
        .hide {
            display: none;
        }
        .slide-out-animation {
            animation-duration: 0.4s;
            animation-iteration-count: 1;
            animation-name: slideout;
        }
        @keyframes slideout {
            from {
                margin-left: 0%;
            }
            to {
                margin-left: -12.5%;
            }
        }
        .slide-in-animation {
            animation-duration: 0.4s;
            animation-iteration-count: 1;
            animation-name: slidein;
        }
        @keyframes slidein {
            from {
                margin-left: -12.5%;
            }
            to {
                margin-left: 0;
            }
        }
    </style>
`;
