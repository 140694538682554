"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const DOMPurify = __importStar(require("dompurify"));
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const html_sanitization_1 = require("../../../utilities/html-sanitization");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const InitialState = {
    editor: 'NOT_SET',
    instructions: '',
    showTitle: true,
    saving: false,
    readonly: false,
    showSave: true,
    showToolbar: true,
    theTitle: '',
    type: 'OTHER',
};
class JOCompanyCallInstructionEditor extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    connectedCallback() {
        window.tinymce.init({
            target: this.querySelector('.jo-company-call-instruction-editor--editor'),
            plugins: [
                'table',
                'link',
                'lists',
            ],
            default_link_target: '_blank',
            extended_valid_elements: 'a[href|target=_blank]',
            toolbar: this.store.showToolbar === true
                ? 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | table | link bullist numlist underline backcolor'
                : '',
            menubar: false,
            setup: (editor) => (this.store.editor = editor),
            readonly: this.store.readonly === true ? 1 : 0,
            height: '100%',
        });
    }
    set instructions(instructions) {
        this.store.instructions = instructions;
        if (this.store.editor !== 'NOT_SET') {
            this.store.editor.setContent(DOMPurify.sanitize(this.store.instructions));
        }
    }
    saveClick() {
        if (this.store.editor === 'NOT_SET') {
            const message = 'The editor has not been created';
            (0, utilities_1.joAlert)('Error', message);
            return;
        }
        if (this.store.theTitle === '') {
            (0, utilities_1.joAlert)('Error', 'Title cannot be empty');
            return;
        }
        this.store.saving = true;
        // TODO would it be better to keep the title and instructions properties of the state always synchronized?
        const title = this.store.theTitle;
        const instructions = this.store.editor.getContent();
        const type = this.store.type;
        this.dispatchEvent(new CustomEvent('saved', {
            detail: {
                title,
                type,
                instructions,
            },
        }));
        this.store.saving = false;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-company-call-instruction-editor--main-container {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    background-color: white;
                    border-radius: var(--jo-wide-border-radius-1);
                    box-shadow: var(--jo-card-shadow-1);
                    padding: 1rem;
                }

                .jo-company-call-instruction-editor--editing-container {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                }

                .jo-company-call-instruction-editor--title-container {
                    box-sizing: border-box;
                    padding-bottom: 1rem;
                }

                .jo-company-call-instruction-editor--editor {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                }

                .jo-company-call-instruction-editor--buttons-container {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 1rem;
                }
            </style>

            <div class="jo-company-call-instruction-editor--main-container">
                <div class="jo-company-call-instruction-editor--editing-container">
                    <div class="jo-company-call-instruction-editor--title-container">
                        <jo-input
                            ?hidden=${state.showTitle === false}
                            .label=${'Title'}
                            .type=${'TEXT_INPUT'}
                            .inputValue=${state.theTitle}
                            @inputchange=${(e) => {
            this.store.theTitle = e.detail.inputValue;
        }}
                        ></jo-input>
                    </div>

                    <div class="jo-company-call-instruction-editor--title-container">
                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Type'}
                            .selectOptions=${[
            'OUTBOUND',
            'INBOUND',
            'OTHER',
        ]}
                            .selectedValue=${state.type}
                            @valueChanged=${(e) => {
            this.store.type = e.detail.selectedValue;
        }}
                        ></jo-input>
                    </div>

                    <div class="jo-company-call-instruction-editor--editor">
                        ${(0, html_sanitization_1.renderHTMLStringSafely)(this.store.instructions)}
                    </div>

                    <div class="jo-company-call-instruction-editor--buttons-container">
                        <jo-button
                            ?hidden=${state.showSave === false}
                            .type=${'ACTION_BUTTON_1'}
                            .text=${state.saving === true ? 'Saving...' : 'Save'}
                            .disabled=${state.saving === true}
                            @joButtonClick=${() => this.saveClick()}
                        ></jo-button>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-call-instruction-editor', JOCompanyCallInstructionEditor);
