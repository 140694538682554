"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStyles = void 0;
const lit_html_1 = require("lit-html");
function getStyles(state) {
    return (0, lit_html_1.html) `
        <style>
            .main-container {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                background-color: white;
                border-radius: var(--jo-wide-border-radius-1);
                box-shadow: var(--jo-card-shadow-1);
                padding: ${state.isMobileDevice ? '0.2rem' : '1rem'};
            }

            .activity-log-header {
                margin: 0;
                padding-bottom: 1rem;
            }

            .action-items-container {
                background-color: ${state.isMobileDevice ? 'rgb(0, 0, 0, 0.05)' : ''};
                padding: ${state.isMobileDevice ? '0' : '1rem'};
                max-height: 700px;
                overflow: auto;
            }

            .action-item-container {
                padding-bottom: ${state.isMobileDevice ? '1.5rem' : '3rem'};
            }

            .action-item {
                background-color: white;
                padding: ${state.isMobileDevice ? '0' : '1rem'};
                box-shadow: 0 0 5px rgb(0, 0, 0, 0.25);
            }

            .action-item-upper-container {
                display: flex;
                flex-direction: column;
                padding-bottom: 1rem;
                padding-left: 0.4rem;
                padding-right: 0.4rem;
            }

            .action-item-upper-container h4 {
                text-align: center;
            }
        </style>
    `;
}
exports.getStyles = getStyles;
