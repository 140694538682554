"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchTransferFromUserCallSid = exports.fetchIsRecipientJillOnACall = void 0;
const common_1 = require("@augment-vir/common");
const graphql_1 = require("../../../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../../../utilities/environment/mapped-env-variables");
async function fetchIsRecipientJillOnACall(recipientJillId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($recipientJillId: Int!) {
                getUsers(id: $recipientJillId) {
                    id
                    is_on_a_call
                }
            }
        `, {
        recipientJillId,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    else {
        const isRecipientJillOnACall = gqlResult.value.data.getUsers.is_on_a_call;
        return {
            succeeded: true,
            value: isRecipientJillOnACall,
        };
    }
}
exports.fetchIsRecipientJillOnACall = fetchIsRecipientJillOnACall;
async function fetchTransferFromUserCallSid(userCallSid) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($userCallSid: String!) {
                findTransfers(filter: {user_call_sid: {eq: $userCallSid}}) {
                    items {
                        id
                        transfer_attempts(orderBy: {field: "created_at", order: DESC}) {
                            transfer_call_sid
                        }
                    }
                }
            }
        `, {
        userCallSid,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    if (gqlResult.value.data.findTransfers.items.length === 0) {
        return {
            succeeded: false,
            userMessage: 'Error getting transfer info',
            developerMessage: 'the findTransfers query returned no results',
        };
    }
    (0, common_1.assertLengthAtLeast)(gqlResult.value.data.findTransfers.items, 1);
    const transfer = gqlResult.value.data.findTransfers.items[0];
    return {
        succeeded: true,
        value: transfer,
    };
}
exports.fetchTransferFromUserCallSid = fetchTransferFromUserCallSid;
