"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-input");
const InitialState = {
    loading: false,
};
class JOLoading extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .main-container {
                    position: fixed;
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: white;
                    pointer-events: none;
                    z-index: 1000;
                    opacity: 1;
                    display: flex;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    align-items: center;
                    justify-content: center;
                }

                .loaded {
                    opacity: 0;
                    transition: opacity 0.5s linear;
                }

                /* Inspired by this: https://stephanwagner.me/only-css-loading-spinner */
                .spinner {
                    width: 50px;
                    height: 50px;
                    border: 5px solid var(--jo-accent-background-dark);
                    border-top-color: var(--jo-secondary);
                    border-radius: 50%;
                    animation: spinner 1s ease-in-out infinite;
                }

                @keyframes spinner {
                    to {
                        transform: rotate(360deg);
                    }
                }
            </style>

            <div class="main-container ${state.loading === true ? '' : 'loaded'}">
                <div class="spinner"></div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-loading', JOLoading);
