"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumnsForGoogleCalendarEventsTable = void 0;
const html_sanitization_1 = require("../../../../../utilities/html-sanitization");
function getColumnsForGoogleCalendarEventsTable(element, companyCalendarConnections) {
    return [
        {
            title: 'Summary',
            getCellData: (googleCalendarEvent) => {
                if (!googleCalendarEvent) {
                    return '';
                }
                return googleCalendarEvent.summary ?? '';
            },
            width: 0.2,
        },
        {
            title: 'Description',
            getCellData: (googleCalendarEvent) => {
                if (!googleCalendarEvent) {
                    return '';
                }
                return (0, html_sanitization_1.renderHTMLStringSafely)(googleCalendarEvent.description ?? '');
            },
            width: 0.5,
        },
        {
            title: 'Calendar',
            getCellData: (googleCalendarEvent) => {
                if (!googleCalendarEvent) {
                    return '';
                }
                return googleCalendarEvent.calendarName;
            },
            width: 0.2,
        },
        {
            title: 'Start',
            getCellData: (googleCalendarEvent) => {
                if (!googleCalendarEvent) {
                    return '';
                }
                return googleCalendarEvent.start?.dateTime
                    ? new Date(googleCalendarEvent.start.dateTime).toLocaleString('en-US', {
                        timeZone: googleCalendarEvent.start.timeZone,
                    })
                    : '';
            },
            width: 0.2,
        },
        {
            title: 'End',
            getCellData: (googleCalendarEvent) => {
                if (!googleCalendarEvent) {
                    return '';
                }
                return googleCalendarEvent.end?.dateTime
                    ? new Date(googleCalendarEvent.end.dateTime).toLocaleString('en-US', {
                        timeZone: googleCalendarEvent.end.timeZone,
                    })
                    : '';
            },
            width: 0.2,
        },
    ];
}
exports.getColumnsForGoogleCalendarEventsTable = getColumnsForGoogleCalendarEventsTable;
