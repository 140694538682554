"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAnswerCallAction = void 0;
const graphql_1 = require("../../../../../../services/graphql");
const store_1 = require("../../../../../../services/store");
const twilio_1 = require("../../../../../../services/twilio");
const connect_jill_to_call_1 = require("../../../../../../utilities/calls/connect-jill-to-call");
const error_handling_1 = require("../../../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../../../utilities/environment/mapped-env-variables");
async function createAnswerCallAction(state) {
    try {
        if (state.incomingConnection === 'NOT_SET' ||
            state.authenticatedUser === 'NOT_SET' ||
            state.callId === 'NOT_SET') {
            return {
                succeeded: false,
                userMessage: 'This call has major issues',
                developerMessage: 'createAnswerCallAction error: something major is not set:' + state,
            };
        }
        if (store_1.GlobalStore.currentCallInformation.incomingCallType === 'USER') {
            await (0, connect_jill_to_call_1.connectUserToCall)(state.authenticatedUser.id, state.callId);
        }
        state.incomingConnection.accept();
        const now = new Date();
        const durationEventInfo = (0, twilio_1.getTimestampCallStartDurationEventInfo)(now, state.authenticatedUser.id, state.callId);
        if (state.incomingCallType === 'USER') {
            (0, twilio_1.setDurationEventLocal)(durationEventInfo);
            await (0, twilio_1.setDurationEventRemote)(durationEventInfo);
        }
        await updateLastCallStartedAtForJill(state.authenticatedUser.id, now);
        return {
            succeeded: true,
            value: {
                type: 'ANSWER_CALL',
                callAnswered: true,
                collectingDuration: true,
                holdButtonEnabled: true,
            },
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-call-screen createAnswerCallAction', error);
    }
}
exports.createAnswerCallAction = createAnswerCallAction;
async function updateLastCallStartedAtForJill(userId, time) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $startTime: DateTime!) {
                    updateUsers(input: {id: $userId, last_call_started_at: $startTime}) {
                        id
                    }
                }
            `, {
            userId,
            startTime: time.toISOString(),
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('setLastCallStartedAt', error);
    }
}
