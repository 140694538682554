"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDisplayNameOfNoteAuthor = exports.formatActionItemNoteMetaInfo = exports.getJillProfilePictureUrl = exports.isActionItemAuthorTheUser = exports.shouldHideImageContainer = void 0;
const constants_1 = require("../../../services/constants");
const utilities_1 = require("../../../services/utilities");
function shouldHideImageContainer(user) {
    if (user === 'NOT_SET')
        return true;
    return (0, utilities_1.isUserAMemberOfJillsOffice)(user);
}
exports.shouldHideImageContainer = shouldHideImageContainer;
function isActionItemAuthorTheUser(user, author) {
    if (user === 'NOT_SET')
        return false;
    if (author)
        return user.id === author.id;
    return false;
}
exports.isActionItemAuthorTheUser = isActionItemAuthorTheUser;
function getJillProfilePictureUrl(profilePictureUrl) {
    if (!profilePictureUrl) {
        return constants_1.defaultJillProfilePicutreUrl;
    }
    return profilePictureUrl;
}
exports.getJillProfilePictureUrl = getJillProfilePictureUrl;
function formatActionItemNoteMetaInfo(actionItemNote) {
    return `${(0, utilities_1.formatDateForActivityLog)(actionItemNote.created_at)} - ${getDisplayNameOfNoteAuthor(actionItemNote.author)}`;
}
exports.formatActionItemNoteMetaInfo = formatActionItemNoteMetaInfo;
function getDisplayNameOfNoteAuthor(author) {
    if (author === null || author === undefined) {
        return 'Note author not found';
    }
    if ((0, utilities_1.isUserAMemberOfJillsOffice)(author) === true &&
        author.first_name !== undefined &&
        author.first_name !== null &&
        author.last_name !== undefined &&
        author.last_name !== null) {
        return `${author.first_name} ${author.last_name.charAt(0)}.`;
    }
    return author.email;
}
exports.getDisplayNameOfNoteAuthor = getDisplayNameOfNoteAuthor;
