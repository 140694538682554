"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createActionItemFlag = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function createActionItemFlag(params) {
    try {
        const input = makeCreateActionItemFlagInput(params);
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreateAction_item_flagsInput!) {
                    createAction_item_flags(input: $input) {
                        id
                    }
                }
            `, {
            input,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createActionItemFlag', error);
    }
}
exports.createActionItemFlag = createActionItemFlag;
function makeCreateActionItemFlagInput(params) {
    return {
        action_item: {
            connect: {
                id: params.actionItemId,
            },
        },
        user: {
            connect: {
                id: params.jillId,
            },
        },
        author: {
            connect: {
                id: params.authorId,
            },
        },
        description: params.description,
        title: params.title,
    };
}
