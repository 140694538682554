"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConversationSidFromChat = void 0;
const common_1 = require("@augment-vir/common");
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function getConversationSidFromChat(chatId) {
    try {
        const result = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($chatId: Int!) {
                    findChats: findChats(filter: {id: {eq: $chatId}}) {
                        items {
                            id
                            twilio_conversation_sid
                        }
                    }
                }
            `, {
            chatId,
        });
        const conversationSid = result.data.findChats.items[0]?.twilio_conversation_sid;
        if (conversationSid == null) {
            return undefined;
        }
        return conversationSid;
    }
    catch (error) {
        throw new Error(`getConversationSidFromChat Failed: ${(0, common_1.extractErrorMessage)(error)}`);
    }
}
exports.getConversationSidFromChat = getConversationSidFromChat;
