"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeSeconds = exports.dispalyDate = exports.fullName = void 0;
function fullName(user) {
    if (!user || user === 'NOT_SET')
        return '';
    return user.first_name + ' ' + user.last_name;
}
exports.fullName = fullName;
function dispalyDate(timestamp) {
    const fullTime = new Date(timestamp).toLocaleString();
    return removeSeconds(fullTime);
}
exports.dispalyDate = dispalyDate;
function removeSeconds(timestamp) {
    return timestamp.split(/:\d\d\s/).join(' ');
}
exports.removeSeconds = removeSeconds;
