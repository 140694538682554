"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
const state_1 = require("./redux/state");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    callAnswered: state_1.InitialJOCallScreenState.callAnswered,
    callScreenMode: state_1.InitialJOCallScreenState.callScreenMode,
    holdButtonEnabled: state_1.InitialJOCallScreenState.holdButtonEnabled,
    incomingCallType: state_1.InitialJOCallScreenState.incomingCallType,
    incomingTransferCompleted: state_1.InitialJOCallScreenState.incomingTransferCompleted,
    onHold: state_1.InitialJOCallScreenState.onHold,
    transferState: state_1.InitialJOCallScreenState.transferState,
};
class JOCallScreenHold extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    holdClick() {
        (0, utilities_1.raiseCustomEvent)(this, 'holdclicked');
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                }

                .button {
                    cursor: pointer;
                }

                .button-hold {
                }

                .button-unhold {
                }
            </style>

            <div class="main-container">
                <jo-button
                    ?hidden=${state.onHold}
                    .type=${'SECONDARY_BUTTON_1'}
                    .inlineStyle=${'font-size: 0.8rem;'}
                    .text=${'Hold'}
                    .disabled=${shouldDisableHoldButton(state)}
                    @joButtonClick=${() => this.holdClick()}
                ></jo-button>

                <jo-button
                    ?hidden=${!state.onHold}
                    .type=${'SECONDARY_BUTTON_1'}
                    .inlineStyle=${'font-size: 0.8rem;'}
                    .text=${'Unhold'}
                    .disabled=${state.holdButtonEnabled === false ||
            state.transferState === 'STARTED'}
                    @joButtonClick=${() => this.holdClick()}
                ></jo-button>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-hold', JOCallScreenHold);
function shouldDisableHoldButton(state) {
    if (state.holdButtonEnabled === false) {
        return true;
    }
    if (state.callAnswered === false) {
        return true;
    }
    if ((0, utilities_1.shouldDisableCallActionButtonsDuringCall)(state.callScreenMode, state.transferState, state.incomingCallType, state.incomingTransferCompleted) === true) {
        return true;
    }
    return false;
}
