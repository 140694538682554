"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
require("../jo-input");
const error_handling_1 = require("../../../utilities/error-handling");
const jo_home_1 = require("./jo-home");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const startOfTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    highLevelStatses: 'NOT_SET',
    loading: true,
    pods: [],
    teams: [],
    selectedPod: 'ALL_PODS',
    selectedTeam: 'ALL_TEAMS',
    startDate: startOfToday.toISOString(),
    endDate: startOfTomorrow.toISOString(),
};
class JOHighLevelStatsCard extends HTMLElement {
    constructor() {
        super();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.subscribeToJOHomeSubscribable();
    }
    subscribeToJOHomeSubscribable() {
        const callback = (() => {
            this.resetDates();
            this.handleFetchAndSetData();
        }).bind(this);
        jo_home_1.JOHomeSubscribable.subscribe(callback);
    }
    resetDates() {
        this.store.startDate = InitialState.startDate;
        this.store.endDate = InitialState.endDate;
    }
    set authenticatedUser(authenticatedUser) {
        if (authenticatedUser === 'NOT_SET') {
            return;
        }
        if (this.store.authenticatedUser !== 'NOT_SET' &&
            this.store.authenticatedUser.id === authenticatedUser.id) {
            return;
        }
        this.store.authenticatedUser = authenticatedUser;
        this.handleFetchAndSetData();
    }
    async handleFetchAndSetData() {
        this.store.loading = true;
        await this.fetchAndSetData();
        this.store.loading = false;
        (0, utilities_1.raiseCustomEvent)(this, 'loaded', true);
    }
    async fetchAndSetData() {
        await this.fetchAndSetPods();
        await this.fetchAndSetTeams();
        await this.fetchAndSetHighLevelStatses();
    }
    async fetchAndSetPods() {
        const podsResult = await fetchPods();
        if (!podsResult.succeeded) {
            throw new Error('fetchPods failed in jo-home-high-level-stats-card');
        }
        this.store.pods = podsResult.value;
    }
    async fetchAndSetTeams() {
        const teamsResult = await fetchTeams();
        if (!teamsResult.succeeded) {
            throw new Error('fetchTeams failed for jo-home-high-level-stats-card');
        }
        this.store.teams = teamsResult.value;
    }
    async fetchAndSetHighLevelStatses() {
        this.store.loading = true;
        const highLevelStatsesResult = await fetchHighLevelStatses(this.store.startDate, this.store.endDate, this.store.selectedPod, this.store.selectedTeam);
        if (!highLevelStatsesResult.succeeded) {
            throw new Error('fetchHighLevelStatses failed in jo-home-high-level-stats-card');
        }
        this.store.highLevelStatses = highLevelStatsesResult.value;
        this.store.loading = false;
    }
    async statsStartDateInputChanged(e) {
        const newStartDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.startDate = newStartDate;
        await this.fetchAndSetHighLevelStatses();
    }
    async statsEndDateInputChanged(e) {
        const newEndDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.endDate = newEndDate;
        await this.fetchAndSetHighLevelStatses();
    }
    podSelected(podName) {
        if (podName === '') {
            this.store.selectedPod = 'ALL_PODS';
        }
        else {
            const selectedPod = this.store.pods.find((pod) => pod.name === podName);
            if (!selectedPod) {
                throw new Error('No selected pod was found.');
            }
            this.store.selectedPod = selectedPod;
        }
        this.fetchAndSetHighLevelStatses();
    }
    teamSelected(teamName) {
        if (teamName === '') {
            this.store.selectedTeam = 'ALL_TEAMS';
        }
        else {
            const selectedTeam = this.store.teams.find((team) => teamName.includes(String(team.id)));
            if (!selectedTeam) {
                throw new Error('No selected team was found.');
            }
            this.store.selectedTeam = selectedTeam;
        }
        this.fetchAndSetHighLevelStatses();
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-home-high-level-stats-card--main-container {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    min-width: 400px;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-narrow-border-radius-1);

                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    font-weight: 200;

                    background-color: white;
                }

                .jo-home-high-level-stats-card--upper-container {
                    box-sizing: border-box;
                    padding: 0.5rem;
                }

                .phone-icon {
                    font-size: 1.5rem;
                    vertical-align: text-bottom;
                }

                .phone-icon-container {
                    box-sizing: border-box;
                    background-color: var(--jo-secondary);
                    color: white;
                    height: 1.75rem;
                    width: 1.75rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .jo-home-high-level-stats-card--header {
                    box-sizing: border-box;
                    margin: 0;
                    margin-right: 0.5rem;
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .jo-home-high-level-stats-card--select-inputs-container {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.9rem;
                }

                .jo-home-high-level-stats-card--header-container {
                    display: flex;
                    justify-content: flex-start;
                    vertical-align: text-top;
                }

                .jo-home-high-level-stats-card--select-inputs-half-container {
                    box-sizing: border-box;
                    padding: 0.5rem;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }

                .jo-home-high-level-stats-card--select {
                    border-radius: 4px;
                    padding: 0.25rem;
                    width: 10rem;
                }

                .jo-home-high-level-stats-card--date-input {
                    border: 1px solid rgb(160, 160, 160);
                    border-radius: 4px;
                    font-size: 0.8rem;
                    width: 90%;
                }

                .jo-home-high-level-stats-card--dates-container {
                    margin-left: 1rem;
                    display: flex;
                    justify-content: space-between;
                }
            </style>

            <div class="jo-home-high-level-stats-card--main-container">
                <div class="jo-home-high-level-stats-card--upper-container">
                    <div class="jo-home-high-level-stats-card--header-container">
                        <div class="jo-home-high-level-stats-card--header">COMPANY STATS</div>
                        <span class="phone-icon-container">
                            <span class="material-icons-outlined phone-icon">phone</span>
                        </span>
                    </div>

                    <div class="jo-home-high-level-stats-card--select-inputs-container">
                        <div class="jo-home-high-level-stats-card--select-inputs-half-container">
                            <div>
                                <jo-input
                                    .label=${'Pod'}
                                    .type=${'SELECT'}
                                    .selectOptions=${state.pods.map((pod) => pod.name)}
                                    .defaultOption=${'All Pods'}
                                    @inputchange=${(e) => this.podSelected(e.detail.selectedValue)}
                                ></jo-input>
                            </div>

                            <div class="jo-global--1rem-above">
                                <jo-input
                                    .label=${'Team'}
                                    .type=${'SELECT'}
                                    .selectOptions=${state.teams.map((team) => `Team ${team.id}`)}
                                    .defaultOption=${'All Teams'}
                                    @inputchange=${(e) => this.teamSelected(e.detail.selectedValue)}
                                ></jo-input>
                            </div>
                        </div>
                        <div class="jo-home-high-level-stats-card--select-inputs-half-container">
                            <div>
                                <label>Start Date</label>
                                <br />
                                <div>
                                    <!-- TODO figure out why the styling only works correctly via inline style, not by putting it in the class  -->
                                    <input
                                        type="date"
                                        class="jo-input--input jo-home-high-level-stats-card--date-input"
                                        style="padding: 0.2rem 0.5rem"
                                        .value=${state.startDate.split('T')[0]}
                                        @change=${(e) => this.statsStartDateInputChanged(e)}
                                        style=""
                                    />
                                </div>
                            </div>
                            <div class="jo-global--1rem-above">
                                <label>End Date</label>
                                <br />
                                <div>
                                    <!-- TODO figure out why the styling only works correctly via inline style, not by putting it in the class  -->
                                    <input
                                        type="date"
                                        class="jo-input--input jo-home-high-level-stats-card--date-input"
                                        style="padding: 0.2rem 0.5rem"
                                        .value=${state.endDate.split('T')[0]}
                                        @change=${(e) => this.statsEndDateInputChanged(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="jo-global--home-card-stat-line">
                    <div>Total Calls</div>
                    <div>
                        ${state.highLevelStatses !== 'NOT_SET' && state.loading === false
            ? state.highLevelStatses.highLevelStats.numCalls
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Total Actual Call Duration</div>
                    <div>
                        ${state.highLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getActualCallDurationString)(state.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Total Billable Call Duration</div>
                    <div>
                        ${state.highLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getTotalBillableCallDurationString)(state.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Efficiency/Day</div>
                    <div>
                        ${state.highLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageEfficiencyPerDayString)(state.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Actual Call Duration</div>
                    <div>
                        ${state.highLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageActualCallDurationString)(state.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Billable Call Duration</div>
                    <div>
                        ${state.highLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageBillableCallDurationString)(state.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Time Between Calls</div>
                    <div>
                        ${state.highLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageTimeBetweenCalls)(state.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
                <div class="jo-global--home-card-stat-line">
                    <div>Average Action Item Time</div>
                    <div>
                        ${state.highLevelStatses !== 'NOT_SET' && state.loading === false
            ? (0, utilities_1.getAverageActionItemTimeString)(state.highLevelStatses.highLevelStats)
            : 'Loading...'}
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-home-high-level-stats-card', JOHighLevelStatsCard);
async function fetchHighLevelStatses(startDate, endDate, selectedPod, selectedTeam) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($startDate: String!, $endDate: String!, $podId: Int, $teamId: Int) {
                    highLevelStatses(
                        startDate: $startDate
                        endDate: $endDate
                        podId: $podId
                        teamId: $teamId
                    ) {
                        highLevelStats {
                            numCalls
                            actualCallDuration
                            billableCallDuration
                            averageActualCallDuration
                            averageBillableCallDuration
                            averageEfficiencyPerDay
                            averageTimeBetweenCalls
                            averageActionItemTime
                        }
                        highLevelStatsForMonths {
                            dateInMonth
                            highLevelStats {
                                numCalls
                                actualCallDuration
                                billableCallDuration
                                averageActualCallDuration
                                averageBillableCallDuration
                                averageEfficiencyPerDay
                                averageTimeBetweenCalls
                                averageActionItemTime
                            }
                            highLevelStatsForDays {
                                dateInDay
                                highLevelStats {
                                    numCalls
                                    actualCallDuration
                                    billableCallDuration
                                    averageActualCallDuration
                                    averageBillableCallDuration
                                    averageEfficiencyPerDay
                                    averageTimeBetweenCalls
                                    averageActionItemTime
                                }
                            }
                        }
                    }
                }
            `, {
            startDate,
            endDate,
            podId: selectedPod === 'ALL_PODS' ? null : selectedPod.id,
            teamId: selectedTeam === 'ALL_TEAMS' ? null : selectedTeam.id,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.highLevelStatses,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchHighLevelStatses', error);
    }
}
async function fetchPods() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findPods {
                        items {
                            id
                            name
                        }
                    }
                }
            `, {});
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.findPods.items,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchPods', error);
    }
}
async function fetchTeams() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findTeams {
                        items {
                            id
                        }
                    }
                }
            `, {});
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.findTeams.items,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchTeams', error);
    }
}
