"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.camelCaseWord = exports.camelCase = void 0;
function camelCase(string) {
    return string.split(' ').map(camelCaseWord).join('');
}
exports.camelCase = camelCase;
function camelCaseWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
exports.camelCaseWord = camelCaseWord;
