"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.combineAddressValuesFromAddressParts = void 0;
const common_1 = require("@augment-vir/common");
function combineAddressValuesFromAddressParts(addressParts) {
    let addressLine1 = '';
    let city = '';
    let state = '';
    let country = '';
    let postalCode = '';
    for (const component of addressParts) {
        const componentType = component.types[0];
        switch (componentType) {
            case 'street_number':
                addressLine1 = component.long_name;
                break;
            case 'route':
                addressLine1 += ' ' + component.short_name;
                break;
            case 'locality':
                city = component.long_name;
                break;
            case 'administrative_area_level_1':
                state = component.long_name;
                break;
            case 'country':
                country = component.short_name;
                break;
            case 'postal_code':
                postalCode = component.long_name;
                break;
        }
    }
    const values = {
        addressLine1,
        city,
        state,
        country,
        postalCode,
    };
    return (0, common_1.mapObjectValues)(values, (key, value) => {
        return value.trim();
    });
}
exports.combineAddressValuesFromAddressParts = combineAddressValuesFromAddressParts;
