"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const mutations_1 = require("./mutations");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    addressalType: 'Addressed',
    flagId: 'NOT_SET',
    noteText: '',
};
class JOFlagAddressalForm extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    async saveButtonClicked() {
        if (this.store.noteText === '') {
            (0, utilities_1.joAlert)('Alert', 'Note cannot be empty');
            return;
        }
        if (this.isTypeActionItemAddressal()) {
            await this.markAsAddressed();
        }
        else {
            await this.markAsMistake();
        }
        (0, utilities_1.raiseCustomEvent)(this, 'flagAddressed');
    }
    async markAsAddressed() {
        (0, utilities_1.assertIsSet)(this.store.flagId);
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser);
        const result = await (0, mutations_1.addressActionItemFlag)(this.store.flagId, this.store.authenticatedUser.id, this.store.noteText);
        (0, utilities_1.assertSucceeded)(result);
    }
    async markAsMistake() {
        (0, utilities_1.assertIsSet)(this.store.flagId);
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser);
        const result = await (0, mutations_1.markActionItemFlagAsMistake)(this.store.flagId, this.store.authenticatedUser.id, this.store.noteText);
        (0, utilities_1.assertSucceeded)(result);
    }
    isTypeActionItemAddressal() {
        return this.store.addressalType === 'Addressed';
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: white;
                    padding: 2rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            </style>

            <div class="main-container">
                <section>
                    ${this.isTypeActionItemAddressal()
            ? (0, lit_html_1.html) `
                              <h2>Address Action Item</h2>
                          `
            : (0, lit_html_1.html) `
                              <h2>Mark as Mistake</h2>
                          `}
                </section>

                <section style="width: 60%">
                    <jo-input
                        .type=${'TEXT_AREA'}
                        .label=${'Note'}
                        .rows=${4}
                        .inputValue=${state.noteText}
                        @valueChanged=${(e) => {
            this.noteTextChanged(e.detail.inputValue);
        }}
                    ></jo-input>
                </section>

                <section style="padding-top: 2rem;">
                    <jo-button
                        .type=${this.isTypeActionItemAddressal()
            ? 'ACTION_BUTTON_GREEN'
            : 'DELETE_BUTTON_1'}
                        .text=${this.isTypeActionItemAddressal()
            ? 'Address and Save'
            : 'Mark as Mistake'}
                        .materialIconName=${this.isTypeActionItemAddressal() ? 'check' : 'close'}
                        @joButtonClick=${() => {
            this.saveButtonClicked();
        }}
                    ></jo-button>
                </section>
            </div>
        `;
    }
    noteTextChanged(noteText) {
        this.store.noteText = noteText;
        (0, utilities_1.raiseCustomEvent)(this, 'addressalNoteTextChanged', noteText);
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-flag-addressal-form', JOFlagAddressalForm);
