"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOHomeActionItemsTable = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const interval_rate_limiter_1 = require("../../../../classes/interval-rate-limiter");
const utilities_1 = require("../../../../services/utilities");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const build_try_execute_async_1 = require("../../../../utilities/error-handling/build-try-execute-async");
const fetch_global_text_list_1 = require("../../../../utilities/global-configs/fetch-global-text-list");
const path_change_pub_sub_1 = require("../../../../utilities/routes/path-change-pub-sub");
const space_and_capitalize_1 = require("../../../../utilities/strings/space-and-capitalize");
require("../../jo-action-item-preview");
require("../../jo-button.element");
require("../../jo-fade-in-overlay");
require("../../jo-table");
const get_table_columns_1 = require("./get-table-columns");
const queries_1 = require("./queries");
const subscriptions_manager_1 = require("./subscriptions-manager");
const executeAsync = (0, build_try_execute_async_1.buildtryExecuteAsync)(utilities_1.handleError);
const InitialState = {
    authenticatedUser: 'NOT_SET',
    selectedPreviewActionItemId: 'NOT_SET',
    subscriptions: {
        actionItemCallbackCreated: null,
        actionItemCallbackUpdated: null,
        actionItemCallbackMoved: null,
    },
    quickNotes: [],
    tableType: 'CALLBACKS',
};
class JOHomeActionItemsTable extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.renderRateLimiter = new interval_rate_limiter_1.IntervalRateLimiter({
            callback: this.triggerReRender.bind(this),
            millisecondsPerInterval: 5000,
        });
        this.subscriptionManager = null;
        this.gqlSubscriptionCallback = this.renderRateLimiter.trigger.bind(this.renderRateLimiter);
        this.callGetCallbacks = (async (offset, limit) => {
            return await fetchActionItemsIfNecessary(offset, limit, this.store.tableType);
        }).bind(this);
        this.urlPathChangeCallback = this.handlePathChange.bind(this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.performSetup();
    }
    async performSetup() {
        this.createSubscriptionManagerIfNecessary();
        this.subscriptionManager?.closeSubscriptions();
        await this.fetchAndSetQuickNotes();
        this.subscribeToUrlPathChange();
        if ((0, utilities_1.isOnDashboard)()) {
            await this.subscriptionManager?.createSubscriptions();
        }
    }
    createSubscriptionManagerIfNecessary() {
        if (!this.subscriptionManager) {
            this.subscriptionManager = new subscriptions_manager_1.JOHomeActionItemsSubscriptionsManager(this.gqlSubscriptionCallback, this.store.tableType);
        }
    }
    async subscribeToUrlPathChange() {
        path_change_pub_sub_1.pathChangePubSub.subscribe(this.urlPathChangeCallback);
    }
    async handlePathChange(newPath, previousPath) {
        if (newPath === '/' && previousPath !== '/')
            await this.reInstantiateSubscriptions();
        else
            this.subscriptionManager?.closeSubscriptions();
    }
    async reInstantiateSubscriptions() {
        this.subscriptionManager?.closeSubscriptions();
        await this.subscriptionManager?.createSubscriptions();
        this.renderRateLimiter.trigger();
    }
    async fetchAndSetQuickNotes() {
        this.store.quickNotes = await executeAsync(fetch_global_text_list_1.fetchGlobalTextList, 'ai_quick_notes');
    }
    disconnectedCallback() {
        this.subscriptionManager?.closeSubscriptions();
    }
    triggerReRender() {
        this.callGetCallbacks = this.callGetCallbacks.bind(this);
        this.store.dispatch({
            type: 'RENDER',
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-home-callbacks-table--main-container {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                }

                .jo-home-callbacks-table--actions {
                    display: flex;
                }

                .jo-home-callbacks-table--action {
                    box-sizing: border-box;
                    padding-right: 0.5rem;
                }

                .jo-home-callbacks-table--action-item-preview-main-container {
                    position: relative;
                    display: flex;
                    align-items: center;
                }
            </style>

            <div class="jo-home-callbacks-table--main-container">
                <div class="jo-global--card">
                    <jo-table
                        .tableName=${(0, space_and_capitalize_1.spaceAndCapitalize)(state.tableType)}
                        .maxItemsPerPage=${10}
                        .columns=${(0, get_table_columns_1.getTableColumns)(state, this.store)}
                        .getItems=${this.callGetCallbacks}
                        .showSearchBar=${false}
                        @itemsfetched=${() => (0, utilities_1.raiseCustomEvent)(this, 'loaded', true)}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
exports.JOHomeActionItemsTable = JOHomeActionItemsTable;
(0, define_custom_element_1.defineCustomElement)('jo-home-action-items-table', JOHomeActionItemsTable);
async function fetchActionItemsIfNecessary(offset, limit, tableType) {
    if ((0, utilities_1.isOnDashboard)())
        return await (0, queries_1.getActionItems)(offset, limit, tableType);
    else
        return { items: [], totalItems: 0 };
}
