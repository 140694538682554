"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJillNamesWhoHaveTouchedThisActionItem = exports.noteAuthorMatchesSelectedJillText = exports.getSelectedJill = void 0;
function getSelectedJill(selectedTextValue, actionItem) {
    return (actionItem.action_item_notes.find(note => {
        return noteAuthorMatchesSelectedJillText(selectedTextValue, note);
    })?.author ?? 'NOT_SET');
}
exports.getSelectedJill = getSelectedJill;
function noteAuthorMatchesSelectedJillText(selectedTextValue, note) {
    return (selectedTextValue.includes(note.author?.first_name || 'NOT_FOUND') &&
        selectedTextValue.includes(note.author?.last_name || 'NOT_FOUND'));
}
exports.noteAuthorMatchesSelectedJillText = noteAuthorMatchesSelectedJillText;
function getJillNamesWhoHaveTouchedThisActionItem(actionItem) {
    if (actionItem === 'NOT_SET')
        return [];
    return actionItem.action_item_notes
        .filter(note => note.author && note.author.auth_role.includes('JILL'))
        .map(note => note.author.first_name + ' ' + note.author.last_name);
}
exports.getJillNamesWhoHaveTouchedThisActionItem = getJillNamesWhoHaveTouchedThisActionItem;
