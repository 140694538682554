"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultPricingInputValues = exports.getCalendarIdForScheduledCalendar = exports.getDateAndTimeFromTime = exports.makeFormattedLocation = exports.makeFormattedServices = exports.makeFormattedCustomerName = exports.formatSchedulingDataForCrewCal = exports.createCrewCalDiv = exports.addCrewCalEventListeners = exports.getSchedularButtonText = exports.makeFormattedEventDescription = exports.isCalendarPresentInSystem = exports.isCalendarReadOnly = void 0;
const common_1 = require("@augment-vir/common");
const utilities_1 = require("../../../../services/utilities");
const camel_case_1 = require("../../../../utilities/data-structures/strings/camel-case");
require("../../jo-button.element");
require("../../jo-input");
const helper_utils_1 = require("../redux/action-creators/send-action-item/get-action-item-note-inputs/helper-utils");
const calculate_default_duration_for_CrewCal_schedule_1 = require("./calculate-default-duration-for-CrewCal-schedule");
function isCalendarReadOnly(cewCalCalendarType) {
    return cewCalCalendarType === 'READ-ONLY';
}
exports.isCalendarReadOnly = isCalendarReadOnly;
function isCalendarPresentInSystem(selectedCalendarRemoteId, companyCalendarConnections) {
    const scheduledCalendarId = Number(selectedCalendarRemoteId);
    const calendarId = getCalendarIdForScheduledCalendar(scheduledCalendarId, companyCalendarConnections);
    if (calendarId === 'NOT_FOUND') {
        return false;
    }
    return true;
}
exports.isCalendarPresentInSystem = isCalendarPresentInSystem;
function makeFormattedEventDescription(params) {
    const { selectedServices, phoneNumbers, pricingInputValues, customFieldResponsesInputValues } = params;
    const phoneNumbersText = makePhoneNumbersText(phoneNumbers);
    const [selectedServicesText, customFieldsText, serviceLineItemsText, priceQuotedText,] = (0, helper_utils_1.getResponseTexts)(selectedServices, customFieldResponsesInputValues, pricingInputValues);
    if (selectedServicesText == null)
        throw new Error('selectedServicesText is null');
    if (customFieldsText == null)
        throw new Error('customFieldsText is null');
    if (serviceLineItemsText == null)
        throw new Error('serviceLineItemsText is null');
    if (priceQuotedText == null)
        throw new Error('priceQuotedText is null');
    return (0, helper_utils_1.joinResponseTexts)([
        selectedServicesText,
        customFieldsText,
        serviceLineItemsText,
        priceQuotedText,
        phoneNumbersText,
    ]);
}
exports.makeFormattedEventDescription = makeFormattedEventDescription;
function makePhoneNumbersText(phoneNumbers) {
    return `Phone Numbers: \n${phoneNumbers
        .map(phoneNumber => (0, utilities_1.formatE164PhoneNumberForDisplay)(phoneNumber.phoneNumber))
        .join(', ')}`;
}
function getSchedularButtonText(calendar) {
    if (calendar === 'NOT_SET') {
        return '';
    }
    return `Schedule Job on ${calendar.type.split('_').map(camel_case_1.camelCaseWord).join(' ')}`;
}
exports.getSchedularButtonText = getSchedularButtonText;
function addCrewCalEventListeners(crewCal) {
    window.addEventListener('message', function (event) {
        if (event && event.data && event.data.type) {
            switch (event.data.type) {
                case 'RC_SELECTION_CONFIRMED':
                    //notify our listeners of the selected
                    crewCal.trigger('selected', event.data.appointment);
                    break;
                case 'RC_SCHEDULING_CLOSE':
                    crewCal.trigger('close', {});
                    break;
                case 'RC_NO_APPOINTMENT_AVAILABLE':
                    crewCal.trigger('no_appointment_available', {});
                    break;
                case 'RC_APPOINTMENTS_LOADED':
                    crewCal.trigger('appointments_loaded', {});
                    break;
            }
        }
    });
}
exports.addCrewCalEventListeners = addCrewCalEventListeners;
function createCrewCalDiv() {
    const crewCalDiv = document.createElement('div');
    crewCalDiv.style.cssText =
        'height: 100vh; width:100vw; position:absolute; top:0; left: 0; z-index: 100';
    document.body.appendChild(crewCalDiv);
    return crewCalDiv;
}
exports.createCrewCalDiv = createCrewCalDiv;
function formatSchedulingDataForCrewCal(params) {
    return {
        new_appointment: getFormattedNewAppointmentDetails(params),
        height: window.innerHeight,
    };
}
exports.formatSchedulingDataForCrewCal = formatSchedulingDataForCrewCal;
function getFormattedNewAppointmentDetails(params) {
    const { company, contact, services } = params;
    const doesAnyOfTheSelectedServicesHavePricingEnabled = services.some(service => service.pricing === true);
    if (!doesAnyOfTheSelectedServicesHavePricingEnabled) {
        return {
            customer_name: makeFormattedCustomerName(contact),
            location: makeFormattedLocation(contact),
            services: makeFormattedServices(services),
            duration: (0, calculate_default_duration_for_CrewCal_schedule_1.calculateDefaultDurationForCrewCalSchedule)(company, services), //fixed duration in minutes
        };
    }
    else {
        return {
            customer_name: makeFormattedCustomerName(contact),
            location: makeFormattedLocation(contact),
            services: makeFormattedServices(services),
        };
    }
}
function makeFormattedCustomerName(contact) {
    return `${contact.firstNameInputValue} ${contact.lastNameInputValue}`;
}
exports.makeFormattedCustomerName = makeFormattedCustomerName;
function makeFormattedServices(services) {
    return services.map(companyService => {
        return {
            id: companyService.id.toString(),
            name: companyService.name,
            cost: companyService.calculatedPrice ?? 0,
        };
    });
}
exports.makeFormattedServices = makeFormattedServices;
function makeFormattedLocation(contact) {
    return prettifyAddressDetails(getContactAddressDetails(contact));
}
exports.makeFormattedLocation = makeFormattedLocation;
function prettifyAddressDetails(params) {
    const { addressLine1, addressLine2, addressCity, addressState, addressZipCode } = params;
    const formattedAddressLine2 = addressLine2 ? addressLine2 + ',' : '';
    return `${addressLine1}, ${formattedAddressLine2} ${addressCity}, ${addressState} ${addressZipCode}`;
}
function getContactAddressDetails(contact) {
    (0, common_1.assertLengthAtLeast)(contact.addressesInputValues, 1);
    const addressLine1 = contact.addressesInputValues[0].line1InputValue;
    const addressLine2 = contact.addressesInputValues[0].line2InputValue;
    const addressCity = contact.addressesInputValues[0].cityInputValue;
    const addressState = contact.addressesInputValues[0].stateInputValue;
    const addressZipCode = contact.addressesInputValues[0].zipCodeInputValue;
    return { addressLine1, addressLine2, addressCity, addressState, addressZipCode };
}
function getDateAndTimeFromTime(date) {
    const localeDateString = new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const localeTimeString = new Date(date).toLocaleTimeString();
    return {
        localeDateString,
        localeTimeString,
    };
}
exports.getDateAndTimeFromTime = getDateAndTimeFromTime;
function getCalendarIdForScheduledCalendar(calendarId, calendarConnections) {
    if (calendarConnections.length === 0) {
        return 'NOT_FOUND';
    }
    const foundCalendar = calendarConnections.find(cc => cc.id === calendarId);
    if (!foundCalendar) {
        return 'NOT_FOUND';
    }
    return foundCalendar.google_calendar_id;
}
exports.getCalendarIdForScheduledCalendar = getCalendarIdForScheduledCalendar;
exports.defaultPricingInputValues = {
    priceQuote: 0,
    serviceLineItemResponsesInputValues: [],
};
