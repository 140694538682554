"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const live_js_1 = require("lit-html/directives/live.js");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
require("../jo-button.element");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    selectedServices: [],
    services: [],
    serviceResponses: [],
    servicesNotProvided: '',
};
const cssNames = (0, css_utils_1.cssPrefixer)('jo-call-screen-services');
class JOCallScreenServices extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const originalServiceResponses = this.store.serviceResponses;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.serviceResponses !== originalServiceResponses) {
            this.store.serviceResponses.forEach((serviceResponse) => {
                this.raiseServiceSelectedEvent(true, serviceResponse.service);
            });
        }
    }
    raiseServiceSelectedEvent(checked, service) {
        (0, utilities_1.raiseCustomEvent)(this, 'serviceselected', {
            checked,
            service,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssNames('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssNames('cards-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-wrap: wrap;
                }

                .${cssNames('card-container')} {
                    width: 100%;
                    box-sizing: border-box;
                    border-radius: 1px;
                    margin-bottom: 0.25rem;
                }

                .${cssNames('card')} {
                    cursor: pointer;
                }

                .${cssNames('card')}:hover {
                    box-shadow: 0px 0px 5px 0px var(--jo-primary);
                }

                .${cssNames('card-name-and-actions-container')} {
                    padding-left: 1vmin;
                }

                .${cssNames('card-name')} {
                    font-weight: bold;
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    margin-right: 4rem;
                }

                .${cssNames('card-checkbox')} {
                    padding-right: 5px;
                }

                .${cssNames('card-header')} input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .${cssNames('card-actions-container')} {
                    padding-top: 10px;
                    display: flex;
                    gap: 1rem;
                }

                .${cssNames('card-action')} {
                    font-size: calc(7px + 1vmin);
                    color: var(--jo-text-default);
                    font-family: sans-serif;
                }

                .${cssNames('card-action-done')} {
                    color: var(--jo-primary);
                    padding-right: 5px;
                }

                .${cssNames('card-info')} {
                    color: var(--jo-dark-grey);
                    margin: 0.5rem 0 0 1rem;
                    font-family: sans-serif;
                    padding-left: 1rem;
                }

                .${cssNames('card-header')} {
                    display: flex;
                }

                .${cssNames('continue-button-container')} {
                    padding-top: 2rem;
                }

                .${cssNames('textarea')} {
                    width: 100%;
                    height: 7rem;
                    resize: none;
                    border: none;
                    outline: none;
                    font-family: sans-serif;
                    font-size: calc(7px + 1vmin);
                    color: var(--jo-text-default);
                }

                .${cssNames('description-container')} {
                    color: var(--jo-text-default);
                    font-family: sans-serif;
                    font-size: calc(7px + 1vmin);
                }

                .${cssNames('description-text')} {
                    padding-top: 0.5rem;
                    white-space: pre-line;
                }

                .${cssNames('selected-card')} {
                    background-color: var(--jo-primary-light) !important;
                }
            </style>

            <div class="${cssNames('main-container')}">
                <div class="${cssNames('cards-container')}">
                    ${state.services.map((service) => {
            const checked = state.selectedServices.some((selectedService) => selectedService.id === service.id);
            return (0, lit_html_1.html) `
                                <div
                                    class="${cssNames('card-container')}"
                                    @click=${() => this.raiseServiceSelectedEvent(!checked, service)}
                                >
                                    <div
                                        class="jo-global--card ${cssNames('card')} ${checked
                ? 'jo-call-screen-services--selected-card'
                : ''}"
                                    >
                                        <div class="${cssNames('card-header')}">
                                            <input
                                                type="checkbox"
                                                class="${cssNames('card-checkbox')}"
                                                .checked=${(0, live_js_1.live)(checked)}
                                                @input=${(e) => this.raiseServiceSelectedEvent(e.target.checked, service)}
                                            />
                                        </div>

                                        <div class="${cssNames('card-actions-container')}">
                                            <div class="${cssNames('card-name')}">
                                                &nbsp;${service.name}
                                            </div>

                                            <div
                                                class="${cssNames('description-container')}"
                                                ?hidden=${service.description === ''}
                                            >
                                                <div>
                                                    <strong>Description:</strong>
                                                </div>
                                                <div class="${cssNames('description-text')}">
                                                    ${service.description}
                                                </div>
                                            </div>

                                            <div
                                                class="${cssNames('card-action')}"
                                                ?hidden=${service.estimates === false}
                                            >
                                                <i
                                                    class="material-icons ${cssNames('card-action-done')} ${service.estimates === false
                ? 'jo-global--not-visible'
                : ''}"
                                                >
                                                    done
                                                </i>
                                                Estimates
                                            </div>

                                            <div
                                                class="${cssNames('card-action')}"
                                                ?hidden=${service.jobs === false}
                                            >
                                                <i
                                                    class="material-icons ${cssNames('card-action-done')} ${service.jobs === false
                ? 'jo-global--not-visible'
                : ''}"
                                                >
                                                    done
                                                </i>
                                                Jobs
                                            </div>

                                            <div
                                                class="${cssNames('card-action')}"
                                                ?hidden=${service.pricing === false}
                                            >
                                                <i
                                                    class="material-icons ${cssNames('card-action-done')} ${service.pricing === false
                ? 'jo-global--not-visible'
                : ''}"
                                                >
                                                    done
                                                </i>
                                                Pricing
                                            </div>

                                            <div
                                                class="${cssNames('card-action')}"
                                                ?hidden=${service.save_without_scheduling === false}
                                            >
                                                <i
                                                    class="material-icons ${cssNames('card-action-done')} ${service.save_without_scheduling === false
                ? 'jo-global--not-visible'
                : ''}"
                                                >
                                                    done
                                                </i>
                                                Save Without Scheduling
                                            </div>

                                            <div
                                                class="${cssNames('card-action')}"
                                                ?hidden=${service.forms === false}
                                            >
                                                <i
                                                    class="material-icons ${cssNames('card-action-done')} ${service.forms === false
                ? 'jo-global--not-visible'
                : ''}"
                                                >
                                                    done
                                                </i>
                                                Forms
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            `;
        })}

                    <hr style="width: 100%; margin: 1rem;" />

                    <div
                        style="width: 100%"
                        ?hidden=${state.servicesNotProvided.trim().length === 0}
                    >
                        <div class="jo-global--card">
                            <div class="${cssNames('card-name-and-actions-container')}">
                                <div class="${cssNames('card-name')}">
                                    Services We Don't Provide:
                                </div>
                                <div class="${cssNames('card-actions-container')}">
                                    <textarea
                                        class="${cssNames('textarea')}"
                                        readonly
                                        style="white-space:  pre-line"
                                    >
                                        ${state.servicesNotProvided}
                                    </textarea
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="${cssNames('continue-button-container')}">
                    <jo-button
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'Continue'}
                        @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'continued')}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-screen-services', JOCallScreenServices);
