"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDefaultDurationForCrewCalSchedule = void 0;
const is_defined_1 = require("../../../../utilities/is-defined");
function calculateDefaultDurationForCrewCalSchedule(company, selectedServices) {
    if (company === 'NOT_SET') {
        throw new Error('company is not provided');
    }
    if (selectedServices === 'NOT_SET') {
        throw new Error('selected services is not provided');
    }
    if (!(0, is_defined_1.isDefined)(company.default_job_duration) || !(0, is_defined_1.isDefined)(company.default_estimate_duration)) {
        throw new Error('one of the default durations are not provided for company');
    }
    const defaultJobDuration = company.default_job_duration === 0 ? 120 : company.default_job_duration;
    const defaultEstimateDuration = company.default_estimate_duration === 0 ? 60 : company.default_estimate_duration;
    if (doesEverySelectedCompanyOnlyHasEstimatesEnabled(selectedServices)) {
        return defaultEstimateDuration;
    }
    if (doesEverySelectedCompanyOnlyHasJobsEnabled(selectedServices)) {
        return defaultJobDuration;
    }
    return Math.max(defaultJobDuration, defaultEstimateDuration);
}
exports.calculateDefaultDurationForCrewCalSchedule = calculateDefaultDurationForCrewCalSchedule;
function doesEverySelectedCompanyOnlyHasEstimatesEnabled(selectedServices) {
    return selectedServices.every(service => service.estimates === true && service.jobs === false && service.pricing === false);
}
function doesEverySelectedCompanyOnlyHasJobsEnabled(selectedServices) {
    return selectedServices.every(service => service.jobs === true && service.estimates === false && service.pricing === false);
}
