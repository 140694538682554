"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noScrollOnFocus = void 0;
const preventDefault = (e) => {
    e.preventDefault();
};
const noScrollOnFocus = (event) => {
    const element = event.target;
    if (element) {
        const removeListener = () => {
            element.removeEventListener('wheel', preventDefault);
            element.removeEventListener('blur', removeListener);
        };
        element.addEventListener('wheel', preventDefault);
        element.addEventListener('blur', removeListener);
    }
};
exports.noScrollOnFocus = noScrollOnFocus;
