"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.doesChatComeFromCustomerSMS = exports.shouldHideNewMessageNotification = exports.getTimeSinceMessageWasCreated = exports.generateSubscriptionCallbackForNewMessageFromContact = exports.releaseAllAssignedChats = exports.changeFavicon = exports.getSortedIdsOfMessages = exports.getIndexOfMostRecentMessage = exports.getMostRecentMessageIndex = exports.getDateTimeFormatForCard = exports.getAvailableChatsFromOpenChatList = exports.getAssignedChatsFromOpenChatList = exports.getMostRecentChatMessage = exports.makeSummary = exports.getMostRecentMessageSummary = void 0;
const constants_1 = require("../../../../../services/constants");
const graphql_1 = require("../../../../../services/graphql");
const utilities_1 = require("../../../../../services/utilities");
const diff_time_1 = require("../../../../../utilities/dates-and-times/diff-time");
const chat_info_for_session_storage_1 = require("../../../jo-call-screen/utilities/chat-info-for-session-storage");
const chat_duration_event_utils_1 = require("../../chat-utils/chat-duration-event-utils");
const queries_and_mutations_1 = require("../queries-and-mutations");
function getMostRecentMessageSummary(chat) {
    const mostRecentMessage = getMostRecentChatMessage(chat);
    if (mostRecentMessage === undefined) {
        return '(Unknown message)';
    }
    return makeSummary(mostRecentMessage);
}
exports.getMostRecentMessageSummary = getMostRecentMessageSummary;
function makeSummary(mostRecentMessage) {
    const summayCharacterLength = 28;
    return mostRecentMessage.body.substring(0, summayCharacterLength) + '...';
}
exports.makeSummary = makeSummary;
function getMostRecentChatMessage(chat) {
    return chat.messages[getMostRecentMessageIndex(chat.messages)];
}
exports.getMostRecentChatMessage = getMostRecentChatMessage;
function getAssignedChatsFromOpenChatList(openChats, userId) {
    return openChats.filter(c => {
        if (c.currently_chatting_user && c.currently_chatting_user.id === userId) {
            return c;
        }
    });
}
exports.getAssignedChatsFromOpenChatList = getAssignedChatsFromOpenChatList;
function getAvailableChatsFromOpenChatList(openChats) {
    const availableChats = getAvailableChats(openChats);
    return sortAvailableChatsByPriority(availableChats);
}
exports.getAvailableChatsFromOpenChatList = getAvailableChatsFromOpenChatList;
function sortAvailableChatsByPriority(availableChats) {
    return availableChats.sort((a, b) => {
        if (a.status === 'RELEASED_BY_JILL' && b.status === 'OPEN') {
            return -1;
        }
        if (a.status === b.status) {
            return 0;
        }
        return 1;
    });
}
function getAvailableChats(openChats) {
    return new Array(...openChats.filter(c => {
        if (!c.currently_chatting_user) {
            return c;
        }
    }));
}
function getDateTimeFormatForCard(isoDate) {
    const dateString = (0, utilities_1.makeDateTwoChar)(isoDate);
    const timeString = new Date(isoDate).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    });
    return dateString + ' ' + timeString;
}
exports.getDateTimeFormatForCard = getDateTimeFormatForCard;
function getMostRecentMessageIndex(messages) {
    let sortedIds = getSortedIdsOfMessages(messages);
    const mostRecentId = sortedIds[sortedIds.length - 1];
    if (mostRecentId == null) {
        throw new Error('mostRecentId is null');
    }
    const index = getIndexOfMostRecentMessage(messages, mostRecentId);
    return index;
}
exports.getMostRecentMessageIndex = getMostRecentMessageIndex;
function getIndexOfMostRecentMessage(messages, mostRecentId) {
    return messages.findIndex(message => {
        return message.id === mostRecentId;
    });
}
exports.getIndexOfMostRecentMessage = getIndexOfMostRecentMessage;
function getSortedIdsOfMessages(messages) {
    let sortedIds = messages.map(m => {
        return m.id;
    });
    sortedIds.sort((a, b) => {
        return a - b;
    });
    return sortedIds;
}
exports.getSortedIdsOfMessages = getSortedIdsOfMessages;
function changeFavicon(newMessage) {
    const faviconLink = document.getElementById('favicon-link');
    (0, utilities_1.assertIsDefined)(faviconLink);
    if (newMessage) {
        faviconLink.href = '../../../images/jo-favicon-new-message.png';
    }
    else {
        faviconLink.href = '../../../images/jo-favicon.png';
    }
}
exports.changeFavicon = changeFavicon;
function releaseAllAssignedChats(assignedChats, userId) {
    assignedChats.forEach(async (chat) => {
        if (chat.currently_chatting_user) {
            await (0, chat_info_for_session_storage_1.fetchChatStateInfoFromStorageAndSaveToDB)(chat.id);
            await (0, queries_and_mutations_1.sendChatBackToQueue)(chat.id, chat.currently_chatting_user?.id);
            await (0, chat_duration_event_utils_1.createChatDurationEvent)({
                type: 'RELEASED_BY_JILL',
                userId,
                chatId: chat.id,
            });
        }
    });
}
exports.releaseAllAssignedChats = releaseAllAssignedChats;
const generateSubscriptionCallbackForNewMessageFromContact = (chatId) => {
    return async () => {
        return await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                subscription ($chatId: Int!) {
                    chatMessageCreatedByContact(chatId: $chatId)
                }
            `, () => new Audio(constants_1.NEW_CHAT_MESSAGE_NOTIFICATION_SOUND_URL).play(), {
            chatId,
        });
    };
};
exports.generateSubscriptionCallbackForNewMessageFromContact = generateSubscriptionCallbackForNewMessageFromContact;
function getTimeSinceMessageWasCreated(mostRecentMessage) {
    const currentTime = new Date();
    const messageCreationTime = new Date(mostRecentMessage.created_at);
    return (0, diff_time_1.diffTime)(currentTime, messageCreationTime);
}
exports.getTimeSinceMessageWasCreated = getTimeSinceMessageWasCreated;
function shouldHideNewMessageNotification(chat) {
    const mostRecentMessage = chat.messages[getMostRecentMessageIndex(chat.messages)];
    if (!mostRecentMessage) {
        return true;
    }
    if (mostRecentMessage.status === 'NEW_MESSAGE' &&
        mostRecentMessage.source !== 'JILL_DASHBOARD') {
        return false;
    }
    return true;
}
exports.shouldHideNewMessageNotification = shouldHideNewMessageNotification;
function doesChatComeFromCustomerSMS(chatSource) {
    return chatSource === 'CUSTOMER_SMS';
}
exports.doesChatComeFromCustomerSMS = doesChatComeFromCustomerSMS;
