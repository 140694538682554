"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootReducer = void 0;
const state_1 = require("./state");
const utilities_1 = require("../../../../services/utilities");
const chat_info_for_session_storage_1 = require("../utilities/chat-info-for-session-storage");
function RootReducer(state, action) {
    if (action.type === 'ACTION_ITEM_ID_SET') {
        return {
            ...state,
            actionItem: action.actionItem,
            actionItemId: action.actionItemId,
            settingUpInitialCallScreenInfoHasInitiated: true,
            collectingDuration: action.collectingDuration,
            companyForCallInstructions: action.companyForCallInstructions,
            companyForCollection: action.companyForCollection,
            companyForCompanyInfo: action.companyForCompanyInfo,
            companyForTransferInstructions: action.companyForTransferInstructions,
            companyId: action.companyId,
            contact: action.contact,
            contactForActivityLog: Symbol('NOT_SET'),
            followupRequestDateInputValue: action.followupRequestDateInputValue,
            jillsToTransferTo: action.jillsToTransferTo,
            previousCustomFieldResponses: action.previousCustomFieldResponses,
            previousLineItemResponses: action.previousLineItemResponses,
            previousServiceResponses: action.previousServiceResponses,
            priceQuote: action.priceQuote,
            showContactActivityLog: false,
        };
    }
    if (action.type === 'ANSWER_CALL') {
        return {
            ...state,
            callAnswered: action.callAnswered,
            collectingDuration: action.collectingDuration,
            holdButtonEnabled: action.holdButtonEnabled,
        };
    }
    if (action.type === 'CLEAR_CONTACT') {
        return {
            ...state,
            contact: Symbol('NOT_SET'),
            previousCustomFieldResponses: [],
            previousServiceResponses: [],
            priceQuote: 0,
            selectedServices: [],
            previousLineItemResponses: [],
        };
    }
    if (action.type === 'CLOSE_SUBSCRIPTIONS_IF_NECESSARY') {
        return {
            ...state,
            subscriptions: {
                jillAndUserReconnectedAfterTransferDeclineSubscription: 'NOT_SET',
                incomingTransferResolvedSubscription: 'NOT_SET',
                transferCallStatusSubscription: 'NOT_SET',
                userCallStatusSubscription: 'NOT_SET',
            },
        };
    }
    if (action.type === 'END_CALL') {
        return {
            ...state,
            callEnded: action.callEnded,
        };
    }
    if (action.type === 'HIDE_TRANSFER_INITIATED_BOX') {
        return {
            ...state,
            showTransferInitiatedBox: action.showTransferInitiatedBox,
            transferState: action.transferState,
        };
    }
    if (action.type === 'HOLD_CLICKED') {
        return {
            ...state,
            onHold: action.onHold,
            collectingDuration: action.collectingDuration,
        };
    }
    if (action.type === 'INCOMING_TRANSFER_COMPLETED') {
        return {
            ...state,
            incomingTransferCompleted: true,
        };
    }
    if (action.type === 'JILL_AND_USER_RECONNECTED_AFTER_TRANSFER_DECLINE') {
        return {
            ...state,
            subscriptions: {
                ...state.subscriptions,
                jillAndUserReconnectedAfterTransferDeclineSubscription: 'NOT_SET',
            },
        };
    }
    if (action.type === 'SAVE_CONTACT') {
        return {
            ...state,
            contact: action.contact,
        };
    }
    if (action.type === 'SERVICE_SELECTED') {
        if (action.checked === true) {
            const newSelectedServicesUnordered = [
                ...state.selectedServices,
                action.service,
            ];
            const newSelectedServicesOrdered = [...newSelectedServicesUnordered].sort((service1, service2) => {
                if (service1.created_at > service2.created_at) {
                    return 1;
                }
                if (service1.created_at < service2.created_at) {
                    return -1;
                }
                return 0;
            });
            return {
                ...state,
                selectedServices: newSelectedServicesOrdered,
            };
        }
        else {
            return {
                ...state,
                selectedServices: state.selectedServices.filter((selectedService) => {
                    return selectedService.id !== action.service.id;
                }),
            };
        }
    }
    if (action.type === 'SET_PREVIOUS_RESPONSES') {
        return {
            ...state,
            previousCustomFieldResponses: action.previousCustomFieldResponses,
            previousLineItemResponses: action.previousLineItemResponses,
            previousServiceResponses: action.previousServiceResponses,
        };
    }
    if (action.type === 'SET_PROPS') {
        return (0, utilities_1.setPropsReducer)(state, action);
    }
    if (action.type === 'SHOW_TRANSFER_INITIATED_BOX') {
        return {
            ...state,
            showTransferInitiatedBox: action.showTransferInitiatedBox,
            transferRecipientName: action.transferRecipientName,
            transferState: action.transferState,
            isTransferRecipientJillOnACall: false,
        };
    }
    if (action.type === 'TRANSFER_INITIATE') {
        return {
            ...state,
            transferCallSid: action.transferCallSid,
            transferId: action.transferId,
            transferState: action.transferState,
            transferTo: action.transferTo,
        };
    }
    if (action.type === 'TRANSFER_INITIATE_FAILED') {
        return {
            ...state,
            showTransferInitiatedBox: false,
            transferCallSid: 'NOT_SET',
            transferCallStatus: 'NOT_SET',
            transferRecipientName: '',
            transferState: 'NOT_STARTED',
        };
    }
    if (action.type === 'TRANSFER_INITIATE_CANCEL_JILL_ON_ANOTHER_CALL') {
        return {
            ...state,
            showTransferInitiatedBox: false,
            transferCallSid: 'NOT_SET',
            transferCallStatus: 'NOT_SET',
            transferRecipientName: '',
            transferState: 'NOT_STARTED',
            isTransferRecipientJillOnACall: true,
        };
    }
    if (action.type === 'TRANSFER_DECLINE') {
        return {
            ...state,
            transferCallSid: 'NOT_SET',
            transferCallStatus: 'NOT_SET',
            transferRecipientName: '',
            transferState: 'NOT_STARTED',
        };
    }
    if (action.type === 'TRANSFER_COMPLETE') {
        return {
            ...state,
            externalTransferSent: action.externalTransferSent,
            internalTransferSent: action.internalTransferSent,
            transferState: 'COMPLETED',
        };
    }
    if (action.type === 'LOAD_CURRENT_CHAT') {
        if (action.props.chatId === 'NOT_SET' || !action.props.chatId) {
            return (0, utilities_1.setPropsReducer)(state, { ...action, type: 'SET_PROPS' });
        }
        const sessionStorageResult = (0, chat_info_for_session_storage_1.getChatStateInfoSessionStorage)(action.props.chatId);
        if (sessionStorageResult === 'NOT_FOUND') {
            const newState = createNewChatStateSessionStorage(state, action);
            return newState;
        }
        return sessionStorageResult;
    }
    if (action.type === 'UPDATE_PREVIOUS_CHAT') {
        (0, chat_info_for_session_storage_1.setChatStateInfoSessionStorage)(action.chatId, action.prevState);
    }
    if (action.type === 'UPDATE_DURATIONS') {
        return {
            ...state,
            actionItemDurationMinutes: action.actionItemDurationMinutes,
            actionItemDurationSeconds: action.actionItemDurationSeconds,
            actionItemDurationTotal: action.actionItemDurationTotal,
            callDurationMinutes: action.callDurationMinutes,
            callDurationSeconds: action.callDurationSeconds,
            callDurationTotal: action.callDurationTotal,
        };
    }
    if (action.type === 'UPDATE_PRICING') {
        const priceMinimums = state.selectedServices.map((service) => {
            return service.price_minimum;
        });
        const largestPriceMinimum = Math.max(...priceMinimums);
        const price = action.pricingInputValues === 'NOT_SET'
            ? 0
            : action.pricingInputValues.serviceLineItemResponsesInputValues.reduce((result, serviceLineItemResponseInputValue) => {
                const parsedQty = serviceLineItemResponseInputValue.quantity;
                const qty = isNaN(parsedQty) ? 0 : parsedQty;
                const updatedPrice = result +
                    qty * serviceLineItemResponseInputValue.service_line_item.price;
                return updatedPrice;
            }, 0);
        const selectedServicesWithCalculatedPrice = state.selectedServices.map(service => {
            return formatSelectedService(service, action);
        });
        if (price > 0 && price < largestPriceMinimum) {
            return {
                ...state,
                selectedServicesWithCalculatedPrice: selectedServicesWithCalculatedPrice,
                calculatedPrice: largestPriceMinimum,
                minimumPriceApplied: true,
                pricingInputValues: action.pricingInputValues,
            };
        }
        else {
            return {
                ...state,
                selectedServicesWithCalculatedPrice: selectedServicesWithCalculatedPrice,
                calculatedPrice: price,
                minimumPriceApplied: false,
                pricingInputValues: action.pricingInputValues,
            };
        }
    }
    if (action.type === 'RESET_STATE') {
        if (state.callScreenMode === 'CHAT') {
            return {
                ...state_1.InitialJOCallScreenState,
                callScreenMode: 'CHAT',
            };
        }
        return state_1.InitialJOCallScreenState;
    }
    return state;
}
exports.RootReducer = RootReducer;
function createNewChatStateSessionStorage(state, action) {
    const newState = (0, utilities_1.setPropsReducer)(state, { ...action, type: 'SET_PROPS' });
    (0, chat_info_for_session_storage_1.setChatStateInfoSessionStorage)(newState.chatId, newState);
    return newState;
}
function formatSelectedService(service, action) {
    const actualCalculatedPrice = calculateActualPrice(service, action);
    return {
        ...service,
        calculatedPrice: Math.max(service.price_minimum, actualCalculatedPrice) / 100,
    };
}
function calculateActualPrice(service, action) {
    let actualCalculatedPrice = 0;
    for (let i = 0; i < service.line_items.length; i++) {
        const lineItem = service.line_items[i];
        if (lineItem == null) {
            throw new Error('lineItem is null');
        }
        const serviceLineItemPrice = lineItem.price;
        const quantity = extractQuantityFromPricingResponses(action.pricingInputValues, service, i);
        actualCalculatedPrice += quantity * serviceLineItemPrice;
    }
    return actualCalculatedPrice;
}
function extractQuantityFromPricingResponses(pricingInputValues, service, i) {
    return pricingInputValues === 'NOT_SET'
        ? 0
        : getQuantityFromServiceLineItem(pricingInputValues, service, i);
}
function getQuantityFromServiceLineItem(pricingInputValues, service, i) {
    const serviceLineItem = findLineItemForSelectedService(pricingInputValues.serviceLineItemResponsesInputValues, service, i);
    return serviceLineItem?.quantity ?? 0;
}
function findLineItemForSelectedService(serviceLineItemResponsesInputValues, service, i) {
    return serviceLineItemResponsesInputValues.find(serviceLineItemResponsesInputValue => {
        const lineItem = service.line_items[i];
        if (lineItem == null) {
            throw new Error('lineItem is null');
        }
        return serviceLineItemResponsesInputValue.service_line_item.id === lineItem.id;
    });
}
