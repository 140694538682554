"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.publishContactConnectedToChatEvent = exports.sendChatClosedByJillEvent = exports.updateChatStatusToClosed = exports.assignJillToActionItem = void 0;
const graphql_1 = require("../../../../../../services/graphql");
const error_handling_1 = require("../../../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../../../utilities/environment/mapped-env-variables");
async function assignJillToActionItem(actionItemId, jillId) {
    if (actionItemId === 'NOT_SET') {
        return {
            succeeded: true,
        };
    }
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($actionItemId: Int!, $jillId: Int!) {
                updateAction_items(input: {id: $actionItemId, user: {connect: {id: $jillId}}}) {
                    id
                }
            }
        `, {
        actionItemId,
        jillId,
    });
    if (result.succeeded === false) {
        return result;
    }
    return {
        succeeded: true,
    };
}
exports.assignJillToActionItem = assignJillToActionItem;
async function updateChatStatusToClosed(chatId) {
    try {
        if (!chatId) {
            return {
                succeeded: false,
                userMessage: 'Error Changing Chat Status to Closed',
                developerMessage: 'updateStatusToClosed chatId is not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateChatsInput!) {
                    updateChats(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                id: chatId,
                status: 'CLOSED',
            },
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingID = gqlResult.value.data.updateChats.id;
        return {
            succeeded: true,
            value: resultingID,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateCurrentlyChattingJill', error);
    }
}
exports.updateChatStatusToClosed = updateChatStatusToClosed;
async function sendChatClosedByJillEvent(chatId) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($chatId: Int!) {
                    publishChatClosedByJillMessage(chatId: $chatId)
                }
            `, {
            chatId,
        });
    }
    catch (error) {
        throw new Error('sendChatClosedByJillEvent Error:' + error);
    }
}
exports.sendChatClosedByJillEvent = sendChatClosedByJillEvent;
async function publishContactConnectedToChatEvent(chatId, contactId) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($chatId: Int!, $contactId: Int!) {
                    publishContactConnectedToChatEvent(chatId: $chatId, contactId: $contactId)
                }
            `, {
            chatId,
            contactId,
        });
    }
    catch (error) {
        throw new Error('publishContactConnectedToChatEvent chatId:' +
            chatId +
            ' contactId: ' +
            contactId +
            ' ' +
            error);
    }
}
exports.publishContactConnectedToChatEvent = publishContactConnectedToChatEvent;
