"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateLastSeenActivelyChattingTimeStamp = exports.updateCurrentlyChattingJill = exports.getUserAssignedPods = exports.fetchOpenChatsForPods = exports.sendChatBackToQueue = exports.markChatMessageAsRead = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function markChatMessageAsRead(messageId) {
    try {
        if (!messageId) {
            return {
                succeeded: false,
                userMessage: 'Error Marking Chat as Read',
                developerMessage: 'markChatMessageAsRead() inputValues are not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateChat_messagesInput!) {
                    updateChat_messages(input: $input) {
                        chat {
                            id
                        }
                    }
                }
            `, {
            input: {
                id: messageId,
                status: 'READ',
            },
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingID = gqlResult.value.data.updateChat_messages.chat.id;
        return {
            succeeded: true,
            value: resultingID,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('markChatMessageAsRead()', error);
    }
}
exports.markChatMessageAsRead = markChatMessageAsRead;
async function sendChatBackToQueue(chatId, userIdToDisconnect) {
    try {
        const result = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($chatId: Int!, $userIdToDisconnect: Int!) {
                    chat: updateChats(
                        input: {
                            id: $chatId
                            currently_chatting_user: {disconnect: {id: $userIdToDisconnect}}
                            status: RELEASED_BY_JILL
                        }
                    ) {
                        id
                        contact {
                            id
                        }
                        messages {
                            id
                            contact {
                                first_name
                                last_name
                            }
                            source
                        }
                    }
                }
            `, {
            chatId,
            userIdToDisconnect,
        });
        return result.data;
    }
    catch (error) {
        throw new Error('sendChatBackToQueue Error: ' + error);
    }
}
exports.sendChatBackToQueue = sendChatBackToQueue;
async function fetchOpenChatsForPods(podIds) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute(
        // Removed filtering by pods for now, but its likely they will want this again in the future. So I am just
        //leaving it commented out for now.
        (0, graphql_1.gql) `
                query {
                    #($podIds: [Int!]) {
                    findChats(
                        filter: {
                            # pod_id: {in: $podIds}
                            status: {in: ["OPEN", "ONGOING", "RELEASED_BY_JILL"]}
                        }
                        orderBy: {field: "updated_at", order: ASC}
                    ) {
                        items {
                            id
                            created_at
                            updated_at
                            temporary_chat_state
                            twilio_conversation_sid
                            source
                            company {
                                id
                                name
                            }
                            pod {
                                id
                                name
                            }
                            contact {
                                id
                                first_name
                                last_name
                                email
                                addresses {
                                    id
                                    line_1
                                    line_2
                                    city
                                    county
                                    postal_code
                                    province
                                    state
                                    zip_code
                                }
                                company {
                                    id
                                }
                                company_name
                                phone_number
                                phone_numbers {
                                    id
                                    number
                                }
                                sales
                                status
                                where_did_you_hear_about_us
                            }
                            messages {
                                id
                                body
                                updated_at
                                created_at
                                status
                                source
                                contact {
                                    id
                                    first_name
                                    last_name
                                }
                                user {
                                    id
                                    first_name
                                    last_name
                                }
                            }
                            currently_chatting_user {
                                id
                            }
                            status
                        }
                    }
                }
            `, {
        // podIds,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const chatList = gqlResult.value.data.findChats.items;
        return {
            succeeded: true,
            value: chatList,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchOpenChatsForPods', error);
    }
}
exports.fetchOpenChatsForPods = fetchOpenChatsForPods;
async function getUserAssignedPods(userId) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($userId: Int!) {
                    user: getUsers(id: $userId) {
                        id
                        pods {
                            id
                            name
                        }
                        float_pods {
                            id
                            name
                        }
                    }
                }
            `, {
            userId,
        });
        if (result.succeeded === false)
            return result;
        const podIds = result.value.data.user;
        return {
            succeeded: true,
            value: podIds,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getUserAssignedPods', error);
    }
}
exports.getUserAssignedPods = getUserAssignedPods;
async function updateCurrentlyChattingJill(userId, chatId) {
    try {
        if (!userId || !chatId) {
            return {
                succeeded: false,
                userMessage: 'Error Assigning Chat to Personal Queue',
                developerMessage: 'updateCurrentlyChattingJill inputValues are not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateChatsInput!) {
                    updateChats(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                id: chatId,
                currently_chatting_user: {
                    connect: {
                        id: userId,
                    },
                },
                users: {
                    connect: {
                        id: userId,
                    },
                },
                status: 'ONGOING',
            },
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingID = gqlResult.value.data.updateChats.id;
        return {
            succeeded: true,
            value: resultingID,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-chat/jo-chat-sidebar/queries-and-mutations.ts --> updateCurrentlyChattingJill', error);
    }
}
exports.updateCurrentlyChattingJill = updateCurrentlyChattingJill;
async function updateLastSeenActivelyChattingTimeStamp(userId, date) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $date: DateTime!) {
                    updateUsers(input: {id: $userId, last_seen_actively_chatting_at: $date}) {
                        id
                    }
                }
            `, {
            userId: userId,
            date: date.toISOString(),
        });
    }
    catch (error) {
        throw new Error(`jo-chat/jo-chat-sidebar/queries-and-mutations.ts --> updateLastSeenActivelyChattingTimeStamp(): ${error}`);
    }
}
exports.updateLastSeenActivelyChattingTimeStamp = updateLastSeenActivelyChattingTimeStamp;
