"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.publishChatQueueUpdatedMessage = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function publishChatQueueUpdatedMessage() {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation {
                    publishChatQueueUpdatedMessage
                }
            `);
    }
    catch (error) {
        throw `Error publishChatQueueUpdatedMessage(): ${error}`;
    }
}
exports.publishChatQueueUpdatedMessage = publishChatQueueUpdatedMessage;
