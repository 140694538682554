"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const fetch_contacts_1 = require("../../../utilities/contacts/fetch-contacts");
const common_1 = require("@augment-vir/common");
const jo_contact_merge_styles_1 = require("./jo-contact-merge-styles");
const jo_contact_merge_templates_1 = require("./jo-contact-merge-templates");
require("../jo-button.element");
const jo_contact_merge_state_1 = require("./jo-contact-merge-state");
const jo_contact_merge_gql_1 = require("./jo-contact-merge-gql");
const queries_1 = require("../jo-activity-log/queries");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
class JOContactMerge extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)({
            contactIds: [],
            loadedContacts: [],
            loadError: undefined,
            selectedContactIndex: -1,
            saving: false,
            saveError: '',
            actionItemLengths: undefined,
        }, state => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        this.store.dispatch({
            type: 'SET_PROPS',
            props: {
                ...props,
                loadedContacts: props.loadedContacts ?? this.store.getState().loadedContacts,
            },
        });
        const state = this.store.getState();
        if ((0, jo_contact_merge_state_1.doWeNeedToUpdateLoadedContacts)(state)) {
            this.store.loadError = undefined;
            this.updateActionItemsLengths(state.contactIds);
            this.loadContacts(state.contactIds);
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            ${jo_contact_merge_styles_1.joContactMergeStyles}
            <h2>Merge contacts</h2>
            <p class="prompt">Choose which contact should remain:</p>
            <div class="table-wrapper">
                ${(0, jo_contact_merge_templates_1.getMainContactMergeChildTemplate)({ elementRoot: this, state, store: this.store })}
            </div>
            <footer>
                <span title=${state.saveError || ''} class="save-error-message">
                    ${state.saveError ??
            (0, lit_html_1.html) `
                        <br />
                        <br />
                    `}
                </span>
                <jo-button
                    .type=${'SECONDARY_BUTTON_1'}
                    .text=${'cancel'}
                    .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                    @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'closeoverlay')}
                ></jo-button>
                <jo-button
                    .type=${'ACTION_BUTTON_1'}
                    .text=${state.saving ? 'Saving...' : 'Save'}
                    .disabled=${state.saving || state.selectedContactIndex === -1}
                    .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                    @joButtonClick=${() => this.triggerSave(state)}
                ></jo-button>
            </footer>
        `;
    }
    async triggerSave(state) {
        if (!state.saving) {
            try {
                this.store.saving = true;
                this.store.saveError = '';
                const idToKeep = state.contactIds[state.selectedContactIndex];
                if (idToKeep == null)
                    throw new Error('idToKeep is null');
                const idsToDiscard = state.contactIds.filter(contactId => contactId !== idToKeep);
                await Promise.all(idsToDiscard.map(async (idToDiscard) => {
                    await (0, jo_contact_merge_gql_1.mergeContactsOnServer)({
                        keepThisContactId: idToKeep,
                        discardThisContactId: idToDiscard,
                    });
                }));
                const contacts = await (0, fetch_contacts_1.fetchContactsById)([idToKeep], jo_contact_merge_gql_1.contactsQueryShape);
                (0, common_1.assertLengthAtLeast)(contacts, 1);
                const resultingContact = contacts[0];
                (0, utilities_1.raiseCustomEvent)(this, 'contactmerged', resultingContact);
                (0, utilities_1.raiseCustomEvent)(this, 'closeoverlay');
            }
            catch (error) {
                console.error({ contactMergeSaveError: error });
                this.store.saveError = (0, common_1.extractErrorMessage)(error);
            }
            finally {
                this.store.saving = false;
            }
        }
    }
    loadContacts(contactIds) {
        this.store.loadedContacts = (0, fetch_contacts_1.fetchContactsById)(contactIds, jo_contact_merge_gql_1.contactsQueryShape);
        this.store.loadedContacts
            .then(result => {
            this.store.loadedContacts = result;
        })
            .catch(error => {
            console.error({ loadContactsForMergingError: error });
            this.store.loadError = {
                idsForError: contactIds,
                errorMessage: error.message ?? JSON.stringify(error),
            };
        });
    }
    updateActionItemsLengths(contactIds) {
        const actionItemLengthsPromise = this.fetchActionItemLengths();
        this.store.actionItemLengths = {
            contactIds,
            actionItemLength: actionItemLengthsPromise,
        };
        actionItemLengthsPromise.then(contactActionItemCounts => this.storeActionItemCounts(contactIds, contactActionItemCounts));
    }
    storeActionItemCounts(contactIds, contactActionItemCounts) {
        const currentActionItemLengthsObject = this.store.actionItemLengths;
        if (currentActionItemLengthsObject &&
            (0, common_1.areJsonEqual)(currentActionItemLengthsObject.contactIds, contactIds)) {
            this.store.actionItemLengths = {
                ...currentActionItemLengthsObject,
                actionItemLength: contactActionItemCounts,
            };
        }
    }
    fetchActionItemLengths() {
        return Promise.all(this.store.contactIds.map(async (contactId) => {
            const actionItemsResult = await (0, queries_1.fetchActionItems)(contactId);
            (0, utilities_1.assertSucceeded)(actionItemsResult);
            return actionItemsResult.value.length;
        }));
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-contact-merge', JOContactMerge);
