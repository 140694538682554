"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContactForSameCallerId = exports.getCompanyWithAddressFoundByOccupantId = void 0;
const fetch_address_by_occupant_id_1 = require("./queries/fetch-address-by-occupant-id");
async function getCompanyWithAddressFoundByOccupantId(company) {
    const addressFoundByOccupantIdResult = await (0, fetch_address_by_occupant_id_1.fetchAddressByOccupantIdFromCompanyId)(company.id);
    if (addressFoundByOccupantIdResult.succeeded === false) {
        return addressFoundByOccupantIdResult;
    }
    const address = addressFoundByOccupantIdResult.value;
    if (address === 'NOT_FOUND') {
        return {
            succeeded: true,
            value: company,
        };
    }
    const companyWithAddress = {
        ...company,
        address,
    };
    return {
        succeeded: true,
        value: companyWithAddress,
    };
}
exports.getCompanyWithAddressFoundByOccupantId = getCompanyWithAddressFoundByOccupantId;
function getContactForSameCallerId(actionItem, contact, callScreenMode) {
    if (callScreenMode === 'INCOMING_NEW_ACTION_ITEM' &&
        actionItem.company !== null &&
        actionItem.company !== undefined &&
        actionItem.company.same_caller_id === true) {
        return Symbol('NOT_SET');
    }
    else {
        return contact;
    }
}
exports.getContactForSameCallerId = getContactForSameCallerId;
