"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSaveContactAction = void 0;
const mutations_1 = require("../../../../jo-contact/mutations");
async function createSaveContactAction(actionItemId, callId, companyId, contactInputValues) {
    const saveContactResult = await (0, mutations_1.saveContact)(companyId, contactInputValues, actionItemId, callId);
    if (saveContactResult.succeeded === false) {
        return saveContactResult;
    }
    const contact = saveContactResult.value;
    return {
        succeeded: true,
        value: {
            type: 'SAVE_CONTACT',
            contact,
        },
    };
}
exports.createSaveContactAction = createSaveContactAction;
