"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-action-item-messages/jo-action-item-messages");
require("../jo-action-item-note");
require("../jo-action-item-preview");
require("../jo-button.element");
require("../jo-chat/jo-chat-transcript/jo-chat-transcript");
require("../jo-input");
require("../jo-loading-text");
const css_1 = require("./css");
const helper_utils_1 = require("./helper-utils");
const queries_1 = require("./queries");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    actionItems: [],
    authenticatedUser: 'NOT_SET',
    allowActionItemSelect: false,
    contact: Symbol('NOT_SET'),
    isMobileDevice: false,
    loading: true,
};
class JOActivityLog extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (typeof this.store.contact !== 'symbol') {
            this.fetchAndSetActionItems(this.store.contact.id);
        }
    }
    async fetchAndSetActionItems(contactId) {
        this.store.loading = true;
        const actionItemsResult = await (0, queries_1.fetchActionItems)(contactId);
        (0, utilities_1.assertSucceeded)(actionItemsResult, utilities_1.handleError);
        const actionItems = actionItemsResult.value;
        this.store.actionItems = actionItems;
        this.store.loading = false;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${(0, css_1.getStyles)(state)}

            <div class="main-container">
                ${(0, utilities_1.isSet)(state.contact)
            ? (0, lit_html_1.html) `
                          <h2 class="activity-log-header">
                              Activity for ${state.contact.first_name} ${state.contact.last_name}
                          </h2>
                      `
            : ''}

                <div class="action-items-container">
                    ${state.loading === true
            ? (0, lit_html_1.html) `
                              <jo-loading-text .text=${'Loading activity'}></jo-loading-text>
                          `
            : (0, helper_utils_1.areThereNoActionItemNotes)(state.actionItems) === true
                ? (0, lit_html_1.html) `
                              <h2 class="action-item-header">No activity to show</h2>
                          `
                : state.actionItems.map((actionItem) => {
                    if (actionItem.action_item_notes.length === 0) {
                        return '';
                    }
                    return (0, lit_html_1.html) `
                                  <div class="action-item-container">
                                      <div class="action-item">
                                          <div class="action-item-upper-container">
                                              <h4>Title: ${actionItem.title}</h4>

                                              ${actionItem.chat
                        ? (0, lit_html_1.html) `
                                                        <jo-chat-transcript
                                                            .props=${{
                            authenticatedUser: this.store.authenticatedUser,
                            actionItemContact: actionItem.contact,
                            chat: actionItem.chat,
                        }}
                                                        ></jo-chat-transcript>
                                                    `
                        : ``}

                                              <jo-button
                                                  style="padding-top: 1rem;"
                                                  ?hidden=${state.allowActionItemSelect === false}
                                                  .type=${'ICON'}
                                                  .text=${'edit'}
                                                  @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'activitylogactionitemselected', { actionItemId: actionItem.id })}
                                              ></jo-button>
                                          </div>

                                          <jo-action-item-messages
                                              .props=${{
                        authenticatedUser: this.store.authenticatedUser,
                        isMobileDevice: this.store.isMobileDevice,
                        actionItem: actionItem,
                    }}
                                          ></jo-action-item-messages>
                                      </div>
                                  </div>
                              `;
                })}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-activity-log', JOActivityLog);
