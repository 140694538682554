"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOActionItemFlagCardStyles = void 0;
const lit_html_1 = require("lit-html");
exports.JOActionItemFlagCardStyles = (0, lit_html_1.html) `
    <style>
        .main-container {
            width: 100%;
            box-sizing: border-box;
            border: 2px solid red;
            font-size: 0.9rem;
        }

        .header-row {
            display: flex;
            justify-content: space-between;
            padding-bottom: 0.5rem;
        }

        .addressed-container {
            color: green;
            display: flex;
            align-items: center;
            font-size: 0.8rem;
        }

        .addressal-info {
            color: green;
            padding-top: 0.25rem;
        }
    </style>
`;
