"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMostRecentCallInfoFromLocalStorage = exports.setValueOnCallInfoForLocalStorage = exports.createAndSetNewCallInfoForLocalStorage = exports.CallInfoForLocalStorage = void 0;
const mostRecentCallInfo = 'mostRecentCallInfo';
class CallInfoForLocalStorage {
    constructor(callId) {
        this.answerCallClicked = false;
        this.endCallClicked = false;
        this.saveActionItemClicked = false;
        this.callId = callId;
    }
}
exports.CallInfoForLocalStorage = CallInfoForLocalStorage;
function createAndSetNewCallInfoForLocalStorage(callId) {
    window.localStorage.setItem(mostRecentCallInfo, JSON.stringify(new CallInfoForLocalStorage(callId)));
}
exports.createAndSetNewCallInfoForLocalStorage = createAndSetNewCallInfoForLocalStorage;
function setValueOnCallInfoForLocalStorage(key, value) {
    const mostRecentCallInfo = JSON.parse(window.localStorage.getItem('mostRecentCallInfo') || '{}');
    window.localStorage.setItem('mostRecentCallInfo', JSON.stringify({
        ...mostRecentCallInfo,
        [key]: value,
    }));
}
exports.setValueOnCallInfoForLocalStorage = setValueOnCallInfoForLocalStorage;
function getMostRecentCallInfoFromLocalStorage() {
    const result = window.localStorage.getItem(mostRecentCallInfo);
    if (result === null)
        return 'NOT_FOUND';
    return JSON.parse(result);
}
exports.getMostRecentCallInfoFromLocalStorage = getMostRecentCallInfoFromLocalStorage;
