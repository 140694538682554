"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCreateActionItemNoteMutationInputs = void 0;
const helper_utils_1 = require("../../../../jo-crew-cal-scheduling/helper-utils");
const helper_utils_2 = require("./helper-utils");
function getCreateActionItemNoteMutationInputs(params) {
    const { callId, jillId, noteText, selectedServices, previousActionItemNotes, customFieldResponsesInputValues, pricingInputValues, scheduledAppointmentDetails, } = params;
    let generatedResponsesText = (0, helper_utils_2.createCustomResponsesText)(selectedServices, customFieldResponsesInputValues, pricingInputValues);
    const createActionItemNoteInput = makeBaseCreateActionItemInputForTableConnections(jillId, callId);
    const noteTextInput = {
        ...createActionItemNoteInput,
        body: noteText,
    };
    const generatedNoteTextInput = {
        ...createActionItemNoteInput,
        body: generatedResponsesText,
    };
    const shouldAppendNewGeneratedNote = (0, helper_utils_2.doesActionItemNeedNewGeneratedCustomResponsesText)(previousActionItemNotes, generatedResponsesText);
    if (scheduledAppointmentDetails !== 'NOT_SET') {
        const scheduledAppointmentNoteText = generateScheduleAppointmentNoteText(scheduledAppointmentDetails);
        generatedResponsesText += '\n\n' + scheduledAppointmentNoteText;
    }
    if (shouldAppendNewGeneratedNote === true) {
        return [
            {
                ...generatedNoteTextInput,
                body: (0, helper_utils_2.getNoteBody)(noteText, generatedResponsesText),
            },
        ];
    }
    else {
        return [noteTextInput];
    }
}
exports.getCreateActionItemNoteMutationInputs = getCreateActionItemNoteMutationInputs;
function makeBaseCreateActionItemInputForTableConnections(jillId, callId) {
    return {
        author: {
            connect: {
                id: jillId,
            },
        },
        call: {
            connect: {
                id: callId === 'NOT_SET' ? null : callId,
            },
        },
    };
}
function generateScheduleAppointmentNoteText(scheduledAppointmentDetails) {
    return [
        `Appointment Scheduled`,
        `Calendar: ${scheduledAppointmentDetails.slot.calendar.name}`,
        `Date: ${(0, helper_utils_1.getDateAndTimeFromTime)(scheduledAppointmentDetails.start_datetime_string)
            .localeDateString}`,
        `Start Time: ${(0, helper_utils_1.getDateAndTimeFromTime)(scheduledAppointmentDetails.start_datetime_string)
            .localeTimeString}`,
        `End Time: ${(0, helper_utils_1.getDateAndTimeFromTime)(scheduledAppointmentDetails.end_datetime_string).localeTimeString}`,
    ].join('\n');
}
