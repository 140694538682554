"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {};
class JONewAlertsNav extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-new-alerts-nav-main-container {
                    box-shadow: 0 0 2px rgb(0, 0, 0, 0.5);
                    border-radius: 4px;
                    font-family: sans-serif;
                    width: 20rem;

                    /* grid-row-end: 23; */
                    /* height: 100%; */
                }

                .jo-new-alerts-nav-header-container {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--jo-accent-background-light);
                    padding: 0rem 1rem;
                }

                .jo-new-alerts-nav-icon {
                    color: var(--jo-secondary);
                    font-size: 3rem;
                    margin-top: 0.25rem;
                }

                .jo-new-alerts-nav-alerts-container {
                    overflow-y: scroll;
                }
            </style>

            <div class="jo-new-alerts-nav-main-container">
                <div class="jo-new-alerts-nav-header-container">
                    <h3>New Alerts</h3>
                    <span class="material-icons jo-new-alerts-nav-icon">error_outline</span>
                </div>
                <div class="jo-new-alerts-nav-alerts-container"></div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-new-alerts-nav', JONewAlertsNav);
