"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOHomeSubscribable = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-loading");
require("./jo-home-action-items-table/jo-home-action-items-table");
require("./jo-home-call-queue-card");
require("./jo-home-high-level-stats-card.element");
require("./jo-home-jill-stats-card/jo-home-jill-stats-card.element");
require("./jo-home-new-alerts-nav");
require("./jo-home-training-tip");
const createJOHomeSubsribable = () => {
    const subscriptionCallbacks = new Set();
    return {
        subscribe(callback) {
            subscriptionCallbacks.add(callback);
            return () => {
                subscriptionCallbacks.delete(callback);
            };
        },
        executeSubscriberCallbacks() {
            subscriptionCallbacks.forEach(callback => callback());
        },
    };
};
exports.JOHomeSubscribable = createJOHomeSubsribable();
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    loading: {
        actionItemsAssignedToJillsTableLoading: true,
        joHomeCallbacksTableLoading: true,
    },
    testStateProperty: [],
};
class JOHome extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.ignoreCertainLoadingPropertiesIfNecessary();
    }
    set loading(loading) {
        if (this.store.loading === loading) {
            return;
        }
        this.store.loading = loading;
        if ((0, utilities_1.isLoading)(this.store.loading) === false) {
            (0, utilities_1.raiseCustomEvent)(this, 'jo-home-loaded', true);
        }
    }
    ignoreCertainLoadingPropertiesIfNecessary() {
        if (!(0, utilities_1.isInAuthRole)([
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], this.store.authenticatedUser))
            if (!(0, auth_rules_1.isJillAtOrAboveAuthRole)(this.store.authenticatedUser, 'JILL_OUTBOUND'))
                this.ignoreWaitingForLoadingProperty('actionItemsAssignedToJillsTableLoading');
    }
    ignoreWaitingForLoadingProperty(loadingPropertyName) {
        this.loading = {
            ...this.store.loading,
            [loadingPropertyName]: false,
        };
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-home--main-container {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                }

                .jo-home--info-cards-container {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-evenly;
                    flex-wrap: wrap;
                }

                .jo-home--info-card-container {
                    box-sizing: border-box;
                    align-self: stretch;
                    padding-bottom: 1rem;
                }

                .jo-home--table-container {
                    font-size: 0.9rem;
                    padding-bottom: 1rem;
                }
            </style>

            <div class="jo-home--main-container">
                <div class="jo-home--info-cards-container">
                    <div class="jo-home--info-card-container">
                        <jo-home-jill-stats-card
                            .authenticatedUser=${state.authenticatedUser}
                        ></jo-home-jill-stats-card>
                    </div>

                    ${(0, utilities_1.authorizedRender)([
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser, (0, lit_html_1.html) `
                            <div class="jo-home--info-card-container">
                                <jo-home-high-level-stats-card
                                    .authenticatedUser=${state.authenticatedUser}
                                ></jo-home-high-level-stats-card>
                            </div>
                        `)}

                    <div class="jo-home--info-card-container">
                        <jo-call-queue-card
                            .authenticatedUser=${state.authenticatedUser}
                        ></jo-call-queue-card>
                    </div>
                </div>

                <div class="jo-home--table-container">
                    <jo-home-action-items-table
                        .props=${{
            authenticatedUser: state.authenticatedUser,
            tableType: 'CALLBACKS',
        }}
                    ></jo-home-action-items-table>
                </div>

                ${isAllowedToSeeActionItemsAssignedToJillsTable(state.authenticatedUser)
            ? (0, lit_html_1.html) `
                          <div class="jo-home--table-container">
                              <jo-home-action-items-table
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
                tableType: 'ASSIGNED_TO_JILLS_OFFICE',
            }}
                              ></jo-home-action-items-table>
                          </div>
                      `
            : ''}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-home', JOHome);
function isAllowedToSeeActionItemsAssignedToJillsTable(user) {
    if (user === 'NOT_SET')
        return false;
    return (0, auth_rules_1.isJillAtOrAboveAuthRole)(user, 'JILL_OUTBOUND') || (0, auth_rules_1.is24_7Jill)(user);
}
