"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-input");
const InitialState = {
    allItemsChecked: false,
    columns: [],
    companyId: 'NOT_SET',
    currentPage: 1,
    customActionTemplate: () => null,
    isCompanyUser: false,
    items: [],
    maxItemsPerPage: 10,
    mobileTemplate: () => (0, lit_html_1.html) ``,
    mobileTableHeader: () => null,
    moveActionItemsSelectedOption: '',
    numPages: 1,
    showSearchBar: true,
    searchDebounce: 0,
    showCheckboxes: false,
    selectedActionItems: [],
    tableName: 'NOT_SET',
};
class JOMobileTable extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    truncateCustomFields(body) {
        return body?.split('Custom Fields')[0]?.substring(0, 100);
    }
    render(state) {
        return (0, lit_html_1.html) `
          <link rel="stylesheet" href="/styles/jo-styles.css">
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
          <style>
              .main-container {
                  height: 100%;
                  width: 100%;
                  box-sizing: border-box;
              }

              .jo-mobile-action-items--table-container {
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 0.9rem;
                    padding: 0.5rem;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                    margin: 0.25rem 0;
                }

              .jo-mobile-action-items--primary-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 0.25rem 1rem;
                padding-right: 0.25rem;
              }

              .jo-mobile-action-item--flex-box-row {
                display: flex;
                flex-direction: row;
                justify-content: ${state.showSearchBar ? `space-between` : 'flex-end'};
                align-items: flex-end;
                padding: 0.5rem;
              }
              .jo-mobile-action-item--check-wrapper {
                display: flex;
                align-items: center;
              }

              .jo-mobile-action-item--customer-name {
                font-weight: 900;
              }
              .jo-mobile-action-item--flex-row {
                display: flex;
                flex-direction: row;
              }

              .nav-clearance {
                min-width: 100%;
                display: block;
                height: 5rem;
              }

              .jo-mobile-action-item--page-numbers-container {
                    box-sizing: border-box;
                    padding-top: 1rem;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }

                .jo-mobile-action-item--page-number {
                    box-sizing: border-box;
                    height: 2rem;
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--jo-accent-background-dark);
                    border-radius: 2px;
                    color: var(--jo-primary);
                    margin-left: -1px;
                }

                .jo-mobile-action-item--page-number-selected {
                    background-color: var(--jo-primary);
                    box-shadow: var(--jo-box-shadow-2);
                    border: 1px solid var(--jo-primary);
                    color: white;
                    transform: scale(1.05);
                    font-weight: bold;
                }
                .jo-table--search-container-mobile {
                  width: 66%;
                }


          </style>

          <div class="main-container">
            <div class="jo-mobile-action-item--flex-box-row">
                <div class="jo-table--search-container-mobile" ?hidden=${!state.showSearchBar}>
                    <jo-input
                        .type=${'SEARCH'}
                        .debounce=${state.searchDebounce}
                        @inputchange=${(e) => (0, utilities_1.raiseCustomEvent)(this, 'searchTextChange', e.detail.inputValue)}
                    ></jo-input>
                </div>
                <div class="jo-mobile-action-item--flex-row">
                  ${state.customActionTemplate({
            items: state.items,
            isCompanyUser: state.isCompanyUser,
            companyId: state.companyId,
        })}
                </div>
              </div>
            </div>

            </div>
            <div class="mobile-table-header">
                ${state.mobileTableHeader()}
            </div>

            <div class="mapped-elements-wrapper">
              ${state.items.map(item => state.mobileTemplate({
            ...item,
            isCompanyUser: state.isCompanyUser,
            companyId: state.companyId,
        }))}
            </div>

            ${state.numPages > 1
            ? (0, lit_html_1.html) `
                          <div class="jo-mobile-action-item--page-numbers-container">
                              ${new Array(state.numPages).fill(0).map((_, index) => {
                const pageNumber = index + 1;
                return (0, lit_html_1.html) `
                                      <div
                                          class="jo-mobile-action-item--page-number ${state.currentPage ===
                    pageNumber
                    ? 'jo-mobile-action-item--page-number-selected'
                    : ''}"
                                          @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'newPageNumberClicked', index)}
                                      >
                                          ${pageNumber}
                                      </div>
                                  `;
            })}
                          </div>
                      `
            : (0, lit_html_1.html) `
                          <br />
                      `}
            <div class="nav-clearance"></div>
          </div>
      `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-mobile-table', JOMobileTable);
