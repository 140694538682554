"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableColumns = void 0;
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../../services/utilities");
function getTableColumns(state, store) {
    const columns = [
        {
            title: 'Actions',
            getCellData: (actionItem) => {
                return makeActionButtons(actionItem, state, store);
            },
            width: 1,
        },
        {
            title: 'Company Name',
            getCellData: (callback) => callback.company?.name,
            width: 1,
        },
        {
            title: 'Pod',
            getCellData: (callback) => callback.company?.pod?.name,
            width: 1,
        },
        {
            title: 'Timezone',
            getCellData: (callback) => callback.company?.timezone,
            width: 1,
        },
        {
            title: 'Contact First Name',
            getCellData: (callback) => callback.contact?.first_name,
            width: 1,
        },
        {
            title: 'Contact Last Name',
            getCellData: (callback) => callback.contact?.last_name,
            width: 1,
        },
        {
            title: 'Contact Phone Number',
            getCellData: (callback) => callback.contact?.phone_number,
            width: 1.5,
        },
        {
            title: 'Updated',
            getCellData: (callback) => new Date(callback.updated_at).toLocaleString(),
            width: 1,
        },
        {
            title: 'Created',
            getCellData: (callback) => new Date(callback.created_at).toLocaleString(),
            width: 1,
        },
    ];
    return columns;
}
exports.getTableColumns = getTableColumns;
function makeActionButtons(actionItem, state, store) {
    return (0, lit_html_1.html) `
        <div class="jo-home-callbacks-table--actions">
            <jo-button
                class="jo-home-callbacks-table--action"
                .type=${'ICON'}
                .icon=${'remove_red_eye'}
                .href=${`/action-item?actionItemId=${actionItem.id}&companyId=${actionItem.company?.id}&callScreenMode=ACTION_ITEM`}
            ></jo-button>

            <jo-button
                class="jo-home-callbacks-table--action"
                .type=${'ICON'}
                .icon=${'phone'}
                @joButtonClick=${() => (0, utilities_1.startOutgoingCallForActionItem)(actionItem, state.authenticatedUser)}
            ></jo-button>

            <div class="jo-home-callbacks-table--action-item-preview-main-container">
                <jo-button
                    class="jo-home-callbacks-table--action"
                    .type=${'ICON'}
                    .icon=${'preview'}
                    @joButtonClick=${() => {
        if (store.selectedPreviewActionItemId === actionItem.id) {
            store.selectedPreviewActionItemId = 'NOT_SET';
        }
        else {
            store.selectedPreviewActionItemId = actionItem.id;
        }
    }}
                ></jo-button>

                <jo-fade-in-overlay
                    .child=${(0, lit_html_1.html) `
                        <div class="jo-global--action-item-preview-container">
                            <jo-action-item-preview
                                .props=${{
        actionItem: actionItem,
        authenticatedUser: state.authenticatedUser,
        quickNotes: state.quickNotes,
    }}
                                @saved=${() => (store.selectedPreviewActionItemId = 'NOT_SET')}
                                @senttocompany=${() => (store.selectedPreviewActionItemId = 'NOT_SET')}
                            ></jo-action-item-preview>
                        </div>
                    `}
                    .showChild=${actionItem.id === state.selectedPreviewActionItemId}
                    @closeoverlay=${() => (store.selectedPreviewActionItemId = 'NOT_SET')}
                ></jo-fade-in-overlay>
            </div>
        </div>
    `;
}
