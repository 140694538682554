"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findChatAssignedToJillDurationEventsBasedOnChatId = exports.createChatDurationEvent = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function createChatDurationEvent(params) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $userId: Int!
                    $chatId: Int!
                    $type: CHAT_DURATION_EVENTS_TYPE!
                    $timestamp: DateTime!
                ) {
                    createChat_duration_events(
                        input: {
                            type: $type
                            user: {connect: {id: $userId}}
                            chat: {connect: {id: $chatId}}
                            timestamp: $timestamp
                        }
                    ) {
                        id
                    }
                }
            `, {
            userId: params.userId,
            chatId: params.chatId,
            type: params.type,
            timestamp: new Date(),
        });
    }
    catch (error) {
        throw new Error(`Error creating chat duration event of type ${params.type} jo-chat -> chat-utils -> chat-duration-event-utils.ts  createChatDurationEvent() ` +
            error);
    }
}
exports.createChatDurationEvent = createChatDurationEvent;
async function findChatAssignedToJillDurationEventsBasedOnChatId(chatId) {
    try {
        const chatDurationEvents = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($chatId: Int!) {
                    findChat_duration_events(
                        filter: {and: [{chat_id: {eq: $chatId}}, {type: {eq: "ASSIGNED_TO_JILL"}}]}
                        orderBy: {field: "timestamp", order: ASC}
                    ) {
                        items {
                            id
                            timestamp
                            type
                            chat {
                                id
                            }
                        }
                    }
                }
            `, {
            chatId,
        });
        return chatDurationEvents.data.findChat_duration_events.items;
    }
    catch (error) {
        throw new Error(`Error finding chat duration events based on chat id jo-chat -> chat-utils -> chat-duration-event-utils.ts  findChatAssignedToJillDurationEventsBasedOnChatId() ` +
            error);
    }
}
exports.findChatAssignedToJillDurationEventsBasedOnChatId = findChatAssignedToJillDurationEventsBasedOnChatId;
