"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCloseSubscriptionsIfNecessaryAction = void 0;
function createCloseSubscriptionsIfNecessaryAction(subscriptions) {
    Object.values(subscriptions).forEach((subscription) => {
        if (subscription && subscription !== 'NOT_SET') {
            subscription.close();
        }
    });
    return {
        succeeded: true,
        value: {
            type: 'CLOSE_SUBSCRIPTIONS_IF_NECESSARY',
        },
    };
}
exports.createCloseSubscriptionsIfNecessaryAction = createCloseSubscriptionsIfNecessaryAction;
