"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../../services/graphql");
const utilities_1 = require("../../../../services/utilities");
const add_to_twilio_conversation_1 = require("../../../../utilities/chats/add-to-twilio-conversation");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const close_subscription_1 = require("../../../../utilities/subscriptions/close-subscription");
require("../../jo-button.element");
const state_1 = require("../../jo-call-screen/redux/state");
require("../../jo-input");
require("./jo-chat-message");
const queries_and_mutations_1 = require("./queries-and-mutations");
const styles_1 = require("./styles");
const create_element_bound_interval_1 = require("../../../../utilities/timeouts/create-element-bound-interval");
const dates_and_times_1 = require("../../../../utilities/dates-and-times/dates-and-times");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    chatId: 'NOT_SET',
    chatMessengerInput: '',
    contactTypingSubscription: 'NOT_SET',
    contactLastSeenTyping: null,
    companyId: 'NOT_SET',
    isLastMessageFromContact: false,
    messages: [],
    newChatMessageFromContactSubscription: 'NOT_SET',
    quickNotes: state_1.InitialJOCallScreenState['chatMessengerQuickNotes'],
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-messenger-jill');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOChatMessengerJill extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, (state, action) => {
            if (action.type === 'SET_PROPS') {
                return (0, utilities_1.setPropsReducer)(state, action);
            }
            return state;
        });
    }
    connectedCallback() {
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: () => {
                this.store.dispatch({
                    type: 'RENDER',
                });
            },
            element: this,
            intervalMs: dates_and_times_1.oneSecondInMilliseconds,
            intervalName: 're-render all the time',
        });
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const previousChatId = this.store.chatId;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.chatId !== 'NOT_SET' && previousChatId !== this.store.chatId) {
            this.closeSubscriptions();
            this.createSubscriptions();
            this.fetchAndSetSortedMessages();
        }
    }
    createSubscriptions() {
        if (this.store.chatId !== 'NOT_SET') {
            this.subscribeToNewMessageFromContactInChat(this.store.chatId);
            this.subscribeToJillTypingEvent(this.store.chatId);
        }
    }
    closeSubscriptions() {
        (0, close_subscription_1.closeSubscription)(this.store.newChatMessageFromContactSubscription);
        (0, close_subscription_1.closeSubscription)(this.store.contactTypingSubscription);
    }
    async subscribeToNewMessageFromContactInChat(chatId) {
        try {
            this.store.newChatMessageFromContactSubscription =
                (await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($chatId: Int!) {
                            chatMessageCreatedByContact(chatId: $chatId)
                        }
                    `, payload => {
                    this.store.isLastMessageFromContact = true;
                    this.fetchAndSetSortedMessages();
                }, {
                    chatId,
                })) ?? 'NOT_SET';
        }
        catch (error) {
            throw new Error(`subscribeToNewMessageFromContactInChat error: ${error}`);
        }
    }
    async subscribeToJillTypingEvent(chatId) {
        try {
            this.store.contactTypingSubscription =
                (await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($chatId: Int!) {
                            chatTypingContact(chatId: $chatId)
                        }
                    `, data => {
                    this.store.contactLastSeenTyping = new Date();
                }, {
                    chatId,
                })) ?? 'NOT_SET';
        }
        catch (error) {
            throw new Error('subscribeToJillTypingEvent Error:' + error);
        }
    }
    async fetchAndSetSortedMessages() {
        if (this.store.chatId !== 'NOT_SET') {
            const resultList = await (0, queries_and_mutations_1.getSortedMessages)(this.store.chatId);
            (0, utilities_1.assertSucceeded)(resultList, utilities_1.handleError, this, 'getSortedMessages()');
            this.store.messages = resultList.value;
            scrollMessengerContainer();
        }
    }
    async handleSendChatClickEvent() {
        if (this.store.chatMessengerInput.trim().length > 0 &&
            this.store.chatId !== 'NOT_SET' &&
            this.store.authenticatedUser !== 'NOT_SET' &&
            this.store.companyId !== 'NOT_SET') {
            const chatId = this.store.chatId;
            const userId = this.store.authenticatedUser.id;
            const body = this.store.chatMessengerInput;
            await (0, queries_and_mutations_1.sendChatMessage)({
                chatId,
                userId,
                body,
                companyId: this.store.companyId,
            });
            await (0, add_to_twilio_conversation_1.addMessageToTwilioConversationIfNecessary)({
                chatId,
                userId,
                body,
            });
            this.store.chatMessengerInput = '';
            (0, utilities_1.raiseCustomEvent)(this, 'chatMessengerInputChanged', '');
        }
        this.store.isLastMessageFromContact = false;
        this.fetchAndSetSortedMessages();
    }
    async handleJillTypingEvent(isTyping) {
        if (this.store.chatId !== 'NOT_SET') {
            await (0, queries_and_mutations_1.sendJillTyping)(this.store.chatId, isTyping);
        }
    }
    wasContactLastSeenTypingWithinXMilisecondsAgo(milisecondsAgo) {
        if (this.store.contactLastSeenTyping === null ||
            this.store.isLastMessageFromContact === true) {
            return false;
        }
        const now = new Date();
        const milisecondsDiff = now.getTime() - this.store.contactLastSeenTyping.getTime();
        if (milisecondsDiff <= milisecondsAgo) {
            return true;
        }
        return false;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForJoChatMessengerJill}

            <div class="${cssNames('main-container')}">
                <section
                    class="jo-global--card ${cssNames('conversation-container')}"
                    id="conversationContainer"
                >
                    ${this.store.messages.length > 0
            ? this.store.messages.map(message => {
                if (message.source === 'CUSTOMER_WIDGET' ||
                    message.source === 'CUSTOMER_SMS') {
                    return (0, lit_html_1.html) `
                                      <div class="${cssNames('incoming-message')}">
                                          <jo-chat-message
                                              .authenticatedUser=${state.authenticatedUser}
                                              .message=${message}
                                              .author=${getCustomerName(message)}
                                              .isIncoming=${true}
                                          ></jo-chat-message>
                                      </div>
                                  `;
                }
                if (message.source === 'JILL_DASHBOARD' ||
                    message.source === 'SYSTEM') {
                    return (0, lit_html_1.html) `
                                      <div class="${cssNames('outgoing-message')}">
                                          <jo-chat-message
                                              .authenticatedUser=${state.authenticatedUser}
                                              .message=${message}
                                              .author=${'You'}
                                              .isIncoming=${false}
                                          ></jo-chat-message>
                                      </div>
                                  `;
                }
            })
            : (0, lit_html_1.html) `
                              <!-- No Chats exist -->
                          `}
                    ${this.wasContactLastSeenTypingWithinXMilisecondsAgo(3000)
            ? (0, lit_html_1.html) `
                              ${scrollMessengerContainer()}
                              <div class="${cssNames('dots-cont')}">
                                  <div class=" ${cssNames('dot dot-1')}"></div>
                                  <div class=" ${cssNames('dot dot-2')}"></div>
                                  <div class=" ${cssNames('dot dot-3')}"></div>
                              </div>
                          `
            : ``}
                </section>

                <section class="${cssNames('text-container')}">
                    <textarea
                        class="${cssNames('text-area')} jo-global--text-area"
                        type="text"
                        autocomplete="off"
                        @input=${(e) => {
            this.store.chatMessengerInput = e.target.value;
            (0, utilities_1.raiseCustomEvent)(this, 'chatMessengerInputChanged', e.target.value);
        }}
                        @focus=${() => {
            this.handleJillTypingEvent(true);
        }}
                        @blur=${() => {
            this.handleJillTypingEvent(false);
        }}
                        .value=${this.store.chatMessengerInput}
                    ></textarea>
                    <div class="${cssNames('icon-container')}">
                        <jo-call-screen-quick-notes
                            .notes=${state.quickNotes}
                            .iconSizePx=${'24px'}
                            @quicknote=${(e) => {
            const noteText = `${this.store.chatMessengerInput}${e.detail}`;
            this.store.chatMessengerInput = noteText;
        }}
                        ></jo-call-screen-quick-notes>

                        <i
                            class="material-icons ${cssNames('action-icon')}"
                            style="font-size: 1.5rem"
                            @click=${() => {
            this.handleSendChatClickEvent();
        }}
                        >
                            send
                        </i>
                    </div>
                </section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-messenger-jill', JOChatMessengerJill);
function scrollMessengerContainer() {
    const chatContainerElement = document.getElementById('conversationContainer');
    if (chatContainerElement) {
        chatContainerElement.scrollTop = chatContainerElement.scrollHeight + 100;
    }
}
function getCustomerName(message) {
    if (!message.contact)
        return 'Them';
    if (message.contact.first_name && message.contact.last_name) {
        return message.contact.first_name + ' ' + message.contact.last_name?.substring(0, 1) + '.';
    }
    return 'Them';
}
