"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const element_vir_1 = require("element-vir");
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
require("../jo-input");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../directives/test-id.directive");
const jo_address_auto_complete_options_element_1 = require("./jo-address-auto-complete-options.element");
const InitialState = {
    address: 'NOT_SET',
    authenticatedUser: undefined,
    addressInputValues: {
        addressId: -1,
        line1InputValue: '',
        line2InputValue: '',
        cityInputValue: '',
        countyInputValue: '',
        postalCodeInputValue: '',
        provinceInputValue: '',
        stateInputValue: '',
        zipCodeInputValue: '',
        zipCodeValid: false,
        formErrors: [],
    },
    internalInputElement: undefined,
    addressAppear: true,
    addressRequire: false,
    checkCounty: false,
    checkZipCode: false,
    cityAppear: true,
    cityRequire: false,
    countyAppear: true,
    countyRequire: false,
    postalCodeAppear: true,
    postalCodeRequire: false,
    provinceAppear: true,
    provinceRequire: false,
    serviceAreaCounties: '',
    serviceAreaZipCodes: '',
    stateAppear: true,
    stateRequire: false,
    zipCodeAppear: true,
    zipCodeRequire: false,
};
class JOAddress extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.autoCompleteElementId = `auto-complete-id-${Math.random()}`;
    }
    set props(props) {
        const originalAddress = this.store.address;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.address !== originalAddress) {
            if (this.store.address === 'NOT_SET') {
                this.addressInputValues = InitialState.addressInputValues;
            }
            else {
                this.store.addressInputValues = {
                    addressId: this.store.address.id,
                    line1InputValue: this.store.address.line_1,
                    line2InputValue: this.store.address.line_2,
                    cityInputValue: this.store.address.city,
                    countyInputValue: this.store.address.county,
                    postalCodeInputValue: this.store.address.postal_code,
                    provinceInputValue: this.store.address.province,
                    zipCodeInputValue: this.store.address.zip_code,
                    stateInputValue: this.store.address.state,
                    zipCodeValid: (0, utilities_1.checkIfZipCodeIsValid)(this.store.addressInputValues.zipCodeInputValue, this.store.serviceAreaZipCodes),
                    formErrors: [],
                };
            }
        }
    }
    set addressInputValues(addressInputValues) {
        this.store.addressInputValues = addressInputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'inputvalueschanged', addressInputValues);
    }
    handlePlaceSelected(selectedParts) {
        if (selectedParts === undefined) {
            return;
        }
        const { addressLine1, city, state, country, postalCode } = selectedParts;
        if (country === 'US') {
            this.store.addressInputValues = {
                ...this.store.addressInputValues,
                line1InputValue: addressLine1,
                cityInputValue: city,
                stateInputValue: state,
                zipCodeInputValue: postalCode,
                postalCodeInputValue: '',
                provinceInputValue: '',
            };
        }
        if (country === 'CA') {
            this.store.addressInputValues = {
                ...this.store.addressInputValues,
                line1InputValue: addressLine1,
                cityInputValue: city,
                provinceInputValue: state,
                postalCodeInputValue: postalCode,
                stateInputValue: '',
                zipCodeInputValue: '',
            };
        }
    }
    render(state) {
        const styles = (0, element_vir_1.css) `
            .jo-address--main-container {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }

            .jo-address--input-container {
                position: relative;
                padding-top: 5px;
                padding-bottom: 5px;
                box-sizing: border-box;
                width: 100%;
            }

            .jo-address--zip-code-container {
                display: flex;
                position: relative;
            }

            .jo-address--zip-code-input {
                width: 100%;
            }

            .jo-address--zip-code-check-indicator {
                position: absolute;
                right: 5px;
                top: 30px;
            }

            ${jo_address_auto_complete_options_element_1.JoAddressAutoCompleteOptions} {
                width: 100%;
                z-index: 9999;
                position: absolute;
            }
        `;
        return (0, element_vir_1.html) `
            <style>
                ${styles}
            </style>

            <div class="jo-address--main-container">
                <div class="jo-address--input-container" ?hidden=${state.addressAppear === false}>
                    <jo-input
                        ${(0, element_vir_1.onDomCreated)(element => {
            const maybeInputElement = element.querySelector('input');
            if (!(maybeInputElement instanceof HTMLInputElement)) {
                throw new Error(`Failed to find nested input element`);
            }
            this.store.internalInputElement = maybeInputElement;
        })}
                        ${(0, test_id_directive_1.testId)('jo-address-line-one-input')}
                        .htmlId=${this.autoCompleteElementId}
                        .label=${'Address line 1'}
                        .type=${'TEXT_INPUT'}
                        .inputValue=${state.addressInputValues.line1InputValue}
                        .formErrors=${state.addressInputValues.formErrors}
                        .showRequiredIcon=${state.addressRequire === true &&
            state.addressInputValues.line1InputValue === ''}
                        .requiredIconType=${'EXPLICIT'}
                        @inputchange=${(event) => {
            this.addressInputValues = {
                ...this.store.addressInputValues,
                line1InputValue: event.detail.inputValue,
                formErrors: event.detail.formErrors,
            };
        }}
                    ></jo-input>
                    <${jo_address_auto_complete_options_element_1.JoAddressAutoCompleteOptions}
                        ${(0, element_vir_1.assign)(jo_address_auto_complete_options_element_1.JoAddressAutoCompleteOptions, {
            query: state.addressInputValues.line1InputValue,
            inputElement: state.internalInputElement,
            authenticatedUser: state.authenticatedUser,
        })}
                        ${(0, element_vir_1.listen)(jo_address_auto_complete_options_element_1.JoAddressAutoCompleteOptions.events.optionSubmitted, event => {
            this.handlePlaceSelected(event.detail);
        })}
                    ></${jo_address_auto_complete_options_element_1.JoAddressAutoCompleteOptions}>
                </div>

                <div class="jo-address--input-container" ?hidden=${state.addressAppear === false}>
                    <jo-input
                        ${(0, test_id_directive_1.testId)('jo-address-line-two-input')}
                        .label=${'Address line 2'}
                        .type=${'TEXT_INPUT'}
                        .inputValue=${state.addressInputValues.line2InputValue}
                        .formErrors=${state.addressInputValues.formErrors}
                        @inputchange=${(e) => {
            this.addressInputValues = {
                ...this.store.addressInputValues,
                line2InputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                    ></jo-input>
                </div>

                <div class="jo-address--input-container" ?hidden=${state.cityAppear === false}>
                    <jo-input
                        ${(0, test_id_directive_1.testId)('jo-address-city-input')}
                        .label=${'City'}
                        .type=${'TEXT_INPUT'}
                        .inputValue=${state.addressInputValues.cityInputValue}
                        .formErrors=${state.addressInputValues.formErrors}
                        .showRequiredIcon=${state.cityRequire === true &&
            state.addressInputValues.cityInputValue === ''}
                        .requiredIconType=${'EXPLICIT'}
                        @inputchange=${(e) => {
            this.addressInputValues = {
                ...this.store.addressInputValues,
                cityInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                    ></jo-input>
                </div>

                <div class="jo-address--input-container" ?hidden=${state.stateAppear === false}>
                    <jo-input
                        ${(0, test_id_directive_1.testId)('jo-address-state-input')}
                        .label=${'State'}
                        .type=${'TEXT_INPUT'}
                        .inputValue=${state.addressInputValues.stateInputValue}
                        .formErrors=${state.addressInputValues.formErrors}
                        .showRequiredIcon=${state.stateRequire === true &&
            state.addressInputValues.stateInputValue === ''}
                        .requiredIconType=${'EXPLICIT'}
                        @inputchange=${(e) => {
            this.addressInputValues = {
                ...this.store.addressInputValues,
                stateInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                    ></jo-input>
                </div>

                <div
                    class="jo-address--input-container jo-address--zip-code-container"
                    ?hidden=${state.zipCodeAppear === false}
                >
                    <jo-input
                        ${(0, test_id_directive_1.testId)('jo-address-zip-code-input')}
                        class="jo-address--zip-code-input"
                        .label=${'Zip'}
                        .type=${'TEXT_INPUT'}
                        .inputValue=${state.addressInputValues.zipCodeInputValue}
                        .formErrors=${state.addressInputValues.formErrors}
                        .showRequiredIcon=${state.zipCodeRequire === true &&
            state.addressInputValues.zipCodeInputValue === ''}
                        .requiredIconType=${'EXPLICIT'}
                        @inputchange=${(e) => {
            this.addressInputValues = {
                ...this.store.addressInputValues,
                zipCodeInputValue: e.detail.inputValue,
                zipCodeValid: (0, utilities_1.checkIfZipCodeIsValid)(e.detail.inputValue, this.store.serviceAreaZipCodes),
                formErrors: e.detail.formErrors,
            };
        }}
                    ></jo-input>
                    <i
                        class="material-icons jo-address--zip-code-check-indicator"
                        style="${state.checkZipCode === false
            ? 'display: none;'
            : 'display: block;'}${state.addressInputValues.zipCodeValid === true
            ? 'color: green;'
            : 'color: red;'}"
                    >
                        ${state.addressInputValues.zipCodeValid === true ? 'done' : 'clear'}
                    </i>
                </div>

                <div
                    class="jo-address--input-container jo-address--zip-code-container"
                    ?hidden=${state.countyAppear === false}
                >
                    ${state.checkCounty === true
            ? (0, element_vir_1.html) `
                              <jo-input
                                  class="jo-address--zip-code-input"
                                  .label=${'County'}
                                  .type=${'SELECT'}
                                  .selectedValue=${state.addressInputValues.countyInputValue}
                                  .selectOptions=${state.serviceAreaCounties === ''
                ? []
                : state.serviceAreaCounties.split(',')}
                                  .defaultOption=${'Select county...'}
                                  .formErrors=${state.addressInputValues.formErrors}
                                  .showRequiredIcon=${state.countyRequire === true &&
                state.addressInputValues.countyInputValue === ''}
                                  .requiredIconType=${'EXPLICIT'}
                                  @inputchange=${(e) => {
                this.addressInputValues = {
                    ...this.store.addressInputValues,
                    countyInputValue: e.detail.selectedValue,
                    formErrors: e.detail.formErrors,
                };
            }}
                              ></jo-input>
                          `
            : (0, element_vir_1.html) `
                              <jo-input
                                  class="jo-address--zip-code-input"
                                  .label=${'County'}
                                  .type=${'TEXT_INPUT'}
                                  .inputValue=${state.addressInputValues.countyInputValue}
                                  .formErrors=${state.addressInputValues.formErrors}
                                  .showRequiredIcon=${state.countyRequire === true &&
                state.addressInputValues.countyInputValue === ''}
                                  .requiredIconType=${'EXPLICIT'}
                                  @inputchange=${(e) => {
                this.addressInputValues = {
                    ...this.store.addressInputValues,
                    countyInputValue: e.detail.inputValue,
                    formErrors: e.detail.formErrors,
                };
            }}
                              ></jo-input>
                          `}
                </div>

                <div
                    class="jo-address--input-container"
                    ?hidden=${state.postalCodeAppear === false}
                >
                    <jo-input
                        .label=${'Postal Code'}
                        .type=${'TEXT_INPUT'}
                        .inputValue=${state.addressInputValues.postalCodeInputValue}
                        .formErrors=${state.addressInputValues.formErrors}
                        .showRequiredIcon=${state.postalCodeRequire === true &&
            state.addressInputValues.postalCodeInputValue === ''}
                        .requiredIconType=${'EXPLICIT'}
                        @inputchange=${(e) => {
            this.addressInputValues = {
                ...this.store.addressInputValues,
                postalCodeInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                    ></jo-input>
                </div>

                <div class="jo-address--input-container" ?hidden=${state.provinceAppear === false}>
                    <jo-input
                        .label=${'Province'}
                        .type=${'TEXT_INPUT'}
                        .inputValue=${state.addressInputValues.provinceInputValue}
                        .formErrors=${state.addressInputValues.formErrors}
                        .showRequiredIcon=${state.provinceRequire === true &&
            state.addressInputValues.provinceInputValue === ''}
                        .requiredIconType=${'EXPLICIT'}
                        @inputchange=${(e) => {
            this.addressInputValues = {
                ...this.store.addressInputValues,
                provinceInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                    ></jo-input>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-address', JOAddress);
