"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOHomeActionItemsSubscriptionsManager = void 0;
const graphql_1 = require("../../../../services/graphql");
const utilities_1 = require("../../../../services/utilities");
class JOHomeActionItemsSubscriptionsManager {
    constructor(subscriptionCallback, tableType) {
        this.subscriptionCallback = subscriptionCallback;
        this.tableType = tableType;
        this.subscriptions = {
            actionItemCallbackCreated: null,
            actionItemCallbackUpdated: null,
            actionItemCallbackMoved: null,
        };
    }
    async createSubscriptions() {
        await Promise.all([
            this.createActionItemCreatedSubscription(),
            this.createActionItemCallbackUpdatedSubscription(),
            this.createActionItemCallbackMovedSubscription(),
        ]);
    }
    closeSubscriptions() {
        Object.values(this.subscriptions).forEach(subscription => {
            if (subscription) {
                subscription.close();
            }
        });
    }
    async createActionItemCreatedSubscription() {
        const subscriptionName = getActionItemChangedSubscriptionName(this.tableType, 'CREATED');
        const subsriptionString = `
            subscription {
                ${subscriptionName}
            }
        `;
        this.subscriptions.actionItemCallbackCreated =
            (await (0, graphql_1.gqlSubscription)(subsriptionString, this.subscriptionCallback)) ?? null;
    }
    async createActionItemCallbackUpdatedSubscription() {
        const subscriptionName = getActionItemChangedSubscriptionName(this.tableType, 'UPDATED');
        const subscriptionString = `
            subscription {
                ${subscriptionName}
            }
        `;
        this.subscriptions.actionItemCallbackUpdated =
            (await (0, graphql_1.gqlSubscription)(subscriptionString, this.subscriptionCallback)) ?? null;
    }
    async createActionItemCallbackMovedSubscription() {
        const statusTypeId = this.tableType === 'CALLBACKS' ? 4 : 3;
        const subscriptionString = `
            subscription {
                actionItemsMovedInCategory(actionItemStatusTypeId: ${statusTypeId})
            }
        `;
        this.subscriptions.actionItemCallbackMoved =
            (await (0, graphql_1.gqlSubscription)(subscriptionString, this.subscriptionCallback)) ?? null;
    }
}
exports.JOHomeActionItemsSubscriptionsManager = JOHomeActionItemsSubscriptionsManager;
function getActionItemChangedSubscriptionName(tableType, crudType) {
    const prefix = tableType === 'CALLBACKS' ? 'actionItemCallback' : 'actionItemAssignedToJillsOffice';
    const suffix = (0, utilities_1.capitalizeFirstLetter)(crudType.toLowerCase());
    const subscriptionName = `${prefix}${suffix}`;
    return subscriptionName;
}
