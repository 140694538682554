"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const common_1 = require("@augment-vir/common");
const elementStyle = (0, lit_html_1.html) `
    <link rel="stylesheet" href="/styles/jo-styles.css" />
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <style>
        :host {
            padding: 10px;
            color: #686968;
            font-family: sans-serif;
            font-size: 14px;
        }

        :host(.selected-contact) {
            border: 2px solid var(--jo-primary);
            border-radius: 4px;
            margin: 6px;
            padding: 2px;
        }

        .contact-card-name {
            font-weight: bold;
            font-size: 14px;
        }

        .contact-card-phone-number {
            padding-top: 3px;
            padding-bottom: 3px;
            font-size: 13px;
        }

        .contact-card-phone-number-title {
            text-decoration: underline;
            font-size: 14px;
        }

        .contact-card-address {
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 13px;
        }

        .contact-card-address-title {
            text-decoration: underline;
            font-size: 14px;
        }

        .contact-card-icons-container {
            display: flex;
        }

        .contact-card-icon-container {
            padding-top: 5px;
        }

        .contact-card-icon-container + .contact-card-icon-container {
            margin-left: 10px;
        }

        @media screen and (max-width: 844px) {
            .contact-card-icon {
                font-size: 3vmin;
            }
        }

        .contact-card-icon {
            padding: 3px;
            background-color: var(--jo-primary);
            color: white;
            border-radius: 2px;
            cursor: pointer;
            font-size: 18px;
        }
        .contact-phone-icon {
            color: var(--jo-primary);
            font-size: 12px;
        }
        .contact-address-icon {
            color: var(--jo-primary);
            font-size: 14px;
        }
        .jo-contact-email {
            font-size: 13px;
        }
    </style>
`;
class JOContactSearchContactCard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)({
            contact: undefined,
            canAssignNewContactToActionItem: false,
            contactIsSelected: false,
            canMergeContacts: false,
        }, state => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        const contact = state.contact;
        if (!contact) {
            return (0, lit_html_1.html) ``;
        }
        if (state.contactIsSelected) {
            this.classList.add('selected-contact');
        }
        else {
            this.classList.remove('selected-contact');
        }
        return (0, lit_html_1.html) `
            ${elementStyle}
            <div class="contact-card-name">
                ${contact.first_name ?? ''} ${contact.last_name ?? ''}
            </div>
            <div>${createPhoneNumbersTemplate(contact.phone_numbers)}</div>
            <div>${createAddressesTemplate(contact.addresses)}</div>
            <div class="jo-contact-email">${contact.email ?? ''}</div>

            <div class="contact-card-icons-container">
                ${createContactCardIcons({
            canAssignNewContactToActionItem: state.canAssignNewContactToActionItem,
            elementRoot: this,
            contact,
            selected: state.contactIsSelected,
            canMergeContacts: state.canMergeContacts,
        })}
            </div>
        `;
    }
}
function createContactCardIcons(contactCardIconsInputs) {
    const iconDefinitions = [
        {
            iconName: 'edit',
            visible: !contactCardIconsInputs.selected &&
                contactCardIconsInputs.canAssignNewContactToActionItem,
            clickEventName: 'contactselected',
            title: 'select contact',
        },
        {
            iconName: 'list',
            visible: true,
            clickEventName: 'contactactivitylogselected',
            title: 'show activity log',
        },
        {
            iconName: 'compare_arrows',
            visible: contactCardIconsInputs.canMergeContacts,
            clickEventName: 'contactcardmergeclicked',
            title: 'merge with this contact',
        },
    ];
    return iconDefinitions
        .filter(iconDefinition => iconDefinition.visible)
        .map(iconDefinition => {
        return (0, lit_html_1.html) `
                <div class="contact-card-icon-container">
                    <i
                        class="material-icons contact-card-icon"
                        title=${iconDefinition.title}
                        @click=${() => {
            (0, utilities_1.raiseCustomEvent)(contactCardIconsInputs.elementRoot, iconDefinition.clickEventName, contactCardIconsInputs.contact);
        }}
                    >
                        ${iconDefinition.iconName}
                    </i>
                </div>
            `;
    });
}
function createPhoneNumbersTemplate(phoneNumbers) {
    return phoneNumbers
        ?.map(phoneNumber => {
        if (!phoneNumber.number) {
            return undefined;
        }
        return (0, lit_html_1.html) `
                <div class="contact-card-phone-number">
                    <div>
                        <div class="material-icons contact-phone-icon">
                            <span>phone</span>
                        </div>
                        ${(0, utilities_1.formatE164PhoneNumberForDisplay)(phoneNumber.number)}
                    </div>
                </div>
            `;
    })
        .filter(common_1.isTruthy);
}
function createAddressesTemplate(addresses) {
    return addresses?.map(address => {
        return (0, lit_html_1.html) `
            <div class="contact-card-address">
                <div>
                    <span class="material-icons contact-address-icon">home</span>
                    ${address.line_1}
                </div>
                <div>${address.line_2}</div>
                <div>
                    ${address.city !== '' ? `${address.city},` : ''} ${address.state}
                    ${address.zip_code}
                </div>
            </div>
        `;
    });
}
(0, define_custom_element_1.defineCustomElement)('jo-contact-search-contact-card', JOContactSearchContactCard);
