"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchPreviousServiceResponses = exports.fetchPreviousLineItemResponses = exports.fetchPreviousCustomFieldResponses = exports.getCurrentCompanyServices = void 0;
const graphql_1 = require("../../../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../../../utilities/environment/mapped-env-variables");
async function getCurrentCompanyServices(companyId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($companyId: Int!) {
                getCompanies(id: $companyId) {
                    id
                    services(filter: {archived: {eq: false}}) {
                        id
                    }
                }
            }
        `, {
        companyId,
    });
    if (gqlResult.succeeded === false) {
        return {
            ...gqlResult,
            developerMessage: `getCurrentCompanyServices error: ${gqlResult.developerMessage}`,
        };
    }
    const companyServices = gqlResult.value.data.getCompanies.services;
    return {
        succeeded: true,
        value: companyServices,
    };
}
exports.getCurrentCompanyServices = getCurrentCompanyServices;
async function fetchPreviousCustomFieldResponses(contactId, currentActionItemId) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($contactId: Int!, $currentActionItemId: Int!) {
                Action_items__PreviousCustomFieldResponses: findAction_items(
                    filter: {id: {ne: $currentActionItemId}, contact_id: {eq: $contactId}}
                    orderBy: {field: "created_at", order: DESC}
                    page: {limit: 1}
                ) {
                    items {
                        id
                        custom_field_responses(orderBy: {field: "created_at", order: ASC}) {
                            id
                            custom_field {
                                id
                                select_values(orderBy: {field: "created_at", order: ASC}) {
                                    id
                                    text_value
                                }
                                text_value
                                type
                                archived
                            }
                            response
                            service {
                                id
                            }
                        }
                    }
                }
            }
        `, {
        contactId,
        currentActionItemId: currentActionItemId === 'NOT_SET' ? -1 : currentActionItemId,
    });
    if (result.succeeded === false) {
        return result;
    }
    const actionItems = result.value.data.Action_items__PreviousCustomFieldResponses.items;
    const firstActionItem = actionItems[0];
    const firstCustomFieldResponses = firstActionItem?.custom_field_responses ?? [];
    const firstCustomFieldResponsesWithIdsReplaced = firstCustomFieldResponses.map((customFieldResponse) => {
        return {
            ...customFieldResponse,
            id: -1,
        };
    });
    return {
        succeeded: true,
        value: firstCustomFieldResponsesWithIdsReplaced,
    };
}
exports.fetchPreviousCustomFieldResponses = fetchPreviousCustomFieldResponses;
async function fetchPreviousLineItemResponses(contactId, currentActionItemId) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($contactId: Int!, $currentActionItemId: Int!) {
                Action_items__PreviousLineItemResponses: findAction_items(
                    filter: {id: {ne: $currentActionItemId}, contact_id: {eq: $contactId}}
                    orderBy: {field: "created_at", order: DESC}
                    page: {limit: 1}
                ) {
                    items {
                        id
                        service_line_item_responses {
                            id
                            quantity
                            service_line_item {
                                id
                                service {
                                    id
                                }
                                archived
                            }
                        }
                    }
                }
            }
        `, {
        contactId,
        currentActionItemId: currentActionItemId === 'NOT_SET' ? -1 : currentActionItemId,
    });
    if (result.succeeded === false) {
        return result;
    }
    const actionItems = result.value.data.Action_items__PreviousLineItemResponses.items;
    const firstActionItem = actionItems[0];
    const firstLineItemResponses = firstActionItem?.service_line_item_responses ?? [];
    const firstLineItemResponsesWithIdsReplaced = firstLineItemResponses.map((lineItemResponse) => {
        return {
            ...lineItemResponse,
            id: -1,
        };
    });
    return {
        succeeded: true,
        value: firstLineItemResponsesWithIdsReplaced,
    };
}
exports.fetchPreviousLineItemResponses = fetchPreviousLineItemResponses;
async function fetchPreviousServiceResponses(contactId, currentActionItemId) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($contactId: Int!, $currentActionItemId: Int!) {
                Action_items__PreviousServiceResponses: findAction_items(
                    filter: {id: {ne: $currentActionItemId}, contact_id: {eq: $contactId}}
                    orderBy: {field: "created_at", order: DESC}
                    page: {limit: 1}
                ) {
                    items {
                        id
                        service_responses(orderBy: {field: "created_at", order: ASC}) {
                            id
                            service {
                                id
                                created_at
                                custom_fields(
                                    orderBy: {field: "created_at", order: ASC}
                                    filter: {archived: {eq: false}}
                                ) {
                                    id
                                    call_screen_appear
                                    call_screen_require
                                    select_values(orderBy: {field: "created_at", order: ASC}) {
                                        id
                                        text_value
                                    }
                                    text_value
                                    type
                                }
                                description
                                estimates
                                jobs
                                forms
                                line_items(
                                    orderBy: {field: "created_at", order: ASC}
                                    filter: {archived: {eq: false}}
                                ) {
                                    id
                                    calculate
                                    description
                                    price
                                    service {
                                        id
                                        name
                                    }
                                }
                                name
                                price_minimum
                                pricing
                                save_without_scheduling
                            }
                        }
                    }
                }
            }
        `, {
        contactId,
        currentActionItemId: currentActionItemId === 'NOT_SET' ? -1 : currentActionItemId,
    });
    if (result.succeeded === false) {
        return result;
    }
    const actionItems = result.value.data.Action_items__PreviousServiceResponses.items;
    const firstActionItem = actionItems[0];
    const firstServiceResponses = firstActionItem?.service_responses ?? [];
    return {
        succeeded: true,
        value: firstServiceResponses,
    };
}
exports.fetchPreviousServiceResponses = fetchPreviousServiceResponses;
